import React, { Component } from 'react'
// import styles from './home.module.scss'
import {
  Card, CardContent, Modal, Grid,
  Paper,
  Typography,
  Button
} from '@material-ui/core'
import Box from '@mui/material/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styles from './speedTestStatusModal.scss';
// import GitHubButton from 'react-github-btn'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setSpeedTestStatusModalDisplayed } from '../../actions/connectionActions'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'inherit',
  p: 4,
};


class SpeedTestStatusModal extends Component {
  handleClose = () => {
    this.props.setSpeedTestStatusModalDisplayed(false)
  }

  render() {
    console.log('status', this.props.speedTestStatusResults)
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open={this.props.speedTestStatusModalDisplayed}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // closeAfterTransition
      >
      <Box sx={style}>
        <Card className={styles.card2} style={{padding:'20px'}}>
          <center>
            <Typography className={ styles.title } component="h1" variant="h5">
              Status
            </Typography>
            <br />
            {!this.props.speedTestStatusResults ? 'Please wait...' : (
              this.props.speedTestStatusResults.map(r => (
                <div>
                  <Typography component="h6" variant="h6">
                    Computer: {r.host.split('__IMUP_DELIMITER__')[1]}<br />
                    {r.status}
                  </Typography>
                  <br />
                </div>
              ))
            )}
            <button
              type="submit"
              className={ styles.button }
              onClick={this.handleClose}
            >
              Close window
            </button><br /><br />
            <Typography component="body2" variant="body2" style={{color:'#f3f3f3'}}>
              results will appear in your dashboard shortly
            </Typography>
          </center>
        </Card>
      </Box>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    speedTestStatusModalDisplayed: state.speedTestStatusModalDisplayed || false,
    speedTestStatusResults: state.speedTestStatusResults
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSpeedTestStatusModalDisplayed: data => { dispatch(setSpeedTestStatusModalDisplayed(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpeedTestStatusModal))

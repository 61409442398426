import React, { Component } from 'react'
import {
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import styles from './organizationUser.module.scss'

/**
 * need to pass in "modalOpen" and "toggleOpen" so this can
 * be controlled by a parent component
 */
class WindowsServiceDownloader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      selectedAPIKey: null,
    }
  }

  async componentDidMount() {
    const { organization } = this.props
    if (!organization || !Array.isArray(organization.apiKeys) || organization.apiKeys.length < 1) {
      return
    }

    this.setState({
      selectedAPIKey: organization.apiKeys[0].name,
    })
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  downloadWindowsService = () => {
    const { organization } = this.props
    const { selectedAPIKey } = this.state
    if (!selectedAPIKey) {
      console.log('need an api key selected to download')
      return
    }

    const apiKey = organization.apiKeys.filter(key => key.name === selectedAPIKey)[0]
    if(!apiKey) {
      console.log('failed to find a matching key')
      return
    }

    const apiKeyId = apiKey._id

    this.setState({
      downloadingClient: true,
    })

    axios({
      url: `${this.props.url}/${this.props.routes.download.windowsServiceInstaller}`,
      method: 'GET',
      params: {
        apiKeyId: apiKeyId
      },
    }).then((response) => {
      if (!!response && !!response.data && response.data.success) {
        this.openInNewTab(response.data.downloadUrl)
      }

      this.setState({
        downloadingClient: false,
      })
      this.props.toggleOpen()
    })
  }

  handleAPIKeySelect = (event, value) => {
    this.setState({selectedAPIKey: value})
  }

  render() {
    const { downloadingClient, selectedAPIKey } = this.state
    const { modalOpen, toggleOpen } = this.props
    const apiKeys = this.props.organization.apiKeys
    const apiKeyNames = Array.isArray(apiKeys) ? apiKeys.map(key => key.name) : []

    return (
      <div>
        <Dialog
          open={ modalOpen }
          onClose={ toggleOpen }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Windows Service Downloader'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="info">This form can be used to download a pre-configured Windows Service installer.</Alert>
              <br/>
              <FormControl>
                <FormLabel id="api-key-selector">API Key</FormLabel>
                <RadioGroup
                  aria-labelledby="api-key-selector"
                  name="radio-buttons-group"
                  defaultValue={selectedAPIKey}
                  value={selectedAPIKey}
                  onChange={this.handleAPIKeySelect}
                >
                  {apiKeyNames.map(key => (
                    <FormControlLabel value={key} control={<Radio />} label={key} />
                  ))}
                </RadioGroup>
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={ styles.buttonGroup }>
              <button disabled={!selectedAPIKey || downloadingClient} onClick={ this.downloadWindowsService } className={ styles.button2 }>
                {!downloadingClient ? 'Download Zip' : (
                  <div>
                  <CircularProgress color="primary" size={ 24 } />
                  Please wait...
                  </div>
                )}
              </button>
              <button disabled={downloadingClient} onClick={ toggleOpen } className={ styles.button3 }>
                Cancel
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    organization: state.organization,
    routes: state.routes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WindowsServiceDownloader))

import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
// const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2)
const valueFormatterUSD = value => `$${twoDecimals(value)}`
const annualPrice = ({ price, annualDiscountMonths }) => price * (12 - annualDiscountMonths)

export default function PricingTable(props) {
  const data = props.pricing
  return (
    <div>
    <Typography variant="body1" component="h2" gutterBottom>
      <strong>Monthly Pricing</strong> (two months free when paid annually)
    </Typography>

    <TableContainer component={Paper}>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF'}}>
            <TableRow>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Hosts</TableCell>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Per host (paid monthly)</TableCell>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Per host (paid annually)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.hosts}>
                <TableCell>{row.hosts}</TableCell>
                <TableCell>{valueFormatterUSD(row.price)}</TableCell>
                <TableCell>{valueFormatterUSD(annualPrice(row))}</TableCell>
              </TableRow>
            ))
          }

          </TableBody>
          {/*
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
          */}
        </Table>
      </TableContainer>
      </div>
  )
}

import React, { Component } from 'react'
import styles from './organizationEdit.module.scss'
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import APIKeysDataTable from './apiKeysDataTable'
import APIKeyForm from './apiKeyForm'
import LogoBlack from '../../imup_black.png'
import LogoWhite from '../../imup_white.png'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import CreateOrganizatonForm from './createOrganizationForm'
import { setOrganization, setOrganizationUsers } from '../../actions/connectionActions'
import PricingTable from '../OrganizationBilling/PricingTable'
import PricingData from '../OrganizationBilling/PricingData'

class OrganizationEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orgId: this.props.match.params.id,
      email: '',
      loading: false,
    }
  }

  async componentDidMount() {

  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  toggleAPIKeysTable = () => {
    this.setState({
      manageAPIKeysOpen: !this.state.manageAPIKeysOpen
    })
  }

  sendInvite = async () => {
    const { email } = this.state

    this.setState({
      loading: true
    })
    alert(`sending invite to ${email}!`)
    // const response = await axios.post(`${this.props.url}/${this.props.routes.user.profile.update}`, userdata)
    this.setState({
      loading: false
    })

    // if (response.data.success) {
    //   console.log(response.data)
    //   this.props.setUser(response.data.user)
    //   this.props.notification({
    //     type: 'success',
    //     title: 'Success!',
    //     message: 'Profile saved!'
    //   })
    //   this.props.history.push('/profile')
    // } else {
    //   this.props.notification({
    //     type: 'danger',
    //     title: 'Save failed!',
    //     message: 'Try again later!'
    //   })
    // }
  }

  displayAPIKeys = () => {
    const apiKeys = !this.props.organization || !this.props.organization.apiKeys
      ? []
      : this.props.organization.apiKeys

    return (
      <div>
        <Grid container spacing={1}>
          <Grid container item xl={12} lg={12} md={12} xs={12} spacing={3}>
            <Card className={ styles.card }>
              <CardContent>
                <APIKeysDataTable data={apiKeys.map(a => { a.id = a._id; return a})} setSelection={this.setAPIKeySelection} />
                <br />
                <br />
                <Button
                  fullWidth
                  variant='contained'
                  onClick={this.deleteAPIKeys}
                  color='secondary'
                >
                    Delete API keys
                </Button>
                <APIKeyForm orgId={this.state.orgId} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  showOrganizationCreate = () => {
    const { theme } = this.props
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  style={{justifyContent: 'center'}}>
                <center>
                  <img src={theme === 'dark' ? LogoWhite : LogoBlack} style={{maxHeight:'100px'}} />
                  <h1>Organizations</h1>
                  <p>Monitor unlimited endpoints from a single dashboard.</p>
                  <p><a href="https://imup.io/orgs" target="blank" style={{color: '#1d98ff', fontSize:'12px'}}> Read more</a></p>
                  <CreateOrganizatonForm />
                </center>
            </Grid>
          </Grid>
      </div>


      // <div>
      // <Grid container spacing={3}>
      //   <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      //   <Typography variant="h3" component="h3" gutterBottom style={{color:'#1d98ff', marginBottom:'40px'}}>
      //     imUp Organizations
      //   </Typography>
      //   <Typography variant="h6" component="h6" gutterBottom >
      //     Monitor unlimited endpoints from a single dashboard
      //   </Typography>
      //   <Alert severity="info">
      //     A payment method is required to open an Org but begins as a 2 week free trial that can be canceled at any time prior to any payment being made.<br /><br />
      //     Read more about orgs here: <a href="https://imup.io/orgs" target="_blank" style={{ color:'#1d98ff' }}>https://imup.io/orgs</a>
      //   </Alert>
      //     <CreateOrganizatonForm />
      //   </Grid>
      //   <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      //     <PricingTable pricing={PricingData} />
      //   </Grid>
      //   </Grid>
      // </div>
    )
  }

  showOrganizationEdit = () => {
    const { loading } = this.state
    return (
      <div className={ styles.profileModify }>
        <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={4} xl={4}>
          <Typography className={ styles.title } component="h1" variant="h5">
            Invite User by Email Address:
          </Typography>
          <ValidatorForm
            ref="form"
            onSubmit={ this.sendInvite }
            // onError={ errors => console.log(errors) }
            className={ styles.content }
          >
              <TextValidator
                label="Email"
                onChange={ this.handleChange }
                name="email"
                size="small"
                value={ this.state.email || '' }
                validators={[
                  'required',
                  'minStringLength:3',
                ]}
                errorMessages={[
                  'this field is required',
                  'min 3 characters',
                ]}
                margin="normal"
                fullWidth
                style={{ paddingTop:'10px'}}
              />
            {
              loading ?
              <Button
                className={ this.button }
                variant="contained"
                color="primary"
                fullWidth
                disabled
              >
                <CircularProgress color="primary" size={ 24 } />
              </Button> :
              <button
                type="submit"
                className={ this.button }
                variant="contained"
                className={ styles.button }
                fullWidth
              >
                Invite
              </button>
            }
          </ValidatorForm>
          {/*}
          <button
            onClick={ this.toggleAPIKeysTable }
            className={ styles.button }
            variant="contained"
            fullWidth
            color={!this.state.manageAPIKeysOpen ? 'primary' : 'secondary'}
          >
            {!this.state.manageAPIKeysOpen ? 'Manage API Keys' : 'Cancel managing API Keys'}
          </button>
          */}
          {!this.state.manageAPIKeysOpen ? null : (
            <div>
              <br />
              <br />
              {this.displayAPIKeys()}
            </div>
          )}
        </Grid>
      </div>
    )
  }

  render() {
    const { orgId } = this.state

    return !!orgId && orgId !== 'create' ? this.showOrganizationEdit() : this.showOrganizationCreate()
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    routes: state.routes,
    theme: state.theme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationEdit))

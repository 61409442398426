import * as React from 'react';
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

const columns = [
  { field: 'name', headerName: 'Name', width: 350 },
  { field: 'email', headerName: 'Email', width: 350 },
];

export default function BasicToolbarFilteringGrid(props) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <NewCustomDataGrid
        onSelectionModelChange={(newSelectionModel) => {
          props.setUserSelection(newSelectionModel)
        }}
        columns={columns}
        rows={props.data}
        showToolbar
        checkboxSelection
      />
    </div>
  );
}

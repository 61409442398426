import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab'
import './stripe.css'
import config from '../../config'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { setOrganization } from '../../actions/connectionActions';
import styles from './payments.module.scss';

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
// const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2)
const valueFormatterUSD = value => `$${twoDecimals(value)}`
const annualPrice = ({ price, annualDiscountMonths }) => price * (12 - annualDiscountMonths) / 12

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      makingPayment: false,
      numberOfHosts: '3',
      subscriptionType: 'annually',
    }
  }

  getPricePerHost = () => {
    let price = 3.00
    this.props.pricing.forEach(pricing => {
      if (this.state.numberOfHosts >= pricing.minHosts) {
        price = pricing.price
      }
    })

    return price
  }

  getAnnualDiscountMonths = () => {
    let annualDiscountMonths = 2
    this.props.pricing.forEach(pricing => {
      if (this.state.numberOfHosts >= pricing.minHosts) {
        annualDiscountMonths = pricing.annualDiscountMonths
      }
    })

    return annualDiscountMonths
  }

  getPricePerHostAnnually = () => {
    return annualPrice({ price: this.getPricePerHost(), annualDiscountMonths: this.getAnnualDiscountMonths() })
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {stripe, elements, notification} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({
      makingPayment: true
    })

    // -- simple single payment
    // const paymentIntent = await axios.post(`${this.props.url}/${this.props.routes.payments.payment}`, { item: 'negotiations' })
    // console.log('payment intent:', paymentIntent)
    // const cardElement = elements.getElement(CardElement);
    // stripe
    //   .confirmCardPayment(paymentIntent.data.clientSecret, {
    //     payment_method: {
    //       card: cardElement
    //     }
    //   })
    //   .then(function(result) {
    //     if (result.error) {
    //       // Show error to your customer
    //       console.log('Error occurred processing payment:', result.error.message)
    //     } else {
    //       // The payment succeeded!
    //       console.log('Payment processing successful:', result.paymentIntent.id)
    //     }
    //   });

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      notification({
        type: 'danger',
        title: 'Credit Card rejected!',
        message: 'Make sure you typed it in correctly or try again with another card!'
      })

      this.setState({
        makingPayment: false
      })
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const response = await axios.post(`${this.props.url}/${this.props.routes.payments.orgSubscription}`, {
        numberOfHosts: Number.parseInt(this.state.numberOfHosts, 10),
        paymentMethod: paymentMethod.id,
        subscriptionType: this.state.subscriptionType,
      })
      console.log('subscription created:', response)

      if (response && response.data && response.data.success) {
        console.log(response.data)
        // check for org even on success cause we'll send back success if
        // the subscription was successful but the mongo store failed
        if (response.data.data) {
          this.props.setOrganization(response.data.data)
        }

        this.props.notification({
          type: 'success',
          title: 'Success!',
          message: `Successfully created an API Key! Redirecting you to home...`
        })

        const that = this
        setTimeout(function(){
          window.location.href = `/org/${that.props.organization.id}`
        }, 2000)
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Failed to create api key!',
          message: 'Please reach out to support or try again later!'
        })

        this.setState({
          makingPayment: false
        })
      }
    }
  };

  handleHostsChange = (e) => {
    this.setState({
      numberOfHosts: e.target.value
    })
  }

  getTrialEndDate = () => {
    const numWeeks = 2
    const now = new Date()
    const trialEnd = new Date(now.setDate(now.getDate() + numWeeks * 7))

    // https://stackoverflow.com/a/5416970
    return `${trialEnd.getMonth()+1}/${trialEnd.getDate()}/${trialEnd.getFullYear()}`
  }

  handleSubscriptionTypeToggle = () => {
    this.setState({
      subscriptionType: this.state.subscriptionType === 'annually' ? 'monthly' : 'annually'
    })
  }
  getTrialEndDate = () => {
    const numWeeks = 2
    const now = new Date()
    const trialEnd = new Date(now.setDate(now.getDate() + numWeeks * 7))

    // https://stackoverflow.com/a/5416970
    return `${trialEnd.getMonth()+1}/${trialEnd.getDate()}/${trialEnd.getFullYear()}`
  }

  render() {
    console.log('tier:', this.props.tier)
    const keyCount = this.props.organization.apiKeys.length
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={ styles.form }>
        { keyCount > 0 ?
        <h1>Add seats to your Org</h1>
        :
        <div>
          <h1>Begin your free 2 week trial</h1>
          <Alert severity="info">
            Your card will not be charged until your 2 week trial period has elapsed. Delete your Organization before {this.getTrialEndDate()} to avoid being charged.<br /><br />
            For large organizations or any questions about pricing, don't be shy, just reach out to us at support@imup.io -- we're pretty cool.
          </Alert>
        </div>
        }
        <form noValidate autoComplete="off" className={ styles.form }>
          <ToggleButtonGroup
            color="primary"
            value={this.state.subscriptionType}
            exclusive
            onChange={this.handleSubscriptionTypeToggle}
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="annually">Annually</ToggleButton>
          </ToggleButtonGroup>
          <br />
          <br />

          {/* https://material-ui.com/components/text-fields/ */}
          <TextField
            id="outlined-basic"
            label="Number of endpoints"
            variant="outlined"
            type="number"
            style={{ width: '10vw' }}
            InputProps={{ inputProps: { min: 3, max: 10000 } }}
            onChange={this.handleHostsChange}
            value={this.state.numberOfHosts}
          />
        </form>
        Subtotal: {this.state.subscriptionType === 'annually'
          ? `${valueFormatterUSD(this.state.numberOfHosts * this.getPricePerHostAnnually() * 12)} / year`
          : `${valueFormatterUSD(this.state.numberOfHosts * this.getPricePerHost())} / month`}

        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button type="submit" className={ styles.button } disabled={!stripe || this.state.makingPayment}>
          Sign up {this.state.subscriptionType === 'annually'
            ? `${valueFormatterUSD(this.getPricePerHostAnnually())}/host per year + tax`
            : `${valueFormatterUSD(this.getPricePerHost())}/host per month + tax`}
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    organization: state.organization,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

const ReduxCheckoutForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm))

const InjectedCheckoutForm = (notification, tier, setUser, pricing) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <ReduxCheckoutForm elements={elements} stripe={stripe} notification={notification} tier={tier} setUser={setUser} pricing={pricing}/>
      )}
    </ElementsConsumer>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe.key);

const App = (props) => {
  return (
    <Elements stripe={stripePromise}>
      {InjectedCheckoutForm(props.notification, props.tier, props.setUser, props.pricing)}
    </Elements>
  );
};

export default App;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { setUserLivenessStatus } from '../../actions/connectionActions'

class Status extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hostStatusIntervalId: null,
    }
  }

  async componentDidMount() {
    try {
      const latestHostName = await axios.get(`${this.props.url}/${this.props.routes.realtime.latestHostName}`)
      if (!!latestHostName && !!latestHostName.data && !!latestHostName.data.data) {
        const that = this
        // every 5 seconds
        let intervalId = setInterval(async function(){
          if (that.shouldEnablePaidFeatures()) {
            const hostConnectedStatus = await axios.post(`${that.props.url}/${that.props.routes.realtime.hostConnectedStatus}`, {
              hosts: [latestHostName.data.data]
            })

            const validData = !!hostConnectedStatus && !!hostConnectedStatus.data
                           && hostConnectedStatus.data.success
                           && hostConnectedStatus.data.data
                           && Array.isArray(hostConnectedStatus.data.data.status)
                           && hostConnectedStatus.data.data.status.length > 0

            if (validData) {
              that.props.setUserLivenessStatus(hostConnectedStatus.data.data.status)
            } else {
              console.log('status.js componentDidMount failed to get host liveness status:', hostConnectedStatus)
            }
          }
        }, 5000)

        this.setState({ hostStatusIntervalId: intervalId })
      }
    } catch (e) {
      console.log('status.js componentDidMount error getting live connectivity status', e)
    }
  }

  shouldEnablePaidFeatures = () => {
    return !!this.props.user && !!this.props.user.plan && this.props.user.plan !== 'reporting'
  }

  componentWillUnmount() {
    clearInterval(this.state.hostStatusIntervalId)
  }

  // settings for navbar status indicator
  statusConfig = {
    online: {
      statusText: 'Connected',
      tooltipText: 'Your internet is reporting online!',
      severity: 'success',
    },
    offline: {
      statusText: 'Disconnected',
      tooltipText: 'Your internet is reporting offline, make sure the imUp app is running on your computer & check your connection!',
      severity: 'error',
    }
  }

  render() {
    const { userLivenessStatus } = this.props
    if (!Array.isArray(userLivenessStatus) || userLivenessStatus.length < 1) {
      return <div></div>
    }

    const config = userLivenessStatus[0].status ? this.statusConfig.online : this.statusConfig.offline

    return (
      <div>
        <center>
          <Tooltip title={config.tooltipText}>
            <Chip label={config.statusText} color={config.severity} />
          </Tooltip>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    routes: state.routes,
    url: state.url,
    user: state.user,
    userLivenessStatus: state.userLivenessStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLivenessStatus: data => { dispatch(setUserLivenessStatus(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Status))

import React, { Component } from 'react'
import styles from './organizations.module.scss'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setOrganizations } from '../../actions/connectionActions'
import OrganizatonForm from './organizationForm'
import OrganizationsTable from './organizationsTable'
import axios from 'axios'

class Organizations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      createOrgOpen: true
    }
  }

  async componentDidMount() {
    const organizations = await axios.get(`${this.props.url}/${this.props.routes.organization.list}`)
    if (organizations && organizations.data.success) {
      this.props.setOrganizations(organizations.data.data)
    }
  }

  displayOrganizations = () => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography style={{display: 'inline-block'}} variant="body1" component="h2" gutterBottom>
                  <strong>Organizations</strong>
                </Typography>
                <OrganizationsTable data={this.props.organizations ? this.props.organizations : []} />
              </CardContent>
            </Card>
            {this.state.createOrgOpen ? <OrganizatonForm toggleEmailForm={this.state.createOrgOpen} /> : null}
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    return (
      <div className={ styles.organizations }>
        {this.displayOrganizations()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    organizations: state.organizations,
    routes: state.routes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setOrganizations: data => { dispatch(setOrganizations(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organizations))

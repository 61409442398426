// COMPONENTS
import React, { useState, Component } from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core'
// MATERIAL UI CORES
import styles from './LocalProviders.module.scss'
import { withStyles } from "@material-ui/core/styles";


export default function LocalProvidersTitle() {
  return (
    <div>
    <Alert severity="error" style={{width: '100%', textAlign: 'left'}}>
      <AlertTitle style={{textAlign: 'left'}}>You can't see the provider list yet...</AlertTitle>
      Click the "MODIFY" button to complete your profile and unlock the ISP list. (required: zipcode, provider name, price, speeds, rating). Thank you for helping to crowdsource a better internet finder for everyone!
    </Alert>
      <hr />
    </div>
  )
}

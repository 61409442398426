import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import styles from './payments.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
import { setOrganization } from '../../actions/connectionActions'

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
// const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2)
const valueFormatterUSD = value => `$${twoDecimals(value)}`
const annualPrice = ({ price, annualDiscountMonths }) => price * (12 - annualDiscountMonths) / 12


class APIKeyForm extends Component {
  constructor(props) {
    super(props)

    const apiKey = props.organization.apiKeys[props.organization.apiKeys.length - 1]

    this.state = {
      sending: false,
      name: apiKey.name,
      seats: apiKey.seats ? apiKey.seats.toString() : '0',
      originalSeats: apiKey.seats ? apiKey.seats.toString() : '0',
      // state for modal dialogs
      unsubscribeDialogOpen: false,
      reactivateDialogOpen: false,
      unsubscribing: false,
      reactivating: false,
      unsubscribeFeedback: '',
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  openUnsubscribeDialogClick = () => {
    this.setState({
      unsubscribeDialogOpen: !this.state.unsubscribeDialogOpen
    })
  }

  removeUnsubscribeDialogClick = () => {
    this.setState({
      unsubscribeDialogOpen: !this.state.unsubscribeDialogOpen
    })
  }

  openReactivateDialogClick = () => {
    this.setState({
      reactivateDialogOpen: !this.state.reactivateDialogOpen
    })
  }

  removeReactivateDialogClick = () => {
    this.setState({
      reactivateDialogOpen: !this.state.reactivateDialogOpen
    })
  }

  reactivateSubscription = async () => {
    this.setState({
      reactivating: true
    })

    let response = null
    try {
      response = await axios.post(`${this.props.url}/${this.props.routes.payments.orgReactivate}`, {})
    } catch (e) {
      this.props.notification({
        type: 'danger',
        title: 'Failed to reactivate subscription!',
        message: 'Please contact support or try again later!'
      })

      this.removeReactivateDialogClick()

      return this.setState({
        reactivating: false
      })
    }

    if (response && response.data && response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'You have successfully reactivated your subscription!'
      })

      this.setState({
        reactivating: false,
      })

      this.props.setOrganization(response.data.data)

      return this.removeReactivateDialogClick()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Failed to reactivate subscription!',
        message: 'Please contact support or try again later!'
      })

      this.setState({
        reactivating: false
      })

      return this.removeReactivateDialogClick()
    }
  }

  handleUnsubscribeOrReactivateClick = () => {
    const { organization } = this.props
    if (!!organization && !!organization.stripe && !!organization.stripe.cancelAt) {
      return this.openReactivateDialogClick()
    }
    return this.openUnsubscribeDialogClick()
  }

  // maybe put a tooltip in here or something too giving them
  // the date they have until to reactivate
  unsubscribeOrReactivate = () => {
    const { organization } = this.props
    if (!!organization && !!organization.stripe && !!organization.stripe.cancelAt) {
      return 'Reactivate'
    }

    return 'Unsubscribe'
  }

  setUnsubscribeFeedback = (event) => {
    this.setState({ unsubscribeFeedback: event.target.value })
  }

  updateAPIKeyClicked = async () => {
    const { name, seats } = this.state
    this.setState({
      sending: true
    })

    const response = await axios.post(`${this.props.url}/${this.props.routes.payments.updateAPIKey}`, {
      apiKeyId: this.props.organization.apiKeys[this.props.organization.apiKeys.length - 1].id,
      apiKeyName: name,
      apiKeySeats: Number.parseInt(seats, 10),
    })

    if (response && response.data && response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Updated API key!'
      })

      this.setState({
        sending: false,
        name: ''
      })

      this.props.setOrganization(response.data.data)

      const that = this
      setTimeout(function(){
        window.location.href = `/org/${that.props.organization.id}`
      }, 2000)

      // this.props.toggleAPIKeyForm()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Updating API key failed!',
        message: 'Try again later!'
      })

      this.setState({
        sending: false
      })
    }
  }

  unsubscribeClicked = async () => {
    this.setState({
      unsubscribing: true
    })

    const postBody = { feedback: this.state.unsubscribeFeedback }
    const response = await axios.post(`${this.props.url}/${this.props.routes.payments.orgUnsubscribe}`, postBody)

    if (response && response.data && response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'You have unsubscribed and will no longer be charged!'
      })

      this.setState({
        unsubscribing: false,
        unsubscribeFeedback: '',
      })

      this.props.setOrganization(response.data.data)

      this.removeUnsubscribeDialogClick()

      // this.props.toggleAPIKeyForm()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Failed to unsubscribe!',
        message: 'Please contact support or try again later!'
      })

      this.setState({
        unsubscribing: false
      })

      this.removeUnsubscribeDialogClick()
    }
  }

  getPricePerHost = (numberOfHosts) => {
    let price = 3.00
    this.props.pricing.forEach(pricing => {
      if (numberOfHosts >= pricing.minHosts) {
        price = pricing.price
      }
    })

    return price
  }

  getAnnualDiscountMonths = (numberOfHosts) => {
    let annualDiscountMonths = 2
    this.props.pricing.forEach(pricing => {
      if (numberOfHosts >= pricing.minHosts) {
        annualDiscountMonths = pricing.annualDiscountMonths
      }
    })

    return annualDiscountMonths
  }

  getPricePerHostAnnually = (numberOfHosts) => {
    return annualPrice({ price: this.getPricePerHost(numberOfHosts), annualDiscountMonths: this.getAnnualDiscountMonths(numberOfHosts) })
  }

  displaySubtotal = () => {
    const { organization } = this.props
    const originalSeatNumber = Number.parseInt(this.state.originalSeats, 10)
    const seatNumber = Number.parseInt(this.state.seats, 10)

    const currentPrice = organization && organization.stripe && organization.stripe.subscriptionType === 'annually'
      ? originalSeatNumber * this.getPricePerHostAnnually(originalSeatNumber) * 12
      : originalSeatNumber * this.getPricePerHost(originalSeatNumber)

    const newPrice = organization && organization.stripe && organization.stripe.subscriptionType === 'annually'
      ? seatNumber * this.getPricePerHostAnnually(seatNumber) * 12
      : seatNumber * this.getPricePerHost(seatNumber)

    if (seatNumber === originalSeatNumber) {
      return `Subtotal: ${valueFormatterUSD(currentPrice)} + tax ${organization.stripe.subscriptionType} (no difference)`
    }
    if (seatNumber > originalSeatNumber) {
      return `Subtotal: ${valueFormatterUSD(newPrice)} + tax ${organization.stripe.subscriptionType} (${valueFormatterUSD(newPrice - currentPrice)} + tax more than you were previously paying)`
    }

    if (seatNumber < originalSeatNumber) {
      return `Subtotal: ${valueFormatterUSD(newPrice)} + tax ${organization.stripe.subscriptionType} (${valueFormatterUSD(currentPrice - newPrice)} + tax less than you were previously paying)`
    }
  }

  render() {
    console.log('organization info:', this.props.organization)

    // const { page, rowsPerPage } = this.state
    return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ValidatorForm
            ref="form"
            onSubmit={ this.updateAPIKeyClicked }
            onError={ errors => console.log(errors) }
            className={ styles.content }
          >
          <Typography variant="body1" gutterBottom component="div">
            <strong>Update seat count</strong>
          </Typography>
            <TextValidator
              label="API Key Name"
              onChange={ this.handleChange }
              name="name"
              value={ this.state.name }
              validators={[
                'minStringLength:0',
                'maxStringLength:200'
              ]}
              errorMessages={[
                // 'min 2 characters',
                'max 200 characters'
              ]}
              margin="normal"
              fullWidth
              variant="outlined"
              style={{ paddingTop:'10px' }}
            />
            <TextValidator
              label="Number of hosts"
              onChange={ this.handleChange }
              name="seats"
              type="number"
              value={ this.state.seats }
              validators={[
                'isPositive',
              ]}
              errorMessages={[
                'must be an integer'
              ]}
              InputProps={{ inputProps: { min: 3, max: 10000 } }}
              margin="normal"
              fullWidth
              variant="outlined"
              style={{ paddingTop:'10px' }}
            />
            <p></p>
            {this.displaySubtotal()}<br />
            {
              this.state.sending ?
              <button
                className={ styles.button }
                variant="contained"
                color="primary"
                fullWidth
                disabled
              >
                <CircularProgress color="primary" size={ 24 } />
              </button> :
              <button
                type="submit"
                className={ styles.button }
                variant="contained"
                color="primary"
                fullWidth
              >
                Update API Key
              </button>
            }
          </ValidatorForm>
          {
            this.state.sending ?
            <button
              className={ styles.buttonDelete }
              variant="contained"
              color="secondary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </button> :
            <button
              type="submit"
              className={ styles.buttonDelete }
              variant="contained"
              color="secondary"
              fullWidth
              // onClick={this.unsubscribeClicked}
              onClick={this.handleUnsubscribeOrReactivateClick}
            >
              {this.unsubscribeOrReactivate()}
            </button>
          }
        </Grid>
        <Dialog
          open={ this.state.unsubscribeDialogOpen }
          onClose={ this.removeUnsubscribeDialogClick }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Unsubscribe from imUp?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure?<br/>You will no longer be charged for imUp services.
              Your API key will remain valid until the end of your paid period.
            </DialogContentText>
            <TextareaAutosize
              minRows={5}
              value={this.state.unsubscribeFeedback}
              onChange={this.setUnsubscribeFeedback}
              placeholder="Thanks for giving us a shot. If you have a moment, please let us know why you're unsubscribing."
              style={{ width: `100%` }}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.unsubscribing} onClick={ this.removeUnsubscribeDialogClick } color="primary">
              No
            </Button>
            <Button disabled={this.state.unsubscribing} onClick={ this.unsubscribeClicked } color="secondary" autoFocus>
              Unsubscribe
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={ this.state.reactivateDialogOpen }
          onClose={ this.removeReactivateDialogClick }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Reactivate imUp subscription?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will continue to be charged for imUp services, beginning next pay period.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.reactivating} onClick={ this.removeReactivateDialogClick } color="secondary">
              No
            </Button>
            <Button disabled={this.state.reactivating} onClick={ this.reactivateSubscription } color="primary" autoFocus>
              Reactivate
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification,
    routes: state.routes,
    organization: state.organization,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(APIKeyForm))

import React, { Component } from 'react'
import styles from './profile.module.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Rating from '@mui/material/Rating';
import axios from 'axios'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  removeUser = async () => {
    const { id, email } = this.props.user
    const userdata = {
      data: {
        id,
        email
      }
    }
    this.setState({
      loading: true
    })
    const response = await axios.delete(`${this.props.url}/${this.props.routes.user.profile.remove}`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile deleted!'
      })
      this.props.history.push('/logout')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Delete failed!',
        message: 'Try again later!'
      })
    }
  }

  removeDialogClick = () => {
    this.setState({
      open: this.state.open ? false : true
    })
  }

  getISPDetails = (field) => {
    const ispDefaults = {
      provider: '',
      providerIds: [],
      supportEmail: '',
      price: 0,
      rating:'',
      downloadSpeed: 0,
      uploadSpeed: 0,
      dataLimit: 0,
      speedThreshold: 0,
      accountNumber: '',
      username: '',
      password: '',
      pin: '',
      imupAuthorized: false,
      additionalDetails: ''
    }

    const ispValues = Object.assign({}, ispDefaults, this.props.user.isp || {})
    return ispValues[field]
  }

  getISPRating = () => {
    const { user } = this.props
    if (!Array.isArray(user.ispRatings)) {
      return null
    }

    const usersCurrentProviderId = !!this.props.user && !!this.props.user.isp && Array.isArray(this.props.user.isp.providerIds) && !!this.props.user.isp.providerIds[0]
      ? this.props.user.isp.providerIds[0]._id
      : null

    let rating = null;
    for (const ispRating of user.ispRatings) {
      if (ispRating.providerId === usersCurrentProviderId) {
        rating = ispRating.rating
      }
    }

    return rating
  }

  getAddressDetails = (field) => {
    const addressDefaults = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: ''
    }

    const addressValues = Object.assign({}, addressDefaults, this.props.user.address || {})
    return addressValues[field]
  }

  getNotificationDetails = (field) => {
    const notificationDefaults = {
      email: false,
      twitter: false,
    }

    const notificationValues = Object.assign({}, notificationDefaults, this.props.user.notifications || {})
    return notificationValues[field]
  }

  getProviderEmail = () => {
    if (!this.props.user.isp) {
      return ''
    }

    if (this.props.user.isp.supportEmail) {
      return this.props.user.isp.supportEmail
    }

    const { providerIds } = this.props.user.isp

    if (!Array.isArray(providerIds) || providerIds.length < 1 || !providerIds[0]) {
      return ''
    }

    if (!Array.isArray(providerIds[0].email) || providerIds[0].email.length < 1) {
      return ''
    }

    return providerIds[0].email[0].address || ''
  }

  getPaymentCardDetails = (field) => {
    const { userPaymentInfo } = this.props
    if (!userPaymentInfo || !userPaymentInfo.card) {
      return ''
    }

    return userPaymentInfo.card[field]
  }

  goToPaymentUpdate = () => {
    window.location.href = `/payment/update`
  }

  render() {
    console.log('Profile.js this.props.user', this.props.user)
    return (
      <div className={ styles.profile }>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={4} justifyContent="center" alignItems="center">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>User details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">{ this.props.user.name }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">{ this.props.user.email }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zipcode</TableCell>
              <TableCell align="right">{ this.getAddressDetails('zipcode') }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="isp table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Connection details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Provider</TableCell>
              <TableCell align="right">{ this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : '' }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monthly cost</TableCell>
              <TableCell align="right">{ this.getISPDetails('price') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Download speed (Mbps)</TableCell>
              <TableCell align="right">{ this.getISPDetails('downloadSpeed') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Upload speed (Mbps)</TableCell>
              <TableCell align="right">{ this.getISPDetails('uploadSpeed') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Slow speed threshold (Mbps)
                <Tooltip title="You will receive alerts when speed test results are slower than this value">
                  <InfoOutlinedIcon
                    style={{fontSize: 'large', color: '#1D98FF', paddingLeft: '5px'}}
                  />
                </Tooltip>
              </TableCell>
              <TableCell align="right">{ this.getISPDetails('speedThreshold') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rating</TableCell>
              <TableCell align="right"><Rating name="read-only" value={ this.getISPRating() } readOnly style={{color: "#1d98ff"}}/></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Payment details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Card Type</TableCell>
              <TableCell align="right">{ this.getPaymentCardDetails('brand') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Card Last 4 Digits</TableCell>
              <TableCell align="right">{ this.getPaymentCardDetails('last4') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Update payment info</TableCell>
              <TableCell align="right"><Button onClick={this.goToPaymentUpdate} disabled={!this.getPaymentCardDetails('last4')}>Update</Button></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Notification details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">{ !!this.getNotificationDetails('email') ? 'On' : 'Off' }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} justifyContent="center" alignItems="center">
          <Card variant="outlined" className={ styles.card } >
            <CardContent>
              <Typography variant="h5" component="h5">
                Your Profile
              </Typography><br />

              <Typography variant="body2" component="p">
                Your ISP profile information is shared anonymously with others to help crowdsource up to date pricing & speed information for your zipcode and surrounding area.<br /><br /> You can see local ISP information on the <Link className={styles.link} to="/localproviders">Local Providers</Link> page.
              </Typography>
              <ButtonGroup>
              <Link className={ styles.link } to="/profile/modify"><button className={ styles.button } style={{marginBottom:'none'}}>Modify</button></Link>
              <button
                onClick={ this.removeDialogClick }
                className={ styles.button2 }
              >
                Delete
              </button>
            </ButtonGroup>
                <Dialog
                  open={ this.state.open }
                  onClose={ this.removeDialogClick }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Delete your profile?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure?<br/>This action is irreversible, you will lose all your data!
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <ButtonGroup className={ styles.buttonGroup }>
                    <button onClick={ this.removeDialogClick } className={ styles.button }>
                      No
                    </button>
                    <button onClick={ this.removeUser } className={ styles.button2 }>
                      Delete
                    </button>
                  </ButtonGroup>
                  </DialogActions>
                </Dialog>
            </CardContent>
          </Card>
        </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    notification: state.notification,
    userPaymentInfo: state.userPaymentInfo,
  }
}

export default connect(mapStateToProps)(Profile)

import React, { useState } from 'react';
import styles from './paymentPlans.module.scss'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SpeedIcon from '@material-ui/icons/Speed';
import Divider from '@material-ui/core/Divider';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import axios from 'axios'
import ProfileInfo from '../Navigation/profileInfo';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'lite' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardHeaderAlternate: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardPricingAlternate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: 'imUp Orgs',
    id: 'orgs',
    description: [
      {
        name: 'Downtime monitoring',
        tooltip: 'Catch any downtime longer than 1 minute'
      },
      {
        name: 'Speed testing',
        tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
      },
      {
        name: 'Remote speed testing',
        tooltip: 'Run a speed test on your computer anytime, from anywhere'
      },
      {
        name: 'Downtime notifications',
        tooltip: 'Receive an email notification after you come back online so you know to take action!'
      },
      {
        name: 'Data export',
        tooltip: 'Export connectivity & speed test data to CSV'
      },
      {
        name: '1 year of storage per host',
        tooltip: 'One year of metrics storage for each of your host'
      },
      {
        name: '24/7 email support',
        tooltip: 'Email support response asap'
      },
      {
        name: ' ',
      },
    ],
    buttonText: 'Upgrade Plan',
    // buttonVariant: 'outlined',
    buttonVariant: 'contained',
  },
]

export default function UpgradePricingModule(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);

  const openDialogClick = () => {
    setOpen(open ? false : true)
  }

  const removeDialogClick = () => {
    setOpen(open ? false : true)
  }


  const handleClickUpgrade = () => {
      props.setPaymentPlan('Internet Upgrade')
      window.location.href = `/payments`
  }

  return (
    <React.Fragment>
    <ProfileInfo />
    <Typography variant="h3" component="h3" gutterBottom style={{color:'#1d98ff', marginBottom:'40px'}}>
    Business
    </Typography>
    <Grid container spacing ={3} alignItems="stretch">
    {tiers.map((tier) => (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card style={{ width: '100%'}}>
            <CardHeader
              title='imUp Orgs'
              subheader='Unlimited endpoints'
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{ align: 'center', color:'#fff' }}
              style={{backgroundColor: '#003f75', color:'#fff'}}
            />
            <CardContent>
            <div className={classes[!!tier.cardContentStyle ? tier.cardContentStyle : 'cardPricing']}>
              <Typography variant="h6" color="textSecondary">
                <Link to="/organization/edit/create" className={ styles.link }><button className={ styles.button3 } style={{display:'inherit', textDecoration: 'none'}}>Pricing</button></Link>
              </Typography>
            </div>
              <Typography variant="body2" color="textSecondary" component="p">
                <ul>
                {tier.description.map((line) => (
                  <Typography component="li" variant="subtitle1" align="center" key={line}>
                    {line.name}
                    {!line.tooltip ? null : (
                      <Tooltip title={line.tooltip}>
                        <InfoOutlinedIcon
                          display={line.tooltip != null}
                          style={{fontSize: 'medium', color: '#1D98FF', paddingLeft: '5px'}}
                        />
                      </Tooltip>
                    )}
                  </Typography>))}
                </ul>
              </Typography>
            </CardContent>
            <CardActions style={{ display:'inherit'}}>
            <center><Link to="/organization/edit/create" className={ styles.link }><button className={ styles.button } style={{display:'inherit', textDecoration: 'none'}}>Create an org</button></Link></center>
            </CardActions>
          </Card>
        </Grid>
      ))}
      </Grid>
    </React.Fragment>
  );
}

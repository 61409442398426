import React from 'react'
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
const valueFormatter = ({ value }) => twoDecimals(value)

const columns = [
  { field: 'hostId', headerName: 'Host ID', width: 350, type: 'string' },
  { field: 'live', headerName: 'Online', width: 150 },
  { field: 'meanUpload', headerName: 'Avg Upload (7d)', width: 180, type: 'number', valueFormatter },
  { field: 'meanDownload', headerName: 'Avg Download (7d)', width: 200, type: 'number', valueFormatter },
  { field: 'maxUpload', headerName: 'Max Upload (7d)', width: 200, type: 'number', valueFormatter },
  { field: 'maxDownload', headerName: 'Max Download (7d)', width: 200, type: 'number', valueFormatter },
  { field: 'minUpload', headerName: 'Min Upload (7d)', width: 180, type: 'number', valueFormatter },
  { field: 'minDownload', headerName: 'Min Download (7d)', width: 200, type: 'number', valueFormatter },
  { field: 'meanLatency', headerName: 'Avg Latency ms (7d)', width: 200, type: 'number', valueFormatter },
];

export default function StatsTableSpeedTest(props) {
  return !props.data || !Array.isArray(props.data) ? null : (
    <div style={{ height: '70vh', width: '100%' }}>
      <NewCustomDataGrid
        columns={columns}
        rows={props.data}
        showToolbar
        filterModel={props.filterModel}
        onFilterModelChange={props.onFilterModelChange}
        onRowClick={(row) => {
          props.onRowClick(row.row)
        }}
      />
    </div>
  )
}

// {
//   "hostSummaries": [
//     {
//       "apiKeyId": "5ff699a9a69e8b00b7140a07",
//       "hostId": "jan 7",
//       "summary": [
//         {
//           "stat": "7 day average download speed",
//           "value": [
//             {
//               "time": "2021-01-01T01:56:08.739Z",
//               "mean": 375.1968132843398
//             }
//           ]
//         },
//         {
//           "stat": "7 day average upload speed",
//           "value": [
//             {
//               "time": "2021-01-01T01:56:08.750Z",
//               "mean": 16.8580684757538
//             }
//           ]
//         }
//       ]
//     },
//     {
//       "apiKeyId": "5ff699a9a69e8b00b7140a07",
//       "hostId": "jan 7 #2",
//       "summary": [
//         {
//           "stat": "7 day average download speed",
//           "value": [
//             {
//               "time": "2021-01-01T01:56:08.739Z",
//               "mean": 408.6410721677036
//             }
//           ]
//         },
//         {
//           "stat": "7 day average upload speed",
//           "value": [
//             {
//               "time": "2021-01-01T01:56:08.750Z",
//               "mean": 19.582874321235476
//             }
//           ]
//         }
//       ]
//     }
//   ]
// }

import React, { Component } from 'react'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styles from './adminUser.module.scss'
import Chart from '../InternetConnectivity/chart'
import SpeedTestTable from '../SpeedTest/table'
import StatsTableSpeedTest from './statsTableSpeedTest'
import StatsTableDowntime from './statsTableDowntime'
import Fab from './fab'
import Modal from './modal'
import TermsOfServiceModal from '../Downloads/modal'
import { setConnectivityData, setDowntimeData, setSpeedTestData, setGeolocationData, setSpeedTestAverage7d, setTermsOfServiceModalDisplayed, setDownloadOS } from '../../actions/connectionActions'
import EmailTable from './emailTable'

class AdminUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      open: false,
      email: {
        attachedUser: this.props.adminViewUser.user.email,
        to: this.getProviderEmail(),
        from: 'support@imup.io',
        bcc: 'support@imup.io',
        subject: `imUp detection: ${this.props.adminViewUser.user.name}`,
        open: false,
        text: `\
  Hello,

  We are emailing to inform you that we have experienced a disconnection issue \
  with our internet service. Can you confirm why we went offline?

  Account details:

  Name: ${this.props.adminViewUser.user.name}
  Account #: ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.accountNumber : ''}
  Address:
  ${this.props.adminViewUser.user.address ? this.props.adminViewUser.user.address.address : ''}
  ${this.props.adminViewUser.user.address ? this.props.adminViewUser.user.address.city : ''}, \
  ${this.props.adminViewUser.user.address ? this.props.adminViewUser.user.address.state : ''} \
  ${this.props.adminViewUser.user.address ? this.props.adminViewUser.user.address.zipcode : ''}


  --- Additional details ---
  Price: ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.price : ''}
  Download speed: ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.downloadSpeed : ''}
  Upload speed: ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.uploadSpeed : ''}
  Data limit: ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.dataLimit : ''}

  User provided:
  ${this.props.adminViewUser.user.isp ? this.props.adminViewUser.user.isp.additionalDetails : ''}
  `,
        sending: false
      }
    }
  }

  getProviderEmail = () => {
    if (!this.props.adminViewUser.user.isp) {
      return ''
    }

    if (this.props.adminViewUser.user.isp.supportEmail) {
      return this.props.adminViewUser.user.isp.supportEmail
    }

    const { providerIds } = this.props.adminViewUser.user.isp

    if (!Array.isArray(providerIds) || providerIds.length < 1 || !providerIds[0]) {
      return ''
    }

    if (!Array.isArray(providerIds[0].email) || providerIds[0].email.length < 1) {
      return ''
    }

    return providerIds[0].email[0].address || ''
  }

  showDownloads = () => {
    const shouldShow = ['Mac OS', 'Windows', 'Linux']
    return shouldShow.includes(this.props.operatingSystem)
  }

  displayChart = () => {
    const { connectivity } = this.props.adminViewUser
    if (Array.isArray(connectivity) && connectivity.length > 0) {
      return (
        <div className={ styles.card }>
          <div style={{margin: '0 auto', width:'90%'}}>
            <Typography variant="h5" component="h2" gutterBottom>
              Internet Connection Status
            </Typography>
            {Chart(connectivity, this.props.theme)}
          </div>
        </div>
      )
    }
    return null
  }

  displayIPAddress = () => {
    if (!this.props.geolocationData) {
      return null
    }

    // not sure what's happening here...
    const { userIP } = this.props.geolocationData.data ? this.props.geolocationData.data : this.props.geolocationData

    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Current IP Address
          </Typography>
          {userIP}
        </CardContent>
      </Grid>
    )
  }

  displayGeolocationData = () => {
    if (!this.props.geolocationData) {
      return null
    }

    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // not sure what's happening here...
    const { country, region, city } = this.props.geolocationData.data ? this.props.geolocationData.data : this.props.geolocationData

    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Location
          </Typography>
          {capitalize(city)}, {region.toUpperCase()}, {country}
        </CardContent>
      </Grid>
    )
  }

  displaySpeedTestDownloadAverage = () => {
    if (!this.props.speedTestAverage7d.download || !this.props.speedTestAverage7d.download[0]) {
      return null
    }
    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Average Download Speed
          </Typography>
          {this.props.speedTestAverage7d.download[0].mean.toFixed(2).toString()} Mbps
        </CardContent>
      </Grid>
    )
  }

  displaySpeedTestUploadAverage = () => {
    if (!this.props.speedTestAverage7d.upload || !this.props.speedTestAverage7d.upload[0]) {
      return null
    }

    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Average Upload Speed
          </Typography>
          {this.props.speedTestAverage7d.upload[0].mean.toFixed(2).toString()} Mbps
        </CardContent>
      </Grid>
    )
  }

  displayDowntimeCard = () => {
    const { speedTestAverage7d } = {}
    const xl = 3
    const lg = 3
    const md = 3

    // only used to determine whether or not to display cards
    const { connectivity, downtime } = this.props.adminViewUser
    if (!Array.isArray(connectivity) || connectivity.length === 0) {
      return null
    }

    return (
      <Card className={ styles.card }>
        <Grid justify="center" container spacing={1}>
          <Grid container justify="center" item xl={xl} lg={lg} md={md} xs={12} spacing={3}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Total downtime
              </Typography>
              {downtime} minutes
            </CardContent>
          </Grid>
          <Grid container justify="center" item xl={xl} lg={lg} md={md} xs={12} spacing={3}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                This month's downtime
              </Typography>
              {downtime} minutes
            </CardContent>
          </Grid>
          {/*
          {geolocationData ? this.displayIPAddress() : null}
          {geolocationData ? this.displayGeolocationData() : null}
          */}
          {speedTestAverage7d ? this.displaySpeedTestDownloadAverage() : null}
          {speedTestAverage7d ? this.displaySpeedTestUploadAverage() : null}
        </Grid>
      </Card>
    )
  }

  displaySpeedTestTable = () => {
    const { speedtest } = this.props.adminViewUser
    if (Array.isArray(speedtest) && speedtest.length > 0) {
      return (
        <Card className={ styles.card }>
          <CardContent className={styles['card-content']}>
            <Typography variant="h5" component="h2" gutterBottom>
              Speed Tests
            </Typography>
            <SpeedTestTable data={speedtest} />
          </CardContent>
        </Card>
      )
    }
    return null
  }

  displayTermsOfServiceModal = (os) => () => {
    this.props.setDownloadOS(os)
    this.props.setTermsOfServiceModalDisplayed(true)
  }

  downloadsButton = () => {
    const { downloadingClient } = this.state
    const downloadBtnColor = downloadingClient ? 'disabled' : 'primary'

    return (
      <Typography component="p">
        <Button variant="contained" color={downloadBtnColor} disabled={downloadingClient} onClick={this.displayTermsOfServiceModal(this.props.operatingSystem)}>Download imup for {this.props.operatingSystem}!</Button>
      </Typography>
    )
  }

  loginOnComputer = () => {
    return (
      <Typography style={{display: 'inline-block'}} gutterBottom>
        Login in your computer to download imup!
      </Typography>
    )
  }

  displayDownloadCard = () => {
    const { connectivityData } = this.props

    if (Array.isArray(connectivityData) && connectivityData.length > 0) {
      return null
    }

    return (
      <Grid container spacing={1}>
        <Grid container item xl={6} lg={6} md={6} xs={12} spacing={3}>
          <Card className={ styles.card }>
            <CardContent>
              <Typography style={{display: 'inline-block'}} gutterBottom>
                Get started with &nbsp;
              </Typography>
              <Typography style={{display: 'inline-block'}} variant="h5" component="h2" gutterBottom>
                imUp
              </Typography>
              <p></p>
              <Typography color="textSecondary" component="subtitle2" gutterBottom>
                Charts will appear on this page with your internet connectivity data after imup gathers some data.
              </Typography>
              { this.showDownloads() ? this.downloadsButton() : this.loginOnComputer()}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  getISPDetails = (field) => {
    const ispDefaults = {
      provider: '',
      providerIds: [],
      price: 0,
      downloadSpeed: 0,
      uploadSpeed: 0,
      dataLimit: 0,
      accountNumber: '',
      username: '',
      password: '',
      pin: '',
      imupAuthorized: false,
      additionalDetails: ''
    }

    const ispValues = Object.assign({}, ispDefaults, this.props.adminViewUser.user.isp || {})
    return ispValues[field]
  }

  getAddressDetails = (field) => {
    const addressDefaults = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: ''
    }

    const addressValues = Object.assign({}, addressDefaults, this.props.adminViewUser.user.address || {})
    return addressValues[field]
  }

  getCustomerDetails = (field) => {
    const customerDefaults = {
      type: '',
      details: ''
    }

    const customerValues = Object.assign({}, customerDefaults, this.props.adminViewUser.user.customer || {})
    return customerValues[field]
  }

  removeDialogClick = () => {
    this.setState({
      open: this.state.open ? false : true
    })
  }

  removeUser = async () => {
    const { id, email } = this.props.adminViewUser.user
    const userdata = {
      data: {
        id,
        email
      }
    }
    this.setState({
      loading: true
    })
    const response = await axios.delete(`${this.props.url}/${this.props.routes.user.profile.remove}`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile deleted!'
      })
      this.props.history.push('/admin')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Delete failed!',
        message: 'Try again later!'
      })
    }
  }

  emailProviderClicked = () => {
    const { email } = this.state
    this.setState({
      email: Object.assign({}, email, { open: !this.state.email.open })
    })
  }

  handleChangeEmail = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { email } = this.state
    this.setState({
      email: Object.assign({}, email, { [name]: value })
    })
  }

  emailProviderSendClicked = async () => {
    const { email } = this.state
    this.setState({
      email: Object.assign({}, email, { sending: true })
    })

    await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, { email, user: email.attachedUser })
    // const response = await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, email)

    this.setState({
      email: Object.assign({}, email, { sending: false, open: false })
    })

    // if (response.data.success) {
    //   this.setState({
    //     email: Object.assign({}, email, { sending: false, open: false })
    //   })
    //
    //   this.props.notification({
    //     type: 'success',
    //     title: 'Success!',
    //     message: 'Sent email!'
    //   })
    // } else {
    //   this.setState({
    //     email: Object.assign({}, email, { sending: false })
    //   })
    //
    //   this.props.notification({
    //     type: 'danger',
    //     title: 'Email failed!',
    //     message: 'Try again later!'
    //   })
    // }
  }

  displayEmailISP = () => {
    if (!this.state.email.open) {
      return null
    }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.emailProviderSendClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
        >
          <TextValidator
            label="Attach to imUp user"
            onChange={ this.handleChangeEmail }
            name="attachedUser"
            value={ this.state.email.attachedUser }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="To"
            onChange={ this.handleChangeEmail }
            name="to"
            value={ this.state.email.to }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="BCC"
            onChange={ this.handleChangeEmail }
            name="bcc"
            value={ this.state.email.bcc }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="From"
            onChange={ this.handleChangeEmail }
            name="from"
            value={ this.state.email.from }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="Subject"
            onChange={ this.handleChangeEmail }
            name="subject"
            value={ this.state.email.subject }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="Text"
            onChange={ this.handleChangeEmail }
            name="text"
            value={ this.state.email.text }
            validators={[
              'minStringLength:0',
              'maxStringLength:10000'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 10000 characters'
            ]}
            margin="normal"
            fullWidth
            multiline
            rows={5}
            rowsMax={Infinity}
            variant="outlined"
            style={{ paddingTop:'10px'}}
          />
          {
            this.state.email.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <Button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Send email to {this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : ''}
            </Button>
          }
        </ValidatorForm>
      </div>
    )
  }

  displayUserProfile = () => {
    if (!this.props.adminViewUser || !this.props.adminViewUser.user) {
      return <div></div>
    }

    return (
      <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>User details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell style={{fontWeight: 'bold', color: '#1D98FF'}}>Plan</TableCell>
              <TableCell align="right" style={{fontWeight: 'bold', color: '#1D98FF'}}>{ this.props.adminViewUser.user.plan }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="right">{ this.props.adminViewUser.user.username }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">{ this.props.adminViewUser.user.name }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">{ this.props.adminViewUser.user.email }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Street Address</TableCell>
              <TableCell align="right">{ this.getAddressDetails('address') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell align="right">{ this.getAddressDetails('city') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell align="right">{ this.getAddressDetails('state') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zipcode</TableCell>
              <TableCell align="right">{ this.getAddressDetails('zipcode') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell align="right">{ this.getAddressDetails('country') }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="isp table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>ISP account information</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Provider</TableCell>
              <TableCell align="right">{ this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : '' }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Support email</TableCell>
              <TableCell align="right">{ this.getProviderEmail() }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account number</TableCell>
              <TableCell align="right">{ this.getISPDetails('accountNumber') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="right">{ this.getISPDetails('username') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Password</TableCell>
              <TableCell align="right">{ this.getISPDetails('password') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pin #</TableCell>
              <TableCell align="right">{ this.getISPDetails('pin') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>imUp authorization</TableCell>
              <TableCell align="right">{ this.getISPDetails('imupAuthorized') ? 'Yes' : 'No' }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="isp table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Connection details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Monthly cost</TableCell>
              <TableCell align="right">{ this.getISPDetails('price') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Download speed (Mbps)</TableCell>
              <TableCell align="right">{ this.getISPDetails('downloadSpeed') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Upload speed (Mbps)</TableCell>
              <TableCell align="right">{ this.getISPDetails('uploadSpeed') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data limit (GB)</TableCell>
              <TableCell align="right">{ this.getISPDetails('dataLimit') }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="isp table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Notes</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Additional details</TableCell>
              <TableCell align="right">{ this.getISPDetails('additionalDetails') }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <p></p>
      <TableContainer component={Paper}>
        <Table aria-label="customer details table">
          <TableBody>
            <TableRow style={{backgroundColor: '#1D98FF'}}>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Customer Details</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">{ this.getCustomerDetails('type') }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Details</TableCell>
              <TableCell align="right">{ this.getCustomerDetails('details') }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

        <Button
          onClick={ this.removeDialogClick }
          className={ styles.button }
          variant="contained"
          color="secondary"
        >
          Delete
        </Button>
        <Button
          className={ styles.button }
          variant="contained"
        >
          <Link className={ styles.link } to="/admin/user/modify">Modify</Link>
        </Button>
        <Button
          onClick={ this.emailProviderClicked }
          className={ styles.button }
          variant="contained"
          color="primary"
        >
          Email provider ({this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : ''})
        </Button>
        <Dialog
          open={ this.state.open }
          onClose={ this.removeDialogClick }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete your profile?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure?<br/>This action is irreversible, you will lose all your data!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={ this.removeDialogClick } color="primary">
              No
            </Button>
            <Button onClick={ this.removeUser } color="secondary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  displayStats = () => {
    const xl = 6
    const lg = 6
    const md = 12
    const xs = 12

    return (
      <Card className={ styles.card }>
        <Grid container justify="center" item xl={xl} lg={lg} md={md} xs={xs} spacing={3}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Average Speeds
            </Typography>
            <StatsTableSpeedTest adminViewUser={this.props.adminViewUser} />
          </CardContent>
        </Grid>
        <Grid container justify="center" item xl={xl} lg={lg} md={md} xs={xs} spacing={3}>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Down-days
            </Typography>
            <StatsTableDowntime adminViewUser={this.props.adminViewUser} />
          </CardContent>
        </Grid>
      </Card>
    )
  }

  displayEmailTable = () => {
    if (!this.props.adminViewUser || !this.props.adminViewUser.user || !Array.isArray(this.props.adminViewUser.user.emails)) {
      return <div></div>
    }

    return (
      <EmailTable
        data={this.props.adminViewUser.user.emails.sort(function(a,b){
          return new Date(b.createdAt) - new Date(a.createdAt)
        })}
      />
    )
  }

  render() {
    // const { user } = this.props

    // console.log('this.props.adminViewUser.user', this.props.adminViewUser.user)

    return (
      <div className={ styles.home }>
        {/*
        {
          user && !user.active ?
          <RegistrationFinish></RegistrationFinish> :
          null
        }
        */}
        <Fab />
        { this.props.adminViewUser ? this.displayUserProfile() : null }
        { this.props.adminViewUser ? this.displayEmailISP() : null }
        { this.props.adminViewUser ? <Modal /> : null }
        { this.props.adminViewUser ? <TermsOfServiceModal /> : null }
        { this.props.adminViewUser ? this.displayDowntimeCard() : null }
        {this.displayDownloadCard()}

        { this.props.adminViewUser ? this.displayStats() : null }
        { this.props.adminViewUser ? this.displayChart() : null }
        { this.props.adminViewUser ? this.displaySpeedTestTable() : null }
        {this.displayEmailTable()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    geolocationData: state.geolocationData,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false,
    adminViewUser: state.adminViewUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminUser))

import React, { Component } from 'react'
import styles from './organizationHost.module.scss'
import { Collapse, IconButton, Button, ButtonGroup, Card, CardContent, Grid, Typography, CardHeader, CardActionArea, CardActions, CardMedia, Tooltip } from '@material-ui/core';
import { PieChart, Pie, BarChart, Bar, CartesianGrid, Legend, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  setOrganization,
  setOrganizationHosts,
  setOrgHostSpeedTestData,
  setOrgHostConnectivityData,
  setSpeedTestStatusModalDisplayed,
  setSpeedTestStatusResults,
  setOrgHostPingData,
  setOrgHostPingChartData,
  setHostLivenessStatus
} from '../../actions/connectionActions'
import SpeedIcon from '@material-ui/icons/Speed';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import axios from 'axios'

import Chart from '../InternetConnectivity/chart'
import SpeedTestTable from '../SpeedTest/table'
import SpeedTestChart from '../SpeedTest/chart'
import PingDataTable from './pingDataTable'
import PingDataChart from '../PingDataChart/PingDataChart'
import SpeedTestStatusModal from '../SpeedTestStatusModal/SpeedTestStatusModal'
import Divider from '@material-ui/core/Divider';
import HostsTable from './hostsTable'
import PacketLossChart from '../Home/packetLossChart'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

class OrganizationHost extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      createOrgOpen: true,
      apiKeyId: this.props.match.params.apiKeyId,
      hostId: decodeURIComponent(this.props.match.params.hostId),
      // optional params
      groupId: decodeURIComponent(this.props.match.params.groupId || ''),
      groupName: decodeURIComponent(this.props.match.params.groupName || ''),
      exportInProgress: false,
      hostStatusIntervalId: null,
    }
  }

  loadConnectivityData = async () => {
    try {
      const connectivityData = await axios.get(`${this.props.url}/${this.props.routes.organization.getOrgHostConnectivityData}`, { params: { apiKey: this.state.apiKeyId, host: this.state.hostId } })
      if (connectivityData && connectivityData.data.success) {
        this.props.setOrgHostConnectivityData(connectivityData.data.data)
      }
    } catch(e) {
      console.log('error getting connectivity data')
      console.log(e)
    }
  }

  loadSpeedTestData = async () => {
    try {
      const speedtestData = await axios.get(`${this.props.url}/${this.props.routes.organization.getOrgHostSpeedTestData}`, { params: { apiKey: this.state.apiKeyId, host: this.state.hostId } })
      if (speedtestData && speedtestData.data.success) {
        this.props.setOrgHostSpeedTestData(speedtestData.data.data)
      }
    } catch(e) {
      console.log('error getting speed test data')
      console.log(e)
    }
  }

  loadPingData = async () => {
    try {
      const pingData = await axios.get(`${this.props.url}/${this.props.routes.organization.getOrgHostPingData}`, { params: { apiKey: this.state.apiKeyId, host: this.state.hostId } })
      if (pingData && pingData.data && pingData.data.data) {
        this.props.setOrgHostPingData(pingData.data.data.map(p => {
          p.id = p.time
          p.latency = p.avg_rtt / 2
          return p
        }))

        this.props.setOrgHostPingChartData(pingData.data.data.map(p => {
          return {
            ...p,
            avg_rtt: p.avg_rtt/1000000,
            latency: p.avg_rtt/2/1000000,
          }
        }))
      }
    } catch(e) {
      console.log('error getting ping stats data')
      console.log(e)
    }
  }

  loadAllTheThings = async () => {
    await Promise.all([
      this.loadConnectivityData(),
      this.loadSpeedTestData(),
      this.loadPingData(),
    ])
  }

  async componentDidMount() {
    await this.loadAllTheThings()

    // copy pasta from OrganizationUser.js to move quickly
    const orgHosts = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: { id:  this.props.organization.id } })
    if (orgHosts && orgHosts.data.success) {
      this.props.setOrganizationHosts(orgHosts.data.data)
    }

    // const organization = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id:  this.state.orgId } })
    // if (organization && organization.data.success) {
    //   console.log('organization data data', organization.data.data)
    //   this.props.setOrganization(organization.data.data)
    // }

    console.log('this.props.organization data data', this.props.organization)
    if (this.props.organization && orgHosts && orgHosts.data.success) {
      const apiKeyLookup = {}
      const apiKeyUsageCount = {}
      const apiKeyNameLookup = {}
      this.props.organization.apiKeys.forEach(k => {
        apiKeyLookup[k.id] = k.seats
        apiKeyNameLookup[k.id] = k.name
        apiKeyUsageCount[k.id] = 0
      })

      orgHosts.data.data.forEach(h => {
        apiKeyUsageCount[h.apiKey] = apiKeyUsageCount[h.apiKey] + 1
      })

      this.setState({ apiKeyLookup, apiKeyUsageCount, apiKeyNameLookup })
    }

    // set polling interval for getting hosts liveness status
    if (this.props.organization && orgHosts && orgHosts.data.success) {
      const that = this

      // every 5 seconds
      let intervalId = setInterval(async function(){
        const hostConnectedStatus = await axios.post(`${that.props.url}/${that.props.routes.realtime.hostConnectedStatus}`, {
          hosts: that.props.organizationHosts ? that.props.organizationHosts.map(h => `${h.apiKey}__IMUP_DELIMITER__${h.hostId}`) : []
        })

        if (hostConnectedStatus && hostConnectedStatus.data && hostConnectedStatus.data.success && hostConnectedStatus.data.data && hostConnectedStatus.data.data.status) {
          that.props.setHostLivenessStatus(hostConnectedStatus.data.data.status)
        } else {
          console.log('failed to get host liveness status')
        }
      }, 5000);

      this.setState({ hostStatusIntervalId: intervalId })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.hostStatusIntervalId);
  }

  runExport = (exportType) => async () => {
    this.setState({
      exportInProgress: true
    })

    try {
      axios({
        url: `${this.props.url}/${this.props.routes.organization.export}`,
        method: 'GET',
        // blob is what allows the file to be downloaded
        responseType: 'blob',
        params: {
          apiKey: this.state.apiKeyId,
          host: this.state.hostId,
          type: exportType,
        },
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.state.hostId}-${exportType}.zip`)
          document.body.appendChild(link)
          link.click()

          this.setState({
            exportInProgress: false
          })
      })
    } catch (e) {
      console.log('error exporting data:', e)
      this.setState({
        exportInProgress: false
      })
    }
  }

  findHostLivenessStatus = (host) => {
    let hostAlive = false
    if (!this.props.hostLivenessStatus) {
      return hostAlive
    }

    // eslint thinks h isn't being used here even though it clearly is
    // eslint-disable-next-line
    for (const h of this.props.hostLivenessStatus) {
      if (host === h.host) {
        hostAlive = h.status
        break;
      }
    }
    return hostAlive
  }

  onHostsTableRowClick = async (row) => {
    console.log('row clicked:', row)
    this.props.history.push(`/host/${row.apiKey}/${encodeURIComponent(row.hostId)}`)
    this.setState({
      apiKeyId: row.apiKey,
      hostId: row.hostId,
    })
    await this.loadAllTheThings()
  }

  displayChart = () => {
    const { orgHostConnectivityData } = this.props
    if (Array.isArray(orgHostConnectivityData) && orgHostConnectivityData.length > 0) {
      return (
        <div>
        <Card variant="outlined" className={ styles.card }>
          <CardHeader
            action={
              <Tooltip title='Export & Download Connectivity Data'>
                <button
                  className={ styles.button2 }
                  onClick={this.runExport('connectivity')}
                  disabled={this.state.exportInProgress}
                >
                <DownloadIcon fontSize="small"/>
                </button>
              </Tooltip>
            }
            subheader="Internet Connection"
            className={styles.cardHeader}
          />
          {Chart(orgHostConnectivityData, this.props.theme)}
        </Card>
        </div>
      )
    }
    return null
  }

  runSpeedTest = async () => {
    this.props.setSpeedTestStatusModalDisplayed(true)
    try {
      const realtimeSpeedTest = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtest}`, {
        hosts: [`${this.state.apiKeyId}__IMUP_DELIMITER__${this.state.hostId}`]
      })
      if (realtimeSpeedTest && realtimeSpeedTest.data.success) {
        console.log('successfully ran speed test')

        const checkSpeedTestStatus = async () => {
          console.log('checking realtime status')
          try {
            const realtimeSpeedTestStatus = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtestStatus}`, {
              hosts: [`${this.state.apiKeyId}__IMUP_DELIMITER__${this.state.hostId}`]
            })
            console.log('realtime status:', realtimeSpeedTestStatus)
            if (realtimeSpeedTestStatus && realtimeSpeedTestStatus.data && realtimeSpeedTestStatus.data.success && realtimeSpeedTestStatus.data.data && realtimeSpeedTestStatus.data.data.status) {
              this.props.setSpeedTestStatusResults(realtimeSpeedTestStatus.data.data.status)
            }
          } catch(e) {
            console.log('error checking realtime status:', e)
          }
        }

      const that = this
      async function runner(repeats = 1) {
        if (repeats > 0) {
          await checkSpeedTestStatus()
          setTimeout(() => runner(repeats - 1), 3000)
        } else {
          if (that.props.speedTestStatusResults) {
            const timedOutResults = that.props.speedTestStatusResults.map(r => ({
              host: r.host,
              status: r.status === 'pending' || r.status === 'running' ? 'timed out' : r.status
            }))

            that.props.setSpeedTestStatusResults(timedOutResults)
          }
        }
      }

      runner(100)

      } else {
        console.log('failed to run speed test')
      }
    } catch(e) {
      console.log('error running speed test')
      console.log(e)
    }
  }

  displaySpeedTestTable = () => {
    const { orgHostSpeedTestData } = this.props
    if (Array.isArray(orgHostSpeedTestData) && orgHostSpeedTestData.length > 0) {
      return (

      <Card variant="outlined" className={ styles.card }>
        <CardHeader
          action={
            <ButtonGroup variant="outlined">
              <Tooltip title="Run a speed test now!">
                <button
                  className={ styles.button2 }
                  style={{ marginBottom: '10px'}}
                  onClick={this.runSpeedTest}
                >
                <SpeedIcon fontSize="small"/></button>
              </Tooltip>
              <Tooltip title='Export & Download Speed Test Data'>
                <button
                  className={ styles.button2 }
                  style={{ marginBottom: '10px'}}
                  onClick={this.runExport('speedtest')}
                  disabled={this.state.exportInProgress}
                >
                <DownloadIcon fontSize="small"/>
                </button>
              </Tooltip>
            </ButtonGroup>
          }
          subheader="Speed Tests"
          className={styles.cardHeader}
        />
        <SpeedTestChart data={orgHostSpeedTestData} theme={this.props.theme} />
            <Accordion style={{margin:'20px'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Speed Test Metrics Table</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SpeedTestTable data={orgHostSpeedTestData} />
              </AccordionDetails>
            </Accordion>
        </Card>
      )
    }
    return null
  }

  displayPingDataTable = () => {
    const { orgHostPingData, orgHostPingChartData, theme } = this.props
    if (Array.isArray(orgHostPingData) && orgHostPingData.length > 0) {
      return (
        <Card variant="outlined" className={ styles.card }>
          <CardHeader
            action={
              <Tooltip title='Export & Download Connectivity Data'>
                <button
                  className={ styles.button2 }
                  onClick={this.runExport('connectivity')}
                  disabled={this.state.exportInProgress}
                >
                <DownloadIcon fontSize="small"/>
                </button>
              </Tooltip>
            }
            subheader="Connection Health"
            className={styles.cardHeader}
          />
          <PingDataChart data={orgHostPingChartData} theme={theme} />
          <CardHeader
            subheader="Packet Loss"
            className={styles.cardHeader}
          />
          <PacketLossChart data={orgHostPingData} theme={theme} />
          <Accordion  style={{margin:'20px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Connection Health Metrics Table</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PingDataTable data={orgHostPingData} />
            </AccordionDetails>
          </Accordion>
        </Card>
      )
    }
    return null
  }

  // displayOrganizations = () => {
  //   const { orgId } = this.state

  //   return (
  //       <Card variant="outlined" className={ styles.card }>
  //         <CardHeader
  //           subheader="Your Hosts"
  //           className={styles.cardHeader}
  //         />
  //         <HostsTable
  //           showLive={true}
  //           serverSidePagination={true}
  //           onRowClick={this.onHostsTableRowClick}
  //           setSelection={() => {}}
  //           data={!this.props.organizationHosts ? [] : this.props.organizationHosts.map(u => { u.id = `${u.apiKey}__IMUP_DELIMITER__${u.hostId}`; u.live = this.findHostLivenessStatus(`${u.apiKey}__IMUP_DELIMITER__${u.hostId}`); return u})}
  //         />
  //       </Card>
  //   )
  // }


  render() {
    const { orgId } = this.state
    return (
      <div className={ styles.organizations }>
        <SpeedTestStatusModal />
        <Breadcrumbs aria-label="breadcrumb">
            <Link href={`/org/${this.props.organization.id}`} className={styles.link}>
            <span style={{color:'#1d98ff'}}>{this.props.organization ? this.props.organization.name : orgId}</span>
            </Link>
            {!this.state.groupId ? null : (
              <Link
                href={`/org/${this.props.organization.id}/group/${this.state.groupId}`}
                className={ styles.link }
              >
                <span style={{color:'#1d98ff', textDecoration: 'none !important'}}>{this.state.name}{this.state.default ? ' (default)' : ''}</span>
                {this.state.groupName || this.state.groupId}
              </Link>
            )}
            <span>
              {this.state.hostId}
            </span>
          </Breadcrumbs>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={11} xl={11} justifyContent="center" alignItems="center">
            {this.displayChart()}
            {this.displaySpeedTestTable()}
            {this.displayPingDataTable()}
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4} justifyContent="center" alignItems="center">
            {this.displayOrganizations()}
            <Divider style={{ margin:'4%'}}/>
          </Grid> */}
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    theme: state.theme,
    organization: state.organization,
    organizationHosts: state.organizationHosts,
    orgHostSpeedTestData: state.orgHostSpeedTestData,
    orgHostConnectivityData: state.orgHostConnectivityData,
    speedTestStatusResults: state.speedTestStatusResults,
    orgHostPingData: state.orgHostPingData,
    orgHostPingChartData: state.orgHostPingChartData,
    hostLivenessStatus: state.hostLivenessStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationHosts: data => { dispatch(setOrganizationHosts(data)) },
    setOrgHostConnectivityData: data => { dispatch(setOrgHostConnectivityData(data)) },
    setOrgHostSpeedTestData: data => { dispatch(setOrgHostSpeedTestData(data)) },
    setOrgHostPingData: data => { dispatch(setOrgHostPingData(data)) },
    setOrgHostPingChartData: data => { dispatch(setOrgHostPingChartData(data)) },
    setSpeedTestStatusModalDisplayed: data => { dispatch(setSpeedTestStatusModalDisplayed(data)) },
    setSpeedTestStatusResults: data => { dispatch(setSpeedTestStatusResults(data)) },
    setHostLivenessStatus: data => { dispatch(setHostLivenessStatus(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationHost))

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styles from './home.module.scss'
import { Button, Card, CardContent, Grid, Typography, Paper } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Modal from './homeDownloadsModal'
import { setTermsOfServiceModalDisplayed, setDownloadOS } from '../../actions/connectionActions'
import Divider from '@mui/material/Divider';

class Downloads extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false
    }
  }

  displayModal = (os) => () => {
    this.props.setDownloadOS(os)
    this.props.setTermsOfServiceModalDisplayed(true)
  }

  getBtnText = (os) => {
    return this.props.operatingSystem === os ? `${os} (recommended)` : os
  }

  displayDownloadCard = () => {
    const { downloadingClient } = this.state
    const downloadBtnColor = downloadingClient ? 'disabled' : 'primary'

    return (
      <div>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Grid item xs={12}>
          <div style={{ maxHeight: 'calc(100vh - 500px)', overflow: 'auto' }}>
            <center><p id="download-modal-description">Download our desktop application<br /> <a href="https://github.com/imup-io/client" target="_blank" style={{color:"#1d98ff"}}>Read about our client here</a><br /></p></center>
          </div>
        </Grid>
        <Divider className={styles.divider} />
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} spacing={3}>
          <Card className={ styles.card } variant="outlined">
            <CardContent>
              <center>
              <Typography variant="h5" component="h5" gutterBottom>
                Mac OS
              </Typography>
              <Typography variant="body2" component="body2" gutterBottom>
                Run the imUp app on your Mac OS computer
              </Typography>
              <p></p>
              <Link to="/MacInstallation" target="_blank" style={{color: '#1D98FF'}}>Mac OS instructions</Link>
              <p></p>
              <Typography component="p">
                <button
                  className={ styles.button }
                  variant="contained"
                  color={downloadBtnColor}
                  disabled={downloadingClient}
                  onClick={this.displayModal('Mac OS')}
                >
                  Download
                </button>
              </Typography>
              </center> 
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} spacing={3}>
          <Card className={ styles.card } variant="outlined" style={{height: '90%'}}>
            <CardContent>
              <center>
              <Typography variant="h5" component="h5" gutterBottom>
                Windows
              </Typography>
              <Typography variant="body2" component="body2" gutterBottom>
                Run the imUp app on your Windows computer
              </Typography>
              <p></p>
              <Link to="/WindowsInstallation" target="_blank" style={{color: '#1D98FF'}}>Windows instructions</Link>
              <p></p>
              <Typography component="p">
                <button
                  className={ styles.button }
                  variant="contained"
                  color={downloadBtnColor}
                  disabled={downloadingClient}
                  onClick={this.displayModal('Windows')}
                >
                  Download
                </button>
              </Typography>
              </center>
            </CardContent>
          </Card>
        </Grid>
        <Alert severity="info" style={{ marginLeft:'20px', marginTop:'2em', width:'100%' }}>For best results: <br /> - you should run the imUp app on a computer that stays online and connected via an ethernet cable <br /> - ensure that your computer has power settings configured that will not put disks to sleep to ensure the app is able to continue running. </Alert><br />
      </Grid>
    </div>
    )
  }

  render() {
    return (
      <div className={ styles.home }>
        {this.displayDownloadCard()}
        { this.props.user ? <Modal /> : null }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    operatingSystem: state.operatingSystem,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Downloads))

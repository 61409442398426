import React from 'react'
import styles from './installationInstructions.module.scss'
import { Button, ButtonGroup, Link, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CodeBlock, dracula } from "react-code-blocks";
import Client from '../../resources/imup_client.png';

export default function MacInstructions() {
  return (
    <div className={ styles.home }>
      <div>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Typography variant="h4" component="h4" gutterBottom>
          Mac OS Instructions
        </Typography>
            <hr />
            <Alert severity="info">When we update the Mac OS client, you will receive a notification in the top left of the app</Alert>
            <br />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} spacing={3}>
            <Card>
            <CardContent>
            <ol>
              <li>Open the imUp.dmg file.</li>
              <li>In the window that pops up, drag the imUp application into your <strong>Applications</strong> folder</li>
              <li>Double click on the <strong>Applications</strong> folder to open it.</li>
              <li>Find imUp in the list of applications.</li>
              <li>Double click on imUp to open it.</li>
              <li>A warning will pop up saying: <strong>This app is downloaded from the internet, are you sure you wan to open?</strong> Click OK.</li>
              <li>Within the app, type in the email address you used to register with imUp and click <strong>Start imUp</strong>. Within 15-30 minutes you should start seeing data at <a target="_blank" rel="noopener noreferrer" href="https://app.imup.io" style={{color: '#1D98FF' }}>https://app.imup.io</a>.</li>
              <li>
                We recommend starting imUp automatically when your computer boots up so we're always able to detect your speeds and downtime.
                <ol>
                  <li>
                    Open <strong>System Preferences</strong> and click on <strong>Users & Groups</strong>. You can get to this quickly by hitting <strong>command + spacebar</strong>, start typing <strong>Users & Groups</strong>, and hitting <strong>enter</strong> when your Mac recognizes what you're searching for.
                  </li>
                  <li>Click the <strong>Login Items</strong> tab.</li>
                  <li>Click the <strong>+</strong> sign and select <strong>imUp</strong> from the list of applications. Then click <strong>Add</strong> and you're done!</li>
                </ol>
              </li>
            </ol>
              </CardContent>
            </Card>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} spacing={3}>
              <img src={Client} alt="imUp Mac" height="520" class="center" styles={{ borderRadius:'8px'}}/>
            </Grid>
        </Grid>
      </div>
    </div>
  )
}

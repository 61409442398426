import React from 'react'
import styles from './privacyPolicy.module.scss'

export default function PrivacyPolicy() {
  return (
    <div className={ styles.home }>
      <div>
        <p><strong>Imup.io Privacy And Security Statement</strong></p>
        <p>We at Imup.io know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. <strong>By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will (or won&rsquo;t) collect, use, and share your information in the following ways.</strong></p>
        <p>Remember that your use of Imup.io&rsquo;s Services is at all times subject to the<a style={{color: '#1D98FF'}} href="https://app.imup.io/terms">Terms of Service,</a> which incorporates this Privacy Policy.&nbsp;</p>
        <p>Imup.io ("we", "our" or "us") is committed to developing technology that gives a safe online experience and protects your privacy.</p>
        <h1>What does this Privacy Policy Cover?</h1>
        <p>This Privacy Policy covers our treatment of personally identifiable information ("Personal Information"), as defined by applicable law, that we gather when you are accessing or using our Services, but not to the practices of companies we don&rsquo;t own or control, or people that we don&rsquo;t manage. This Privacy and Security Statement discloses the privacy policy and security practices for the Imup.io site.&nbsp;</p>
        <p>We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to allow you to set up a user account and profile, to contact you. In certain cases, we may also share some Personal Information with third parties, but only as described below.</p>
        <p>We do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us. This site offers plans that are specifically designed for persons who are 13 years of age or older and is intended for use by persons who have the authority to make internet service purchasing decisions for their household or business. No one under the age of 13 may provide any personal information to us or access or use our paid Services.&nbsp;</p>
        <p>If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:support@imup.io" style={{color: '#1D98FF'}}>support@imup.io</a> and such information will be deleted as soon as practicable.&nbsp;</p>
        <h1>Important Notice to all non-US Residents</h1>
        <p>Imup.io and its servers are operated in the United States and is intended for use therein. Please be aware that any information may be transferred, stored, processed or maintained on computers, servers and systems located outside your governmental jurisdiction of origin and may thus fall under less protective data regulations. If you are outside the U.S. and provide any Personal Information then you are doing so at your own risk and with no warrant of compliance with jurisdictional laws on the part of Imup.io.</p>
        <h1>Security and Protection of Personal Information</h1>
        <p>Imup.io is committed to maintaining the security of Personal Information (defined below) obtained through our site. We take commercially reasonable precautions to protect your Personal Information both online and offline.</p>
        <p>Our security policy keeps Personal Information that is stored in our systems protected from unauthorized access. Imup.io secures personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. Imup.io systems are configured with data encryption technologies and firewalls. When you send Personal Information to us over the Internet, or we send it to service providers over the Internet, your data is protected by Secure Socket Layer (SSL) technology to ensure safe transmission.</p>
        <p>Your account is protected by a password for your privacy and security. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password and/or other sign-on mechanism appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.</p>
        <p>While we endeavor to protect the privacy of your account and other Personal Information we hold in our records and take all legally necessary steps to ensure that all Personal Information is protected through reasonable data protection means, we unfortunately cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.</p>
        <p>Our employees are prohibited from using or disseminating such information in any unauthorized manner.&nbsp;</p>
        <p>We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you; for example, we may use a payment processing company to receive and process your credit card transactions for us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us.&nbsp;</p>
        <h1>Collection of your Personal Information</h1>
        <p>We receive and store any information you provide to us. For example, through the registration process and/or through your account settings, we may collect Personal Information such as your name, email address, phone number, and third-party account credentials (for example, your log-in credentials for third party sites). If you provide your third-party account credentials to us or otherwise sign in to the Services through a third party site or service, you understand some content and/or information in those accounts (&ldquo;Third Party Account Information&rdquo;) may be transmitted into your account with us, and that Third Party Account Information transmitted to our Services is covered by this Privacy Policy.</p>
        <p>On those pages of our site where Personal Information is required for the purchase of a product or service, we describe what information is required in order to provide you with the product, service or information that you have requested.</p>
        <p>We do not knowingly collect information from children under 13. If a Service Provider requires a birth date to order a specific product or service, you will not be able to enter a birth date that indicates that you are less than 13 years old.</p>
        <p>We collect Personally Identifiable Information when you:</p>
        <ul>
        <li>Become authorized to use our site through enrollment</li>
        <li>Purchase a product or service</li>
        <li>Request further information regarding a product or service</li>
        <li>Disconnect a service</li>
        <li>Contact us</li>
        </ul>
        <h1>User Provided Personal Information</h1>
        <p>Imup.io collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. Imup.io also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.</p>
        <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through the Imup.io public message boards or the Imup.io blog, this information may be collected and used by others. Note: Imup.io does not read any of your private online communications.</p>
        <p>Imup.io encourages you to review the privacy statements of web sites you choose to link to from Imup.io so that you can understand how those web sites collect, use and share your information. Imup.io is not responsible for the privacy statements or other content on web sites outside of the Imup.io and Imup.io family of web sites.</p>
        <h1>Non User Provided Information</h1>
        <p>There is also information about your computer hardware, software, and network that is automatically collected by Imup.io. This information can include: your IP address, Internet Service Provider, Operating System, browser type, domain names, access times and referring Web site addresses. This information is used by Imup.io for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Imup.io web site.</p>
        <h1>Purchase Information</h1>
        <p>Imup.io collects payment information necessary to set up the billing for Imup.io membership and the services and products that you purchase.</p>
        <p>If you use a credit card for payment, the information Imup.io may collect includes card number, card type, cardholder name, billing address and expiration date.</p>
        <p>If you use automatic deduction (electronic funds transfer) to purchase services or products, Imup.io may collect account information necessary to set up the billing and payment for the services and products that you purchase. This account information includes the account number, account type, account name and the ABA routing number of the financial institution where the account is located.</p>
        <h1>What choices do I have?</h1>
        <p>You can opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.</p>
        <p>You may be able to add, update, or delete information as explained above. When you update information, however, we may maintain a copy of the unrevised information in our records. You may request deletion of your account by going to your Settings page or emailing us at support@imup.io. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally.</p>
        <h1>Right of Deletion</h1>
        <p>Upon receipt of a &ldquo;verifiable consumer request&rdquo; as defined in Section 1798.105 of the California Consumer Privacy Act, Imup.io will delete all personal information from its records and direct all third parties with whom such information has been shared to do the same. However, please note that Imup.io may retain certain personal information or not fully comply with such a deletion request if Imup.io, in its sole and good faith discretion, determines that such information is necessary to the business for purposes of such as providing continued services to the person filing such a request, detecting or resolving issues related to the provision of services to others, complying with present or future legal obligations, using the information solely for internal use in a manner that a reasonable customer could expect, conducting research in the public interest or the exercise of free speech.</p>
        <h1>Use of Information</h1>
        <p>Imup.io collects and uses your personal information to operate the Imup.io web site and deliver the services you have requested. Imup.io may also use your personal information to inform you of other products or services available from Imup.io and its affiliates. Imup.io may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>
        <p>We may communicate with you if you&rsquo;ve provided us the means to do so. For example, if you&rsquo;ve given us your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the Services. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make our communications with you more interesting and improve our services. If you do not want to receive communications from us, please indicate your preference by opting-out. For email communications, you can opt-out using the Unsubscribe link provided in the email.&nbsp;</p>
        <h1>Internet Service Providers</h1>
        <p>All services and products throughout our site are provided by unaffiliated third parties. When choosing to purchase products or services from any service provider, you must take into consideration that you will be transacting business directly with this service provider. The information provided will be similar to information that would be provided pursuant to a purchase of such product or service by telephone or in person.</p>
        <p>Imup.io provides your personal and purchase information to third party service providers, as necessary, to enroll you in the services selected. While it is our intention to only work with reputable service providers, Imup.io encourages you to review the privacy policies of third party service providers. Imup.io is not responsible for the privacy statements or other service providers. Imup.io is not responsible for the security of personal information used to enroll you in third part services and is not liable for losses resulting from third party service provider data breaches.</p>
        <p>We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our partners. We may also provide aggregate usage information to our partners (or allow partners to collect that information from you), who may use such information to understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal online experience. However, we never disclose aggregate usage or de-identified information to a partner (or allow a partner to collect such information) in a manner that would identify you as an individual.&nbsp;</p>
        <h1>Other Third Parties</h1>
        <p>Imup.io does not sell, rent or lease its customer lists to third parties. Imup.io may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, Imup.io may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Imup.io, and they are required to maintain the confidentiality of your information.</p>
        <h1>Disclosure of Sensitive Information</h1>
        <p>Imup.io does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.</p>
        <h1>Legal Obligations</h1>
        <p>Imup.io will disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Imup.io or the site; (b) protect and defend the rights or property of Imup.io; and, (c) act under exigent circumstances to protect the personal safety of users of Imup.io, or the public.</p>
        <h1>Will Imup.io ever change this Privacy Policy?</h1>
        <p>We&rsquo;re constantly trying to improve our Services, so Imup.io will occasionally update this Privacy Policy to reflect company and customer feedback, but we will alert you to changes by placing a notice on the Services, by sending you an email, and/or by some other means. Please note that if you&rsquo;ve opted not to receive legal notice emails from us (or you haven&rsquo;t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.</p>
        <p>Imup.io encourages you to periodically review this Statement to be informed of how Imup.io is protecting and using your information.</p>
        <h1>Contact Information</h1>
        <p>Imup.io welcomes your comments regarding this Statement of Privacy. If you believe that Imup.io has not adhered to this Statement, please contact Imup.io at support@imup.io. We will use commercially reasonable efforts to promptly determine and remedy the problem.</p>
      </div>
    </div>
  )
}

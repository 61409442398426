import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import OrganizationBilling from '../OrganizationBilling/OrganizationBilling';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import styles from './payments.module.scss'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '70%',
      height: '72%',
      overflow: 'auto'
    },
    checkbox: {
      color: 'red',
      '&$checked': {
        color: 'red',
      },
    },
    checked: {},
  }));

function ModalWithButton(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
        <>
            <Button className={styles.button} styles={{marginTop:'20px !important'}} onClick={handleOpen}>
                Create API key
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
            >
                <Paper className={classes.paper}>
                    <OrganizationBilling open={open} onClose={handleClose} />
                </Paper>
            </Modal>
        </>
    );
}

export default ModalWithButton;

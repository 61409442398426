import React, { Component } from 'react'
import styles from './payments.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StripeForm from './stripe'
import { setOrganization } from '../../actions/connectionActions'

class Payments extends Component {
  render() {
    return (
      <div className={ styles.stripe }>
        <StripeForm
          tier={this.props.paymentPlan}
          notification={this.props.notification}
          setOrganization={this.props.setOrganization}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    paymentPlan: state.paymentPlan,
    organization: state.organization,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments))

import React, { Component } from 'react'
import styles from './payments.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StripeForm from './stripe'
import { setOrganization } from '../../actions/connectionActions'
import APIKeyForm from './apiKeyForm'
import PricingTable from './PricingTable'
import PricingData from './PricingData'
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios'

class Payments extends Component {
  async componentDidMount() {

    // necessary until everyone has the new state from API server
    const orgId = typeof this.props.user.organizations[0] === 'string'
      ? this.props.user.organizations[0]
      : this.props.user.organizations[0]._id

    const organization = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id: orgId } })
    if (!!organization && !!organization.data && organization.data.success) {
      this.props.setOrganization(organization.data.data)
    }
  }

  isExistingCustomer = () => {
    if (!this.props.organization) {
      return false
    }

    if (!this.props.organization.stripe || (!this.props.organization.stripe.customerId || !this.props.organization.stripe.subscriptionId) || !Array.isArray(this.props.organization.apiKeys) || this.props.organization.apiKeys.length < 1) {
      return false
    }

    return true
  }
  displayCreateOrUpdate = () => {
    if (!this.props.organization) {
      return <div>Error loading organization</div>
    }


    if (!this.isExistingCustomer()) {
      return (
        <StripeForm
          tier={this.props.paymentPlan}
          notification={this.props.notification}
          setOrganization={this.props.setOrganization}
          pricing={PricingData}
        />
      )
    }

    return (
      <div>
        <APIKeyForm pricing={PricingData} />
      </div>
    )
  }
  getTrialEndDate = () => {
    const numWeeks = 2
    const now = new Date()
    const trialEnd = new Date(now.setDate(now.getDate() + numWeeks * 7))

    // https://stackoverflow.com/a/5416970
    return `${trialEnd.getMonth()+1}/${trialEnd.getDate()}/${trialEnd.getFullYear()}`
  }
  render() {
    return (
      <div className={ styles.stripe }>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {this.displayCreateOrUpdate()}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <PricingTable pricing={PricingData} />
            <br />
            {this.isExistingCustomer() ? null
             : (
              <p>Your card information is not stored in imUp.io. All payments are secured through Stripe Payment Processing. For more information on Stripe security, please visit their site <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe" style={{color: '#1D98FF' }}>here</a></p>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    notification: state.notification,
    paymentPlan: state.paymentPlan,
    organization: state.organization,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments))

import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function UsersTable(props) {
  return !props.data ? null : (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="host data">
        <TableHead style={{backgroundColor: '#1D98FF'}}>
          <TableRow>
            <TableCell align="left" style={{color: '#fff', fontWeight: 'bold'}}>Name</TableCell>
            <TableCell align="left" style={{color: '#fff', fontWeight: 'bold'}}>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => !row ? null : (
            <TableRow key={row.email}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import styles from './navigation.module.scss'

const useStyles = makeStyles((theme) => ({
  listItemDone: {
    "&:hover": {
      color: "#1D98FF"
    }
  },
}));

export default function Admin(props) {
  const classes = useStyles();

  const showAdmin = () => {
    return props.user && props.user.email && (props.user.email.endsWith("@imup.io") || props.user.email === 'brad7miller@gmail.com')
  }

  return (
    <div className={ styles.nav }>
      <div className={ styles.flexGrow }>
        <font>
          {!props.authenticated || !showAdmin() ? null : (
            <List>
              {[''].map((text, index) => (
                <ListItem className={classes.listItemDone} button component={Link} to="/admin">
                  <ListItemIcon>
                    {index % 2 === 0 ? <SupervisorAccountIcon /> : <SupervisorAccountIcon />}
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                </ListItem>
              ))}
            </List>
          )}
        </font>
      </div>
    </div>
  )
}

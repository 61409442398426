import React, { Component } from 'react'
import styles from './registrationVerifyEmail.module.scss'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

class RegistrationVerifyEmail extends Component {
  render = () => {
    return (
      <div className={ styles.registration }>
        <div>
          <h1 style={{color:'#1d98ff'}}>Verify your email address</h1>
          <p>We emailed you a verification link. If you don't see it, check your <b>spam</b> or <b>promotions</b> folder.</p>
          <p>Please verify your email address before attempting to login.</p>
          <br />
          Already verified? <Link to="/login" className={styles[`link-${this.props.theme}`]} style={{color: '#1D98FF'}}>Login</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    notification: state.notification,
    theme: state.theme
  }
}

export default connect(mapStateToProps)(RegistrationVerifyEmail)

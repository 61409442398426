import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  Card,
  Grid,
  CircularProgress,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
import styles from './profileModify.module.scss'
import { setUser, setProviders } from '../../actions/connectionActions'
import AddISPModal from './addISPModal'
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

class Profile extends Component {
  constructor(props) {
    super(props)

    let {
      id,
      twitterUsername,
      name,
      email,
      isp,
      address,
      notifications,
    } = this.props.user
    if (!isp) {
      isp = {
        provider: '',
        providerIds: [],
        supportEmail: '',
        price: 0.00,
        rating: 0,
        startDate: null,
        endDate: null,
        downloadSpeed: 0,
        uploadSpeed: 0,
        dataLimit: 0,
        speedThreshold: 0,
        accountNumber: '',
        twitterUsername: '',
        password: '',
        pin: '',
        imupAuthorized: false,
        additionalDetails: ''
      }
    }
    if (!address) {
      address = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: ''
      }
    }
    if (!notifications) {
      notifications = {
        email: false,
        twitter: false,
      }
    }
    this.state = {
      id,
      twitterUsername,
      name,
      email,
      isp,
      address,
      notifications,
      loading: false,
      addISPModalOpen: false,
    }
  }

  getISPRating = () => {
    const { user } = this.props
    if (!Array.isArray(user.ispRatings)) {
      return null
    }

    const usersCurrentProviderId = !!this.state.isp && Array.isArray(this.state.isp.providerIds) && !!this.state.isp.providerIds[0]
      ? this.state.isp.providerIds[0]._id
      : null

    let rating = null;
    for (const ispRating of user.ispRatings) {
      if (ispRating.providerId === usersCurrentProviderId) {
        rating = ispRating.rating
      }
    }

    return rating
  }

  async componentDidMount() {
    const providers = await axios.get(`${this.props.url}/${this.props.routes.provider.list}`)

    if (providers.data.success && Array.isArray(providers.data.data)) {
      this.props.setProviders(providers.data.data)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentStateProviderIdsEmpty = !Array.isArray(this.state.isp.providerIds) || this.state.isp.providerIds.length < 1 || !this.state.isp.providerIds[0]
    const prevStateProviderIdsEmpty = !Array.isArray(prevState.isp.providerIds) || prevState.isp.providerIds.length < 1 || !prevState.isp.providerIds[0]

    const currentStateProviderId = currentStateProviderIdsEmpty ? '' : this.state.isp.providerIds[0]._id
    const prevStateProviderId = prevStateProviderIdsEmpty ? '' : prevState.isp.providerIds[0]._id

    if (currentStateProviderIdsEmpty || (currentStateProviderId === prevStateProviderId)) {
      return
    }

    // provider id changed in the form, setting associated ISP rating
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { rating: this.getISPRating() })
    })
  }

  saveProfile = async () => {
    const { id, name, email, isp, address, notifications } = this.state
    const userdata = {
      id,
      name,
      email,
      isp,
      address,
      notifications,
    }
    // if there isn't a zipcode then we display 0 as the rating in our form
    // we want the actual data we're sending to the API server to reflect
    // what the user is seeing
    isp.rating = !this.state.address.zipcode ? 0 : this.state.isp.rating

    this.setState({
      loading: true
    })
    const response = await axios.post(`${this.props.url}/${this.props.routes.user.profile.update}`, userdata)
    this.setState({
      loading: false
    })
    if (!!response && !!response.data && response.data.success) {
      console.log(response.data)
      this.props.setUser(response.data.user)
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile saved!'
      })
      this.props.history.push('/profile')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  handleChangeISP = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { [name]: value })
    })
  }

  createNewISP = () => {
    return (
      <button className={styles.button} onClick={this.onProviderBlur} style={{marginLeft:'5%'}}>Add new ISP</button>
    )
  }

  handleChangeISPProvider = (event, newValue) => {
    console.log('handleChangeISPProvider event', event)
    console.log('handleChangeISPProvider newValue', newValue)
    // if (name === 'provider') {
    //   alert('Create new provider')
    //   return
    // }

    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { providerIds: [newValue] })
    })
  }

  // handleInputValueChangeISPProvider = (event, newValue) => {
  //   console.log('handleInputValueChangeISPProvider newValue', newValue)
  //   const { isp } = this.state
  //   this.setState({
  //     isp: Object.assign({}, isp, { provider: newValue })
  //   })
  // }

  handleChangeAddress = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { address } = this.state
    this.setState({
      address: Object.assign({}, address, { [name]: value })
    })
  }

  handleChangeCheckBox = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { [name]: checked })
    })
  }

  onProviderBlur = (event) => {
    console.log('onProviderBlur event', event.target.value)

    let providerExists = false
    this.props.providers.forEach(provider => {
      if (provider.name === event.target.value) {
        providerExists = true
      }
    })

    if (!providerExists) {
      // alert('This looks like a new provider! Please input details for ' + event.target.value)
      this.setState({
        addISPModalOpen: true
      })
    }
  }

  ispModalHandleClose = () => {
    this.setState({
      addISPModalOpen: !this.state.addISPModalOpen
    })
  }

  showISPFields = () => {
    return (
      <div>
        {/*
          <FormControl>
            <InputLabel id="provider-label">Provider</InputLabel>
            <Select
              labelId="provider-label"
              id="provider"
              name="provider"
              value={ this.state.isp.provider || '' }
              onChange={this.handleChangeISP}
            >
              {this.props.providers.map(provider => (
                <MenuItem value={provider.id}>{provider.name}</MenuItem>
              ))}

              <MenuItem value={0}>Add Provider</MenuItem>
            </Select>
          </FormControl>
        */}

        <Autocomplete
          id="provider"
          freeSolo
          onBlur={this.onProviderBlur}
          options={this.props.providers}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Provider" variant="outlined" />}
          name="provider"
          value={ (Array.isArray(this.state.isp.providerIds) && this.state.isp.providerIds[0]) || '' }
          onChange={this.handleChangeISPProvider}
          // onInputChange={this.handleInputValueChangeISPProvider}
          // inputValue={this.state.isp.provider || ''}
        />
        <TextValidator
          label="Price per month"
          onChange={ this.handleChangeISP }
          name="price"
          size="small"
          value={ this.state.isp.price || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="large"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <TextValidator
          label="Download speed (Mbps)"
          onChange={ this.handleChangeISP }
          name="downloadSpeed"
          value={ this.state.isp.downloadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <TextValidator
          label="Upload speed (Mbps)"
          onChange={ this.handleChangeISP }
          name="uploadSpeed"
          value={ this.state.isp.uploadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <TextValidator
          label="Speed threshold value (Mbps)"
          onChange={ this.handleChangeISP }
          name="speedThreshold"
          value={ this.state.isp.speedThreshold || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <span style={{color:'grey'}}>If notifications are enabled below, you will receive alerts when speed test results are slower than this value</span>
        <br />
        <br />
        <Typography component="body" variant="body" style={{backgroundColor: "inherit"}}>
          Provider Rating:
        </Typography>
        <Rating
          style={{color: "#1d98ff"}}
          label="rating"
          name="rating"
          // only allow setting rating if there's a zipcode as well
          disabled={ !this.state.address.zipcode }
          // don't display a value for the rating if there is no zipcode
          // so its clear we won't save any value
          value={ !this.state.address.zipcode ? 0 : this.state.isp.rating }
          onChange={ this.handleChangeISP }
        />
        <p></p>
      </div>
    )
  }

  handleChangeNotificationsCheckBox = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const { notifications } = this.state
    this.setState({
      notifications: Object.assign({}, notifications, { [name]: checked })
    })
  }

  showNotificationFields = () => {
    return (
      <div>
        <FormGroup>
          <FormControlLabel
            disabled={this.props.user.plan === 'reporting'}
            control={
              <Checkbox
                checked={this.state.notifications.email}
                onChange={this.handleChangeNotificationsCheckBox}
                name='email'
                color='success'
              />
            }
            label="Email"
          />
        </FormGroup>
      </div>
    )
  }

  // postProvider = async () => {
  //   const response = await axios.post(`${this.props.url}/${this.props.routes.provider.new}`, { name: 'NextLink', email: 'support@nextlink.com' })
  //   console.log('postProvider response', response)
  // }

  render() {
    const { loading } = this.state
    return (
      <div className={ styles.profileModify }>
        <Grid container spacing={3}>
          <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={4} xl={4}>
            <Typography className={ styles.title } component="h1" variant="h5">
              My Info:
            </Typography>
            <Card variant="outlined" className={ styles.card }>
            <ValidatorForm
              ref="form"
              onSubmit={ this.saveProfile }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Name"
                onChange={ this.handleChange }
                name="name"
                size="small"
                value={ this.state.name || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                style={{ paddingTop:'10px'}}
              />
              <TextValidator
                label="Zip Code"
                onChange={ this.handleChangeAddress }
                name="zipcode"
                value={ this.state.address.zipcode || '' }
                validators={[
                  'minStringLength:0',
                  'maxStringLength:200'
                ]}
                errorMessages={[
                  // 'min 2 characters',
                  'max 200 characters'
                ]}
                margin="normal"
                fullWidth
                style={{ paddingTop:'10px'}}
              />
              </ValidatorForm>
              </Card>
              <p></p>


              <Card variant="outlined" className={ styles.card }>
              {this.createNewISP()}
              <br />
              <ValidatorForm
                ref="form"
                onSubmit={ this.saveProfile }
                // onError={ errors => console.log(errors) }
                className={ styles.content }
              >
              {this.showISPFields()}
              </ValidatorForm>
              </Card>
              <p></p>

              <Typography component="h1" variant="h6">
                Notifications:
              </Typography>
              <Card variant="outlined" className={ styles.card }>
              <ValidatorForm
                ref="form"
                onSubmit={ this.saveProfile }
                // onError={ errors => console.log(errors) }
                className={ styles.content }
              >
                {this.showNotificationFields()}
                {
                  loading ?
                  <button
                    className={ styles.button }
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress color="primary" size={ 24 } />
                  </button> :
                  <button
                    type="submit"
                    className={ styles.button }
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Save
                  </button>
                }
              </ValidatorForm>
              </Card>
          </Grid>
        </Grid>
        <AddISPModal
          addISPModalOpen={this.state.addISPModalOpen}
          handleClose={this.ispModalHandleClose}
          handleChangeISPProvider={this.handleChangeISPProvider}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    notification: state.notification,
    providers: state.providers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => { dispatch(setUser(user)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

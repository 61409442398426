// COMPONENTS
import TableToolbar from "./TableToolbar";
import React, { useState, Component } from "react";

// MATERIAL UI CORES
import { DataGrid, GridMenu } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core/styles";


const CustomDataGrid = withStyles((theme) => ({
  root: {
    // ROOT
    height: "100%",
    border: "none !important",

    // UNCOMMENT THIS TO HIDE SCROLL BAR INSIDE THE DATA GRID
    // '& .MuiDataGrid-window': {
    //   '&::-webkit-scrollbar': {
    //     // width: 10,
    //     // height: 10,
    //     backgroundColor: 'transparent',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     // width: 10,
    //     // height: 10,
    //     backgroundColor: 'transparent',
    //   }
    // },
    "& .MuiTablePagination-root": {
      color: `${theme.palette.type === 'dark' ?  '#d3d3d3' : 'black'}`,
    },
    "& .MuiFormLabel-root": {
      position: "inherit",
      color: 'red'
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      position: "inherit !important",
      color: 'red'
    },
    "& .MuiInputLabel-root": {
      position: "inherit !important",
      color: 'red'
    },
    "& .MuiDataGrid-cellContent": {
      color: `${theme.palette.type === 'dark' ?  '#d3d3d3' : 'black'}`,
    },
    "& .MuiButton-root": {
      boxShadow: 'none',
      color: "#1d98ff",
      "&:hover": {
        backgroundColor: "inherit",
        color: "#64b8ff",
      }
    },
    // CHECKED
    "& .Mui-checked": {
      color: "blue"
    },

    // HEADER
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none"
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "none"
    },
    "& .MuiDataGrid-sortIcon": {
      color: "#1d98ff"
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: 0
      // color: colors['slategray'],
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
      fontSize: 12,
      color: `${theme.palette.type === 'dark' ?  '#fff' : 'black'}`,
    },

    "& .MuiDataGrid-iconSeparator": {
    },

    // ROW
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "gray",
      }
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: `${"#1d98ff"}20`,
      "&:hover": {
        backgroundColor: "gray"
      }
    },

    // CELL
    "& .MuiDataGrid-cell:focus": {
      outline: "none"
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none"
    },

    // PAGINATION
    "& .MuiTablePagination-caption": {
      fontSize: 12
    },
    "& .MuiTablePagination-select": {
      fontSize: 12
    },
    "& .MuiIconButton-root": {

    }
  }
}))(DataGrid);

const paginationSelectStyles = {
  menu: {
    "& .MuiMenuItem-root": {
    }
  }
};

// CUSTOM STYLE FOR POPUP FROM GRIDTOOLBARDENSITYSELECTOR AND GRIDTOOLBAREXPORT
const CustomMenu = withStyles((theme) => ({
  root: {
    "& .MuiMenuItem-root": {
      fontSize: 12
    }
  }
}))(GridMenu);

const NewCustomDataGrid = withStyles(paginationSelectStyles)((props) => (
  <CustomDataGrid
    {...props}
    components={{
      Toolbar: TableToolbar,
      Menu: CustomMenu
    }}
    componentsProps={{
      pagination: {
        SelectProps: {
          MenuProps: {
            className: props.classes.menu
          }
        }
      }
    }}
  />
));

export default NewCustomDataGrid;

import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import styles from './app.module.scss'
import config from './config'
import Navigation from './components/Navigation/Navigation'
import OrgNavigation from './components/Navigation/Navigation_Orgs'
import { connect } from 'react-redux'
import { setUrl, setRoutes, setAuth, setUser, setNotifications, setOperatingSystem, setTheme, setUserPaymentInfo, setOrganization, setOrganizationGroupProfiles } from './actions/connectionActions'
// import ReactNotification from "react-notifications-component"
// import "react-notifications-component/dist/theme.css"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "animate.css"
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { CssBaseline } from '@material-ui/core'

toast.configure({
  autoClose: 5000,
  draggable: false
})

// https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = 'unknown';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

class App extends Component {
  constructor(props) {
    super(props)
    this.url = config.api.url
    this.routes = config.api.routes
    this.props.setNotifications(this.notification)
    this.props.setUrl(this.url)
    this.props.setRoutes(this.routes)
    axios.defaults.withCredentials = true
  }

  async componentDidMount() {
    const operatingSystem = getOS()
    console.log("Operating system:", operatingSystem)
    this.props.setOperatingSystem(operatingSystem)

    // exponential back-off retry delay between requests for 3 retries
    axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay })

    axios.interceptors.response.use(undefined, (error) => {
      // we can't just say 'if (error.response.status === 401)' because
      // then if error.response doesn't exist and we go to check .status, it'll
      // break. but we don't want to log people out if there's no response from
      // the server at all because google's gateway errors can cause that behavior.
      if(!!error.response && error.response.status === 401) {
        this.props.setAuth(false)
        this.notification({
          type: 'danger',
          title: 'Logged out',
          message: 'Please login to continue'
        })
        return Promise.reject(error)
      }

      return Promise.reject(error)
    })
    const authenticated = await axios.post(`${this.url}/${config.api.routes.auth.check}`)
    if (!!authenticated && !!authenticated.data) {
      this.props.setAuth(authenticated.data.success)
      this.props.setUser(authenticated.data.user)
    } else {
      this.props.setAuth(false)
      this.props.setUser({})
    }

    try {
      const paymentInfo = await axios.get(`${this.props.url}/${this.props.routes.payments.userGetPaymentInfo}`)
      if (paymentInfo && paymentInfo.data && paymentInfo.data.data) {
        this.props.setUserPaymentInfo(paymentInfo.data.data)
      } else {
        this.props.setUserPaymentInfo({})
      }
    } catch(e) {
      console.log('error getting payment info')
      console.log(e)
    }
  }



  notification(options) {
    let { type, title, message } = options
    if (type === 'danger') {
      type = 'warning'
    }
    toast[type](<div><b>{title}</b><br/>{message}</div>)
  }

  render() {
    const hasOrg = this.props.user && this.props.user.organizations && this.props.user.organizations.length > 0;
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={ createMuiTheme({palette: { type: this.props.theme }}) }>
          <CssBaseline />
          <div className={ styles.app }>
            { hasOrg ?
            <OrgNavigation
            authenticated={this.props.authenticated}
            theme={this.props.theme}
            setTheme={this.props.setTheme}
            user={this.props.user}
            operatingSystem={this.props.operatingSystem}
            orgGroups={this.props.organizationGroupProfiles}
          />
        :
            <Navigation
              authenticated={this.props.authenticated}
              theme={this.props.theme}
              setTheme={this.props.setTheme}
              user={this.props.user}
              operatingSystem={this.props.operatingSystem}
              orgGroups={this.props.organizationGroupProfiles}
            />
          }
          </div>
        </MuiThemeProvider>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    theme: state.theme,
    user: state.user,
    operatingSystem: state.operatingSystem,
    routes: state.routes,
    organization: state.organization,
    organizationGroupProfiles: state.organizationGroupProfiles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUrl: url => { dispatch(setUrl(url)) },
    setRoutes: routes => { dispatch(setRoutes(routes)) },
    setAuth: authenticated => { dispatch(setAuth(authenticated)) },
    setUser: (user) => { dispatch(setUser(user)) },
    setNotifications: notifications => { dispatch(setNotifications(notifications)) },
    setOperatingSystem: data => { dispatch(setOperatingSystem(data)) },
    setTheme: theme => { dispatch(setTheme(theme)) },
    setUserPaymentInfo: data => { dispatch(setUserPaymentInfo(data)) },
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationGroupProfiles: data => { dispatch(setOrganizationGroupProfiles(data)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

import * as React from 'react';
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

const columns = [
  { field: 'name', headerName: 'Name', width: 350 },
  { field: 'seats', headerName: 'Seats', width: 350 },
  { field: 'key', headerName: 'Key', width: 350 }
];

export default function BasicToolbarFilteringGrid(props) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <NewCustomDataGrid
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelection(newSelectionModel)
        }}
        // onRowClick={(row) => {
        //   // alert(JSON.stringify(row, null, 2))
        //   // console.log(row.row.key)
        //   setAPIKey(row.row.key)
        //   setOpen(true)
        // }}
        columns={columns}
        rows={props.data}
        showToolbar
        checkboxSelection
      />
    </div>
  );
}

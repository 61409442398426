import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'transparent',
  },
}) );

const steps = [
  'Create org',
  'Create API key',
  'Create org group',
];

export default function HorizontalLabelPositionBelowStepper(data) {
  const classes = useStyles();
  return (
    <Box>
      <Stepper activeStep={data.data} alternativeLabel className={classes.paper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
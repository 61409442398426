import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import axios from 'axios'
import {
  setUser,
  setProviders,
  setOrganizationGroupProfiles,
} from '../../actions/connectionActions'
import styles from './profileHostGroupModify.module.scss'
import './style.css';
import AddISPModal from '../ProfileModify/addISPModal'
import ISPProfile from './ispProfile'
import { WithContext as ReactTags } from 'react-tag-input'
import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';

class ProfileHostGroupModify extends Component {
  constructor(props) {
    super(props)

    // we use this page for both creating a new group and
    // modifying an existing one so we need to look at the route
    // and see which it is. there are probably less hacky ways to
    // reuse the code but this works
    const newGroupProfile = !!props.history &&
      !!props.history.location && !!props.history.location.pathname &&
      props.history.location.pathname.startsWith('/profilehostgroup/create')

    // orgId param always exists whether its the create or modify page
    const orgId = this.props.match.params.orgId
    const groupProfileId = newGroupProfile ? '' : decodeURIComponent(this.props.match.params.groupProfileId)
    const groupProfileMatch = newGroupProfile ? [] : props.organizationGroupProfiles.filter(x => x.id === groupProfileId)
    let existingDefaultGroup = false
    if (Array.isArray(props.organizationGroupProfiles)) {
      const defaultGroupMatchArr = props.organizationGroupProfiles.filter(x => x.default)
      if (defaultGroupMatchArr.length > 0) {
        existingDefaultGroup = true
      }
    }

    // TODO: should pop up alert the user there's a problem here maybe
    // if they group profile id in the route doesn't match any
    // of the groups we have in state, kick the user back to org dash
    if (!newGroupProfile && (groupProfileMatch.length < 1 || !groupProfileMatch[0])) {
      this.props.history.push(`/org/${orgId}`)
    }

    let clientConfig = null
    let notifications = null
    let ispProfiles = []
    if (!newGroupProfile) {
      // TODO: this has some extraneous info like id's and
      // created at dates we might want to filter out on the backend
      const {
        discoverGateway,
        insecureSpeedTest,
        pingEnabled,
        quietSpeedTest,
        speedTestEnabled,
        allowlistedIPs,
        blocklistedIPs,
      } = groupProfileMatch[0].clientConfig

      clientConfig = {
        discoverGateway,
        insecureSpeedTest,
        pingEnabled,
        quietSpeedTest,
        speedTestEnabled,
        allowlistedIPs: allowlistedIPs.map(ip => ({ id: ip, text: ip })),
        blocklistedIPs: blocklistedIPs.map(ip => ({ id: ip, text: ip })),
      }

      if (Array.isArray(groupProfileMatch[0].ispProfiles)) {
        ispProfiles = groupProfileMatch[0].ispProfiles
      }

      if (!!groupProfileMatch[0].notifications) {
        notifications = groupProfileMatch[0].notifications
      }
    }

    if (!notifications) {
      notifications = {
        offlineNotifications: false,
        downloadSpeedThreshold: false,
        uploadSpeedThreshold: false,
        packetLossThreshold: false,
        latencyThreshold: false,
      }
    }

    // set defaults if this is a new group
    if (!clientConfig) {
      clientConfig = {
        discoverGateway: false,
        insecureSpeedTest: false,
        pingEnabled: false,
        quietSpeedTest: false,
        speedTestEnabled: false,
        allowlistedIPs: [],
        blocklistedIPs: [],
      }
    }


    // I have no idea if an address or multiple is something
    // people are going to want in the group profiles
    let address = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: ''
    }

    this.state = {
      speedThreshold: newGroupProfile ? null : groupProfileMatch[0].speedThreshold,
      newGroupProfile,
      // this is meaningless if its a new host group being created
      groupProfileId,
      name: newGroupProfile ? 'default' : groupProfileMatch[0].name,
      default: newGroupProfile ? !existingDefaultGroup : groupProfileMatch[0].default,
      existingDefaultGroup,
      clientConfig,
      notifications,
      address,
      orgId,
      loading: false,
      ispProfiles,
      newISPProfile: {
        providerId: null,
        price: 0.00,
        // rating: 0,
        downloadSpeed: 0,
        uploadSpeed: 0,
        dataLimit: 0,
        downloadSpeedThreshold: 0,
        uploadSpeedThreshold: 0,
        latencyThreshold: 0,
        packetLossThreshold: 0,
        additionalDetails: '',
      },
      // used so we can get a default into the new
      // provider modal. not sure this is necessary
      newISPProfileProviderName: '',
      addISPModalOpen: false,
      newISPProfileFormShowing: false,
      // used to prevent form from allowing duplicate group submission
      existingGroupProfileNames: !Array.isArray(props.organizationGroupProfiles)
        ? []
        : props.organizationGroupProfiles.map(p => p.name)
    }
  }

  async componentDidMount() {
    try {
      const providers = await axios.get(`${this.props.url}/${this.props.routes.provider.list}`)
      if (!!providers && !!providers.data && providers.data.success && Array.isArray(providers.data.data)) {
        this.props.setProviders(providers.data.data)
      }
    } catch (e) {
      console.log('error fetching providers list:', e)
    }

    ValidatorForm.addValidationRule('isUniqueGroup', value => !this.state.existingGroupProfileNames.includes(value))
  }

  clearNewISPProfileForm = () => {
    this.setState({
      newISPProfileFormShowing: false,
      newISPProfile: {
        providerId: null,
        price: 0.00,
        // rating: 0,
        downloadSpeed: 0,
        uploadSpeed: 0,
        dataLimit: 0,
        downloadSpeedThreshold: 0,
        uploadSpeedThreshold: 0,
        latencyThreshold: 0,
        packetLossThreshold: 0,
        additionalDetails: '',
      },
    })
  }

  getHostGroupProfile = async () => {
    const {
      newGroupProfile,
      name,
      clientConfig,
      orgId,
      groupProfileId,
      notifications,
    } = this.state

    const formatClientConfig = () => {
      return Object.assign({}, clientConfig, {
        allowlistedIPs: clientConfig.allowlistedIPs.map(ip => ip.id),
        blocklistedIPs: clientConfig.blocklistedIPs.map(ip => ip.id),
      })
    }

    const groupProfileData = {
      groupProfile: {
        organization: orgId,
        default: this.state.default,
        name,
        notifications,
        address: null,
        clientConfig: formatClientConfig(),
      }
    }

    this.setState({
      loading: true
    })

    let response = null

    const reqRoute = this.props.routes.organization[newGroupProfile ? 'addGroupProfile' : 'updateGroupProfile']
    // modifying a group profile requires passing in its unique id
    if (!newGroupProfile) {
      groupProfileData._id = groupProfileId
    }

    return groupProfileData
  }

  deleteISPProfile = state => async () => {
    let newISPProfile = false
    let profile = null
    if (state === 'newISPProfile') {
      newISPProfile = true
      profile = this.state[state]
    } else {
      profile = this.state.ispProfiles[state]
    }

    if (newISPProfile) {
      // just wipe the state back to defaults here and close the form
      this.clearNewISPProfileForm()
      return
    }

    const deleteISPProfileData = {
      orgId: this.state.orgId,
      groupProfileId: this.state.groupProfileId,
      ispProfileIds: [profile._id]
    }

    try {
      const response = await axios.post(`${this.props.url}/${this.props.routes.organization.deleteISPProfiles}`, deleteISPProfileData)
      if (!!response && !!response.data && response.data.success) {
        this.setState({
          ispProfiles: response.data.ispProfiles,
        })

        if (newISPProfile) {
          this.clearNewISPProfileForm()
        }

        this.props.notification({
          type: 'success',
          title: 'Success!',
          message: 'ISP profile deleted!'
        })
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Deleting ISP profile failed!',
          message: 'Please try again later or reach out to support!'
        })
      }
    } catch (e) {
      console.log(e)
      alert('error occured deleting isp profile')
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  handleClientConfigChangeCheckBox = (event) => {
    const { name, checked } = event.target
    const { clientConfig } = this.state
    this.setState({
      clientConfig: Object.assign({}, clientConfig, { [name]: checked })
    })
  }

  handleChangeCheckBox = (event) => {
    const { name, checked } = event.target
    this.setState({
      [name]: checked
    })
  }

  addToListField = (listField) => (add) => {
    this.setState({
      clientConfig: Object.assign({}, this.state.clientConfig, {
        [listField]: (this.state.clientConfig[listField] || []).concat([add])
      })
    })
  }

  removeFromListField = (listField) => (delIndex) => {
    function removeItem(array, n) {
      return array.filter((elem, i) => i !== n);
    }

    this.setState({
      clientConfig:  Object.assign({}, this.state.clientConfig, {
        [listField]: removeItem(this.state.clientConfig[listField] || [], delIndex)
      })
    })
  }

  showHostConfigFields = () => {
    const { clientConfig } = this.state
    const { setState } = this
    return (
      <div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.clientConfig.insecureSpeedTest}
                onChange={this.handleClientConfigChangeCheckBox}
                name='insecureSpeedTest'
                color='success'
              />
            }
            label="Insecure (non-HTTPS) Speed Test"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.clientConfig.discoverGateway}
                onChange={this.handleClientConfigChangeCheckBox}
                name='discoverGateway'
                color='success'
              />
            }
            label="Discover Gateway"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.clientConfig.pingEnabled}
                onChange={this.handleClientConfigChangeCheckBox}
                name='pingEnabled'
                color='success'
              />
            }
            label="Ping Stats Enabled (ICMP)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.clientConfig.quietSpeedTest}
                onChange={this.handleClientConfigChangeCheckBox}
                name='quietSpeedTest'
                color='success'
              />
            }
            label="Quiet Speed Test (no stdout log output)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.clientConfig.speedTestEnabled}
                onChange={this.handleClientConfigChangeCheckBox}
                name='speedTestEnabled'
                color='success'
              />
            }
            label="Automatic Random Speed Testing Enabled"
          />
          <br />
          <Divider style={{marginBottom:'10px'}}/>
          <Alert severity="info">
            <AlertTitle>Speed testing block/allow</AlertTitle>
            If you need to restrict speed testing to only run while your endpoints are on a specific network, you can specify a list of IP addresses or CIDR ranges here. If you leave this field blank, speed testing will run on any network. This is useful if you don't want to run speed tests while a user is at a coffee shop, for example.
          </Alert>
          <div style={{marginTop: '20px'}}>
            <strong>Allowlist:</strong><br />
            <Typography variant="caption" display="block" gutterBottom>
            Speed tests will only run if the endpoint returns one of these IP addresses from the command:<br /> $ curl ifconfig.io
            </Typography>
              <ReactTags
                // this.state.clientConfig.allowlist
                tags={this.state.clientConfig.allowlistedIPs || []}
                handleDelete={this.removeFromListField('allowlistedIPs')}
                handleAddition={this.addToListField('allowlistedIPs')}
                allowDragDrop={false}
                // tags={[{ id: '127.0.0.1/32', text: '127.0.0.1/32' }, { id: '192.0.4.41/32', text: '192.0.4.41/32' }]}
                // suggestions={suggestions}
                // delimiters={delimiters}
                // handleDelete={handleDelete}
                // handleAddition={handleAddition}
                // handleDrag={handleDrag}
                // handleTagClick={handleTagClick}
                // inputFieldPosition="bottom"
                // autocomplete
                placeholder="Allowlist IP or CIDR"
                allowDeleteFromEmptyInput={false}
                inputFieldPosition="inline"
          />
          </div>
          <div style={{marginTop: '20px'}}>
            <strong>Blocklist:</strong><br />
            <Typography variant="caption" display="block" gutterBottom>
              Speed tests will not run if the endpoint returns one of these IP addresses from the command:<br /> $ curl ifconfig.io
            </Typography>
              <ReactTags
                // this.state.clientConfig.allowlist
                tags={this.state.clientConfig.blocklistedIPs || []}
                // tags={[{ id: '127.0.0.1/32', text: '127.0.0.1/32' }, { id: '192.0.4.41/32', text: '192.0.4.41/32' }]}
                // suggestions={suggestions}
                // delimiters={delimiters}
                // handleDelete={handleDelete}
                handleDelete={this.removeFromListField('blocklistedIPs')}
                handleAddition={this.addToListField('blocklistedIPs')}
                allowDragDrop={false}
                // handleDrag={handleDrag}
                // handleTagClick={handleTagClick}
                // inputFieldPosition="bottom"
                // autocomplete
                placeholder="Blocklist IP or CIDR"
                allowDeleteFromEmptyInput={false}
                inputFieldPosition="inline"
              />
          </div>
        </FormGroup>
        <div style={{marginTop: '20px'}}>
          <span style={{color:'grey'}}>
            These settings will be automatically loaded into any host's client on
            startup if the host belongs to this group or if the host belongs to no
            group but you make this the "default".
          </span>
        </div>
      </div>
    )
  }

  /**
   * state can be either a string 'newISPProfile' or an index integer
   * pointing to which element of the ispProviders array to alter
   * @param {string|int} state
   * @returns
   */
  handleChangeISP = (state) => (event) => {
    const name = event.target.name
    const value = event.target.value

    if (state === 'newISPProfile') {
      const profile = this.state[state]

      this.setState({
        [state]: Object.assign({}, profile, { [name]: value })
      })

      return
    }

    const ispProfilesClone = JSON.parse(JSON.stringify(this.state.ispProfiles))
    ispProfilesClone[state][name] = value

    return this.setState({
      ispProfiles: ispProfilesClone
    })
  }

  handleChangeISPProfileProvider = (state) => (event, target) => {
    if (state === 'newISPProfile') {
      const profile = this.state[state]

      return this.setState({
        [state]: Object.assign({}, profile, { providerId: target })
      })
    }

    const ispProfilesClone = JSON.parse(JSON.stringify(this.state.ispProfiles))
    ispProfilesClone[state].providerId = target

    return this.setState({
      ispProfiles: ispProfilesClone
    })
  }

  saveISPProfile = state => async () => {
    let newISPProfile = false
    let groupProfileId = null
    let profile = null
    let response = null
    if (state === 'newISPProfile') {
      newISPProfile = true
      profile = this.state[state]
    } else {
      profile = this.state.ispProfiles[state]
    }

    const hostGroupProfile = await this.getHostGroupProfile()

    try {
      const reqRoute = this.props.routes.organization[this.state.newGroupProfile ? 'addGroupProfile' : 'updateGroupProfile']
      response = await axios.post(`${this.props.url}/${reqRoute}`, hostGroupProfile)
      if (!response || !response.data || !response.data.success) {
        this.setState({
          loading: false
        })

        return this.props.notification({
          type: 'danger',
          title: 'Saving group profile failed!',
          message: 'Please try again later or reach out to support!'
        })
      }

      groupProfileId = response.data.groupProfileId

      // I think this is ok to update here? maybe should wait til the end?
      this.props.setOrganizationGroupProfiles(response.data.groupProfiles)
    } catch (e) {
      if (e.response.status == 400) {
        this.setState({
          loading: false
        })
        return this.props.notification({
          type: 'danger',
          title: 'Saving group profile failed!',
          message: 'Group name must be unique!'
        })
      }

      return this.props.notification({
        type: 'danger',
        title: 'Saving group profile failed!',
        message: 'Please try again later or reach out to support!'
      })
    }


    // START ISP PROFILE SAVING

    const {
      providerId,
      price,
      // rating: 0,
      downloadSpeed,
      uploadSpeed,
      dataLimit,
      downloadSpeedThreshold,
      uploadSpeedThreshold,
      latencyThreshold,
      packetLossThreshold,
      additionalDetails,
    } = profile

    // we need to make sure we're passing in the string id and not the
    // fully populated provider object
    let provider = null

    // idk if i need the instanceof check but I think mongo actually might use object
    // wrapped strings or something for _id's so I'm leaving it in
    // https://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string-in-javascript#comment51976839_9436948
    if (typeof providerId === 'string' || providerId instanceof String) {
      provider = providerId
    } else if (typeof providerId === 'object' && providerId !== null) {
      provider = providerId._id
    }

    const ispProfileData = {
      groupProfileId: groupProfileId,
      ispProfile: {
        providerId: provider,
        price,
        downloadSpeed,
        uploadSpeed,
        dataLimit,
        downloadSpeedThreshold,
        uploadSpeedThreshold,
        latencyThreshold,
        packetLossThreshold,
        additionalDetails,
        organization: this.state.orgId,
      }
    }

    response = null
    try {
      const reqRoute = this.props.routes.organization[newISPProfile ? 'addISPProfile' : 'updateISPProfile']

      // modifying an ISP profile requires passing in its unique id
      if (!newISPProfile) {
        ispProfileData._id = profile._id
      }

      response = await axios.post(`${this.props.url}/${reqRoute}`, ispProfileData)
    } catch (e) {
      // handle the actual error handling after the try/catch so we don't duplicate code
      console.log('saveISPProfile error', e)
    } finally {
      this.setState({
        loading: false
      })
    }

    if (!!response && !!response.data && response.data.success) {
      this.setState({
        ispProfiles: response.data.ispProfiles,
      })

      // this.props.setOrganizationGroupProfiles(response.data.groupProfiles)
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Group profile saved!'
      })

      return this.props.history.goBack()
    } else {
      // this should be a weird edge case so lets leave in the weirder message
      // to help us debug if it happens
      return this.props.notification({
        type: 'danger',
        title: `${newISPProfile ? 'Saving' : 'Updating'} ISP profile failed!`,
        message: 'Please try again later or reach out to support!'
      })
    }
  }

  // TODO: this is a horrible pattern and super inefficient as we
  // add more ISPs since it's looping through the entire list
  onISPProfileProviderBlur = state => (event) => {
    const inputedProviderName = event.target.value

    let providerExists = false
    this.props.providers.forEach(provider => {
      if (provider.name === inputedProviderName) {
        providerExists = true
      }
    })

    if (!providerExists) {
      this.setState({
        addISPModalOpen: true,
        newISPProfileProviderName: inputedProviderName,
      })
    }
  }

  showISPFields = () => {
    const { ispProfiles, newISPProfileFormShowing } = this.state

    return (
      <div>
        {ispProfiles.length < 1 ? (
          <div>
            <ISPProfile
              onDeleteHandler={this.deleteISPProfile('newISPProfile')}
              onChangeHandler={this.handleChangeISP('newISPProfile')}
              onChangeProviderHandler={this.handleChangeISPProfileProvider('newISPProfile')}
              profile={this.state.newISPProfile}
              providers={this.props.providers}
              saveISPProfile={this.saveISPProfile('newISPProfile')}
              deleteButtonText='Reset form'
            />
            <br/>
            <br/>
          </div>
        ) : null}

        {ispProfiles.length > 0 ? (
          <div>
            {/* use index to know which array item to modify in state */}
            {ispProfiles.map((ispProfile, index) => (
              <ISPProfile
                onDeleteHandler={this.deleteISPProfile(index)}
                onChangeHandler={this.handleChangeISP(index)}
                onChangeProviderHandler={this.handleChangeISPProfileProvider(index)}
                profile={ispProfile}
                providers={this.props.providers}
                saveISPProfile={this.saveISPProfile(index)}
              />
            ))}
          </div>
        ) : null}
      </div>
    )
  }

  handleChangeNotificationsCheckBox = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const { notifications } = this.state
    this.setState({
      notifications: Object.assign({}, notifications, { [name]: checked }),
    })
  }

  render() {
    const { loading, newGroupProfile, orgId } = this.state
    return (
      <div className={ styles.profileModify }>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography className={ styles.title } component="h1" variant="h5">
              Group Profile Settings:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <ButtonGroup>
              <button
                className={ styles.button }
                onClick={() => this.props.history.goBack()}
              >
                Back
              </button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={ 12 } sm={ 12 } md={ 8 } lg={12} xl={12}>
            <Card variant="outlined" className={ styles.card }>
            <Typography className={ styles.title } component="h6" variant="h6">
              Details
            </Typography>
            <hr />
            <ValidatorForm
              ref="form"
              onSubmit={ this.saveHostGroup }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Name"
                onChange={ this.handleChange }
                name="name"
                size="small"
                value={ this.state.name }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100',
                  // defined in componentDidMount
                  'isUniqueGroup'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters',
                  'group name must be unique',
                ]}
                margin="normal"
                fullWidth
                style={{ paddingTop:'10px'}}
              />

              <FormGroup>
                <Tooltip title={'Can only be checked if there isn\'t already an existing default'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={this.state.existingDefaultGroup}
                      checked={this.state.default}
                      onChange={this.handleChangeCheckBox}
                      name='default'
                      color='success'
                    />
                  }
                  label="Default"
                />
                </Tooltip>
                <Alert severity="info">
                  <AlertTitle>Default</AlertTitle>
                  Setting default will make this group the default group for all hosts that don't declare a group.
                </Alert>
                <hr />

                  Notifications:
                <FormControlLabel
                  // should probably disable this if there's no threshold
                  // configured but need some UI work to make it obvious
                  // that's whats going on
                  // disabled={!this.state.speedThreshold}
                  control={
                    <Checkbox
                      checked={this.state.notifications.downloadSpeedThreshold}
                      onChange={this.handleChangeNotificationsCheckBox}
                      name='downloadSpeedThreshold'
                      color='success'
                    />
                  }
                  label="Slow Download Speed Notifications"
                />
                <FormControlLabel
                  // should probably disable this if there's no threshold
                  // configured but need some UI work to make it obvious
                  // that's whats going on
                  // disabled={!this.state.speedThreshold}
                  control={
                    <Checkbox
                      checked={this.state.notifications.uploadSpeedThreshold}
                      onChange={this.handleChangeNotificationsCheckBox}
                      name='uploadSpeedThreshold'
                      color='success'
                    />
                  }
                  label="Slow Upload Speed Notifications"
                />
                <FormControlLabel
                  // should probably disable this if there's no threshold
                  // configured but need some UI work to make it obvious
                  // that's whats going on
                  // disabled={!this.state.speedThreshold}
                  control={
                    <Checkbox
                      checked={this.state.notifications.packetLossThreshold}
                      onChange={this.handleChangeNotificationsCheckBox}
                      name='packetLossThreshold'
                      color='success'
                    />
                  }
                  label="High Packet Loss Notifications"
                />
                <FormControlLabel
                  // should probably disable this if there's no threshold
                  // configured but need some UI work to make it obvious
                  // that's whats going on
                  // disabled={!this.state.speedThreshold}
                  control={
                    <Checkbox
                      checked={this.state.notifications.latencyThreshold}
                      onChange={this.handleChangeNotificationsCheckBox}
                      name='latencyThreshold'
                      color='success'
                    />
                  }
                  label="High Latency Notifications"
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.notifications.offlineNotifications}
                      onChange={this.handleChangeNotificationsCheckBox}
                      name='offlineNotifications'
                      color='success'
                    />
                  }
                  label="Offline Notifications"
                /> */}
              </FormGroup>
              </ValidatorForm>
              </Card>
              <p></p>
              </Grid>
              <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={12} xl={12} styles={{marginTop: '50%'}}>
              {/* <Card variant="outlined" className={ styles.card }>
              <Typography className={ styles.title } component="h6" variant="h6">
                Host Configuration
              </Typography>
              <hr />
              <br />
              <ValidatorForm
                ref="form"
                onSubmit={ this.saveHostGroup }
                // onError={ errors => console.log(errors) }
                className={ styles.content }
              >

              {this.showHostConfigFields()}

              {
                loading ?
                <button
                  className={ styles.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </button> :
                null
              }
              </ValidatorForm>
              </Card> */}

              <p></p>
              </Grid>
              <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={12} xl={12}>
              <Card variant="outlined" className={ styles.card }>
              <Typography className={ styles.title } component="h6" variant="h6">
                Group ISP
              </Typography>
              <hr />
                <br />
                {this.showISPFields()}
              </Card>
              <p></p>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    notification: state.notification,
    providers: state.providers,
    organizationGroupProfiles: state.organizationGroupProfiles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => { dispatch(setUser(user)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) },
    setOrganizationGroupProfiles: data => { dispatch(setOrganizationGroupProfiles(data)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHostGroupModify)

import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './home.module.scss'

export default function StatsTableSpeedTest(props) {
  return !props.data ? null : (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="speed test data">
        <TableHead style={{backgroundColor: '#1D98FF'}}>
          <TableRow>
            <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Stat</TableCell>
            <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Mbps</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles['table-body']}>
          {props.data.map((row) => (
            <TableRow key={row.stat}>
              <TableCell component="th" scope="row">{row.stat}</TableCell>
              <TableCell align="right">{!!row.value ? row.value.toFixed(2).toString() : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

import React from 'react'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Fab from './fab';
import DarkIcon from '@material-ui/icons/Brightness3';
import LightIcon from '@material-ui/icons/WbSunny';
import PersonIcon from '@material-ui/icons/Person';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Tooltip } from '@material-ui/core';
import styles from './navigation.module.scss'

const useStyles = makeStyles((theme) => ({
  listItemDone: {
    "&:hover": {
      color: "#1D98FF"
    }
  },
}));

export default function ToggleTheme(props) {
  const classes = useStyles();

  const switchTheme = () => {
    props.theme === 'light' ? props.setTheme('dark') : props.setTheme('light')
  }

  return (
    <div>
    <ButtonGroup size="small">
      <Fab/>
      <button
        className={ styles.button }
        onClick={ switchTheme }
      >
        {props.theme === 'dark' ? <Tooltip title='Light mode'><LightIcon/></Tooltip> : <Tooltip title='Dark mode'><DarkIcon/></Tooltip>}
      </button>
    </ButtonGroup>
    </div>
  )
}

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import styles from './adminUserRegistrationChart.module.scss'
import { useTheme } from '@material-ui/core/styles';

const dateFormat = x => {
  const _d = new Date(x);

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)

  return dateString !== 'Invalid Date at Invalid Date' ? dateString : x
};

const getDayFromDate = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const dateWithoutTime = _d.setHours(0,0,0,0)

  return dateWithoutTime
};

const getMonthFromDate = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  // const dateWithoutTime = _d.setHours(0,0,0,0)

  // first day of month, in epoch format
  return new Date(_d.getFullYear(), _d.getMonth(), 1).getTime()
};

function formatUsersPerMonth (users) {
  if (!users) {
    return []
  }
  const formattedUsers = []
  let selectedMonth = null
  // number of users registered in a particular day
  let selectedCount = 0
  users.forEach((user, index, arr) => {
    const monthFromDate = getMonthFromDate(user.createdAt)
    if (selectedMonth !== monthFromDate) {
      // make sure this isn't the first iteration of the loop
      // so selectedDate is meaningful/has data
      if (!!selectedMonth) {
        formattedUsers.push({ day: selectedMonth, registered: selectedCount })
      }

      // reset the count
      selectedCount = 0
      // set the new selectedMonth to the one from this user
      selectedMonth = monthFromDate
    }

    selectedCount += 1

    // if this is the last item in the array,
    // make sure it gets added to the array that's getting charted
    if (index === (arr.length - 1)) {
      formattedUsers.push({ day: selectedMonth, registered: selectedCount })
    }
  })

  return formattedUsers
}
function formatUsersPerDay (users) {
  if (!users) {
    return []
  }
  const formattedUsers = []
  let selectedDay = null
  // number of users registered in a particular day
  let selectedCount = 0
  users.forEach((user, index, arr) => {
    const dayFromDate = getDayFromDate(user.createdAt)
    if (selectedDay !== dayFromDate) {
      // make sure this isn't the first iteration of the loop
      // so selectedDate is meaningful/has data
      if (!!selectedDay) {
        formattedUsers.push({ day: selectedDay, registered: selectedCount })
      }

      // reset the count
      selectedCount = 0
      // set the new selectedDay to the one from this user
      selectedDay = dayFromDate
    }

    selectedCount += 1

    // if this is the last item in the array,
    // make sure it gets added to the array that's getting charted
    if (index === (arr.length - 1)) {
      formattedUsers.push({ day: selectedDay, registered: selectedCount })
    }
  })

  return formattedUsers
}

function AdminUserRegistrationChart(props) {
  const formattedUsers = props.type === 'perDay' ? formatUsersPerDay(props.users) : formatUsersPerMonth(props.users)

  return (
    <div style={{ borderRadius: '5px'}}>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={formattedUsers}
          margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
          baseValue="dataMin"
        >
          <XAxis
            dataKey="day"
            minTickGap={20}
            tickFormatter={dateFormat}
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
          />
          <YAxis
            dataKey="registered"
            type="number"
            orientation="left"
            domain={['dataMin -1', 'dataMax +1']}
            axisLine="false"
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
          />
          <defs>
            <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1d98ff" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#1d98ff" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Tooltip
            contentStyle={{backgroundColor: props.theme === 'dark' ?  '#303030' : 'white'}}
            formatter={
              (value, name, props) => [value, "Users Registered"]
            }
            labelFormatter={dateFormat}
          />
          <Area type="monotone" dataKey="registered" fillOpacity={1} stroke={"#1d98ff"} strokeWidth={2} fill="url(#colorSuccess)" layout="vertical"/>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default AdminUserRegistrationChart;

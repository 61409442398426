import React, { Component } from 'react'
// import styles from './home.module.scss'
import {
  Card, CardContent, Modal, Grid,
  Paper,
  Typography,
  Button,
  MenuItem,
  CircularProgress
} from '@material-ui/core'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@material-ui/core/Fade';
import styles from './home.module.scss'
import './modal.css'
// import GitHubButton from 'react-github-btn'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import { setModalDisplayed } from '../../actions/connectionActions'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'inherit',
  p: 4,
};

class SupportModal extends Component {
  constructor(props) {
    super(props)

    let {
      id,
      username,
      name,
      email,
    } = this.props.user

    this.state = {
      id,
      username,
      name,
      email,
      loading: false,
      message: '',
      subject: ''
    }
  }

  handleClose = () => {
    this.props.setModalDisplayed(false)
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  submitRequest = async () => {
    const { id, username, name, email, message, subject } = this.state
    const supportData = {
      id,
      username,
      name,
      email,
      message,
      subject
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(`${this.props.url}/${this.props.routes.support.send}`, supportData)
    this.setState({
      loading: false
    })
    if (response && response.data.success) {
      console.log(response.data)
      this.props.setModalDisplayed(false)
      this.setState({
        message: '',
        subject: ''
      })
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Support request sent!'
      })
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }
  }

  render() {
    // const { user } = this.props
    const { loading } = this.state

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open={this.props.modalDisplayed}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // closeAfterTransition
      >
      <Box sx={style}>
          <Card className={styles.card}>
              <div className={ styles.support }>
                <Grid item xs={ 12 } sm={ 12 } md={ 20 }>
                    <Typography className={ styles.title } component="h1" variant="h5">
                      Contact Support
                    </Typography>
                    <ValidatorForm
                      ref="form"
                      onSubmit={ this.submitRequest }
                      // onError={ errors => console.log(errors) }
                      className={ styles.content }
                    >
                      <SelectValidator
                        label="Subject"
                        onChange={ this.handleChange }
                        name="subject"
                        value={ this.state.subject || '' }
                        validators={[
                          'required',
                          'minStringLength:15',
                          'maxStringLength:18'
                        ]}
                        errorMessages={[
                          'this field is required',
                          'min 3 characters',
                          'max 5 characters'
                        ]}
                        margin="normal"
                        fullWidth
                      >
                        <MenuItem key="0" value="General Support">
                          General Support
                        </MenuItem>
                      </SelectValidator>
                      <TextValidator
                        label="Message"
                        onChange={ this.handleChange }
                        name="message"
                        value={ this.state.message || '' }
                        validators={[
                          'required',
                          'minStringLength:5',
                          'maxStringLength:10000'
                        ]}
                        errorMessages={[
                          'this field is required',
                          'min 5 characters',
                          'max 10000 characters'
                        ]}
                        margin="normal"
                        fullWidth
                        style={{ paddingTop:'10px'}}
                      />
                      {
                        loading ?
                        <button
                          className={ styles.button }
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled
                        >
                          <CircularProgress color="primary" size={ 24 } />
                        </button> :
                        <button
                          type="submit"
                          className={ styles.button }
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Submit
                        </button>
                      }
                    </ValidatorForm>
                </Grid>
              </div>
          </Card>
          </Box>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    modalDisplayed: state.modalDisplayed || false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setModalDisplayed: data => { dispatch(setModalDisplayed(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportModal))

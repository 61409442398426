import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import styles from './organization.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setEmails } from '../../actions/connectionActions'
import axios from 'axios'
import { setAdminViewEmails, setOrganization } from '../../actions/connectionActions'

class APIKeyForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      open: false,
      name: '',
      seats: 0
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  // getISPDetails = (field) => {
  //   const ispDefaults = {
  //     provider: '',
  //     price: 0,
  //     downloadSpeed: 0,
  //     uploadSpeed: 0,
  //     dataLimit: 0,
  //     accountNumber: '',
  //     username: '',
  //     password: '',
  //     pin: '',
  //     imupAuthorized: false,
  //     additionalDetails: ''
  //   }
  //
  //   const ispValues = Object.assign({}, ispDefaults, this.props.user.isp || {})
  //   return ispValues[field]
  // }

  createAPIKeyClicked = async () => {
    const { name, seats } = this.state
    this.setState({
      sending: true
    })

    const response = await axios.post(`${this.props.url}/${this.props.routes.organization.createAPIKey}`, {
      name: name,
      seats: seats,
      orgId: this.props.orgId
    })

    console.log('create API key response:', response)

    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Created API key!'
      })

      this.setState({
        sending: false,
        name: ''
      })

      this.props.setOrganization(Object.assign({}, this.props.organization, {
        apiKeys: response.data.data
      }))

      // this.props.toggleAPIKeyForm()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Creating API key failed!',
        message: 'Try again later!'
      })

      this.setState({
        sending: false
      })
    }
  }

  displayCreateAPIKey = () => {
    // if (!this.state.email.open) {
    //   return null
    // }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.createAPIKeyClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
        >
          <TextValidator
            label="API Key Name"
            onChange={ this.handleChange }
            name="name"
            value={ this.state.name }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
          />
          <TextValidator
            label="Number of seats"
            onChange={ this.handleChange }
            name="seats"
            value={ this.state.seats }
            validators={[
              'isPositive',
            ]}
            errorMessages={[
              'must be an integer'
            ]}
            margin="normal"
            fullWidth
          />
          {
            this.state.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <Button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Create API Key
            </Button>
          }
        </ValidatorForm>
      </div>
    )
  }

  render() {
    // const { page, rowsPerPage } = this.state
    return (
      this.props.user ? this.displayCreateAPIKey() : <div></div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    organization: state.organization,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEmails: data => { dispatch(setEmails(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) },
    setOrganization: data => { dispatch(setOrganization(data)) },
    // setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    // setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    // setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    // setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    // setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    // setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    // setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(APIKeyForm))

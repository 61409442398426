import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { Button, Card, CardHeader, ButtonGroup, CardContent, Grid, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { useState } from 'react'
import SpeedIcon from '@material-ui/icons/Speed';
import { Alert } from '@material-ui/lab'
import AlertTitle from '@material-ui/lab/AlertTitle';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ComputerIcon from '@material-ui/icons/Computer';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import {
  setOrganization,
  setOrganizationUsers,
  setOrganizationHosts,
  setSpeedTestStatusModalDisplayed,
  setSpeedTestStatusResults,
  setHostLivenessStatus,
  setOrgPaymentInfo,
  setOrganizationHostsCount,
  setUser,
  setOrganizationGroupProfiles,
} from '../../actions/connectionActions'
import HostsTable from './hostsTable'
import GroupProfilesTable from './groupProfilesTable'
import UsersTable from './usersTable'
import APIKeyTable from './apiKeyTable'
import Help from './help'
import Stepper from './stepper'
import SpeedTestStatusModal from '../SpeedTestStatusModal/SpeedTestStatusModal'
import WindowsServiceDownloader from './WindowsServiceDownloader'
import styles from './organizationUser.module.scss'
import GroupMenu from './groupMenu'
import Snackbar from './snackbar'
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import DownloadModal from './downloadModal'
import StripeModal from '../OrganizationPaymentUpdate/modal'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';


// in componentDidMount:
// - sets "organization" redux state from GET 'v1/organization/' with '{ id: orgId }' query params
// - sets "organizationUsers" redux state from GET 'v1/organization/users' with '{ id: orgId }' query params
// - sets "organizationHostsCount" redux state from POST 'v1/organization/hostcount' with '{ id: orgId }' request body
// - sets "organizationGroupProfiles" redux state from GET 'v1/organization/getGroupProfiles' with '{ id: orgId }' query params
// - loops over "organization.apiKeys" redux state to set react state for the following:
//   - apiKeyLoopup: map of api key ids to the number of seats they're allocated
//   - apiKeyNameLookup: map of api key ids to their common name
//   - apiKeyUsageCount: map of an api key id to the number of hosts reporting under that key
// - sets "orgPaymentInfo" redux state from GET 'v1/payments/orgPaymentInfo' with no params
class OrganizationUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      createOrgOpen: true,
      orgId: this.props.match.params.id,
      dialogOpenAddUsers: false,
      dialogOpen: false,
      dialogAPIKeyName: '',
      dialogAPIKey: '',
      apiKeyLookup: {},
      apiKeyNameLookup: {},
      apiKeyUsageCount: {},
      selectedHosts: [],
      hostStatusIntervalId: null,
      exportInProgress: false,
      setInterval: setInterval,
      clearInterval: clearInterval,
      hideHostsInProgress: false,
      deleteModalOpen: false,
      pendingDelete: false,
      deleteHostsModalOpen: false,
      pendingDeleteHosts: false,
      selectedGroupProfiles: [],
      deleteGroupInProgress: false,
      deleteGroupProfilesModalOpen: false,
      windowsServiceDownloaderOpen: false,
    }
  }

  // TODO: I don't know what the page will do if it fails to get data.
  // maybe I could kick them back to a different page or we can make
  // a support page we automatically send them to
  async getOrganization() {
    try {
      const organization = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id: this.state.orgId } })
      if (organization && organization.data.success) {
        this.props.setOrganization(organization.data.data)
        return organization.data.data
      }
    } catch (e) {
      console.log('getOrganization error:', e)
    }

    return null
  }

  async getOrganizationUsers() {
    try {
      const organizationUsers = await axios.get(`${this.props.url}/${this.props.routes.organization.getUsers}`, { params: { id: this.state.orgId } })
      if (organizationUsers && organizationUsers.data.success) {
        this.props.setOrganizationUsers(organizationUsers.data.data)
        return organizationUsers.data.data
      }
    } catch (e) {
      console.log('getOrganizationUsers error:', e)
    }

    return null
  }

  // used for the total Hosts Count at the top of Org User Dashboard
  async getOrganizationHostsCount() {
    try {
      const orgHostsCount = await axios.post(`${this.props.url}/${this.props.routes.organization.getHostCount}`, { id: this.state.orgId })
      console.log('componentDidMount orgHostsCount:', orgHostsCount)
      if (orgHostsCount && orgHostsCount.data.success) {
        this.props.setOrganizationHostsCount(orgHostsCount.data.count)
        return orgHostsCount.data
      }
    } catch (e) {
      console.log('getOrganizationHostsCount error:', e)
    }

    return null
  }

  async getOrganizationGroupProfiles() {
    try {
      const groupProfiles = await axios.get(`${this.props.url}/${this.props.routes.organization.getGroupProfiles}`, { params: { id: this.state.orgId } })
      if (!!groupProfiles && !!groupProfiles.data && groupProfiles.data.success) {
        this.props.setOrganizationGroupProfiles(groupProfiles.data.groupProfiles)
        return groupProfiles.data.groupProfiles
      }
    } catch (e) {
      console.log('error get organization group profiles:', e)
    }

    return null
  }

  async getOrganizationPaymentInfo() {
    try {
      const paymentInfo = await axios.get(`${this.props.url}/${this.props.routes.payments.orgGetPaymentInfo}`)
      if (paymentInfo && paymentInfo.data && paymentInfo.data.data) {
        this.props.setOrgPaymentInfo(paymentInfo.data.data)
        return paymentInfo.data.data
      } else {
        this.props.setOrgPaymentInfo({})
      }
    } catch (e) {
      console.log('error getting payment info')
      console.log(e)
    }

    return null
  }

  async componentDidMount() {
    await Promise.all([
      this.getOrganization(),
      this.getOrganizationUsers(),
      this.getOrganizationHostsCount(),
      this.getOrganizationGroupProfiles(),
      this.getOrganizationPaymentInfo(),
    ]).then(async ([organization, orgUsers, orgHostsCount, orgGroupProfiles, orgPaymentInfo]) => {
      if (!!organization && Array.isArray(organization.apiKeys) && orgHostsCount && Array.isArray(orgHostsCount.countPerKey)) {
        const apiKeyLookup = {}
        const apiKeyUsageCount = {}
        const apiKeyNameLookup = {}
        organization.apiKeys.forEach(k => {
          apiKeyLookup[k.id] = k.seats
          apiKeyNameLookup[k.id] = k.name
          apiKeyUsageCount[k.id] = 0
        })

        orgHostsCount.countPerKey.forEach(hc => {
          apiKeyUsageCount[hc.apiKey] = hc.count
        })

        this.setState({ apiKeyLookup, apiKeyUsageCount, apiKeyNameLookup })
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.hostStatusIntervalId);
  }

  toggleOrgDeleteModal = () => {
    this.setState({
      deleteModalOpen: this.state.deleteModalOpen ? false : true
    })
  }

  toggleOrgDeleteHostsModal = () => {
    this.setState({
      deleteHostsModalOpen: !this.state.deleteHostsModalOpen
    })
  }

  toggleDeleteGroupProfilesModal = () => {
    this.setState({
      deleteGroupProfilesModalOpen: !this.state.deleteGroupProfilesModalOpen
    })
  }

  setHostsSelection = (hosts) => {
    this.setState({
      selectedHosts: hosts
    })
  }

  setGroupProfilesSelection = (group) => {
    this.setState({
      selectedGroupProfiles: group
    })
  }

  deleteGroups = async () => {
    const groupOrGroups = this.state.selectedGroupProfiles.length > 1 ? 'groups' : 'group'
    const itOrThey = this.state.selectedGroupProfiles.length > 1 ? 'They' : 'It'

    try {
      this.setState({
        deleteGroupInProgress: true
      })

      const deleteGroupProfilesResp = await axios.post(`${this.props.url}/${this.props.routes.organization.deleteGroupProfiles}`, {
        orgId: this.state.orgId,
        groupProfileIds: this.state.selectedGroupProfiles,
      })

      if (!!deleteGroupProfilesResp && !!deleteGroupProfilesResp.data && deleteGroupProfilesResp.data.success) {
        this.props.setOrganizationGroupProfiles(deleteGroupProfilesResp.data.groupProfiles)

        this.props.notification({
          type: 'success',
          title: `Successfully deleted ${this.state.selectedGroupProfiles.length} ${groupOrGroups}!`,
          message: `${itOrThey} will no longer appear in your dashboard.`
        })

        return this.setState({
          selectedGroupProfiles: [],
          deleteGroupInProgress: false,
          deleteGroupProfilesModalOpen: false,
        })
      }
    } catch (e) {
      console.log('error deleting group profiles', e)
    }

    this.setState({
      deleteGroupInProgress: false,
      deleteGroupProfilesModalOpen: false,
    })

    return this.props.notification({
      type: 'danger',
      title: `There was a problem deleting your ${groupOrGroups}.`,
      message: 'Please try again soon or reach out to us for support.'
    })
  }

  hideHosts = async () => {
    this.setState({
      hideHostsInProgress: true,
      pendingDeleteHosts: true,
    })

    try {
      const hideHostsRes = await axios.post(`${this.props.url}/${this.props.routes.organization.hideHostIds}`, {
        orgId: this.state.orgId,
        hosts: this.state.selectedHosts,
      })

      if (!!hideHostsRes && !!hideHostsRes.data && hideHostsRes.data.success) {
        const orgHosts = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: { id: this.state.orgId } })
        if (orgHosts && orgHosts.data.success) {
          this.props.setOrganizationHosts(orgHosts.data.data)
        }

        const hostOrHosts = this.state.selectedHosts.length > 1 ? 'hosts' : 'host'

        this.props.notification({
          type: 'success',
          title: `Successfully hid ${this.state.selectedHosts.length} ${hostOrHosts}!`,
          message: `They will no longer appear in your dashboard.`
        })

        return this.setState({
          selectedHosts: []
        })
      } else {
        return this.props.notification({
          type: 'danger',
          title: 'There was a problem hiding your hosts.',
          message: 'Please try again soon or reach out to us for support.'
        })
      }
    } catch (e) {
      console.log('error hiding hosts:', e)

      return this.props.notification({
        type: 'danger',
        title: 'There was a problem hiding your hosts.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } finally {
      this.setState({
        hideHostsInProgress: false,
        pendingDeleteHosts: false,
      })
      this.toggleOrgDeleteHostsModal()
    }
  }

  runExport = async () => {
    this.setState({
      exportInProgress: true
    })

    try {
      const runExportRes = await axios.post(`${this.props.url}/${this.props.routes.organization.exportMultiple}`, {
        orgId: this.state.orgId,
        hosts: this.state.selectedHosts,
      })

      if (runExportRes && runExportRes.data.success) {
        const hostOrHosts = this.state.selectedHosts.length > 1 ? 'hosts' : 'host'
        return this.props.notification({
          type: 'success',
          title: `Successfully requested export for ${this.state.selectedHosts.length} ${hostOrHosts}!`,
          message: `This may take a few minutes, but we'll send you an email when it's finished.`
        })
      }

      // you'll get a 200 response code but with success: false if there's
      // a failure but the API server wants to communicate a message
      if (runExportRes && !runExportRes.data.success) {
        return this.props.notification({
          type: 'danger',
          title: 'There was a problem requesting your export.',
          message: runExportRes.data.message,
        })
      }

      // don't think the code will ever get here due to
      // axios intercepting anything but 200s
      return this.props.notification({
        type: 'danger',
        title: 'There was a problem requesting your export.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } catch (e) {
      console.log('error running mass export:', e)

      return this.props.notification({
        type: 'danger',
        title: 'There was a problem requesting your export.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } finally {
      this.setState({
        exportInProgress: false
      })
    }
  }

  runSpeedTest = async () => {
    this.props.setSpeedTestStatusModalDisplayed(true)
    try {
      const realtimeSpeedTest = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtest}`, {
        hosts: this.state.selectedHosts
      })
      if (realtimeSpeedTest && realtimeSpeedTest.data.success) {
        const checkSpeedTestStatus = async () => {
          try {
            const realtimeSpeedTestStatus = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtestStatus}`, {
              hosts: this.state.selectedHosts
            })
            if (realtimeSpeedTestStatus && realtimeSpeedTestStatus.data && realtimeSpeedTestStatus.data.success && realtimeSpeedTestStatus.data.data && realtimeSpeedTestStatus.data.data.status) {
              this.props.setSpeedTestStatusResults(realtimeSpeedTestStatus.data.data.status)
            }
          } catch (e) {
            console.log('error checking realtime status:', e)
          }
        }

        const that = this
        async function runner(repeats = 1) {
          if (repeats > 0) {
            await checkSpeedTestStatus()
            setTimeout(() => runner(repeats - 1), 3000)
          } else {
            if (that.props.speedTestStatusResults) {
              const timedOutResults = that.props.speedTestStatusResults.map(r => ({
                host: r.host,
                status: r.status === 'pending' || r.status === 'running' ? 'timed out' : r.status
              }))

              that.props.setSpeedTestStatusResults(timedOutResults)
            }
          }
        }

        runner(100)

      } else {
        console.log('failed to run speed test')
      }
    } catch (e) {
      console.log('error running speed test')
      console.log(e)
    }
  }

  onHostsTableRowClick = (row) => {
    this.props.history.push(`/host/${row.apiKey}/${encodeURIComponent(row.hostId)}`)
  }

  onGroupProfilesTableRowClick = (row) => {
    this.props.history.push(`/org/${this.state.orgId}/group/${row.id}`)
  }

  onAPIKeyTableRowClick = (row) => {
    this.setState({
      dialogOpen: true,
      dialogAPIKeyName: row.name,
      dialogAPIKey: row.key
    })
  }

  findHostLivenessStatus = (host) => {
    let hostAlive = false
    if (!this.props.hostLivenessStatus) {
      return hostAlive
    }

    // eslint thinks h isn't being used here even though it clearly is
    // eslint-disable-next-line
    for (const h of this.props.hostLivenessStatus) {
      if (host === h.host) {
        hostAlive = h.status
        break;
      }
    }
    return hostAlive
  }

  goToBillingPage = () => {
    this.props.history.push(`/billing/organization`)
  }

  deleteOrganization = async () => {
    this.setState({
      pendingDelete: true
    })

    const deleteOrgReq = await axios.delete(`${this.props.url}/${this.props.routes.organization.deleteOrg}`, {
      data: { orgId: this.state.orgId }
    })

    if (deleteOrgReq && deleteOrgReq.data.success) {
      this.props.setUser(deleteOrgReq.data.user)
      this.props.notification({
        type: 'success',
        title: `Success!`,
        message: `Deleted organization.`
      })

      this.setState({
        pendingDelete: false,
        deleteModalOpen: false,
      })

      return setTimeout(function () {
        window.location.href = `/`
      }, 2000)
    }

    this.setState({
      pendingDelete: false,
    })

    return this.props.notification({
      type: 'danger',
      title: `Error`,
      message: `Failed to delete organization. Please reach out to support.`
    })
  }

  createNewGroup = () => {
    this.props.history.push(`/profilehostgroup/create/${this.state.orgId}`)
  }

  displayOrg = () => {
    const { orgId } = this.state

    return (
      <Grid container spacing={3} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <Typography style={{ float: 'left', paddingLeft: '12px', paddingTop: '20px' }} variant="h4" component="h2" gutterBottom>
              <span style={{ color: '#1d98ff' }}>{this.props.organization ? this.props.organization.name : orgId}</span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  displayTop = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    console.log('organizationGroupProfiles', organizationGroupProfiles)
    const hostsPacketLossCount = organizationGroupProfiles.reduce(function (acc, curr) {
      return acc.hostsAbovePacketLossThreshold + curr.hostsAbovePacketLossThreshold
    })
    const isLoss = hostsPacketLossCount > 0

    return (
      <Grid container spacing={3} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={styles.card}>
            <CardContent style={{ paddingBottom: '0px' }}>
              <Grid container justifycontent="right" alignItems="center">
                <Typography variant="body2" component="body1" gutterBottom>
                  Packet loss breached in the last hour
                </Typography><br /><br />
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{ color: '#00ff33' }}>
                    {!organizationGroupProfiles || !Array.isArray(organizationGroupProfiles) ? 0 : organizationGroupProfiles.reduce(function (acc, curr) {
                      return acc.hostsAbovePacketLossThreshold + curr.hostsAbovePacketLossThreshold
                    })}
                    <Typography variant="body2" component="body1" gutterBottom>
                      hosts
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <PersonIcon style={{ width: '100%', height: '100%', color: '#00ff33' }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={styles.card}>
            <CardContent style={{ paddingBottom: '0px' }}>
              <Grid container justifycontent="right" alignItems="center">
                <Typography variant="body2" component="body1" gutterBottom>
                  Speed test threshold breached
                </Typography><br /><br />
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{ color: '#FE4C40' }}>
                    {!organizationGroupProfiles || !Array.isArray(organizationGroupProfiles) ? 0 : organizationGroupProfiles.reduce(function (acc, curr) {
                      return acc.hostsBelowSpeedThreshold + curr.hostsBelowSpeedThreshold
                    })}
                    <Typography variant="body2" component="body1" gutterBottom>
                      hosts
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <VpnKeyIcon style={{ width: '100%', height: '100%', color: '#FE4C40' }} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  displayAdminTools = () => {
    return (
      <Card variant="outlined" className={styles.card}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="speed-testing-content"
            id="speed-testing-header"
          >
            <Typography>Admin</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography style={{ display: 'inline-block' }} variant="body1" component="h2" gutterBottom>
                  <strong>API Keys</strong>
                </Typography>
                <Tooltip title="API Keys: You can request that imUp create API keys for you. Simply click the Help button at the bottom right and submit a ticket. Once you get a key, plug it into your app to begin reporting metrics to this dashboard."><InfoIcon style={{ fontSize: 'small' }} style={{ paddingLeft: '10px' }} /></Tooltip>
                <Link to="/billing/organization"><button className={styles.buttonRight} >+ / - seats</button></Link>
                {!this.props.organization ? null : (<APIKeyTable onRowClick={this.onAPIKeyTableRowClick} data={this.props.organization.apiKeys} />)}
                <Dialog
                  open={this.state.dialogOpen}
                  onClose={() => this.setState({ dialogOpen: false })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      boxShadow: 'none',
                      borderRadius: '10px',
                    },
                  }}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    API Key: {this.state.dialogAPIKey}
                  </DialogContent>
                  <DialogActions>
                    <ButtonGroup>
                      <Snackbar data={this.state.dialogAPIKey} />
                      <Button onClick={() => this.setState({ dialogOpen: false })} className={styles.buttonRight}>
                        Close
                      </Button>
                    </ButtonGroup>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={this.state.dialogOpenAddUsers}
                  onClose={() => this.setState({ dialogOpenAddUsers: false })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{`Add or Remove Users`}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please email support@imup.io to add or remove users.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({ dialogOpenAddUsers: false })} className={styles.button2}>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography style={{ display: 'inline-block' }} variant="body1" component="h2" gutterBottom>
                  <strong>Users</strong>
                </Typography>
                <Tooltip title="Users: All the users listed in this table have read access to your org. They can also innitiate remote speed tests. If you wish to add more users to your org, click the Help button in the bottom right and submit a request to imUp."><InfoIcon style={{ fontSize: 'small' }} style={{ paddingLeft: '10px' }} /></Tooltip>
                <button className={styles.buttonRight} onClick={() => this.setState({ dialogOpenAddUsers: true })} >add users</button>
                <UsersTable data={this.props.organizationUsers} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label="imUp billing table">
                        <TableBody>
                          <TableRow style={{ backgroundColor: '#1D98FF' }}>
                            <TableCell style={{ color: '#fff', fontWeight: 'bold' }}>Billing</TableCell>
                            <TableCell />
                          </TableRow>
                          <TableRow>
                            <TableCell>Card Type</TableCell>
                            <TableCell align="right">{this.getPaymentCardDetails('brand')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Card Last 4 Digits</TableCell>
                            <TableCell align="right">{this.getPaymentCardDetails('last4')}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Update payment info</TableCell>
                            <TableCell align="right"><Button onClick={this.goToPaymentUpdate} disabled={!this.getPaymentCardDetails('last4')} className={styles.button}>Update</Button></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <center><button onClick={this.toggleOrgDeleteModal} className={styles.button3} ><LocalAtmIcon fontSize="small" style={{ paddingRight: '5px' }} />Delete Organization</button></center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card>
    )
  }

  // calls 'v1/organization/hoststable/pagination'
  loadHostsTableRows = async ({ page, filterValue, sortModel }) => {
    const apiKeys = !!this.props.organization ? this.props.organization.apiKeys : []
    // TODO: (I don't think this is still an issue but I'm too burned out to look)
    // handle this case and figure out why their docs suggest this in the table:
    // const [page, setPage] = React.useState(0)
    if (page === 0) {
      return []
    }

    let orgHosts = null
    try {
      orgHosts = await axios.post(`${this.props.url}/${this.props.routes.organization.hostsTablePagination}`, {
        id: this.state.orgId,
        page,
        filterValue,
        sortModel,
      })
    } catch (e) {
      console.log('loadHostsTableRows error:', e)
    }

    if (orgHosts && orgHosts.data.success && !!orgHosts.data.data) {
      // update the org hosts list in redux because the liveness status redis polling
      // is based on this in componentDidMount
      if (Array.isArray(orgHosts.data.data.hostIds)) {
        this.props.setOrganizationHosts(orgHosts.data.data.hostIds)
      }

      // this.props.setOrganizationHosts(orgHosts.data.data)
      const rows = Array.isArray(orgHosts.data.data.hostIds) ? orgHosts.data.data.hostIds : []

      console.log('OrganizationUser loadHostsTableRows rows:', rows)

      return { rows, rowCount: orgHosts.data.data.rows }
    }

    return []
  }



  displayGroupProfilesTable = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    const hasGroupProfile = Array.isArray(organizationGroupProfiles) && organizationGroupProfiles.length > 0
    if (!hasGroupProfile) {
      return null
    }

    return (
      <div>
        <Divider style={{ marginTop: '50px' }} />
        <Card variant="outlined" className={styles.card}>
          <CardHeader
            action={
              <ButtonGroup variant="outlined">
                <Tooltip title="Delete selected group profile(s)">
                  <button
                    disabled={!Array.isArray(this.state.selectedGroupProfiles) || this.state.selectedGroupProfiles.length < 1}
                    className={styles.button2Red}
                    onClick={this.toggleDeleteGroupProfilesModal}
                  >
                    <ClearIcon fontSize="small" />
                  </button>
                </Tooltip>
                <Tooltip title="Add a new group profile">
                  <button
                    disabled={this.state.createGroupInProgress}
                    className={styles.button2}
                    onClick={this.createNewGroup}
                  >
                    <AddIcon fontSize="small" />
                  </button>
                </Tooltip>
              </ButtonGroup>
            }
            title='Groups'
            subheader="Click a group below to view its hosts or edit profile settings"
            className={styles.cardHeader}
          />
          <GroupProfilesTable
            orgId={orgId}
            setSelection={this.setGroupProfilesSelection}
            data={organizationGroupProfiles}
            theme={this.props.theme}
          />
        </Card>
      </div>
    )
  }

  getPaymentCardDetails = (field) => {
    const { orgPaymentInfo } = this.props
    if (!orgPaymentInfo || !orgPaymentInfo.card) {
      return ''
    }

    return orgPaymentInfo.card[field]
  }

  goToPaymentUpdate = () => {
    window.location.href = `/organization/paymentupdate`
  }

  displayHelp = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Help />
        </Grid>
      </Grid>
    )
  }


  displayAlerts = () => {
    return (
      <div>
        {Object.keys(this.state.apiKeyUsageCount).map(c => {
          const usageCount = this.state.apiKeyUsageCount[c]
          const allowedCount = this.state.apiKeyLookup[c]
          const keyName = this.state.apiKeyNameLookup[c]
          return usageCount <= allowedCount ? null : (
            <Alert severity="warning">You are using {usageCount} of {allowedCount} seats with {keyName} key!</Alert>
          )
        })}
      </div>
    )
  }

  displayWelcomeAlert = () => {
    const orgAPIKeys = !!this.props.organization && Array.isArray(this.props.organization.apiKeys) ? this.props.organization.apiKeys : []
    const orgGroupProfiles = !!this.props.organizationGroupProfiles && Array.isArray(this.props.organizationGroupProfiles) ? this.props.organizationGroupProfiles : []

    if (orgAPIKeys.length > 0 && orgGroupProfiles.length === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ justifyContent: 'center' }}>
              <center>
                <DesktopAccessDisabledIcon style={{ fontSize: '100px', color: '#1D98FF' }} />
                <h1>Org Groups</h1>
                <p>Your API key looks good and so do you. You're almost ready to get rolling, <br />let's just quickly create your first group to organize your endpoints. <br /><br />You can create as many groups as you need afterwards.</p>
                <br />
                <button
                  disabled={this.state.createGroupInProgress}
                  className={styles.button}
                  onClick={this.createNewGroup}
                >
                  Create Group
                </button>
                <center><p onClick={this.toggleOrgDeleteModal} style={{ color: 'red', cursor: 'pointer', marginTop: '30px' }}>Delete Organization</p></center>
              </center>
            </Grid>
          </Grid>
        </div>
      )
    }
    if (!!this.props.organization && this.props.organization.apiKeys.length == 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ justifyContent: 'center' }}>
              <center>
                <KeyOffIcon style={{ fontSize: '100px', color: '#1D98FF' }} />
                <h1>Welcome to your Org!</h1>
                <p>It appears you don't have an API key yet. API keys are used to associate your endpoints<br />with your Org by passing the key to the imUp app when you run it.</p>
                <StripeModal />
                <center><p onClick={this.toggleOrgDeleteModal} style={{ color: 'red', cursor: 'pointer', marginTop: '30px' }}>Delete Organization</p></center>
              </center>
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  displayHostsAlerts = () => {
    const numHosts = this.props.organizationHostsCount || 0
    if (numHosts > 0) {
      return null
    }
    return (
      <Alert severity="success">To add hosts to your org: <a href="/downloads" style={{ color: '#1d98ff' }}>download the app</a>, paste in your API key, then click start!</Alert>
    )
  }

  displayBreachAlerts = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    const hasGroupProfile = !!organizationGroupProfiles && Array.isArray(organizationGroupProfiles) && organizationGroupProfiles.length > 0

    const props = this.props
    const offendingHosts = hasGroupProfile && this.props.organizationHostsCount > 0 ? organizationGroupProfiles.reduce(function (acc, curr) {
      const ispProfile = Array.isArray(curr.ispProfiles) && curr.ispProfiles.length > 0 ? curr.ispProfiles[0] : null

      let currHostsAboveLatencyThreshold = []
      let currHostsAbovePacketLossThreshold = []
      let currHostsBelowDownloadSpeedThreshold = []
      let currHostsBelowUploadSpeedThreshold = []
      if (!!ispProfile && !!ispProfile.hostsAboveLatencyThreshold) {
        currHostsAboveLatencyThreshold = Object.keys(ispProfile.hostsAboveLatencyThreshold).map(key => {
          const hostIdsAboveLatencyThreshold = ispProfile.hostsAboveLatencyThreshold[key]

          let apiKeyName = ''
          if (props.organization && Array.isArray(props.organization.apiKeys)) {
            const apiKeyInfo = props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
            apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
          }

          return hostIdsAboveLatencyThreshold.map(hostId => {
            return {
              id: `${key}__IMUP_DELIMITER__${hostId}`,
              hostId: hostId,
              apiKeyName: apiKeyName,
              apiKey: key,
            }
          })
        }).flat()
      }

      if (!!ispProfile && !!ispProfile.hostsAbovePacketLossThreshold) {
        currHostsAbovePacketLossThreshold = Object.keys(ispProfile.hostsAbovePacketLossThreshold).map(key => {
          const hostIdsAbovePacketLossThreshold = ispProfile.hostsAbovePacketLossThreshold[key]

          let apiKeyName = ''
          if (props.organization && Array.isArray(props.organization.apiKeys)) {
            const apiKeyInfo = props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
            apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
          }

          return hostIdsAbovePacketLossThreshold.map(hostId => {
            return {
              id: `${key}__IMUP_DELIMITER__${hostId}`,
              hostId: hostId,
              apiKeyName: apiKeyName,
              apiKey: key,
            }
          })
        }).flat()
      }

      if (!!ispProfile && !!ispProfile.hostsBelowDownloadSpeedThreshold) {
        currHostsBelowDownloadSpeedThreshold = Object.keys(ispProfile.hostsBelowDownloadSpeedThreshold).map(key => {
          const hostIdsAboveDownloadSpeedThreshold = ispProfile.hostsBelowDownloadSpeedThreshold[key]

          let apiKeyName = ''
          if (props.organization && Array.isArray(props.organization.apiKeys)) {
            const apiKeyInfo = props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
            apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
          }

          return hostIdsAboveDownloadSpeedThreshold.map(hostId => {
            return {
              id: `${key}__IMUP_DELIMITER__${hostId}`,
              hostId: hostId,
              apiKeyName: apiKeyName,
              apiKey: key,
            }
          })
        }).flat()
      }

      if (!!ispProfile && !!ispProfile.hostsBelowUploadSpeedThreshold) {
        currHostsBelowUploadSpeedThreshold = Object.keys(ispProfile.hostsBelowUploadSpeedThreshold).map(key => {
          const hostIdsAboveUploadSpeedThreshold = ispProfile.hostsBelowUploadSpeedThreshold[key]

          let apiKeyName = ''
          if (props.organization && Array.isArray(props.organization.apiKeys)) {
            const apiKeyInfo = props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
            apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
          }

          return hostIdsAboveUploadSpeedThreshold.map(hostId => {
            return {
              id: `${key}__IMUP_DELIMITER__${hostId}`,
              hostId: hostId,
              apiKeyName: apiKeyName,
              apiKey: key,
            }
          })
        }).flat()
      }

      return {
        hostsAbovePacketLossThreshold: acc.hostsAbovePacketLossThreshold + curr.hostsAbovePacketLossThreshold,
        hostsAboveLatencyThreshold: acc.hostsAboveLatencyThreshold + curr.hostsAboveLatencyThreshold,
        hostsBelowDownloadSpeedThreshold: acc.hostsBelowDownloadSpeedThreshold + curr.hostsBelowDownloadSpeedThreshold,
        hostsBelowUploadSpeedThreshold: acc.hostsBelowUploadSpeedThreshold + curr.hostsBelowUploadSpeedThreshold,
        hostsAboveLatencyThresholdInfo: (acc.hostsAboveLatencyThresholdInfo || []).concat(currHostsAboveLatencyThreshold),
        hostsAbovePacketLossThresholdInfo: (acc.hostsAbovePacketLossThresholdInfo || []).concat(currHostsAbovePacketLossThreshold),
        hostsBelowDownloadSpeedThresholdInfo: (acc.hostsBelowDownloadSpeedThresholdInfo || []).concat(currHostsBelowDownloadSpeedThreshold),
        hostsBelowUploadSpeedThresholdInfo: (acc.hostsBelowUploadSpeedThresholdInfo || []).concat(currHostsBelowUploadSpeedThreshold),
      }
    }, {
      hostsAbovePacketLossThreshold: 0,
      hostsAboveLatencyThreshold: 0,
      hostsBelowDownloadSpeedThreshold: 0,
      hostsBelowUploadSpeedThreshold: 0,
      hostsAboveLatencyThresholdInfo: [],
      hostsAbovePacketLossThresholdInfo: [],
      hostsBelowDownloadSpeedThresholdInfo: [],
      hostsBelowUploadSpeedThresholdInfo: [],
    }) : {
      hostsAbovePacketLossThreshold: 0,
      hostsAboveLatencyThreshold: 0,
      hostsBelowDownloadSpeedThreshold: 0,
      hostsBelowUploadSpeedThreshold: 0,
      hostsAboveLatencyThresholdInfo: [],
      hostsAbovePacketLossThresholdInfo: [],
      hostsBelowDownloadSpeedThresholdInfo: [],
      hostsBelowUploadSpeedThresholdInfo: [],
    }

    const isHighPacketLoss = offendingHosts.hostsAbovePacketLossThreshold > 0
    const isHighLatency = offendingHosts.hostsAboveLatencyThreshold > 0
    const isLowDownloadSpeed = offendingHosts.hostsBelowDownloadSpeedThreshold > 0
    const isLowUploadSpeed = offendingHosts.hostsBelowUploadSpeedThreshold > 0

    const hostsBelowDownloadSpeedThresholdFilter = btoa(JSON.stringify({
      "items": [
        {
          "columnField": "hostId",
          "operatorValue": "isAnyOf",
          "value": offendingHosts.hostsBelowDownloadSpeedThresholdInfo.map(hostInfo => hostInfo.hostId)
        }
      ],
      "linkOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    }))

    const hostsBelowUploadSpeedThresholdFilter = btoa(JSON.stringify({
      "items": [
        {
          "columnField": "hostId",
          "operatorValue": "isAnyOf",
          "value": offendingHosts.hostsBelowUploadSpeedThresholdInfo.map(hostInfo => hostInfo.hostId)
        }
      ],
      "linkOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    }))

    const hostsAboveLatencyThresholdFilter = btoa(JSON.stringify({
      "items": [
        {
          "columnField": "hostId",
          "operatorValue": "isAnyOf",
          "value": offendingHosts.hostsAboveLatencyThresholdInfo.map(hostInfo => hostInfo.hostId)
        }
      ],
      "linkOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    }))

    const hostsAbovePacketLossThresholdFilter = btoa(JSON.stringify({
      "items": [
        {
          "columnField": "hostId",
          "operatorValue": "isAnyOf",
          "value": offendingHosts.hostsAbovePacketLossThresholdInfo.map(hostInfo => hostInfo.hostId)
        }
      ],
      "linkOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    }))

    return (
      <div style={{ marginTop: '20px' }}>
        <Typography variant="h6" component="h6" gutterBottom>
          <strong>Notifications</strong>
        </Typography>
        <Divider />
        <br />
        <Grid container spacing={3}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            {(isLowDownloadSpeed || isLowUploadSpeed) && this.props.organizationHostsCount > 0 ? <strong>Speeds:</strong> : null}
            {isLowDownloadSpeed && this.props.organizationHostsCount > 0 ?
              <Alert
                severity="warning"
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',

                }}
              >
                <AlertTitle style={{ textAlign: 'left' }}>Slow download speeds detected</AlertTitle>
                Within the last hour, there are {offendingHosts.hostsBelowDownloadSpeedThreshold} endpoints breaching their respective download speed thresholds. <Link to={`/orgSummary/${orgId}/${hostsBelowDownloadSpeedThresholdFilter}`} style={{ color: '#1D98FF' }}>View all hosts</Link>
              </Alert>
              : null
            }
            {isLowUploadSpeed && this.props.organizationHostsCount > 0 ?
              <Alert
                severity="warning"
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',

                }}
              >
                <AlertTitle style={{ textAlign: 'left' }}>Slow upload speeds detected</AlertTitle>
                Within the last hour, there are {offendingHosts.hostsBelowUploadSpeedThreshold} endpoints breaching their respective upload speed thresholds. <Link to={`/orgSummary/${orgId}/${hostsBelowUploadSpeedThresholdFilter}`} style={{ color: '#1D98FF' }}>View all hosts</Link>
              </Alert>
              : null
            }
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            {isHighPacketLoss && this.props.organizationHostsCount > 0 ? <strong>Packet Loss:</strong> : null}
            {isHighPacketLoss && this.props.organizationHostsCount > 0 ?
              <Alert
                severity="warning"
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',

                }}
              >
                <AlertTitle style={{ textAlign: 'left' }}>Packet loss detected</AlertTitle>
                Within the last hour, there are {offendingHosts.hostsAbovePacketLossThreshold} endpoints breaching their respective packet loss thresholds. <Link to={`/orgSummary/${orgId}/${hostsAbovePacketLossThresholdFilter}`} style={{ color: '#1D98FF' }}>View all hosts</Link>
              </Alert>
              : null
            }
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            {isHighLatency && this.props.organizationHostsCount > 0 ? <strong>Latency:</strong> : null}
            {isHighLatency && this.props.organizationHostsCount > 0 ?
              <Alert
                severity="warning"
                style={{
                  textAlign: 'left',
                  marginBottom: '10px',

                }}
              >
                <AlertTitle style={{ textAlign: 'left' }}>High latency detected</AlertTitle>
                Within the last hour, there are {offendingHosts.hostsAboveLatencyThreshold} endpoints breaching their respective latency thresholds. <Link to={`/orgSummary/${orgId}/${hostsAboveLatencyThresholdFilter}`} style={{ color: '#1D98FF' }}>View all hosts</Link>
              </Alert>
              : null
            }
          </Grid>
          {!hasGroupProfile || isHighLatency || isHighPacketLoss || isLowDownloadSpeed || isLowUploadSpeed || this.props.organizationHostsCount == 0 ?
            null :
            <Alert
              severity="success"
              style={{
                textAlign: 'left',
              }}
            >
              All endpoints are reporting optimal performance.
            </Alert>
          }
        </Grid>
      </div>
    )
  }

  displayAddEndpointsAlert = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    const hasGroupProfile = !!organizationGroupProfiles && Array.isArray(organizationGroupProfiles) && organizationGroupProfiles.length > 0

    return (
      <div style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {hasGroupProfile && this.props.organizationHostsCount < 1 ?
              <>
                <Typography variant="h6" component="h6" gutterBottom>
                  <strong>Notifications</strong>
                </Typography>
                <Divider />
                <br />
                <Alert
                  severity="success"
                  style={{
                    textAlign: 'left',
                    marginTop: '10px'
                  }}
                >
                  With your group created, you can now begin adding endpoints to your Org by clicking the <strong>ADD ENDPOINTS</strong> button in the menu.<br /><br />
                  Once your endpoints are reporting in, you will receive notifications about their performance here.
                </Alert>
              </>
              :
              null
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  displayPlanExpiring = () => {
    if (!this.props.organization || !this.props.organization.stripe || !this.props.organization.stripe.cancelAt) {
      return null
    }
    const expiryDate = new Date(this.props.organization.stripe.cancelAt)
    const humanReadableDate = expiryDate.toLocaleDateString([], {
      // weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

    return (
      <Alert severity="warning">Your paid plan and API keys expire on {humanReadableDate}: <a href="/billing/organization" style={{ color: '#1d98ff' }}>reactivate</a> to continue your subscription</Alert>
    )
  }

  toggleWindowsServiceDownloader = () => {
    this.setState({
      windowsServiceDownloaderOpen: !this.state.windowsServiceDownloaderOpen
    })
  }

  render() {
    if (!this.props.organization) {
      return (
        <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center', marginTop: '4em' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ justifyContent: 'center' }}>
            <center>
              <center>
                <CircularProgress />
              </center>
              <h1>Loading organization...</h1>
            </center>
          </Grid>
        </Grid>
      )
    }

    const orgGroupProfilesExist = Array.isArray(this.props.organizationGroupProfiles) && this.props.organizationGroupProfiles.length > 0
    const orgGroupProfilesExistOnOrg = Array.isArray(this.props.organization.groupProfiles) && this.props.organization.groupProfiles.length > 0
    const orgAPIKeysExist = Array.isArray(this.props.organization.apiKeys) && this.props.organization.apiKeys.length > 0

    const pluralGroupProfiles = Array.isArray(this.state.selectedGroupProfiles) && this.state.selectedGroupProfiles.length > 1
    const { organizationGroupProfiles } = this.props
    const hasGroupProfile = organizationGroupProfiles && Array.isArray(organizationGroupProfiles) && organizationGroupProfiles.length > 0
    const numHosts = this.props.organizationHostsCount || 0
    const hasHosts = numHosts > 0

    return (
      <div>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifycontent="center" >
            {
              orgAPIKeysExist && (orgGroupProfilesExist || orgGroupProfilesExistOnOrg) ? null
                : !orgAPIKeysExist ? <Stepper data={1} />
                  : <Stepper data={2} />
            }
            <SpeedTestStatusModal />
            {hasHosts && hasGroupProfile ?
              null : this.displayWelcomeAlert()
            }
            {!hasGroupProfile || !hasHosts ?
              this.displayAddEndpointsAlert() : this.displayBreachAlerts()
            }
            {this.displayPlanExpiring()}
            {hasGroupProfile &&
              <>
                {this.displayGroupProfilesTable()}
                {this.displayAdminTools()}
              </>
            }
          </Grid>
        </Grid>
        {/* <WindowsServiceDownloader
          modalOpen={this.state.windowsServiceDownloaderOpen}
          toggleOpen={this.toggleWindowsServiceDownloader}
        /> */}
        {/* confirmation modal for deleting entire organization */}
        <Dialog
          open={this.state.deleteModalOpen}
          onClose={this.toggleOrgDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete your organization?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="error">Are you sure? This action is irreversible.</Alert>
              <br />
              Your organization will be unsubscribed from any payment plans, and all API keys will be deleted.
              <br />
              You will lose all access to your data in the future.
              <br />
              Alternatively, you can <a href="/billing/organization" style={{ color: '#1d98ff' }}>unsubscribe</a> without deleting your organization.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={styles.buttonGroup}>
              <button disabled={this.state.pendingDelete} onClick={this.toggleOrgDeleteModal} className={styles.button2}>
                No
              </button>
              <button disabled={this.state.pendingDelete} onClick={this.deleteOrganization} className={styles.button3}>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        {/* confirmation modal for deleting hosts */}
        <Dialog
          open={this.state.deleteHostsModalOpen}
          onClose={this.toggleOrgDeleteHostsModal}
          aria-labelledby="alert-dialog-delete-hosts"
          aria-describedby="alert-dialog-delete-hosts"
        >
          <DialogTitle id="alert-dialog-delete-hosts">{'Hide your hosts?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-hosts">
              <Alert severity="error">Are you sure?</Alert>
              <br />
              Your selected hosts will no longer be visible in the dashboard.
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={styles.buttonGroup}>
              <button disabled={this.state.pendingDeleteHosts} onClick={this.toggleOrgDeleteHostsModal} className={styles.button2}>
                No
              </button>
              <button disabled={this.state.pendingDeleteHosts} onClick={this.hideHosts} className={styles.button3}>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        {/* confirmation modal for deleting group profiles */}
        <Dialog
          open={this.state.deleteGroupProfilesModalOpen}
          onClose={this.toggleDeleteGroupProfilesModal}
          aria-labelledby="alert-dialog-delete-groups"
          aria-describedby="alert-dialog-delete-groups"
        >
          <DialogTitle id="alert-dialog-delete-groups">{`Delete group profile${pluralGroupProfiles ? 's' : ''}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-groups">
              <Alert severity="error">Are you sure?</Alert>
              <br />
              {pluralGroupProfiles ? 'These' : 'This'} group profile{pluralGroupProfiles ? 's' : ''} will be permanently deleted.
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={styles.buttonGroup}>
              <button disabled={this.state.deleteGroupInProgress} onClick={this.toggleDeleteGroupProfilesModal} className={styles.button2}>
                No
              </button>
              <button disabled={this.state.deleteGroupInProgress} onClick={this.deleteGroups} className={styles.button3}>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    theme: state.theme,
    notification: state.notification,
    organization: state.organization,
    organizationUsers: state.organizationUsers,
    organizationHostsCount: state.organizationHostsCount,
    organizationHosts: state.organizationHosts,
    organizationGroupProfiles: state.organizationGroupProfiles,
    routes: state.routes,
    speedTestStatusResults: state.speedTestStatusResults,
    hostLivenessStatus: state.hostLivenessStatus,
    orgPaymentInfo: state.orgPaymentInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationUsers: data => { dispatch(setOrganizationUsers(data)) },
    setOrganizationHosts: data => { dispatch(setOrganizationHosts(data)) },
    setOrganizationGroupProfiles: data => { dispatch(setOrganizationGroupProfiles(data)) },
    setSpeedTestStatusModalDisplayed: data => { dispatch(setSpeedTestStatusModalDisplayed(data)) },
    setSpeedTestStatusResults: data => { dispatch(setSpeedTestStatusResults(data)) },
    setHostLivenessStatus: data => { dispatch(setHostLivenessStatus(data)) },
    setOrgPaymentInfo: data => { dispatch(setOrgPaymentInfo(data)) },
    setOrganizationHostsCount: data => { dispatch(setOrganizationHostsCount(data)) },
    setUser: (user) => { dispatch(setUser(user)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationUser))

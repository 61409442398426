import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styles from './paymentPlans.module.scss'
import { Button, Card, CardContent, Grid, Typography, CardHeader, CardActionArea, CardActions, CardMedia } from '@material-ui/core';
import Pricing from './pricing'
import { setUser, setPaymentPlan } from '../../actions/connectionActions'
import UpgradePricingModule from './upgradepricingmodule'

class Payments extends Component {
  render() {
    const { user, url, routes, notification, setUser, setPaymentPlan } = this.props

    return (
      <div className={ styles.stripe }>
      <Grid container spacing={3}>
         <Grid item xs={12} sm={12} md={12} lg={9} xl={9} justifyContent="center" alignItems="center">
           <Pricing
            stripe={user.stripe || {}}
             currentPlan={user.plan}
             url={url}
             routes={routes}
             notification={notification}
             setUser={setUser}
             setPaymentPlan={setPaymentPlan}
           />
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="center" alignItems="center">
           <UpgradePricingModule
           currentPlan={user.plan}
           url={url}
           routes={routes}
           notification={notification}
           setUser={setUser}
           setPaymentPlan={setPaymentPlan}/>
         </Grid>
       </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: data => { dispatch(setUser(data)) },
    setPaymentPlan: data => { dispatch(setPaymentPlan(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments))

import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import styles from './organizations.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setOrganizations } from '../../actions/connectionActions'
import axios from 'axios'
import { Card, CardContent, Grid, Typography, CardHeader } from '@material-ui/core'

class OrganizatonForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      downloadingClient: false,
      open: false,
      name: ''
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  createOrganizationClicked = async () => {
    const { name } = this.state
    this.setState({
      sending: true
    })

    const organization = { name }

    const response = await axios.post(`${this.props.url}/${this.props.routes.organization.create}`, { organization })

    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Created organization!'
      })

      this.props.setOrganizations(response.data.data)


      this.setState({
        sending: false,
        name: ''
      })
      // this.props.toggleEmailForm()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Creating organization failed!',
        message: 'Try again later!'
      })

      this.setState({
        sending: false
      })
    }
  }

  displayOrganizationForm = () => {
    // if (!this.state.email.open) {
    //   return null
    // }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.createOrganizationClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
        >
        <Typography variant="body1" component="h2" gutterBottom>
          <strong>Create an Organization</strong>
        </Typography>
          <TextValidator
            label="Name"
            onChange={ this.handleChange }
            name="name"
            value={ this.state.name || ''}
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            variant="outlined"
            style={{ paddingTop:'10px'}}
          />
          {
            this.state.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Create
            </button>
          }
        </ValidatorForm>
      </div>
    )
  }

  render() {
    // const { page, rowsPerPage } = this.state
    return (
      this.displayOrganizationForm()
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: data => { dispatch(setOrganizations(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizatonForm))

import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const dateFormat = x => {
  const _d = new Date(x);

  const mm = _d.getMonth() + 1;
  const dd = _d.getDate();
  return `${mm}/${dd}`
};

export default function StatsTableDowntime(props) {
  return !props.adminViewUser || !props.adminViewUser.downtimeStats ? null : (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="speed test data">
        <TableHead style={{backgroundColor: '#1D98FF'}}>
          <TableRow>
            <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Date</TableCell>
            <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Minutes Down</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.adminViewUser.downtimeStats.map((row) => (
            <TableRow key={row.time}>
              <TableCell component="th" scope="row">{dateFormat(row.time)}</TableCell>
              <TableCell align="right">{row.success}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

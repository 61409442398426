import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Admin from '../Admin/Admin'
import AdminUser from '../AdminUser/AdminUser'
import AdminReport from '../AdminReport/AdminReport'
import AdminUserProfileModify from '../AdminUserProfileModify/AdminUserProfileModify'
import Home from '../Home/Home'
import Downloads from '../Downloads/newDownloads'
import LocalProviders from '../LocalProviders/LocalProviders'
import Login from '../Login/Login'
import Logout from '../Logout/Logout'
import Registration from '../Registration/Registration'
import ActivationHash from '../ActivationHash/ActivationHash'
import Recovery from '../Recovery/Recovery'
import RecoveryHash from '../RecoveryHash/RecoveryHash'
import RegistrationVerifyEmail from '../RegistrationVerifyEmail/RegistrationVerifyEmail'
import Organizations from '../Organizations/Organizations'
import Organization from '../Organization/Organization'
import OrganizationEdit from '../OrganizationEdit/OrganizationEdit'
import OrganizationUser from '../OrganizationUser/OrganizationUser'
import OrganizationHost from '../OrganizationHost/OrganizationHost'
import OrganizationExport from '../OrganizationExport/OrganizationExport'
import Error from '../Error/Error'
import Profile from '../Profile/Profile'
import TermsOfService from '../TermsOfService/TermsOfService'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import PaymentPlans from '../PaymentPlans/PaymentPlans'
import Payments from '../Payments/Payments'
import MacInstructions from '../InstallationInstructions/MacInstructions'
import WindowsInstructions from '../InstallationInstructions/WindowsInstructions'
// import WindowsServiceInstructions from '../InstallationInstructions/WindowsServiceInstructions'
import DockerInstructions from '../InstallationInstructions/DockerInstructions'
// import LinuxInstructions from '../InstallationInstructions/LinuxInstructions'
// import LinuxArmInstructions from '../InstallationInstructions/LinuxArmInstructions'
import ProfileModify from '../ProfileModify/ProfileModify'
import OrganizationSummary from '../OrganizationSummary/OrganizationSummary'
import OrganizationBilling from '../OrganizationBilling/OrganizationBilling'
import OrganizationPaymentUpdate from '../OrganizationPaymentUpdate/OrganizationPaymentUpdate'
import PaymentUpdate from '../PaymentUpdate/Payments'
import FAQ from '../FAQ/FAQ'
import ProfileHostGroupModify from '../ProfileHostGroupModify/ProfileHostGroupModify'
import OrganizationGroup from '../OrganizationUser/OrganizationGroup'

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route { ...rest }
    render={(props) => (
      authenticated ?
      <Component { ...props } /> :
      <Redirect to='/login' />
    )}
  />
)

export default function Routes(props) {
  const hasOrg = !!props.user && Array.isArray(props.user.organizations) && props.user.organizations.length > 0

  return (
    <Switch>
      {/*
        making "/" a private route creates a weird glitch cause the browser doesn't realize its logged in in time(?)
        need to make / a splash page and not private, and make an if/else based on whether they're logged in(?)
      */}
      <PrivateRoute path="/" component={ !hasOrg ? Home : () => <Redirect to={`/org/${props.orgId}`} /> } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/downloads" component={ Downloads } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/admin" component={ Admin } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/admin/user" component={ AdminUser } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/admin/report" component={ AdminReport } authenticated={ props.authenticated } exact />
      <PrivateRoute path='/admin/user/modify' component={ AdminUserProfileModify } authenticated={ props.authenticated } exact/>
      <Route path="/login" component={ Login } exact />
      <Route path="/terms" component={ TermsOfService } exact />
      <Route path="/privacy" component={ PrivacyPolicy } exact />
      <Route path="/MacInstallation" component={ MacInstructions } exact />
      <Route path="/WindowsInstallation" component={ WindowsInstructions } exact />
      {/* <Route path="/LinuxInstallation" component={ LinuxInstructions } exact /> */}
      {/* <Route path="/LinuxArmInstallation" component={ LinuxArmInstructions } exact /> */}
      <Route path="/DockerInstallation" component={ DockerInstructions } exact />
      {/* <Route path="/WindowsServiceInstallation" component={ WindowsServiceInstructions } exact /> */}
      <PrivateRoute path="/admin/organizations" component={ Organizations } authenticated={ props.authenticated } exact />
      <Route path="/faq" component={ FAQ } exact />
      <Route path="/logout" component={ Logout } exact />
      <Route path="/registration" component={ Registration } exact />
      <Route path="/verify" component={ RegistrationVerifyEmail } exact />
      <Route path="/activation/:hash" component={ ActivationHash } exact />
      <Route path="/recovery" component={ Recovery } exact />
      <Route path="/recovery/:hash" component={ RecoveryHash } />
      <PrivateRoute path="/billing/organization" component={ OrganizationBilling } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/organization/paymentupdate" component={ OrganizationPaymentUpdate } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/organization/:id" component={ Organization } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/organization/edit/:id" component={ OrganizationEdit } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/org/:id" component={ OrganizationUser } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/exports/organization/:id" component={ OrganizationExport } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/orgSummary/:id/:filterModel" component={ OrganizationSummary } authenticated={ props.authenticated } />
      <PrivateRoute path="/host/:apiKeyId/:hostId/:groupId?/:groupName?" component={ OrganizationHost } authenticated={ props.authenticated } />
      <PrivateRoute path="/localProviders" component={ LocalProviders } authenticated={props.authenticated} exact />
      <PrivateRoute path="/plans" component={ PaymentPlans } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/payments" component={ Payments } authenticated={ props.authenticated } exact />
      <PrivateRoute path="/payment/update" component={ PaymentUpdate } authenticated={ props.authenticated } exact />
      <PrivateRoute path='/profile' component={ Profile } authenticated={ props.authenticated } exact/>
      <PrivateRoute path='/profile/modify' component={ ProfileModify } authenticated={ props.authenticated } exact/>
      <PrivateRoute path='/profilehostgroup/modify/:orgId/:groupProfileId' component={ ProfileHostGroupModify } authenticated={ props.authenticated } exact/>
      <PrivateRoute path='/profilehostgroup/create/:orgId' component={ ProfileHostGroupModify } authenticated={ props.authenticated } exact/>
      <PrivateRoute path='/org/:id/group/:groupId' component={ OrganizationGroup } authenticated={ props.authenticated } exact/>
      <Route component={ Error } />
    </Switch>
  )
}

import React, { useState, Component } from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, Button } from '@material-ui/core';
import * as react from "react";
import styles from './LocalProviders.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { Tooltip, ResponsiveContainer } from 'recharts';
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person';
import { Card, CardContent } from '@material-ui/core';
import Chip from '@mui/material/Chip';

function getZip(props) {
  return props.row.zipcode;
}
function getLocalProviders(props) {
  return props.row.name
}
function getPrice(props) {
  return props.row.price;
}
function getDownload(props) {
  return props.row.downloadSpeed;
}
function getDownload7DayAvg(props) {
  if (!props.row.speedTestData || !props.row.speedTestData.download || !props.row.speedTestData.download.avg30Day) {
    return 0
  }

  return Number.parseFloat(props.row.speedTestData.download.avg30Day).toFixed(2)
}
function getUpload7DayAvg(props) {
  if (!props.row.speedTestData || !props.row.speedTestData.upload || !props.row.speedTestData.upload.avg30Day) {
    return 0
  }

  return Number.parseFloat(props.row.speedTestData.upload.avg30Day).toFixed(2)
}
function getUpload(props) {
  return props.row.uploadSpeed;
}
function getDate(props) {
  return props.row.updatedAt;
}
function getRating(props) {
  return props.row.rating;
}
function getOnline(props) {
  return !!props.row.online ? props.row.online.thirtyMins : null
}
function countUnique(iterable) {
  return new Set(iterable).size;
}

const columns = [
  {
    field: 'online',
    headerName: 'Status',
    width: 100,
    valueGetter: getOnline,
    renderCell: (props) => {
      //const date = new Date(params.value * 1000);
      return (
        <Chip label={props.row.online.thirtyMins ? 'online' : 'offline' } color={props.row.online.thirtyMins ? 'success' : 'error' } />
      );
    },
  },
  { field: 'Zipcode', headerName: 'Zip', width: 120, valueGetter: getZip},
  { field: 'Provider', headerName: 'Provider', width: 120, valueGetter: getLocalProviders},
  { field: 'price', headerName: 'Price', width: 75, valueGetter: getPrice},
  { field: 'downloadSpeed', headerName: 'Download (mbps)', width: 150, valueGetter: getDownload},
  { field: 'downloadSpeedAvg30Day', headerName: 'User download avg', width: 150, valueGetter: getDownload7DayAvg},
  { field: 'uploadSpeed', headerName: 'Upload (mbps)', width: 150, valueGetter: getUpload},
  { field: 'uploadSpeedAvg30Day', headerName: 'User upload avg', width: 150, valueGetter: getUpload7DayAvg},
  {
    field: 'rating',
    headerName: 'Rating',
    width: 175,
    valueGetter: getRating,
    renderCell: (props) => {
      //const date = new Date(params.value * 1000);
      return <Rating name="read-only" style={{color:"#1d98ff"}} value={(props.row.rating)} readOnly />;
    },
  },
];

export default function LocalProvidersTable(props) {
  // const [selectionModel, setSelectionModel] = React.useState([])
  console.log('LocalProvidersTable props', props)
  const gridWrapperRef = react.useRef(null);
  react.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
  });
  return !props.data ? null : (
    <div ref={gridWrapperRef}>
        <CardContent>
          <NewCustomDataGrid
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            columns={columns}
            rows={props.data}
            color='#1d98ff'
            showToolbar
            autoHeight='true'
          />
          </CardContent>
      </div>
  )

}

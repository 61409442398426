import React, { Component } from 'react'
import styles from './home.module.scss'
import {
  Grid,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import DownloadModal from './downloadModal'
import LogoBlack from '../../imup_black.png'
import LogoWhite from '../../imup_white.png'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setOrganization } from '../../actions/connectionActions'


class OrganizationEdit extends Component {
    
  constructor(props) {
    super(props)

    this.state = {
      orgId: this.props.match.params.id,
      email: '',
      loading: false,
    }
  }

  async componentDidMount() {

  }
//   if (authenticated) {
//     return <Redirect to="/landing" />
//   }



  render() {
    const { theme } = this.props
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  style={{justifyContent: 'center'}}>
                <center>
                  <img src={theme === 'dark' ? LogoWhite : LogoBlack} style={{maxHeight:'100px'}} />
                  {/* <h1>Welcome</h1> */}
                  <p>Select an option below to get started.</p>
                  <DownloadModal data={this.props} text="Personal"/>
                  <Link to="/organization/edit/create"><button className={styles.buttonLanding} style={{width:'200px'}}><center>Business</center></button></Link> 
                </center>
            </Grid>
          </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    routes: state.routes,
    theme: state.theme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationEdit))

import React from 'react'
import styles from './termsOfService.module.scss'

export default function TermsOfService() {
  return (
    <div className={ styles.home }>
      <div>
        <h2>imUp.io Terms Of Use</h2>
        <p>This site is offered to you conditioned on your acceptance without modification of the terms, conditions and notices contained herein. Your use of this site constitutes your agreement to all such terms, conditions and notices. This site is powered and operated by Pint Technologies, LLC ("Pint Technologies&rdquo;, &ldquo;imUp.io", "we", &ldquo;our&rdquo;, or "us"). You must be 13 years of age or older to use this site.</p>
        <p><strong>ImUp.io</strong> is a service of Pint Technologies, LLC, that, among other things, allows subscribers to locate, evaluate, test, and enroll in internet services through third party providers. By using the Service, you accept the terms of this Agreement. This is a legally binding agreement between you and imUp.io.</p>
        <p>imUp.io operates solely as a service provider by providing users with software to conduct uptime health checks and speed tests, and an interface to review results from not only yourself, but the greater community of users as well. You are not permitted to use the Service unless you have successfully subscribed to be a member of the Service ("Member").</p>
        <p>The Service is available to Members to assist in the purchase, refund, or inquiry of Internet services. Additionally, optional related services may be purchased. The price and other terms remain subject to service agreements between the buying Member and the third party provider. ImUp.io cannot guarantee the rates, refunds, credits, and/or services provided by the third party vendor.</p>
        <p>ImUp.io has the right to cancel or suspend a Member's account at any time and without notice upon a breach, violation or non-compliance of this Agreement or any other policies, terms of use, or agreements of imUp.io. Notwithstanding the foregoing, under no circumstances shall imUp.io be required to issue refunds hereunder.</p>
        <p>ImUp.io is the provider of the Service and is not a party to any contract concluded pursuant to the Service. ImUp.io is not responsible or liable to any Member, or any other user of the Service in any way, including, without limitation, any disputes arising out of the contract between Members and third party internet service providers, such as disputes related whether the parties have fully performed their obligations thereunder and any disputes related to whether a contract has been validly entered into or concluded.</p>
        <p>Each internet transaction shall be consummated directly between the member and third party provider, and imUp.io has no interest, as broker or otherwise, in such transaction.</p>
        <h5><strong>COPYRIGHT AND TRADEMARK NOTICES</strong></h5>
        <p>The materials, content, data and information provided to or made available through the Service are the property of imUp.io or its licensors, and are protected by U.S. copyright laws, other copyright laws, and international conventions. Except as explicitly provided for in this Agreement, you may not distribute, transmit, display, reproduce, modify, create derivative works from, or otherwise exploit any of the materials, code, or applications available through the Service.</p>
        <p>ImUp.io and the names of other products and services referred to through the Service are the trademarks of Pint Technologies, LLC and their respective owners. You may not use any trademark or service mark appearing through the Service without the prior written consent of the owner of the mark.</p>
        <h5><strong>FEES</strong></h5>
        <p>The use of the imUp.io site requires an imUp.io service subscription, and we currently offer two options: Monthly and Annual subscriptions. A separate service subscription is required for each meter.</p>
        <p>Some notes about imUp.io service subscriptions:</p>
        <p>Until you cancel a subscription by notifying imUp.io member care at support@imup.io, it will automatically renew at the end of each subscription term at the same fee (even if you are not using the imUp.io portal).</p>
        <p>Monthly imUp.io service subscriptions may not be transferred to another subscriber.</p>
        <p>Although we strive to make the imUp.io service available as much as possible, there may be periods of downtime (for example, for scheduled maintenance, software updates, or events outside of our reasonable control).</p>
        <h5><strong>MULTI-SERVICE DISCOUNT</strong></h5>
        <p>In some cases, we offer discounted imUp.io service fees when you have more than one location on the same account. Contact customer service at support@imup.io for more information.</p>
        <p>Some restrictions that apply to the multi-service discount:</p>
        <h5><strong>PAYMENT TERMS</strong></h5>
        <p>When you provide credit card information to us, you represent that you are the authorized user of the card, and agree to notify us of any changes to the relevant account number, expiration date, and billing address, or if the card expires or is canceled. You agree that we may receive updated credit card information (such as a new expiration date) from your credit card issuer.</p>
        <p>We will charge your credit card on each billing date for your imUp.io service subscription. You are responsible for reviewing your credit card statement for billing accuracy.</p>
        <p>Unless notified by imUp.io, your service subscription will automatically renew at the end of each subscription term at the same fee, and you authorize us to automatically charge the subscription fee to your credit card each billing date until you cancel your subscription 30 days prior to auto-renewal by contacting member care at support@imup.io. Subscription fees paid prior to cancellation are nonrefundable.</p>
        <p>Some notes about payments:</p>
        <p>By subscribing to the imUp.io service, you authorize us to charge the applicable subscription fees (and any other applicable fees, such as an early termination fee or service fee) to your credit card on file.</p>
        <p>If your credit card reaches its expiration date and you have not updated your payment information with us or cancelled your account, you authorize us to continue billing your credit card and you will remain responsible for any uncollected amounts.</p>
        <p>Each charge on the applicable billing date applies to the subscription period immediately following the billing date (so a charge for a monthly subscription in January is for the imUp.io service in the month of February).</p>
        <p>All prices on imUp.io are listed in U.S. Dollars.</p>
        <p>We reserve the right to increase the monthly subscription fee for imUp.io service subscribers paying month-to-month with no commitment, but we will notify you 30 days in advance. If you do not accept the new fees, you should terminate your subscription before the new fees go into effect.</p>
        <p>We reserve the right to suspend or terminate your subscription to the service without notice if your credit card is rejected or if your card issuer seeks the return of payments previously made to us. You agree to pay (1) any outstanding balance in full within 30 days of cancellation of your subscription, and (2) a late charge on all amounts more than 30 days past due. The late charge will be 1.5% of the past due amount or the highest rate allowed by law (whichever is less) per month. Such rights are in addition to and not in lieu of any other legal right or remedies available to imUp.io.</p>
        <p>We also reserve the right to refer your account to a third party for collection to pursue unpaid amounts, and you will remain liable to us for all unpaid charges and all the costs we incur to collect those charges (such as collection agency fees).</p>
        <h5><strong>SERVICES</strong></h5>
        <h6>General</h6>
        <p>You agree that no joint venture, partnership, employment or agency relationship exists between you and us as a result of this agreement or use of this site. Nothing contained in this site shall be construed to prevent us from complying with law enforcement requests or requirements relating to your use of this site or information provided to or gathered by us with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remainder of the agreement shall continue in effect. This agreement constitutes the entire agreement between you and us with respect to your use of this site and software. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Fictitious names of companies, products, people, characters and/or data mentioned herein are not intended to represent any real individual, company, product, or event. Any rights not expressly granted herein are reserved.</p>
        <h6>Use of Our Services</h6>
        <p>Whenever you order or purchase a service or product through this site you are warranting to us and the applicable service provider that you are at least 13 years of age and possess the legal authority to enter into the transactions made available through this site and will use this service in accordance with all terms and conditions herein and as set forth in this site. You agree to be financially responsible for your use of and all transactions made in connection with services and products ordered or purchased through this site. Without limitation, any speculative, false or fraudulent purchase, order, reservation or notification to any service provider through this site is prohibited. You agree that you will be completely responsible for all charges, fees, duties, taxes and assessments arising out of the purchases and orders you make through this site.</p>
        <h6>Communication</h6>
        <p>In connection with your use of the Service, imUp.io is permitted to send you communications by email and text, including service announcements, administrative messages, and other information applicable to your membership and electricity service.</p>
        <p>We may occasionally provide communications on relevant industry information, frequently asked questions, and best practice tips as an addition to your service. You may opt-out of those communications, which are provided as a convenience.</p>
        <p>ImUp.io may also record its phone conversations and text chats with you for quality control, training purposes, and resolution of disputes. Anyone who has authorization to use your account may likewise be recorded. You agree that none of this obligates imUp.io to record any communication with you, and you acknowledge that some communications will not be recorded or be available to be retrieved.</p>
        <h6>Liability Disclaimer</h6>
        <p>IF YOU RELY ON THE SERVICE, OR ANY INFORMATION, PRODUCT OR SERVICE AVAILABLE THROUGH THE SERVICE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER PROBLEMS WITH THE INFORMATION, PRODUCTS, AND SERVICES PUBLISHED ON OR PROMOTED OVER THE SERVICE. THE SERVICE AND THE WEBSITE ARE PROVIDED TO YOU "AS IS." IMUP.IO AND ITS AFFILIATES, AGENTS AND LICENSORS, CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE INFORMATION AVAILABLE THROUGH THE SERVICE (OR ANY INFORMATION, GOODS OR SERVICES THAT ARE REFERRED TO, ADVERTISED OR PROMOTED ON, OR SOLD THROUGH THE SERVICE). NOR DOES IMUP.IO, ITS AFFILIATES, AGENTS AND LICENSORS GUARANTEE THAT THE SERVICE WILL BE ERROR FREE, OR CONTINUOUSLY AVAILABLE, OR THAT THE SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.&nbsp;</p>
        <p>UNDER NO CIRCUMSTANCES WILL IMUP.IO OR ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES, COSTS, EXPENSES, FEES OR LIABILITIES ARISING OUT OF YOUR USE OF THE SERVICE OR ANY PRODUCT OR SERVICE LINKED TO FROM OR ADVERTISED OR PROMOTED ON THE SERVICE, INCLUDING, WITHOUT LIMITATION, DIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR OTHER DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS, LOSS OF BUSINESS OR LOSS OF DATA), EVEN IF IMUP.IO IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THESE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY IS LIMITED TO THE MEMBERSHIP FEES YOU PAID DURING THE PERIOD OF YOUR INTERNET SERVICES CONTRACT. YOU AGREE TO INDEMNIFY AND HOLD HARMLESS IMUP.IO, ITS EMPLOYEES, OWNERS, REPRESENTATIVES, AND LICENSEES AGAINST ANY AND ALL CLAIMS, OF WHATEVER NATURE, THAT ARISE OUT OF YOUR USE OF THE SERVICE.</p>
        <h6>No Unlawful or Prohibited Use</h6>
        <p>As a condition of your use of this site, you warrant that you will not use this site for any purpose that is unlawful or prohibited by these terms, conditions and notices.</p>
        <h6>Internet Service Providers</h6>
        <p>This site does not represent or imply that any service category available on this site contains a complete or comprehensive list or choice of all service providers that can provide service to you at your particular address. This site may exclude certain service providers that do not meet imUp.io's proprietary standards. Accordingly, there may be service providers that can provide you with service at your particular address that do not appear on this site and are not listed as a choice for you to select under a particular service category contained on this site. You are in no way obligated to use this site or to choose any particular service provider that appears on this site.</p>
        <p>Each Member authorizes imUp.io to request and/or retrieve information related to your internet subscription and usage. ImUp.io will establish accounts, on your behalf, in order to retrieve this information when necessary. These accounts may be established with your Internet Service Provider ("ISP"). If established, your credentials will be made available to you at your request. Member agrees to abide by the service provider&rsquo;s TOS whenever an account is established on their behalf.</p>
        <p>We reserve the right to retain data collected on behalf of the member during the service. Upon termination of services, we will cancel any agreements made on behalf of the customer or transfer their access to the customer, unless expressly agreed to by the customer, within a reasonable period, up to 45 days. We are not responsible for delays by third parties in cancelling or transferring agreements. We may continue to receive data for terminated or transferred agreements, and reserve the right to retain the data collected. We will not instigate new agreements after the termination of service.</p>
        <p>Each Member agrees and understands that he or she is appointing imUp.io as his or her limited agent for purposes of selecting and enrolling the member's service location for internet service with Retail Internet providers from time to time on their behalf. BY USING THIS SITE, EACH MEMBER ACKNOWLEDGES AND AGREES THAT HE OR SHE IS AUTHORIZING IMUP.IO TO ENROLL AND MAKE DECISION ON BEHALF OF THE CUSTOMER IN AN ATTEMPT TO PROVIDE MONETARY SAVINGS FOR THE CUSTOMER. In addition to enrollments for service, each member acknowledges that imUp.io may call a Retail Electricity Provider on their behalf, in reference to their account.</p>
        <p>Any member can rescind this authorization at any time by providing notice to us at support@imup.io. Member acknowledges that our ability to support the member services may be limited or compromised without these authorizations.</p>
        <h6>Modification of These Terms and Conditions</h6>
        <p>We reserve the right to change the terms, conditions and notices under which this site is offered.</p>
      </div>
    </div>
  )
}

import React from 'react'
import styles from './installationInstructions.module.scss'
import { Button, ButtonGroup, Link, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CodeBlock, dracula } from "react-code-blocks";
import Client from '../../resources/imup_client.png';

export default function MacInstructions() {
  return (
    <div className={ styles.home }>
      <div>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Typography variant="h4" component="h4" gutterBottom>
          Windows Instructions
        </Typography>
            <hr />
            <Alert severity="info">When we update the Windows client, you will receive a notification in the top left of the app</Alert>
            <br />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} spacing={3}>
            <Card>
            <CardContent>
            <ol>
              <li>Open the imUp.msi file.</li>
              <li>A warning will pop up saying <strong>Windows protected your PC</strong>. Click <strong>More Info</strong>, and then <strong>Run anyway</strong>. Now the imUp application will install and start up!</li>
              <li>Within the app, type in the email address you used to register with imUp and click <strong>Start imUp</strong>. Within 15-30 minutes you should start seeing data at <a href="https://app.imup.io" target="_blank" rel="noopener noreferrer" style={{color: '#1D98FF' }}>https://app.imup.io</a>.</li>
            </ol>
              </CardContent>
            </Card>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} spacing={3}>
              <img src={Client} alt="imUp Mac" height="520" class="center"/>
            </Grid>
        </Grid>
      </div>
    </div>
  )
}

const apiRoutes = {
  auth: {
    check: 'v1/auth/check',
    activation: 'v1/auth/activation',
    login: {
      local: 'v1/auth/login/local',
      facebook: 'v1/auth/login/facebook',
      google: 'v1/auth/login/google',
      github: 'v1/auth/login/github',
      twitter: 'v1/auth/login/twitter',
    },
    connect: {
      twitter: 'v1/auth/connect/twitter',
    },
    logout: 'v1/auth/logout',
    recovery: 'v1/auth/recovery',
    registration: 'v1/auth/registration',
    registrationFinish: 'v1/auth/registration/finish'
  },
  user: {
    profile: {
      update: 'v1/user/profileupdate',
      remove: 'v1/user/profileremove',
    },
    emails: {
      get: 'v1/user/emails',
      send: 'v1/user/email'
    }
  },
  data: {
    connectivity: 'v1/data/getConnectivity',
    speedtest: 'v1/data/getSpeedtest',
    downtime: 'v1/data/downtime',
    address: 'v1/data/address',
    speedtestAverage: 'v1/data/speedtest/avg',
    speedtestStats: 'v1/data/speedtest/stats',
    pingData: 'v1/data/pingdata',
    export: 'v1/data/export',
    localProviders: 'v1/data/localProviders'
  },
  realtime: {
    speedtest: 'v1/realtime/speedtest',
    speedtestStatus: 'v1/realtime/speedteststatus',
    hostConnectedStatus: 'v1/realtime/hostconnectedstatus',
    latestHostName: 'v1/realtime/latestHostName',
  },
  payments: {
    payment: 'v1/payments/payment',
    orgSubscription: 'v1/payments/orgSubscription',
    orgGetPaymentInfo: 'v1/payments/orgPaymentInfo',
    orgUpdatePaymentMethod: 'v1/payments/orgUpdatePaymentMethod',
    subscription: 'v1/payments/subscription',
    unsubscribe: 'v1/payments/unsubscribe',
    reactivate: 'v1/payments/reactivate',
    updateAPIKey: 'v1/payments/updateSubscription',
    orgUnsubscribe: 'v1/payments/orgUnsubscribe',
    orgReactivate: 'v1/payments/orgReactivate',
    userGetPaymentInfo: 'v1/payments/paymentInfo',
    updatePaymentMethod: 'v1/payments/updatePaymentMethod'
  },
  support: {
    send: 'v1/support/send'
  },
  geolocation: 'https://us-central1-tidy-ivy-287314.cloudfunctions.net/geolocation',
  admin: {
    users: 'v1/admin/users',
    user: 'v1/admin/user',
    email: 'v1/admin/email',
    emails: 'v1/admin/emails',
    sendQueuedEmail: 'v1/admin/queuedemail',
    createEmail: 'v1/admin/createemail',
    onlineUsers: 'v1/admin/onlineusers',
    activeUsers: 'v1/admin/activeusers'
  },
  provider: {
    list: 'v1/provider/list',
    new: 'v1/provider/new',
    update: 'v1/provider/update',
    delete: 'v1/provider/delete'
  },
  reports: {
    report: 'v1/reports/report',
    reports: 'v1/reports/reports',
    reportGroups: 'v1/reports/reportgroups'
  },
  organization: {
    // manage group profiles
    getGroupProfiles: 'v1/organization/getGroupProfiles',
    addGroupProfile: 'v1/organization/addGroupProfile',
    updateGroupProfile: 'v1/organization/updateGroupProfile',
    deleteGroupProfiles: 'v1/organization/deleteGroupProfiles',
    // manage isp profiles
    getISPProfiles: 'v1/organization/getISPProfiles',
    addISPProfile: 'v1/organization/addISPProfile',
    updateISPProfile: 'v1/organization/updateISPProfile',
    deleteISPProfiles: 'v1/organization/deleteISPProfiles',
    // various other org things
    list: 'v1/organization/list',
    create: 'v1/organization/',
    userCreate: 'v1/organization/create',
    get: 'v1/organization/',
    getHostIds: 'v1/organization/hosts',
    hideHostIds: 'v1/organization/host/hide',
    getUsers: 'v1/organization/users',
    addUsers: 'v1/organization/adduser',
    deleteUsers: 'v1/organization/deleteuser',
    createAPIKey: 'v1/organization/api/create',
    deleteAPIKey: 'v1/organization/api/delete',
    getOrgHostSpeedTestData: 'v1/organization/host/speedtest',
    getOrgHostConnectivityData: 'v1/organization/host/connectivity',
    getOrgHostPingData: 'v1/organization/host/ping',
    summary: 'v1/organization/summary',
    export: 'v1/organization/host/export',
    exportMultiple: 'v1/organization/host/exportbulk',
    exportDownload: 'v1/organization/export/download',
    exportList: 'v1/organization/export/list',
    hostsTablePagination: 'v1/organization/hoststable/pagination',
    getHostCount: 'v1/organization/hostcount',
    deleteOrg: 'v1/organization/',
  },
  download: {
    windowsServiceInstaller: 'v1/download/downloadWindowsServiceInstaller',
  },
}

const config = {
  api: {
    url: process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://localhost:3001'
       : process.env.REACT_APP_ENVIRONMENT === 'pinttech' ? 'https://api.pinttech.io'
       : 'https://api.imup.io',
    routes: apiRoutes
  },
  apiVersion: 'v1',
  stripe: {
    key: process.env.REACT_APP_ENVIRONMENT === 'development'
      ? 'pk_test_51HW4wQKX6jdRztysEf85ZHA1MdzPYsoNHpHcTobZTKHk8AFcTA1nUR2u401dMvN6UJuW26KJjtrUbkn9VXaFgcbb00K618QAdL'
      : 'pk_live_51HW4wQKX6jdRztysAWix6NMHf2Yo8BYXVfRN630C4fIAukw62Wy8fA5VoSitDjjvnr6xNtMfMDBoTWSixm3c1B5300QHrdcOtX'
  },
  google: {
    places: 'AIzaSyAdUJt7KGEValO8RlVAkKvPJFz24uPETzk'
  }
}

module.exports = config

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import { setUsers, setAdminViewUser } from '../../actions/connectionActions'
import { useTheme } from '@material-ui/core/styles';
import styles from './admin.module.scss'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>

    </div>
  );
}

class UsersTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  async componentDidMount() {
    const users = await axios.get(`${this.props.url}/${this.props.routes.admin.users}`)
    console.log('users:', users)
    if (users) {
      this.props.setUsers(users.data.data)
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.adminViewEmails.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  isUserOnline = (email) => {
    if (!Array.isArray(this.props.onlineUsers)) {
      return ''
    }

    return this.props.onlineUsers.includes(email) ? 'X' : ''
  }

  displayUsersTable = (data) => {
    const that = this
    async function handleUserClick(email) {
      console.log('email:', email)
      const userData = await axios.post(`${that.props.url}/${that.props.routes.admin.user}`, { email })
      console.log('userData:', userData)
      if (userData && userData.data && userData.data.data) {
        that.props.setAdminViewUser(userData.data.data)
      } else {
        that.props.setAdminViewUser({ user: {}, connectivity: [], speedtest: [], downtime: 0 })
      }

      that.props.history.push('/admin/user')
    }

    const { page, rowsPerPage } = this.state

    return (
      <TableContainer component={Paper}>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF'}}>
            <TableRow>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Online</TableCell>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Email</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Name</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Customer Type</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Admin</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Agreed to Terms</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Tier</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*<TableRow key={row.email} onClick={() => getUserData(row.email)}>*/}
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.email} onClick={() => handleUserClick(row.email)}>
                <TableCell align="center">{this.isUserOnline(row.email)}</TableCell>
                <TableCell component="th" scope="row">{row.email}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.customer ? row.customer.type : ''}</TableCell>
                <TableCell align="right">{row.admin.toString()}</TableCell>
                <TableCell align="right">{row.appTermsOfService.length > 0 ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{row.plan}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <div>
        {this.props.users ? this.displayUsersTable(this.props.users) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    users: state.users,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    onlineUsers: state.onlineUsers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: data => { dispatch(setUsers(data)) },
    setAdminViewUser: data => { dispatch(setAdminViewUser(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable))

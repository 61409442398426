import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import styles from './adminReport.module.scss'
import { setConnectivityData, setDowntimeData, setSpeedTestData, setGeolocationData, setSpeedTestAverage7d, setTermsOfServiceModalDisplayed, setDownloadOS } from '../../actions/connectionActions'
import ReportsTable from './reportsTable'

class AdminReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      open: false,
    }
  }

  render() {
    return (
      <div className={ styles.home }>
       <ReportsTable />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    geolocationData: state.geolocationData,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false,
    adminViewUser: state.adminViewUser,
    adminViewReports: state.adminViewReports
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminReport))

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoBlack from '../../imup_black.png'
import LogoWhite from '../../imup_white.png'
import { Link, withRouter } from 'react-router-dom'
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Status from './status';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ToggleTheme from './toggleTheme'
import ProfileInfo from './profileInfo'
import PolicyIcon from '@material-ui/icons/Policy';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AppBar from '@material-ui/core/AppBar';
import Admin from './admin'
import Routes from '../Routes/Routes'
import Cloud from './imup_logo.svg'
import Logo from './imup_logo.svg'
import BusinessIcon from '@material-ui/icons/Business';
import LogoMobile from './icon.ico'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DownloadModal from '../Home/downloadModal'

//Setting styles for new navigation menu
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex-grid',
    [theme.breakpoints.up('md')]: {
      marginLeft: '15%',
      marginRight: '15%'
    },
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      height: '60px',
    },
    backgroundColor: '#232323',
    boxShadow: '1px 0px #434343',
  },
  menuButton: {
    marginRight: 10,
    marginTop: 0
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    zIndex: '1000'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      backgroundColor: 'transparent',
    },
    zIndex: '1000'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,

  },
  title: {
    marginLeft: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemDone: {
    "&:hover": {
      color: "#1D98FF"
    }
  },
}));


const desktopOS = ['Mac OS', 'Windows', 'Linux']
//Export MiniDrawer function  and return all the bullshit
function MiniDrawer(props) {
  const isMobile = !desktopOS.includes(props.operatingSystem)
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(!isMobile);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
    if (isMobile) {
      setShowDrawer(true);
    }
  };

  const handleDrawerToggle = () => {
    if (isMobile) {
      setOpen(!open); setShowDrawer(!open)
    }
  }

  const handleDrawerClose = () => {
    if (isMobile) {
      setShowDrawer(false);
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (isMobile) {
      setOpen(!open);
      setShowDrawer(!open);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const showDrawerContent = () => {
    const userBelongsToOrg = !!props.user && Array.isArray(props.user.organizations) && props.user.organizations.length > 0
    // necessary until everyone has the new state from API server
    const orgId = !userBelongsToOrg ? null
      : typeof props.user.organizations[0] === 'string' ? props.user.organizations[0]
        : props.user.organizations[0].id
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          zIndex: '1000'
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <Toolbar />
        <List>
          {['Dashboard'].map((text, index) => (
            <ListItem className={classes.listItemDone} component={Link} to="/" button key={text}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemIcon>{index % 1 === 0 ? <HomeIcon /> : <HomeIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['Profile'].map((text, index) => (
            <ListItem className={classes.listItemDone} component={Link} to="/profile" button key={text}
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon>{index % 2 === 0 ? <PersonIcon /> : <PersonIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['Plans'].map((text, index) => (
            <ListItem className={classes.listItemDone} component={Link} to="/plans" button key={text}
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon>{index % 3 === 0 ? <MonetizationOnIcon /> : <MonetizationOnIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['Local Providers'].map((text, index) => (
            <ListItem className={classes.listItemDone} component={Link} to="/localProviders" button key={text}
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              <ListItemIcon>{index % 4 === 0 ? <BusinessIcon /> : <BusinessIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['Sign out'].map((text, index) => (
            <ListItem className={classes.listItemDone} component={Link} to="/logout" button key={text}
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemIcon>{index % 5 === 0 ? <DirectionsWalkIcon /> : <DirectionsWalkIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <Admin authenticated={props.authenticated} user={props.user} />
        </List>
        <Divider />
        <List>
          {!userBelongsToOrg ? (
            <List>
              <ListItem
                className={classes.listItemDone}
                component={Link}
                to={`/organization/edit/${userBelongsToOrg ? orgId : 'create'}`}
                button
                key={'Org Edit'}
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary={`${userBelongsToOrg ? 'Edit' : 'Create'} Org`} />
              </ListItem>
            </List>
          ) : (
            <div>
              <ListItem
                className={classes.listItemDone}
                component={Link}
                to={`/org/${orgId}`}
                button
                key={'Org Dashboard'}
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
              >
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary={'Org Dashboard'} />
              </ListItem>
              <ListItemButton onClick={handleClick2}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Org Groups" />
                {open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open2} timeout="auto" unmountOnExit>
                {!Array.isArray(props.orgGroups) ? null : props.orgGroups.map(data => {
                  return (
                    <ListItem
                      className={classes.listItemDone}
                      component={Link}
                      to={`/org/${orgId}/group/${data.id}`}
                      button
                      key={'Org Dashboard'}
                      selected={selectedIndex === 8}
                      onClick={(event) => handleListItemClick(event, 8)}
                    >
                      <ListItemText primary={data.name} />
                    </ListItem>
                  );
                })}
              </Collapse>
              <ListItem
                className={classes.listItemDone}
                component={Link}
                to={`/billing/organization`}
                button
                key={'Org Billing'}
                selected={selectedIndex === 9}
                onClick={(event) => handleListItemClick(event, 9)}
              >
                <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                <ListItemText primary={'Org Billing'} />
              </ListItem>
              <ListItem
                className={classes.listItemDone}
                component={Link}
                to={`/exports/organization/${orgId}`}
                button
                key={'Org Exports'}
                selected={selectedIndex === 10}
                onClick={(event) => handleListItemClick(event, 10)}
              >
                <ListItemIcon><CloudDownloadIcon /></ListItemIcon>
                <ListItemText primary={'Org Export'} />
              </ListItem>
            </div>
          )}
        </List>
        {/*userBelongsToOrg ? null : (
            <List>
              <ListItem
                className={classes.listItemDone}
                component={Link}
                to={`/organization/edit/${userBelongsToOrg ? props.user.organizations[0] : 'create'}`}
                button
                key={'Org Edit'}
                selected={selectedIndex === 11}
                onClick={(event) => handleListItemClick(event, 11)}
              >
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary={`${userBelongsToOrg ? 'Edit' : 'Create'} Org`} />
              </ListItem>
            </List>
          )
        */}
        <Divider />
        <List>
          {['Terms of service'].map((text, index) => (
            <ListItem className={classes.listItemDone} component="a" target="_blank" href="https://www.imup.io/tos" button key={text}
              selected={selectedIndex === 12}
              onClick={(event) => handleListItemClick(event, 12)}
            >
              <ListItemIcon>{index % 12 === 0 ? <PolicyIcon /> : <PolicyIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['Privacy policy'].map((text, index) => (
            <ListItem className={classes.listItemDone} component="a" target="_blank" href="https://www.imup.io/privacy" button key={text}
              selected={selectedIndex === 13}
              onClick={(event) => handleListItemClick(event, 13)}
            >
              <ListItemIcon>{index % 13 === 0 ? <PolicyIcon /> : <PolicyIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          {['FAQ'].map((text, index) => (
            <ListItem className={classes.listItemDone} component="a" target="_blank" href="https://imup.io/faq" button key={text}
              selected={selectedIndex === 14}
              onClick={(event) => handleListItemClick(event, 14)}
            >
              <ListItemIcon>{index % 14 === 0 ? <LiveHelpIcon /> : <LiveHelpIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <List className={clsx(classes.menuButton, {
          [classes.hide]: !open,
        })}>
          <ProfileInfo user={props.user} authenticated={props.authenticated} />
          <DownloadModal data={props} text="Downloads" />
        </List>
      </Drawer>
    )
  }

  const showAppBar = () => {
    const { downtimeData, userLivenessStatus } = props
    const { pathname } = props.location;
    const orgPage = pathname.includes('org')

    // const navbarText = {
    //   '/': 'Dashboard',
    //   '/profile': 'My Profile',
    //   '/profile/modify': 'Edit Profile',
    //   '/downloads': 'Downloads',
    //   '/plans': 'Plans',
    //   '/admin': 'Admin Dashboard',
    //   '/admin/user': 'Admin User View',
    //   '/admin/report': 'Admin Report',
    //   '/admin/user/modify': 'Admin User Edit',
    //   '/terms': 'Terms of Service',
    //   '/privacy': 'Privacy Policy',
    //   '/faq': 'Frequently Asked Questions',
    //   '/installation': 'Installation Instructions',
    //   '/organization/edit/create': 'Create Org'
    // }

    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <AppBar
          position="fixed"
          className={clsx(classes.appBar)}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            {desktopOS.includes(props.operatingSystem) ? null : (
              <MenuIcon style={{ height: '20px', marginRight: '10px' }} onClick={handleDrawerToggle} />
            )}
            <Grid item xs={12} sm={12} md={12} lg={1} xl={1} justify="flex-start">
              <Link to="/"><img src={LogoWhite} alt="imUp logo" height={isMobile ? '27' : '33'} /></Link>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} justify="space-between">
              <center>
                {orgPage ? null : (
                  <Status />
                )}
              </center>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={1} xl={1} justify="flex-end" justifyContent="flex-end">
              <ToggleTheme theme={props.theme} setTheme={props.setTheme} user={props.user} />
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {props.authenticated ? showAppBar() : null}
      {props.authenticated && showDrawer ? showDrawerContent() : null}

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes authenticated={props.authenticated} />
      </main>
    </div>
  );
}

export default withRouter(props => <MiniDrawer {...props} />);

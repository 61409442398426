import React, { Component } from 'react'
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ValidatorForm } from 'react-material-ui-form-validator'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styles from './home.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { setTermsOfServiceModalDisplayed, setUser } from '../../actions/connectionActions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'inherit',
  p: 4,
};

class TermsOfServiceModal extends Component {
  constructor(props) {
    super(props)

    let {
      id,
      username,
      name,
      email,
    } = this.props.user

    // this.state = {
    //   id,
    //   username,
    //   name,
    //   email,
    //   loading: false,
    //   message: '',
    //   subject: ''
    // }

    this.state = {
      id,
      username,
      name,
      email,
      loading: false,
      message: '',
      subject: '',
      downloadingClient: false
    }

    console.log('TermsOfServiceModal constructor:', this.props)
  }

  handleClose = () => {
    console.log('handle close!')
    this.props.setTermsOfServiceModalDisplayed(false)
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  submitRequest = async () => {
    console.log('submit request')

    this.setState({
      downloadingClient: true,
      loading: true
    })

    axios({
      url: `${this.props.url}/v1/download/auth`,
      method: 'GET',
      params: {
        os: this.props.downloadOS
      },
      // important
      // responseType: 'blob',
    }).then((response) => {
      if (response && response.data && response.data.success) {
        this.props.setUser(response.data.user)
        this.openInNewTab(response.data.data)

        this.setState({
          downloadingClient: false,
          loading: false
        })

        this.props.setTermsOfServiceModalDisplayed(false)
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Error',
          message: 'Something went wrong, please try again later'
        })
      }
    })
      .catch(e => {
        console.log('error downloading client:', e)

        this.setState({
          downloadingClient: false,
          loading: false
        })
      })

    // const { id, username, name, email, message, subject } = this.state
    // const supportData = {
    //   id,
    //   username,
    //   name,
    //   email,
    //   message,
    //   subject
    // }
    // this.setState({
    //   loading: true
    // })
    // const response = await axios.post(`${this.props.url}/${this.props.routes.support.send}`, supportData)
    // this.setState({
    //   loading: false
    // })
    // if (response && response.data.success) {
    //   console.log(response.data)
    //   this.props.setTermsOfServiceModalDisplayed(false)
    //   this.setState({
    //     message: '',
    //     subject: ''
    //   })
    //   this.props.notification({
    //     type: 'success',
    //     title: 'Success!',
    //     message: 'Support request sent!'
    //   })
    // } else {
    //   this.props.notification({
    //     type: 'danger',
    //     title: 'Save failed!',
    //     message: 'Try again later!'
    //   })
    // }
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    // const { user } = this.props
    const { loading } = this.state

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open={this.props.termsOfServiceModalDisplayed}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Card className={styles.card2}>
            <Fade in={this.props.termsOfServiceModalDisplayed}>
              <div>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography component="h3" variant="h5">
                    <center>Please agree to our terms of service before downloading:</center>
                  </Typography>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.submitRequest}
                    style={{ paddingTop: 'none', paddingBottom: 'none' }}
                  // onError={ errors => console.log(errors) }
                  >
                    <center><p>
                      <a href="https://www.imup.io/tos" target="_blank" style={{ color: '#1D98FF' }}>
                        <Typography component="body1" variant="body1">
                          Terms of Service
                        </Typography>
                      </a>
                    </p>
                      <p>
                        <a href="https://www.imup.io/privacy" target="_blank" style={{ color: '#1D98FF' }}>
                          <Typography component="body1" variant="body1">
                            Privacy Policy
                          </Typography>
                        </a>
                      </p></center>
                    {
                      loading ?
                        <center>
                          <button
                            className={styles.button}
                            disabled
                          >
                            Downloading... <CircularProgress color="primary" size={24} />
                          </button></center> :
                        <center><button
                          type="submit"
                          className={styles.button}
                        >
                          Agree
                        </button></center>
                    }
                  </ValidatorForm>
                  <Typography component="body1" variant="body1">
                    <center>Review the installation instructions if you need help installing or starting the app.</center>
                  </Typography>
                </Grid>
              </div>
            </Fade>
          </Card>
        </Box>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false,
    downloadOS: state.downloadOS
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setUser: (user) => { dispatch(setUser(user)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsOfServiceModal))

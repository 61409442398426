export default [
  { minHosts: 1, hosts: '3 - 10', price: 3.00, annualDiscountMonths: 2 },
  { minHosts: 11, hosts: '11 - 50', price: 2.70, annualDiscountMonths: 2 },
  { minHosts: 51, hosts: '51 - 100', price: 2.40, annualDiscountMonths: 2 },
  { minHosts: 101, hosts: '101 - 250', price: 2.10, annualDiscountMonths: 2 },
  { minHosts: 251, hosts: '251 - 500', price: 1.80, annualDiscountMonths: 2 },
  { minHosts: 501, hosts: '501 - 750', price: 1.50, annualDiscountMonths: 2 },
  { minHosts: 751, hosts: '751 - 1000', price: 1.20, annualDiscountMonths: 2 },
  { minHosts: 1001, hosts: '1001+', price: 0.90, annualDiscountMonths: 2 },
]

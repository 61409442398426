import React, { useState } from 'react';
import styles from './paymentPlans.module.scss'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SpeedIcon from '@material-ui/icons/Speed';
import Divider from '@material-ui/core/Divider';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import axios from 'axios'
import ProfileInfo from '../Navigation/profileInfo';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'lite' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardHeaderAlternate: {
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardPricingAlternate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

const tiers = [
  {
    title: 'Reporting Plan',
    id: 'reporting',
    subheader: 'Its free!',
    price: '0',
    description: [
      {
        name: 'Downtime monitoring',
        tooltip: 'Catch any downtime longer than 1 minute'
      },
      {
        name: 'Speed testing',
        tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
      },
      {
        name: '-',
      },
      {
        name: '-',
      },
      {
        name: '-',
      },
      {
        name: '-',
      },
      {
        name: '-',
      },
      {
        name: '2 days of storage',
        tooltip: 'Two days of metrics storage'
      },
      {
        name: 'Weekday email support',
        tooltip: 'Email support during business hours'
      },
    ],
    buttonText: 'Upgrade Plan',
    // buttonVariant: 'outlined',
    buttonVariant: 'contained',
  },
  {
    title: 'Lite Plan',
    id: 'lite',
    subheader: 'Extended storage',
    price: '1',
    description: [
      {
        name: 'Downtime monitoring',
        tooltip: 'Catch any downtime longer than 1 minute'
      },
      {
        name: 'Speed testing',
        tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download and upload speeds'
      },
      {
        name: 'Remote speed testing',
        tooltip: 'Run a speed test on your computer anytime, from anywhere'
      },
      {
        name: 'Downtime notifications',
        tooltip: 'Receive an email or tweet notification when you go offline'
      },
      {
        name: 'Slow speed notifications',
        tooltip: 'Receive an email or tweet notification when your speeds are slower than expected'
      },
      {
        name: 'Data export',
        tooltip: 'Export connectivity & speed test data to CSV'
      },
      {
        name: '-',
      },
      {
        name: '2 weeks of storage',
        tooltip: 'Two weeks of metrics storage'
      },
      {
        name: 'Weekday email support',
        tooltip: 'Email support during business hours'
      },
    ],
    buttonText: 'Upgrade Plan',
    // buttonVariant: 'outlined',
    buttonVariant: 'contained',
  },
  {
    title: 'Integrity Plan',
    id: 'integrity',
    subheader: 'Most popular',
    price: '3',
    description: [
      {
        name: 'Downtime monitoring',
        tooltip: 'Catch any downtime longer than 1 minute'
      },
      {
        name: 'Speed testing',
        tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
      },
      {
        name: 'Remote speed testing',
        tooltip: 'Run a speed test on your computer anytime, from anywhere'
      },
      {
        name: 'Downtime notifications',
        tooltip: 'Receive an email or tweet notification when you go offline'
      },
      {
        name: 'Slow speed notifications',
        tooltip: 'Receive an email or tweet notification when your speeds are slower than expected'
      },
      {
        name: 'Data export',
        tooltip: 'Export connectivity & speed test data to CSV'
      },
      {
        name: 'Request local providers',
        tooltip: 'You may request that an imUp representative search for other ISPs in your area based on plans other imUp users have reported'
      },
      {
        name: '1 month of storage',
        tooltip: 'One month of metrics storage'
      },
      {
        name: 'Weekday email support',
        tooltip: 'Email support during business hours'
      },
    ],
    buttonText: 'Upgrade Plan',
    buttonVariant: 'contained',
  },
  // {
  //   title: 'Rise Plan',
  //   id: 'rise',
  //   subheader: 'Set and forget',
  //   price: '7',
  //   description: [
  //     {
  //       name: 'Downtime monitoring',
  //       tooltip: 'Catch any downtime longer than 1 minute'
  //     },
  //     {
  //       name: 'Speed testing',
  //       tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
  //     },
  //     {
  //       name: 'Downtime notifications',
  //       tooltip: 'Receive an email or tweet notification when you go offline'
  //     },
  //     {
  //       name: 'ISP emailing',
  //       tooltip: 'Access to the easy, single click feature that notifies your ISP if you experience any issues'
  //     },
  //     {
  //       name: 'Data export',
  //       tooltip: 'Export connectivity & speed test data to CSV'
  //     },
  //     {
  //       name: 'Automatic negotiations',
  //       tooltip: 'An imUp negotiator will contact and begin negotiating with your ISP on your behalf if we notice degradation'
  //     },
  //     {
  //       name: 'Plan analysis',
  //       tooltip: 'We will check with your ISP and others in your area to identify cheaper or better deals that are available to you'
  //     },
  //     {
  //       name: '-'
  //     },
  //     {
  //       name: '1 year of storage',
  //       tooltip: 'One year of metrics storage for each of your endpoints'
  //     },
  //     {
  //       name: 'Weekday email support',
  //       tooltip: 'Email support during business hours'
  //     },
  //     {
  //       name: <br />
  //     },
  //     {
  //       name: '+30% of savings',
  //     },
  //   ],
  //   buttonText: 'Upgrade Plan',
  //   // buttonVariant: 'outlined',
  //   buttonVariant: 'contained',
  // },

  // {
  //   title: 'Pro Plan',
  //   id: 'professional',
  //   subheader: 'For corp or families',
  //   price: '10',
  //   description: [
  //     {
  //       name: 'Downtime monitoring',
  //       tooltip: 'Catch any downtime longer than 1 minute'
  //     },
  //     {
  //       name: 'Speed testing',
  //       tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
  //     },
  //     {
  //       name: 'Downtime notifications',
  //       tooltip: 'Receive an email or tweet notification when you go offline'
  //     },
  //     {
  //       name: 'ISP emailing',
  //       tooltip: 'Access to the easy, single click feature that notifies your ISP if you experience any issues'
  //     },
  //     {
  //       name: 'Automatic negotiations',
  //       tooltip: 'An imUp negotiator will contact and begin negotiating with your ISP on your behalf if we notice degradation'
  //     },
  //     {
  //       name: 'Plan analysis',
  //       tooltip: 'We will check with your ISP and others in your area to identify cheaper or better deals that are available to you'
  //     },
  //     {
  //       name: 'Org dashboard',
  //       tooltip: 'Access to the Org dashboard which allows you to see all of your endpoints in a single place. Read more here: https://imup.io/orgs'
  //     },
  //     {
  //       name: '-'
  //     },
  //     {
  //       name: '1 year of storage',
  //       tooltip: 'One year of metrics storage for each of your hosts'
  //     },
  //     {
  //       name: '24/7 email & chat support',
  //       tooltip: 'Access to imUp support 24/7 via email or chat!'
  //     },
  //     {
  //       name: <br />
  //     },
  //     {
  //       name: '+30% of savings',
  //     },
  //   ],
  //   buttonText: 'Upgrade Plan',
  //   // buttonVariant: 'outlined',
  //   buttonVariant: 'contained',
  // },
  // {
  //   title: 'You Plan',
  //   id: 'you',
  //   subheader: 'If you love what were doing',
  //   price: '100',
  //   description: [
  //     {
  //       name: 'Downtime monitoring',
  //       tooltip: 'Catch any downtime longer than 1 minute'
  //     },
  //     {
  //       name: 'Speed testing',
  //       tooltip: 'Randomly scheduled speed tests throughout the day. Measuring download & upload speeds'
  //     },
  //     {
  //       name: 'Downtime notifications',
  //       tooltip: 'Receive an email or tweet notification when you go offline'
  //     },
  //     {
  //       name: 'ISP emailing',
  //       tooltip: 'Access to the easy, single click feature that notifies your ISP if you experience any issues'
  //     },
  //     {
  //       name: 'Automatic negotiations',
  //       tooltip: 'An imUp negotiator will contact and begin negotiating with your ISP on your behalf if we notice degradation'
  //     },
  //     {
  //       name: 'Plan analysis',
  //       tooltip: 'We will check with your ISP and others in your area to identify cheaper or better deals that are available to you'
  //     },
  //     {
  //       name: 'Org dashboard',
  //       tooltip: 'Access to the Org dashboard which allows you to see all of your endpoints in a single place. Read more here: https://imup.io/orgs'
  //     },
  //     {
  //       name: 'Sponsorship',
  //       tooltip: 'We will place your logo in our app and on our website as a sponsored supporter!'
  //     },
  //     {
  //       name: '1 year of storage',
  //       tooltip: 'One year of metrics storage for each of your hosts'
  //     },
  //     {
  //       name: '24/7 email & chat support',
  //       tooltip: 'Access to imUp support 24/7 via email or chat!'
  //     },
  //     {
  //       name: <br />
  //     },
  //     {
  //       name: '+30% of savings',
  //     },
  //   ],
  //   buttonText: 'Upgrade Plan',
  //   // buttonVariant: 'outlined',
  //   buttonVariant: 'contained',
  // },
];

const planNames = {
  integrity: 'Integrity Plan',
  professional: 'Professional Plan',
  lite: 'Lite Plan',
  negotiations: 'Negotiations Plan',
  rise: 'Rise Plan',
  you: 'You Plan',
  internetUpgrade: 'Internet Upgrade',
}

export default function Pricing(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false);
  const [reactivating, setReactivating] = useState(false);
  const [unsubscribeFeedback, setUnsubscribeFeedback] = useState('');

  const openDialogClick = () => {
    setOpen(open ? false : true)
  }

  const removeDialogClick = () => {
    setOpen(open ? false : true)
  }

  const openReactivateDialogClick = () => {
    setReactivateDialogOpen(reactivateDialogOpen ? false : true)
  }

  const removeReactivateDialogClick = () => {
    setReactivateDialogOpen(reactivateDialogOpen ? false : true)
  }

  const reactivateUser = async () => {
    setReactivating(true)
    const response = await axios.post(`${props.url}/${props.routes.payments.reactivate}`)
    console.log('reactivateUser response', response)

    if (!!response && response.status === 200) {
      console.log('reactivated successfully:', response)
      setReactivating(false)
      removeReactivateDialogClick()

      // check for user even on success cause we'll send back success if
      // the subscription was successful but the mongo store failed
      if (!!response.data.user) {
        props.setUser(response.data.user)
      }


      props.notification({
        type: 'success',
        title: 'Thanks for sticking with us!',
        message: `Successfully reactivated the ${planNames[props.currentPlan]}! Redirecting you to home...`
      })

      setTimeout(function(){
        window.location.href = `/`
      }, 5000)
    } else {
      setReactivating(false)
      removeReactivateDialogClick()
      props.notification({
        type: 'danger',
        title: 'Error',
        message: 'Failed to reactivate. Please reach out to support or try again later!'
      })
    }
  }

  const unsubscribeUser = async () => {
    setUnsubscribing(true)
    const response = await axios.post(`${props.url}/${props.routes.payments.unsubscribe}`, { feedback: unsubscribeFeedback })
    console.log('unsubscribeUser response', response)

    if (!!response && response.status === 200) {
      console.log('unsubscribed successfully:', response)
      setUnsubscribing(false)
      removeDialogClick()

      // check for user even on success cause we'll send back success if
      // the subscription was successful but the mongo store failed
      if (!!response.data.user) {
        props.setUser(response.data.user)
      }


      props.notification({
        type: 'success',
        title: 'Sorry to see you go!',
        message: `Successfully unsubscribed from the ${planNames[props.currentPlan]}! Your plan will stay active until your paid period ends. Redirecting you to home...`
      })

      setTimeout(function(){
        window.location.href = `/`
      }, 5000)
    } else {
      setUnsubscribing(false)
      removeDialogClick()
      props.notification({
        type: 'danger',
        title: 'Error',
        message: 'Failed to unsubscribe. Please reach out to support or try again later!'
      })
    }
  }

  const handleClick = (tier) => {
    if (props.currentPlan === tier.id) {
      if (!!props.stripe && !!props.stripe.cancelAt) {
        return openReactivateDialogClick()
      }

      openDialogClick()
    } else {
      props.setPaymentPlan(tier.title)
      window.location.href = `/payments`
    }
  }

  // maybe put a tooltip in here or something too giving them
  // the date they have until to reactivate
  const unsubscribeOrReactivate = (props, tier) => {
    let text = 'Unsubscribe'
    if (props.currentPlan === tier.id && tier.id !== 'reporting') {
      if (!!props.stripe && !!props.stripe.cancelAt) {
        return 'Reactivate'
      }

      return 'Unsubscribe'
    }

    return tier.buttonText
  }

  return (
    <React.Fragment>
    <ProfileInfo />
    <Typography variant="h3" component="h3" gutterBottom style={{color:'#1d98ff', marginBottom:'40px'}}>
      Personal
    </Typography>
    <Grid container spacing ={3} alignItems="stretch">
      {tiers.map((tier) => (
        // Professional Plan card is full width at sm breakpoint
        <Grid item key={tier.title} xs={12} sm={12} md={4} lg={4} xl={4}>
          <Card>
            <CardHeader
              title={tier.title}
              subheader={tier.subheader}
              titleTypographyProps={{ align: 'center' }}
              subheaderTypographyProps={{ align: 'center', color:'#fff' }}
              action={tier.title === 'Pro' ? <StarIcon /> : null}
              className={classes[!!tier.cardHeaderStyle ? tier.cardHeaderStyle : 'cardHeader']}
              style={{backgroundColor: '#1D98FF', color:'#fff'}}
            />
            <CardContent>
              <div className={classes[!!tier.cardContentStyle ? tier.cardContentStyle : 'cardPricing']}>
                <Typography component="h2" variant="h3" color="textPrimary">
                  ${tier.price}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  /mo
                </Typography>
              </div>
              <ul>
              {tier.description.map((line) => (
                <Typography component="li" variant="subtitle1" align="center" key={line}>
                  {line.name}
                  {!line.tooltip ? null : (
                    <Tooltip title={line.tooltip}>
                      <InfoOutlinedIcon
                        display={line.tooltip != null}
                        style={{fontSize: 'medium', color: '#1D98FF', paddingLeft: '5px'}}
                      />
                    </Tooltip>
                  )}
                </Typography>))}
              </ul>
            </CardContent>
            <CardActions style={{display: 'block'}}>
              <center><button disabled={tier.id === 'reporting'} fullWidth variant={tier.buttonVariant} className={ styles.button } onClick={() => handleClick(tier)}>
                {unsubscribeOrReactivate(props, tier)}
              </button></center>
            </CardActions>
          </Card>
          <br />
        </Grid>
      ))}
        <Dialog
          open={ open }
          onClose={ removeDialogClick }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Unsubscribe from imUp?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure?<br/>You will no longer be charged for imUp services.
            </DialogContentText>
            <TextareaAutosize
              minRows={5}
              value={unsubscribeFeedback}
              onChange={(event) => setUnsubscribeFeedback(event.target.value)}
              placeholder="Thanks for giving us a shot. If you have a moment, please let us know why you're unsubscribing."
              style={{ width: `100%` }}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={unsubscribing} onClick={ removeDialogClick } color="primary">
              No
            </Button>
            <Button disabled={unsubscribing} onClick={ unsubscribeUser } color="secondary" autoFocus>
              Unsubscribe
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={ reactivateDialogOpen }
          onClose={ removeReactivateDialogClick }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Reactivate imUp subscription?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will continue to be charged for imUp services, beginning next pay period.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button disabled={reactivating} onClick={ removeReactivateDialogClick } color="secondary">
              No
            </Button>
            <Button disabled={reactivating} onClick={ reactivateUser } color="primary" autoFocus>
              Reactivate
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}

import React, { useRef } from 'react'
import {
  ButtonGroup,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AddISPModal from '../ProfileModify/addISPModal'
import styles from './profileHostGroupModify.module.scss'
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';

export default function ISPProfile(props) {
  const {
    onDeleteHandler,
    onChangeHandler,
    onChangeProviderHandler,
    profile,
    providers,
    saveISPProfile,
    deleteButtonText,
  } = props

  const [addISPModalOpen, setAddISPModalOpen] = React.useState(false)
  const [newISPProfileProviderName, setNewISPProfileProviderName] = React.useState(false)
  const [formSubmitting, setFormSubmitting] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

  const formEl = useRef(null)

  // TODO: this is a horrible pattern and super inefficient as we
  // add more ISPs since it's looping through the entire list
  // when a user clicks out of the ISP drop down, if they typed
  // in a provider name that doesn't exist in the list already,
  // we open a modal form for them to add the new ISP and we
  // prepopulate the name field for them with whatever they had
  // tried putting in the dropdown
  const onProviderBlur = event => {
    const inputedProviderName = event.target.value

    let providerExists = false
    providers.forEach(provider => {
      if (provider.name === inputedProviderName) {
        providerExists = true
      }
    })

    if (!providerExists) {
      setNewISPProfileProviderName(inputedProviderName)
      setAddISPModalOpen(true)
    }
  }

  const toggleDeleteModal = () => setDeleteModalOpen(!deleteModalOpen)

  return (
    <div>
      <ValidatorForm
        ref={formEl}
        onSubmit={ saveISPProfile }
        className={ styles.content }
      >
        <Autocomplete
          id="provider"
          freeSolo
          onBlur={onProviderBlur}
          options={providers}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Provider" variant="outlined" />}
          name="provider"
          value={profile.providerId || ''}
          onChange={onChangeProviderHandler}
        />
        <TextValidator
          label="Price per month"
          onChange={onChangeHandler}
          name="price"
          size="small"
          value={ profile.price || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="large"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <TextValidator
          label="Download speed (Mbps)"
          onChange={onChangeHandler}
          name="downloadSpeed"
          value={ profile.downloadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />
        <TextValidator
          label="Upload speed (Mbps)"
          onChange={onChangeHandler}
          name="uploadSpeed"
          value={ profile.uploadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        /><br /><br />
        <Typography variant="h6" gutterBottom component="div">
          Thresholds
        </Typography>
        <Alert severity="info" style={{marginTop:'20px'}}>Thresholds are optional. If you want to receive notifications about excessive packet loss or slow speeds, enter the thresholds below. You will be alerted if any of the following values are breached by any of your endpoints in this particular group.</Alert>
        <TextValidator
          label="Download Speed threshold value (Mbps)"
          onChange={onChangeHandler}
          name="downloadSpeedThreshold"
          value={ profile.downloadSpeedThreshold || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />

        <TextValidator
          label="Upload Speed threshold value (Mbps)"
          onChange={onChangeHandler}
          name="uploadSpeedThreshold"
          value={ profile.uploadSpeedThreshold || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />

        <TextValidator
          label="Packet Loss threshold value (%)"
          onChange={onChangeHandler}
          name="packetLossThreshold"
          value={ profile.packetLossThreshold || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />

        <TextValidator
          label="Average Latency threshold value (ms)"
          onChange={onChangeHandler}
          name="latencyThreshold"
          value={ profile.latencyThreshold || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
          style={{ paddingTop:'10px'}}
        />

        <br/>
        <TextareaAutosize
          name="additionalDetails"
          minRows={3}
          value={profile.additionalDetails}
          onChange={onChangeHandler}
          placeholder="Additional notes about your ISP can go here."
          style={{ width: `100%` }}
        />
        {/**
          * need to avoid rendering the modal at all vs. just hiding it
          * because we're trying to pass in a default string to the
          * constructor
          */}
        {!addISPModalOpen ? null : (
          <AddISPModal
            addISPModalOpen={addISPModalOpen}
            handleClose={() => {
              setNewISPProfileProviderName('')
              setAddISPModalOpen(!addISPModalOpen)
            }}
            handleChangeISPProvider={(event, target) => {
              // turn the callback from the modal and make it look
              // like a normal input event so we can set state easily
              const fakeEvent = {
                target: {
                  name: 'providerId',
                  value: target
                }
              }
              onChangeHandler(fakeEvent)
            }}
            defaultProviderName={newISPProfileProviderName}
          />
        )}

        {formSubmitting ? (
          <button
            className={ styles.button }
            variant="contained"
            color="primary"
            fullWidth
            disabled
          >
            <CircularProgress color="primary" size={ 24 } />
          </button>
        ) : (
        <button
          type="submit"
          className={ styles.button }
          variant="contained"
          color="primary"
          fullWidth
        >
          Save Group
        </button>
      )}
      </ValidatorForm>

      <Dialog
        open={ deleteModalOpen }
        onClose={ toggleDeleteModal }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete ISP profile?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?<br/>This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ButtonGroup className={ styles.buttonGroup }>
          <button onClick={ toggleDeleteModal } className={ styles.button }>
            No
          </button>
          <button onClick={ onDeleteHandler } className={ styles.button2 }>
            Delete
          </button>
        </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  )
}

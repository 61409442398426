import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
import styles from './adminUserProfileModify.module.scss'
import { setAdminViewUser, setProviders } from '../../actions/connectionActions'
import AddISPModal from './addISPModal'

class AdminUserProfileModify extends Component {
  constructor(props) {
    super(props)

    let {
      id,
      username,
      name,
      email,
      isp,
      address,
      customer
    } = this.props.adminViewUser.user
    if (!isp) {
      isp = {
        provider: '',
        providerIds: [],
        supportEmail: '',
        price: 0.00,
        startDate: null,
        endDate: null,
        downloadSpeed: 0,
        uploadSpeed: 0,
        dataLimit: 0,
        accountNumber: '',
        username: '',
        password: '',
        pin: '',
        imupAuthorized: false,
        additionalDetails: ''
      }
    }
    if (!address) {
      address = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: ''
      }
    }
    if (!customer) {
      customer = {
        type: '',
        details: ''
      }
    }
    this.state = {
      id,
      username,
      name,
      email,
      isp,
      address,
      customer,
      loading: false,
      addISPModalOpen: false
    }
  }

  async componentDidMount() {
    const providers = await axios.get(`${this.props.url}/${this.props.routes.provider.list}`)

    if (providers.data.success && Array.isArray(providers.data.data)) {
      this.props.setProviders(providers.data.data)
    }
  }

  saveProfile = async () => {
    const { id, username, name, email, isp, address, customer } = this.state
    const userdata = {
      id,
      username,
      name,
      email,
      isp,
      address,
      customer
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(`${this.props.url}/${this.props.routes.user.profile.update}`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      console.log(response.data)
      this.props.setAdminViewUser({ ...this.props.adminViewUser, user: response.data.user })
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile saved!'
      })
      this.props.history.push('/admin/user')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  handleChangeISP = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { [name]: value })
    })
  }

  handleChangeISPProvider = (event, newValue) => {
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { providerIds: [newValue] })
    })
  }

  handleChangeAddress = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { address } = this.state
    this.setState({
      address: Object.assign({}, address, { [name]: value })
    })
  }

  handleChangeCustomer = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { customer } = this.state
    this.setState({
      customer: Object.assign({}, customer, { [name]: value })
    })
  }

  handleChangeCheckBox = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const { isp } = this.state
    this.setState({
      isp: Object.assign({}, isp, { [name]: checked })
    })
  }

  onProviderBlur = (event) => {
    console.log('onProviderBlur event', event.target.value)

    let providerExists = false
    this.props.providers.forEach(provider => {
      if (provider.name === event.target.value) {
        providerExists = true
      }
    })

    if (!providerExists) {
      // alert('This looks like a new provider! Please input details for ' + event.target.value)
      this.setState({
        addISPModalOpen: true
      })
    }
  }

  ispModalHandleClose = () => {
    this.setState({
      addISPModalOpen: !this.state.addISPModalOpen
    })
  }

  showISPFields = () => {
    return (
      <div>
        <Autocomplete
          id="provider"
          freeSolo
          onBlur={this.onProviderBlur}
          options={this.props.providers}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Providers" variant="outlined" />}
          name="provider"
          value={ (Array.isArray(this.state.isp.providerIds) && this.state.isp.providerIds[0]) || '' }
          onChange={this.handleChangeISPProvider}
          // onInputChange={this.handleInputValueChangeISPProvider}
          // inputValue={this.state.isp.provider || ''}
        />
        <TextValidator
          label="Support email"
          onChange={ this.handleChangeISP }
          name="supportEmail"
          value={ this.state.isp.supportEmail || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:1000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 1000 characters'
          ]}
          margin="normal"
          fullWidth
        />

        {/*
          <TextValidator
            label="Provider"
            onChange={ this.handleChangeISP }
            name="provider"
            value={ this.state.isp.provider || '' }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
          />
        */}
        <TextValidator
          label="Price per month"
          onChange={ this.handleChangeISP }
          name="price"
          value={ this.state.isp.price || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Download speed (Mbps)"
          onChange={ this.handleChangeISP }
          name="downloadSpeed"
          value={ this.state.isp.downloadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Upload speed (Mbps)"
          onChange={ this.handleChangeISP }
          name="uploadSpeed"
          value={ this.state.isp.uploadSpeed || null }
          validators={[
            'isFloat',
          ]}
          errorMessages={[
            'must be a number'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Data limit (GB)"
          onChange={ this.handleChangeISP }
          name="dataLimit"
          value={ this.state.isp.dataLimit || null }
          validators={[
            'isPositive',
          ]}
          errorMessages={[
            'must be an integer'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Account Number"
          onChange={ this.handleChangeISP }
          name="accountNumber"
          value={ this.state.isp.accountNumber || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:5000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 5000 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Username"
          onChange={ this.handleChangeISP }
          name="username"
          value={ this.state.isp.username || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:5000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 5000 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Password"
          onChange={ this.handleChangeISP }
          name="password"
          value={ this.state.isp.password || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:5000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 5000 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Pin"
          onChange={ this.handleChangeISP }
          name="pin"
          value={ this.state.isp.pin || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:5000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 5000 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <p></p>
        <FormControl component="fieldset">
          <FormLabel component="legend">imUp representative added as authorized user on account</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="primary" checked={ this.state.isp.imupAuthorized || false } onChange={this.handleChangeCheckBox} name="imupAuthorized" />}
              label="Authorized"
            />
          </FormGroup>
          <FormHelperText>Important!</FormHelperText>
        </FormControl>
        <TextValidator
          label="Addition details"
          onChange={ this.handleChangeISP }
          name="additionalDetails"
          value={ this.state.isp.additionalDetails || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:5000'
          ]}
          errorMessages={[
            'min 0 characters',
            'max 5000 characters'
          ]}
          margin="normal"
          fullWidth
        />
      </div>
    )
  }

  showAddressFields = () => {
    return (
      <div>
        <TextValidator
          label="Address"
          onChange={ this.handleChangeAddress }
          name="address"
          value={ this.state.address.address || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 0 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="City"
          onChange={ this.handleChangeAddress }
          name="city"
          value={ this.state.address.city || '' }
          validators={[
            // 'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 2 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="State"
          onChange={ this.handleChangeAddress }
          name="state"
          value={ this.state.address.state || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 0 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Zip Code"
          onChange={ this.handleChangeAddress }
          name="zipcode"
          value={ this.state.address.zipcode || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 0 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Country"
          onChange={ this.handleChangeAddress }
          name="country"
          value={ this.state.address.country || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 2 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
      </div>
    )
  }

  showCustomerFields = () => {
    return (
      <div>
        <TextValidator
          label="Type"
          onChange={ this.handleChangeCustomer }
          name="type"
          value={ this.state.customer.type || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:200'
          ]}
          errorMessages={[
            // 'min 0 characters',
            'max 200 characters'
          ]}
          margin="normal"
          fullWidth
        />
        <TextValidator
          label="Details"
          onChange={ this.handleChangeCustomer }
          name="details"
          value={ this.state.customer.details || '' }
          validators={[
            'minStringLength:0',
            'maxStringLength:3000'
          ]}
          errorMessages={[
            // 'min 0 characters',
            'max 3000 characters'
          ]}
          margin="normal"
          fullWidth
          multiline
          rows={5}
          rowsMax={Infinity}
        />
      </div>
    )
  }

  render() {
    const { loading } = this.state
    return (
      <div className={ styles.profileModify }>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
          <Paper>
            <Typography className={ styles.title } component="h1" variant="h5">
              Modify profile
            </Typography>
            <ValidatorForm
              ref="form"
              onSubmit={ this.saveProfile }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Username"
                onChange={ this.handleChange }
                name="username"
                value={ this.state.username || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="Name"
                onChange={ this.handleChange }
                name="name"
                value={ this.state.name || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <p></p>
              <Typography component="h1" variant="h6">
                ISP:
              </Typography>
              {this.showISPFields()}
              <p></p>
              <Typography component="h1" variant="h6">
                Address:
              </Typography>
              {this.showAddressFields()}
              <p></p>
              <Typography component="h1" variant="h6">
                Customer:
              </Typography>
              {this.showCustomerFields()}
              {/*<SelectValidator
                label="Age"
                onChange={ this.handleChange }
                name="age"
                value={ this.state.age || '' }
                validators={[
                  'required',
                  'minStringLength:3',
                  'maxStringLength:5'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 3 characters',
                  'max 5 characters'
                ]}
                margin="normal"
                fullWidth
              >
                <MenuItem key="0" value="10-20">
                  -20
                </MenuItem>
                <MenuItem key="1" value="21-30">
                  21-30
                </MenuItem>
                <MenuItem key="2" value="31-40">
                  31-40
                </MenuItem>
                <MenuItem key="3" value="41-50">
                  41-50
                </MenuItem>
                <MenuItem key="4" value="51-60">
                  51-60
                </MenuItem>
                <MenuItem key="5" value="60+">
                  60+
                </MenuItem>
              </SelectValidator>
              <TextValidator
                label="Location"
                onChange={ this.handleChange }
                name="location"
                value={ this.state.location || '' }
                validators={[
                  'required',
                  'minStringLength:3',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 3 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              */}
              {
                loading ?
                <Button
                  className={ this.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </Button> :
                <Button
                  type="submit"
                  className={ this.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              }
            </ValidatorForm>
          </Paper>
        </Grid>
        <AddISPModal
          addISPModalOpen={this.state.addISPModalOpen}
          handleClose={this.ispModalHandleClose}
          handleChangeISPProvider={this.handleChangeISPProvider}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    notification: state.notification,
    adminViewUser: state.adminViewUser,
    providers: state.providers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAdminViewUser: (user) => { dispatch(setAdminViewUser(user)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserProfileModify)

import React, { Component } from 'react'
import {
  Card,
  CardContent,
  Modal,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styles from './adminUserProfileModify.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import { setTermsOfServiceModalDisplayed, setProviders } from '../../actions/connectionActions'

class AddISPModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      loading: false,
    }

    console.log('TermsOfServiceModal constructor:', this.props)
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  submitRequest = async () => {
    console.log('submit request')

    this.setState({
      downloadingClient: true,
      loading: true
    })

    const response = await axios.post(`${this.props.url}/${this.props.routes.provider.new}`, { name: this.state.name, email: this.state.email })
    console.log('create provider response', response)

    if (response.data.success && !!response.data.data) {
      const provider = response.data.data
      this.props.setProviders([provider])

      // allow passing in a callback function to change a provider
      // but don't require it. this probably needs a refactor
      if (!!this.props.handleChangeISPProvider) {
        [provider].forEach(p => {
          if (p.name === this.state.name) {
            this.props.handleChangeISPProvider(null, p)
          }
        })
      }

      this.setState({
        downloadingClient: false,
        loading: false,
        name: '',
        email: ''
      })

      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Added ISP!'
      })

      this.props.handleClose()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }
  }

  render() {
    // const { user } = this.props
    const { loading } = this.state

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        // aria-describedby="transition-modal-description"
        open={this.props.addISPModalOpen}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      // closeAfterTransition
      >
        <Fade in={this.props.addISPModalOpen}>
          <Card className={styles.card}>
            <CardContent>
              <div className={styles.modal}>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper>
                    <Typography className={styles.title} component="h1" variant="h5">
                      Add new ISP
                    </Typography>
                    <ValidatorForm
                      ref="form"
                      onSubmit={this.submitRequest}
                      // onError={ errors => console.log(errors) }
                      className={styles.content}
                    >
                      <TextValidator
                        label="Name"
                        onChange={this.handleChange}
                        name="name"
                        value={this.state.name || ''}
                        validators={[
                          'required',
                          'minStringLength:5',
                          'maxStringLength:100'
                        ]}
                        errorMessages={[
                          'this field is required',
                          'min 5 characters',
                          'max 100 characters'
                        ]}
                        margin="normal"
                        fullWidth
                        style={{ paddingTop: '10px' }}
                      />
                      <TextValidator
                        label="Email"
                        onChange={this.handleChange}
                        name="email"
                        value={this.state.email || ''}
                        validators={[
                          'isEmail'
                        ]}
                        errorMessages={[
                          'must be an email',
                        ]}
                        margin="normal"
                        fullWidth
                        style={{ paddingTop: '10px' }}
                      />
                      {
                        loading ?
                          <Button
                            className={this.button}
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled
                          >
                            <CircularProgress color="primary" size={24} />
                          </Button> :
                          <Button
                            type="submit"
                            className={this.button}
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            Add ISP
                          </Button>
                      }
                    </ValidatorForm>
                  </Paper>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false,
    downloadOS: state.downloadOS
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddISPModal))

import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import styles from './admin.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setEmails } from '../../actions/connectionActions'
import axios from 'axios'
import { setAdminViewEmails } from '../../actions/connectionActions'

class EmailForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      open: false,
      email: {
        to: '',
        from: '',
        bcc: '',
        subject: '',
        open: false,
        text: '',
        sending: false,
        attachedUser: null
      }
    }
  }

  handleChangeEmail = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { email } = this.state
    this.setState({
      email: Object.assign({}, email, { [name]: value })
    })
  }

  getISPDetails = (field) => {
    const ispDefaults = {
      provider: '',
      price: 0,
      downloadSpeed: 0,
      uploadSpeed: 0,
      dataLimit: 0,
      accountNumber: '',
      username: '',
      password: '',
      pin: '',
      imupAuthorized: false,
      additionalDetails: ''
    }

    const ispValues = Object.assign({}, ispDefaults, this.props.user.isp || {})
    return ispValues[field]
  }

  // don't actually send email, add something to a mongo queue?
  emailProviderSendClicked = async () => {
    const { email } = this.state
    this.setState({
      email: Object.assign({}, email, { sending: true })
    })

    // await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, email)
    // const response = await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, email)

    const response = await axios.post(`${this.props.url}/${this.props.routes.admin.createEmail}`, { email, user: this.state.email.attachedUser })

    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Posted email to database!'
      })

      this.props.setAdminViewEmails(response.data.data.sort(function(a,b){
        return new Date(b.createdAt) - new Date(a.createdAt)
      }))
      this.props.toggleEmailForm()
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Posting email failed!',
        message: 'Try again later!'
      })
    }

    this.setState({
      email: Object.assign({}, email, { sending: false, open: false })
    })
  }

  displayEmailISP = () => {
    // if (!this.state.email.open) {
    //   return null
    // }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.emailProviderSendClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
        >
          <TextValidator
            label="Attach to imUp user"
            onChange={ this.handleChangeEmail }
            name="attachedUser"
            value={ this.state.email.attachedUser || '' }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="To"
            onChange={ this.handleChangeEmail }
            name="to"
            value={ this.state.email.to }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="BCC"
            onChange={ this.handleChangeEmail }
            name="bcc"
            value={ this.state.email.bcc }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="From"
            onChange={ this.handleChangeEmail }
            name="from"
            value={ this.state.email.from }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="Subject"
            onChange={ this.handleChangeEmail }
            name="subject"
            value={ this.state.email.subject }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="Text"
            onChange={ this.handleChangeEmail }
            name="text"
            value={ this.state.email.text }
            validators={[
              'minStringLength:0',
              'maxStringLength:10000'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 10000 characters'
            ]}
            margin="normal"
            fullWidth
            multiline
            rows={5}
            rowsMax={Infinity}
            variant="outlined"
            style={{ paddingTop:'10px'}}
          />
          {
            this.state.email.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <Button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Add email to database
            </Button>
          }
        </ValidatorForm>
      </div>
    )
  }

  render() {
    // const { page, rowsPerPage } = this.state
    return (
      this.props.user ? this.displayEmailISP() : <div></div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEmails: data => { dispatch(setEmails(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) }
    // setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    // setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    // setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    // setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    // setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    // setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    // setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailForm))

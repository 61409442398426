import React, { Component } from 'react'
import styles from './organizationSummary.module.scss'
import { Card, CardContent, Typography } from '@material-ui/core'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  setOrgHostSpeedTestData,
  setOrgHostConnectivityData,
  setOrgHostsSummary,
  setOrganization,
  setOrganizationHosts,
  setHostLivenessStatus,
} from '../../actions/connectionActions'
import axios from 'axios'
import StatsTableSpeedTest from './statsTableSpeedTest'

class OrganizationHost extends Component {
  constructor(props) {
    super(props)

    let filterModel = null
    if (!!this.props.match.params.filterModel) {
      try {
        filterModel = JSON.parse(atob(this.props.match.params.filterModel))
      } catch (e) {
        console.log('error parsing filter model', e)
      }
    }

    this.state = {
      downloadingClient: false,
      createOrgOpen: true,
      orgId: this.props.match.params.id,
      filterModel: filterModel,
      hostStatusIntervalId: null,
    }
  }

  async componentDidMount() {
    const summary = await axios.get(`${this.props.url}/${this.props.routes.organization.summary}`, { params: { orgId: this.state.orgId } })
    if (summary && summary.data.success) {
      this.props.setOrgHostsSummary(summary.data.data)
    }

    // copy pasta from OrganizationUser.js to move quickly
    const orgHosts = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: { id:  this.state.orgId } })
    if (orgHosts && orgHosts.data.success) {
      this.props.setOrganizationHosts(orgHosts.data.data)
    }

    const organization = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id:  this.state.orgId } })
    if (organization && organization.data.success) {
      this.props.setOrganization(organization.data.data)
    }

    if (organization && organization.data.success && orgHosts && orgHosts.data.success) {
      const apiKeyLookup = {}
      const apiKeyUsageCount = {}
      const apiKeyNameLookup = {}
      this.props.organization.apiKeys.forEach(k => {
        apiKeyLookup[k.id] = k.seats
        apiKeyNameLookup[k.id] = k.name
        apiKeyUsageCount[k.id] = 0
      })

      orgHosts.data.data.forEach(h => {
        apiKeyUsageCount[h.apiKey] = apiKeyUsageCount[h.apiKey] + 1
      })

      this.setState({ apiKeyLookup, apiKeyUsageCount, apiKeyNameLookup })
    }

    // set polling interval for getting hosts liveness status
    if (organization && organization.data.success && orgHosts && orgHosts.data.success) {
      const that = this

      // every 5 seconds
      let intervalId = setInterval(async function(){
        const hostConnectedStatus = await axios.post(`${that.props.url}/${that.props.routes.realtime.hostConnectedStatus}`, {
          hosts: that.props.organizationHosts ? that.props.organizationHosts.map(h => `${h.apiKey}__IMUP_DELIMITER__${h.hostId}`) : []
        })

        if (hostConnectedStatus && hostConnectedStatus.data && hostConnectedStatus.data.success && hostConnectedStatus.data.data && hostConnectedStatus.data.data.status) {
          that.props.setHostLivenessStatus(hostConnectedStatus.data.data.status)
        } else {
          console.log('failed to get host liveness status')
        }
      }, 5000);

      this.setState({ hostStatusIntervalId: intervalId })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.hostStatusIntervalId);
  }

  displayChart = () => {
    const { orgHostConnectivityData } = this.props
    if (Array.isArray(orgHostConnectivityData) && orgHostConnectivityData.length > 0) {
      return (
        <div className={ styles['org-card'] }>
          <div style={{margin: '0 auto', width:'90%'}}>
            <Typography variant="h5" component="h2" gutterBottom>
              Internet Connection Status
            </Typography>
            {/*{Chart(orgHostConnectivityData, this.props.theme)}*/}
          </div>
        </div>
      )
    }
    return null
  }

  displaySpeedTestTable = () => {
    const { orgHostSpeedTestData } = this.props
    if (Array.isArray(orgHostSpeedTestData) && orgHostSpeedTestData.length > 0) {
      return (
        <Card className={ styles['org-card'] }>
          <CardContent className={styles['card-content']}>
            <Typography variant="h5" component="h2" gutterBottom>
              Speed Tests
            </Typography>
            {/*<SpeedTestTable data={orgHostSpeedTestData} />*/}
          </CardContent>
        </Card>
      )
    }
    return null
  }

  findHostLivenessStatus = (host) => {
    let hostAlive = false
    if (!this.props.hostLivenessStatus) {
      return hostAlive
    }

    // eslint thinks h isn't being used here even though it clearly is
    // eslint-disable-next-line
    for (const h of this.props.hostLivenessStatus) {
      if (host === h.host) {
        hostAlive = h.status
        break;
      }
    }
    return hostAlive
  }

  onHostsTableRowClick = (row) => {
    this.props.history.push(`/host/${row.apiKey}/${encodeURIComponent(row.hostId)}`)
  }

  render() {
    const { organization, orgHostsSummary } = this.props
    return (
      <div className={ styles['organization-summary'] }>
      <Card className={ styles['org-card'] }>
        <CardContent className={styles['card-content']}>
        <Typography style={{display: 'inline-block'}} variant="h5" component="h2" gutterBottom>
        Org: <span style={{color: '#1d98ff'}}>{organization.name} {this.state.hostId}</span><br />
        </Typography><br />
        Host Averages
            <StatsTableSpeedTest
              onRowClick={this.onHostsTableRowClick}
              filterModel={this.state.filterModel}
              onFilterModelChange={(model) => this.setState({filterModel: model})}
              data={!Array.isArray(orgHostsSummary)
                ? []
                : orgHostsSummary.map(u => {
                    u.id = `${u.apiKey}__IMUP_DELIMITER__${u.hostId}`
                    u.live = this.findHostLivenessStatus(`${u.apiKey}__IMUP_DELIMITER__${u.hostId}`)

                    if (!this.props.organization || !Array.isArray(this.props.organization.apiKeys)) {
                      u.apiKeyName = ''
                      return u
                    }

                    const apiKeyInfo = this.props.organization.apiKeys.filter(key => key.id === u.apiKey)
                    u.apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
                    return u
                })}
            />
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    user: state.user,
    theme: state.theme,
    organization: state.organization,
    orgHostSpeedTestData: state.orgHostSpeedTestData,
    orgHostConnectivityData: state.orgHostConnectivityData,
    orgHostsSummary: state.orgHostsSummary,
    organizationHosts: state.organizationHosts,
    hostLivenessStatus: state.hostLivenessStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrgHostConnectivityData: data => { dispatch(setOrgHostConnectivityData(data)) },
    setOrgHostSpeedTestData: data => { dispatch(setOrgHostSpeedTestData(data)) },
    setOrgHostsSummary: data => { dispatch(setOrgHostsSummary(data)) },
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationHosts: data => { dispatch(setOrganizationHosts(data)) },
    setHostLivenessStatus: data => { dispatch(setHostLivenessStatus(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationHost))

import React, { Component } from 'react'
import styles from './recovery.module.scss'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import axios from 'axios'
import Cloud from '../../cloud.svg'
import { Link } from "react-router-dom"


class Recovery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      loading: false
    }
  }

  recover = async () => {
    const { email } = this.state
    const userdata = {
      email: email
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(`${this.props.url}/${this.props.routes.auth.recovery}`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Reset requested',
        message: 'If this email address is registered, a password reset email was sent'
      })
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Recovery failed!',
        message: 'Some error occurred.'
      })
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  render = () => {
    const { loading } = this.state

    return (
      <div className={ styles.recovery }>
      <Grid
        // xs={ 12 }
        // sm={ 6 }
        // md={ 4 }

        container
        spacing={3}
        alignItems="center"
        justify="center"
      >

        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <center>
        <img src={Cloud} alt="imUp logo" width="150" height="125" />

            <Typography component="h1" variant="h5">
              Password Recovery
            </Typography>
            <ValidatorForm
              ref="form"
              onSubmit={ this.recover }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Email"
                onChange={ this.handleChange }
                name="email"
                value={ this.state.email || '' }
                validators={[
                  'required',
                  'isEmail',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'email is not valid',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop:'10px'}}
              />
              {
                loading ?
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </Button> :
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{backgroundColor: '#1C98FF', borderRadius: '8px'}}
                >
                  <strong>Reset Password</strong>
                </Button>
              }
            </ValidatorForm>
            <Typography className={ styles.content } component="body" style={{marginTop: '30px'}}>
              <Link to="/login" style={{color: '#1C98FF', textAlign: 'center'}}>Back</Link>
              <br />
              <br />
              &copy; 2023 imUp.io | All Rights Reserved
            </Typography>
            </center>
        </Grid>
      </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(Recovery)

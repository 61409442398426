import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import styles from './createOrganizationForm.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setOrganizations, setUser } from '../../actions/connectionActions'
import axios from 'axios'
import { Card, CardContent, Grid, Typography, CardHeader } from '@material-ui/core'

// copy pasta from Organizations/organizationForm.js
class CreateOrganizatonForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sending: false,
      downloadingClient: false,
      open: false,
      name: ''
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  createOrganizationClicked = async () => {
    const { name } = this.state
    this.setState({
      sending: true
    })

    const organization = { name }

    const response = await axios.post(`${this.props.url}/${this.props.routes.organization.userCreate}`, { organization })
    const orgExists = !!response.data.data && !!response.data.data.user
                   && Array.isArray(response.data.data.user.organizations)
                   && response.data.data.user.organizations.length > 0

    if (!!response && !!response.data && response.data.success && orgExists) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Created organization!'
      })

      // TODO: need to set the new user profile here
      // and set the org and then switch to org dashboard
      // this.props.setOrganizations(response.data.data)
      // { user: req.user, org: org[0] }
      this.props.setUser(response.data.data.user)
      this.props.history.push(`/org/${response.data.data.user.organizations[0].id}`)

      this.setState({
        sending: false,
        name: ''
      })
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Creating organization failed!',
        message: 'Try again later!'
      })

      this.setState({
        sending: false
      })
    }
  }

  displayOrganizationForm = () => {
    // if (!this.state.email.open) {
    //   return null
    // }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.createOrganizationClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
          style={{marginTop:'40px'}}
        >
          <TextValidator
            label="Org Name"
            onChange={ this.handleChange }
            name="name"
            value={ this.state.name || ''}
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            variant="outlined"
            style={{ width:'300px'}}
          />
          {
            this.state.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Create
            </button>
          }
          <Typography variant="body" component="body" gutterBottom style={{marginTop:'50px'}} >
            Create unlimited groups to organize your endpoints, each with unique ISP profiles<br /> & thresholds for speed, latency, and packet loss notifications.
          </Typography><br />
          <Typography variant="body" component="body" gutterBottom >
            Use a single command to install on all of your endpoints with your RMM tools.
          </Typography>
        </ValidatorForm>
      </div>
    )
  }

  render() {
    // const { page, rowsPerPage } = this.state
    return (
      this.displayOrganizationForm()
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: data => { dispatch(setOrganizations(data)) },
    setUser: (user) => { dispatch(setUser(user)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOrganizatonForm))

import React from 'react'
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

const valueFormatterDate = ({ value }) => {
  const _d = new Date(value);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
}

// -- Example of Export Data
// [
//   {
//     fileName: '623f6d133f9f0a0059aa0e72/2022-5-14_18-44-32.zip',
//     storageBucket: 'imup-public-temp',
//     name: 'Export from 2022-5-14 at 18-44-32',
//     initiatedBy: 'saldinger@imup.io',
//     _id: 627ff89079cf7e0f03c8cbdc,
//     hosts: [{
//       "apiKeyId": "623f6ed73f9f0a0059aa0e73",
//       "hostId": "Host #1",
//     }, {
//         "apiKeyId": "623f6ed73f9f0a0059aa0e73",
//         "hostId": "Host #2",
//     }, {
//         "apiKeyId": "623f6ed73f9f0a0059aa0e73",
//         "hostId": "Steven testing realtime",
//     }],
//     organization: 623f6d133f9f0a0059aa0e72,
//     status: "completed",
//     createdAt: 2022-05-14T18:44:32.857Z,
//     updatedAt: 2022-05-14T18:44:32.857Z,
//     __v: 0,
//     id: '627ff89079cf7e0f03c8cbdc',
//   }
// ]
const columns = [
  { field: 'createdAt', headerName: 'Requested', width: 140,  type: 'date', valueFormatter: valueFormatterDate },
  { field: 'updatedAt', headerName: 'Finished', width: 140,  type: 'date', valueFormatter: valueFormatterDate },
  { field: 'name', headerName: 'Export', width: 300, type: 'string' },
  { field: 'initiatedBy', headerName: 'Initiated By', width: 220, type: 'string' },
  { field: 'status', headerName: 'Status', width: 220, type: 'string' },
]

export default function ExportsTable(props) {
  return !props.data || !Array.isArray(props.data) ? null : (
    <div style={{ height: '70vh', width: '100%' }}>
      <NewCustomDataGrid
        columns={columns}
        rows={props.data}
        onRowClick={(row) => {
          props.onRowClick(row.row)
        }}
        showToolbar
      />
    </div>
  )
}

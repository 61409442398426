import React from 'react'
import styles from './installationInstructions.module.scss'
import { Button, ButtonGroup, Link, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { CodeBlock, dracula } from "react-code-blocks";



export default function DockerInstructions() {
  return (
      <div>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} spacing={3}>
        <Typography variant="h4" component="h4" gutterBottom>
          Docker Instructions
        </Typography>
            <hr />
            <Alert severity="info">Docker will always update the app via the 'latest' tag on start up if you specify `--pull=always` in `docker run` command or `docker-compose pull` before `docker-compose up`</Alert>
            <br />
            <ButtonGroup>
              <a href="https://hub.docker.com/r/imup/client">
                <button
                  className={ styles.button }
                >
                  Docker Hub
                </button>
              </a>
            </ButtonGroup>
            <br />
            <br />
                <Typography variant="h6" component="h6" gutterBottom>
                  Most Users:
                </Typography>
                <CodeBlock
                  text={`docker run --pull=always -e 'EMAIL=you@example.com' imup/client:latest`}
                  language="bash"
                  theme={dracula}
                />
                <br />
                <Typography variant="h6" component="h6" gutterBottom>
                  Organizations:
                </Typography>
                <CodeBlock
                  text={`docker run --pull=always -e API_KEY="my-api-key" -e HOST_ID="my host id" imup/client:latest`}
                  language="bash"
                  theme={dracula}
                />
                <br />
                <Typography variant="h6" component="h6" gutterBottom>
                  Docker Compose (most users):
                </Typography>
                <CodeBlock
                  text={`---
version: '3.3'
services:
    client:
        image: imup/client:latest
        environment:
            EMAIL: you@example.com
            PING_ENABLED: "true"`}
                    language="bash"
                    showLineNumbers="true"
                    theme={dracula}
                />
                <br />
                <Typography variant="h6" component="h6" gutterBottom>
                  Docker Compose (organizations):
                </Typography>
                <CodeBlock
                  text={`---
version: '3.3'
services:
    client:
        image: imup/client:latest
        environment:
            HOST_ID: My Host
            API_KEY: my-api-key
            REALTIME: "true"`}
                  language="bash"
                  showLineNumbers="true"
                  theme={dracula}
                />
                  <pre>
                    <code>

                    </code>
                  </pre>
            <br />
            <br />

            <Card className={ styles.card } variant="outlined">
              <CardContent>
                <strong>Environment Variables:</strong>
                <ul>
                  <li><strong>EMAIL</strong>: The email address you sign into https://app.imUp.io with</li>
                  <li><strong>HOST_ID</strong>: However you'd like the host to be identified in https://app.imup.io/</li>
                  <li><strong>API_KEY</strong>: An API key associated with your organization</li>
                  <li><strong>PING_ENABLED=true</strong>: Optionally collect ping statistics like packet loss</li>
                  <li><strong>REALTIME=true</strong>: Enable on-demand remote speed testing, real-time downtime alerts, and real-time liveness status in the organizations dashboard</li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
  )
}

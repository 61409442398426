// COMPONENTS
import React, { useState, Component } from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from '@material-ui/core'
// MATERIAL UI CORES
import styles from './LocalProviders.module.scss'
import { withStyles } from "@material-ui/core/styles";


export default function LocalProvidersTitle() {
  return (
    <div>
      <Alert severity="error" style={{width: '100%', textAlign: 'left'}}>
        <AlertTitle style={{textAlign: 'left'}}>Sorry, we don't support your area yet...</AlertTitle>
        We currently don't support zipcode lookups for addresses outside of the US. We are actively
        working on this and once your area is supported, all local data will magically appear!
        <br/>
        If you're passionate about this feature and would like us to do our best to prioritize your area,
        reach out to us at support@imup.io and let us know!
      </Alert>
      <hr />
    </div>
  )
}

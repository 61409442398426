import React, { Component } from 'react'
import { Grid, Typography } from '@material-ui/core'
import styles from './organizationExport.module.scss'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setOrganization, setOrganizationExports } from '../../actions/connectionActions'
import ExportsTable from '../ExportsTable/ExportsTable'

class OrganizationExport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orgId: this.props.match.params.id,
      downloadInProgress: false,
    }
  }


  async componentDidMount() {
    try {
      const exportList = await axios({
        url: `${this.props.url}/${this.props.routes.organization.exportList}`,
        method: 'GET',
        params: { orgId: this.state.orgId }
      })

      if (!!exportList && !!exportList.data && !!exportList.data.data && Array.isArray(exportList.data.data.exports)) {
        this.props.setOrganizationExports(exportList.data.data.exports)
      }
    } catch(e) {
      console.log('error getting export list', e)
    }
  }

  displayTitle = () => {
    const { orgId } = this.state

    return (
      <Grid container spacing={3} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
            <Typography style={{float: 'left', paddingLeft:'12px'}} variant="h4" component="h2" gutterBottom>
              <span style={{color:'#1d98ff'}}>
                {this.props.organization ? this.props.organization.name : orgId} Data Exports (Beta)
              </span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  downloadExport = async (row) => {
    if (!!this.state.downloadInProgress) {
      return this.props.notification({
        type: 'danger',
        title: 'Download already in progress',
        message: 'You already have a download in progress, please wait until it completes.'
      })
    }

    if (row.status !== 'completed') {
      return this.props.notification({
        type: 'danger',
        title: 'This export has not completed successfully',
        message: 'Please wait until the export has finished or retry the export if it failed.'
      })
    }

    this.setState({
      downloadInProgress: true
    })

    try {
      const response = await axios({
        url: `${this.props.url}/${this.props.routes.organization.exportDownload}`,
        method: 'GET',
        // blob is what allows the file to be downloaded
        responseType: 'blob',
        params: {
          orgId: this.state.orgId,
          exportId: row.id,
        },
      })

      if (!!response && !!response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${row.name}.zip`)
        document.body.appendChild(link)
        link.click()

        this.props.notification({
          type: 'success',
          title: 'Downloaded export',
          message: 'Successfully downloaded export'
        })
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Error downloading export',
          message: 'Something went wrong, please try again later or contact support'
        })
      }
    } catch (e) {
      console.log('error downloading export:', e)
    } finally {
      this.setState({
        downloadInProgress: false
      })
    }
  }

  render() {
    const data = this.props.organizationExports || []

    return (
      <Grid container spacing={5}>
        {this.displayTitle()}
        <Grid item xs={12} sm={12} md={12} lg={9} xl={8} justifycontent="center" >
          <ExportsTable data={data} onRowClick={this.downloadExport}/>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    notification: state.notification,
    organization: state.organization,
    organizationExports: state.organizationExports,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationExports: data => { dispatch(setOrganizationExports(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationExport))

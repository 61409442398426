import React from 'react';
import { Link } from 'react-router-dom'
import styles from './organizationUser.module.scss'
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'


export default function Help() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Show Help"
      />
      <Fade in={checked}>
      <Grid container spacer={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center">
          <Card variant="outlined" className={ styles.card } >
            <CardContent>
              <Typography variant="h5" component="h5">
                imUp Orgs
              </Typography><br />
              <Typography variant="body1" component="p">
                <strong>Running the app:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                Run the desktop app on Windows or Mac, enter your Org API key and a hostname for the machine. We also offer a Windows service and a Docker image you can run anywhere for a lightweight solution!
              </Typography><br /><br />
              <Typography variant="body1" component="p">
                <strong>API Keys:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                You can request that imUp create API keys for you. Simply click the "Help" button at the bottom right and submit a ticket. Once you get a key, plug it into your app to begin reporting metrics to this dashboard.
              </Typography>
              <Link className={ styles.link } to="/profile/modify"><button className={ styles.button } style={{marginBottom:'none'}}>Modify</button></Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fade>
    </div>
  );
}

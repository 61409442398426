import React, { Component } from 'react'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'
import styles from './admin.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setEmails } from '../../actions/connectionActions'
import axios from 'axios'
import { setAdminViewEmails, setProviders } from '../../actions/connectionActions'

class ProviderForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      open: false,
      provider: {
        name: '',
        email: '',
        open: false,
        sending: false
      }
    }
  }

  handleChangeProvider = (event) => {
    const name = event.target.name
    const value = event.target.value
    const { provider } = this.state
    this.setState({
      provider: Object.assign({}, provider, { [name]: value })
    })
  }

  // don't actually send email, add something to a mongo queue?
  newProviderClicked = async () => {
    const { provider } = this.state
    this.setState({
      provider: Object.assign({}, provider, { sending: true })
    })

    const response = await axios.post(`${this.props.url}/${this.props.routes.provider.new}`, { name: provider.name, email: provider.email })
    console.log('create provider response', response)

    if (response.data.success && Array.isArray(response.data.data)) {
      this.props.setProviders(response.data.data)

      this.setState({
        provider: {
          name: '',
          email: '',
          sending: false
        }
      })

      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Added ISP!'
      })

      this.props.toggleProviderForm()
    } else {
      this.setState({
        provider: Object.assign({}, provider, { sending: false })
      })

      this.props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }

    // // await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, email)
    // // const response = await axios.post(`${this.props.url}/${this.props.routes.admin.email}`, email)
    //
    // const response = await axios.post(`${this.props.url}/${this.props.routes.admin.createEmail}`, { email, user: this.state.email.attachedUser })
    //
    // if (response.data.success) {
    //   this.props.notification({
    //     type: 'success',
    //     title: 'Success!',
    //     message: 'Posted email to database!'
    //   })
    //
    //   this.props.setAdminViewEmails(response.data.data.sort(function(a,b){
    //     return new Date(b.createdAt) - new Date(a.createdAt)
    //   }))
    //   this.props.toggleProviderForm()
    // } else {
    //   this.props.notification({
    //     type: 'danger',
    //     title: 'Posting email failed!',
    //     message: 'Try again later!'
    //   })
    // }
    //
    // this.setState({
    //   email: Object.assign({}, email, { sending: false, open: false })
    // })
  }

  displayProviderForm = () => {
    // if (!this.state.email.open) {
    //   return null
    // }

    return (
      <div>
        <ValidatorForm
          ref="form"
          onSubmit={ this.newProviderClicked }
          // onError={ errors => console.log(errors) }
          className={ styles.content }
        >
          <TextValidator
            label="Name"
            onChange={ this.handleChangeProvider }
            name="name"
            value={ this.state.provider.name || '' }
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          <TextValidator
            label="Email"
            onChange={ this.handleChangeProvider }
            name="email"
            value={ this.state.provider.email || ''}
            validators={[
              'minStringLength:0',
              'maxStringLength:200'
            ]}
            errorMessages={[
              // 'min 2 characters',
              'max 200 characters'
            ]}
            margin="normal"
            fullWidth
            style={{ paddingTop:'10px'}}
          />
          {
            this.state.provider.sending ?
            <Button
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
              disabled
            >
              <CircularProgress color="primary" size={ 24 } />
            </Button> :
            <Button
              type="submit"
              className={ styles.button }
              variant="contained"
              color="primary"
              fullWidth
            >
              Add provider to database
            </Button>
          }
        </ValidatorForm>
      </div>
    )
  }

  render() {
    // const { page, rowsPerPage } = this.state
    return (
      this.props.user ? this.displayProviderForm() : <div></div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEmails: data => { dispatch(setEmails(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) }
    // setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    // setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    // setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    // setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    // setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    // setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    // setDownloadOS: data => { dispatch(setDownloadOS(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderForm))

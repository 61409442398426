import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setUsers, setAdminViewUser, setAdminViewEmails } from '../../actions/connectionActions'
import styles from './admin.module.scss'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)

  return `${dateString} at ${timeString}`
}

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>

    </div>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [to, setTo] = React.useState(row.to);
  const [bcc, setBCC] = React.useState(row.bcc);
  const [from, setFrom] = React.useState(row.from);
  const [subject, setSubject] = React.useState(row.subject);
  const [text, setText] = React.useState(row.text);
  const [sending, setSending] = React.useState(false);

  const formEl = useRef(null)

  const sendButtonClicked = async () => {
    setSending(true)

    const emailContent = {
      to,
      bcc,
      from,
      subject,
      text,
      id: row._id
    }

    const response = await axios.post(`${props.url}/${props.routes.admin.sendQueuedEmail}`, emailContent)

    setSending(false)

    if (response.data.success) {
      props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Email sent!'
      })
    } else {
      props.notification({
        type: 'danger',
        title: 'Email failed to send!',
        message: 'Try again later!'
      })
    }
  }

  const deleteButtonClicked = async () => {
    // const sent = await axios.get(`${this.props.url}/${this.props.routes.admin.sendQueuedEmail}`)

    const resp = await axios.delete(`${props.url}/${props.routes.admin.email}`, { data: { id: row._id} })

    console.log('delete response:', resp)
    if (resp.data.success) {
      props.setAdminViewEmails(resp.data.data.sort(function(a,b){
        return new Date(b.createdAt) - new Date(a.createdAt)
      }))
    }
  }

  const handleChangeText = (event) => {
    setText(event.target.value)
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.from}</TableCell>
        <TableCell component="th" scope="row" alignItems="left">
          {dateFormat(row.createdAt)}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.sent ? dateFormat(row.sent) : 'Pending'}
        </TableCell>
        <TableCell>
          <button className={ styles.button } style={{ padding:'none'}} onClick={deleteButtonClicked}>
            Delete
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Text
              </Typography>

              <ValidatorForm
                ref={formEl}
                onSubmit={ sendButtonClicked }
              >
                <TextValidator
                  label="To"
                  onChange={ (event) => setTo(event.target.value) }
                  name="to"
                  value={ to }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                <TextValidator
                  label="BCC"
                  onChange={ (event) => setBCC(event.target.value) }
                  name="bcc"
                  value={ bcc }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                <TextValidator
                  label="From"
                  onChange={ (event) => setFrom(event.target.value) }
                  name="from"
                  value={ from }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                <TextValidator
                  label="Subject"
                  onChange={ (event) => setSubject(event.target.value) }
                  name="subject"
                  value={ subject }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                <TextValidator
                  label="Text"
                  name="text"
                  value={ text }
                  onChange={ handleChangeText }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:10000'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 10000 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  rowsMax={Infinity}
                  variant="outlined"
                  style={{ paddingTop:'10px'}}
                />
                {
                  sending ?
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress color="primary" size={ 24 } />
                  </Button> :
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Send email to {row.to}
                  </Button>
                }
              </ValidatorForm>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class EmailsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.adminViewEmails.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  // async componentDidMount() {
  //   const users = await axios.get(`${this.props.url}/${this.props.routes.admin.users}`)
  //   console.log('users:', users)
  //   if (users) {
  //     this.props.setUsers(users.data.data)
  //   }
  // }

  displayEmailsTable = (data) => {
    const { page, rowsPerPage } = this.state
    // const emptyRows = this.emptyRows()

    // const that = this
    // async function handleUserClick(email) {
    //   console.log('email:', email)
    //   const userData = await axios.post(`${that.props.url}/${that.props.routes.admin.user}`, { email })
    //   console.log('userData:', userData)
    //   if (userData && userData.data && userData.data.data) {
    //     that.props.setAdminViewUser(userData.data.data)
    //   } else {
    //     that.props.setAdminViewUser({ user: {}, connectivity: [], speedtest: [], downtime: 0 })
    //   }
    //
    //   that.props.history.push('/admin/user')
    // }

    return (
      <TableContainer component={Paper}>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF'}}>
            <TableRow>
              <TableCell />
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>From</TableCell>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Time Submitted</TableCell>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Time Sent</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => (
             <Row
                key={row.createdAt}
                row={row}
                url={this.props.url}
                routes={this.props.routes}
                notification={this.props.notification}
                setAdminViewEmails={this.props.setAdminViewEmails}
              />
           ))}

            {/*<TableRow key={row.email} onClick={() => handleUserClick(row.email)}>*/}
            {/*
              data.map((row) => (
              <TableRow key={row.createdAt}>
                <TableCell component="th" scope="row">
                  {dateFormat(row.createdAt)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.sent ? dateFormat(row.sent) : 'Pending'}
                </TableCell>
                <TableCell align="right">{row.to}</TableCell>
                <TableCell align="right">{row.from}</TableCell>
                <TableCell align="right">{row.subject}</TableCell>
              </TableRow>
            )) */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <div>
        {this.props.adminViewEmails ? this.displayEmailsTable(this.props.adminViewEmails.filter(x => !x.sent)) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    users: state.users,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    adminViewEmails: state.adminViewEmails,
    notification: state.notification,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: data => { dispatch(setUsers(data)) },
    setAdminViewUser: data => { dispatch(setAdminViewUser(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailsTable))

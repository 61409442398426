import React, { Component } from 'react'
import styles from './registration.module.scss'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  ButtonGroup,
  Tooltip,
  Grid,
  CircularProgress
} from '@material-ui/core'
import LogoBlack from '../../imup_black.png'
import LogoWhite from '../../imup_white.png'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
import { Link } from "react-router-dom"
import FacebookIcon from '@material-ui/icons/Facebook'

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 2
    }}
  />
);

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: null,
      email: null,
      password: null,
      passwordAgain: null,
      loading: false
    }
  }

  termsOfService = () => (
    <a href="https://www.imup.io/tos" target="_blank" style={{ color: '#1D98FF' }}>
      <Typography component="subtitle2" variant="subtitle2">
        Terms of Service
      </Typography>
    </a>
  )

  privacyPolicy = () => (
    <a href="https://www.imup.io/privacy" target="_blank" style={{ color: '#1D98FF' }}>
      <Typography component="subtitle2" variant="subtitle2">
        Privacy Policy
      </Typography>
    </a>
  )

  register = async () => {
    const { username, name, email, password } = this.state
    const userdata = {
      username,
      name,
      email,
      password
    }
    this.setState({
      loading: true
    })
    const response = await axios.put(`${this.props.url}/${this.props.routes.auth.registration}`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Successfully registered!',
        message: 'Check your email to verify!'
      })
      this.props.history.push('/verify')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Sign up failed!',
        message: 'Email already registered!'
      })
    }
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false
      }
      return true
    })
  }

  render = () => {
    const { loading } = this.state
    const { theme } = this.props
    return (
      <div className={styles.registration}>
        <Grid
          // xs={ 12 }
          // sm={ 6 }
          // md={ 4 }

          container
          spacing={3}

          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <img src={theme === 'dark' ? LogoWhite : LogoBlack} alt="imUp logo" width="225" style={{ marginBottom: '30px', paddingTop: '30px', position: 'absolute', top: '8%', left: '50%', transform: 'translate(-50%,-50%)' }} />

            <ValidatorForm
              ref="form"
              onSubmit={this.register}
            // onError={ errors => console.log(errors) }
            >
              Create new account:
              <TextValidator
                // need to remove username eventually
                label="Username"
                onChange={this.handleChange}
                name="username"
                value={this.state.username || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '10px' }}
              />
              <TextValidator
                label="Name"
                onChange={this.handleChange}
                name="name"
                value={this.state.name || ''}
                validators={[
                  'required',
                  'minStringLength:3',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '10px' }}
              />
              <TextValidator
                label="Email"
                onChange={this.handleChange}
                name="email"
                value={this.state.email || ''}
                validators={[
                  'required',
                  'isEmail',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'email is not valid',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '10px' }}
              />
              <TextValidator
                label="Password"
                onChange={this.handleChange}
                name="password"
                type="password"
                value={this.state.password || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '10px' }}
              />
              <TextValidator
                label="Password again"
                onChange={this.handleChange}
                name="passwordAgain"
                type="password"
                value={this.state.passwordAgain || ''}
                validators={[
                  'required',
                  'isPasswordMatch',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'password mismatch',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '10px' }}
              />
              {
                loading ?
                  <Button
                    className={this.button}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress color="primary" size={24} />
                  </Button> :
                  <Button
                    type="submit"
                    className={this.button}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ backgroundColor: '#1C98FF', borderRadius: '8px' }}
                  >
                    Sign up
                  </Button>
              }
              <br />
              <br />
              <br />
              <Grid justify="center" container spacing={5}>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ColoredLine color="#1C98FF" />
                </Grid>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }}>
                  or
                </Grid>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ColoredLine color="#1C98FF" />
                </Grid>
              </Grid>
              <br />
              <br />
              Login With:
              <br />
              <br />

              <ButtonGroup disableElevation variant="contained">
                <Tooltip title="Login with Google">
                  <Button
                    href={`${this.props.url}/${this.props.routes.auth.login.google}`}
                  >
                    <svg
                      className="svgIcon-use"
                      width="20"
                      height="25"
                      viewBox="0 0 25 25"
                    >
                      <g fill="none">
                        <path
                          d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.65 2.535-6.24z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.5 21c2.295 0 4.22-.76 5.625-2.06l-2.747-2.132c-.76.51-1.734.81-2.878.81-2.214 0-4.088-1.494-4.756-3.503h-2.84v2.202A8.498 8.498 0 0 0 12.5 21z"
                          fill="#34A853"
                        />
                        <path
                          d="M7.744 14.115c-.17-.51-.267-1.055-.267-1.615s.097-1.105.267-1.615V8.683h-2.84A8.488 8.488 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.79 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z"
                          fill="#EA4335"
                        />
                      </g>
                    </svg>
                    <font className={styles.space}></font>
                  </Button>
                </Tooltip>
                <Tooltip title="Login with Facebook">
                  <Button
                    href={`${this.props.url}/${this.props.routes.auth.login.facebook}`}
                  >
                    <FacebookIcon fontSize="small"></FacebookIcon>
                    <font className={styles.space}></font>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </ValidatorForm>
            <Typography component="body" >
              <Link to="/login" style={{ color: '#1C98FF', textAlign: 'center' }}>Already have an account?</Link>
              <br />
              <br />
              &copy; 2023 imUp.io | All Rights Reserved
              <center>{this.termsOfService()} | {this.privacyPolicy()}</center>
            </Typography>
          </Grid>

        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    notification: state.notification,
    theme: state.theme
  }
}

export default connect(mapStateToProps)(App)

import React from 'react'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

const twoDecimals = value => isNaN(value) ? '0.00' : `${value.toFixed(2).toString()}`

const tickFormat = x => {
  const _d = new Date(x);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
};

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)
  const dateTimeString = `${dateString} at ${timeString}`

  return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
}

function tooltipFormatter (value, name, props) {
  const valueWithUnits = `${twoDecimals(value)} ms`
  const labelName = name === 'latency' ? 'Latency' : 'Average RTT'

  return [valueWithUnits, labelName]
}

export default function PingDataChart(props) {
  return !props.data || !Array.isArray(props.data) ? null : (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={props.data}
          margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
          baseValue="dataMin"
        >
          <XAxis
            tickMargin={15}
            dataKey="time"
            minTickGap={10}
            tickFormatter={tickFormat}
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
            reversed="true"
          />
          <YAxis
            dataKey="avg_rtt"
            type="number"
            orientation="left"
            axisLine="false"
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
          />
          <defs>
            <linearGradient id="download" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1d98ff" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#1d98ff" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="upload" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgb(245, 59, 255)" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="rgb(245, 59, 255)" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Tooltip
            contentStyle={{backgroundColor: props.theme === 'dark' ?  '#303030' : 'white'}}
            formatter={tooltipFormatter}
            labelFormatter={dateFormat}
          />
          <Area type="monotone" dataKey="latency" fillOpacity={1} stroke={"#1d98ff"} strokeWidth={2} fill="url(#download)" layout="vertical"/>
          <Area type="monotone" dataKey="avg_rtt" fillOpacity={1} stroke={"rgb(245, 59, 255)"} strokeWidth={2} fill="url(#upload)" layout="vertical"/>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

import React, { Component } from 'react'
import styles from './payments.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StripeForm from './stripe'
import { setUser } from '../../actions/connectionActions'

class Payments extends Component {
  render() {
    return (
      <div className={ styles.stripe }>
        <StripeForm tier={this.props.paymentPlan} notification={this.props.notification} setUser={this.props.setUser}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    notification: state.notification,
    paymentPlan: state.paymentPlan
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: data => { dispatch(setUser(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payments))

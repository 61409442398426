import React, { Component } from 'react'
import styles from './login.module.scss'
import { connect } from 'react-redux'
import { setAuth, setUser } from '../../actions/connectionActions'
import { withRouter } from 'react-router'
import { Redirect, Link } from "react-router-dom"
import {
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  ButtonGroup,
  Grid,
  CircularProgress
} from '@material-ui/core'
import Cloud from '../../cloud.svg'
import LogoBlack from '../../imup_black.png'
import LogoWhite from '../../imup_white.png'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
// import GitHubIcon from '@material-ui/icons/GitHub'
import FacebookIcon from '@material-ui/icons/Facebook'

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 2
    }}
  />
);

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      password: null,
      loading: false
    }
  }

  addNotification() {
    this.notificationDOMRef.current.addNotification({
      title: "Awesomeness",
      message: "Awesome Notifications!",
      type: "warning",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    })
  }

  login = async () => {
    const { email, password } = this.state
    const userdata = {
      email: email,
      password: password
    }
    this.setState({
      loading: true
    })
    const response = await axios.post(`${this.props.url}/${this.props.routes.auth.login.local}`, userdata)
    if (response.data.success) {
      this.props.setAuth(true)
      this.props.setUser(response.data.user)
      this.props.history.push('/')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Login failed!',
        message: 'Bad email or password, or you have not verified your email address!'
      })
    }
    this.setState({
      loading: false
    })
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    this.setState({
      [name]: value
    })
  }

  termsOfService = () => (
    <a href="https://www.imup.io/tos" target="_blank" style={{ color: '#1D98FF' }}>
      <Typography component="subtitle2" variant="subtitle2">
        Terms of Service
      </Typography>
    </a>
  )

  privacyPolicy = () => (
    <a href="https://www.imup.io/privacy" target="_blank" style={{ color: '#1D98FF' }}>
      <Typography component="subtitle2" variant="subtitle2">
        Privacy Policy
      </Typography>
    </a>
  )

  render = () => {
    const { loading } = this.state
    const { authenticated, theme } = this.props

    if (authenticated) {
      return <Redirect to="/" />
    }

    return (
      <div className={styles.login}>
        <Grid
          // xs={ 12 }
          // sm={ 6 }
          // md={ 4 }

          container
          spacing={3}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '70vh' }}
        >

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <img src={theme === 'dark' ? LogoWhite : LogoBlack} alt="imUp logo" width="225" style={{ paddingTop: '30px', position: 'absolute', top: '8%', left: '50%', transform: 'translate(-50%,-50%)' }} />
            <br />
            <ValidatorForm
              ref="form"
              onSubmit={this.login}
              // onError={ errors => console.log(errors) }
              className={styles.content}
            >


              <ButtonGroup disableElevation variant="contained">
                <Tooltip title="Login with Google">
                  <Button
                    href={`${this.props.url}/${this.props.routes.auth.login.google}`}
                  >
                    <svg
                      className="svgIcon-use"
                      width="20"
                      height="25"
                      viewBox="0 0 25 25"
                    >
                      <g fill="none">
                        <path
                          d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.65 2.535-6.24z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.5 21c2.295 0 4.22-.76 5.625-2.06l-2.747-2.132c-.76.51-1.734.81-2.878.81-2.214 0-4.088-1.494-4.756-3.503h-2.84v2.202A8.498 8.498 0 0 0 12.5 21z"
                          fill="#34A853"
                        />
                        <path
                          d="M7.744 14.115c-.17-.51-.267-1.055-.267-1.615s.097-1.105.267-1.615V8.683h-2.84A8.488 8.488 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.79 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z"
                          fill="#EA4335"
                        />
                      </g>
                    </svg>
                    <font className={styles.space}></font>
                  </Button>
                </Tooltip>
                <Tooltip title="Facebook login is temporarily disabled. You can login to your account by going through the reset password flow and logging in with an email/password combination.">
                  <span>
                    <Button
                      disabled
                      href={`${this.props.url}/${this.props.routes.auth.login.facebook}`}
                    >
                      <FacebookIcon fontSize="small" style={{ color: '#4267B2' }}></FacebookIcon>
                      <font className={styles.space}></font>
                    </Button>
                  </span>
                </Tooltip>
              </ButtonGroup>

              <br />
              <br />
              <br />
              <Grid justify="center" container spacing={5}>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ColoredLine color="#1C98FF" />
                </Grid>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }}>
                  or
                </Grid>
                <Grid justify="center" item xl={3} lg={3} md={3} xs={3} spacing={3} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ColoredLine color="#1C98FF" />
                </Grid>
              </Grid>



              <TextValidator
                label="Email"
                onChange={this.handleChange}
                name="email"
                value={this.state.email || ''}
                validators={['required', 'isEmail', 'minStringLength:5', 'maxStringLength:100']}
                errorMessages={['this field is required', 'email is not valid', 'min length 5', 'max length 100']}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '8px' }}
              />
              <TextValidator
                label="Password"
                onChange={this.handleChange}
                name="password"
                type="password"
                value={this.state.password || ''}
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                variant="outlined"
                style={{ paddingTop: '8px' }}
              />
              <Grid justifyContent="center" alignItems="center" container spacing={3}>
                <Grid justifyContent="center" alignItems="center" item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="secondary" />}
                    label="Remember"
                  />
                </Grid>
                <Grid justifyContent="center" alignItems="center" item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Link to="/recovery" style={{ color: '#1C98FF', textAlign: 'right', paddingTop: '3px' }}>Reset password</Link><br />
                </Grid>
              </Grid>
              {
                loading ?
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled
                  >
                    <CircularProgress color="primary" size={24} />
                  </Button> :
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ backgroundColor: '#1C98FF', borderRadius: '8px' }}
                  >
                    <strong>Sign in</strong>
                  </Button>
              }

              {/*
                <Button
                  href={ `${this.props.url}/${this.props.routes.auth.login.github}` }
                  variant="contained"
                  className={ styles.github }
                  fullWidth
                >
                  <GitHubIcon fontSize="small"></GitHubIcon>
                  <font className={ styles.space }></font>
                  Continue with GitHub
                </Button>
                */}

            </ValidatorForm>
          </Grid>
          <Typography className={styles.content} component="body">
            <Link to="/registration" style={{ color: '#1C98FF', align: 'center' }}>Create new account</Link>
            <br />
            <br />
          </Typography>
        </Grid>
        <center>&copy; 2023 imUp.io | All Rights Reserved</center>
        <center>{this.termsOfService()} | {this.privacyPolicy()}</center>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    authenticated: state.authenticated,
    notification: state.notification,
    theme: state.theme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuth: (authenticated) => { dispatch(setAuth(authenticated)) },
    setUser: (user) => { dispatch(setUser(user)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))

import React, { Component } from 'react'
import { Fab } from '@material-ui/core'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { setModalDisplayed } from '../../actions/connectionActions'

// not sure why styles isn't working with the fab button...
// import styles from './home.module.scss'

const styleFab = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

class FabBtn extends Component {
  handleClose = () => {
    this.props.setModalDisplayed(false)
  }

  handleClick = () => {
    if (this.props.modalDisplayed) {
      this.props.setModalDisplayed(false)
    } else {
      this.props.setModalDisplayed(true)
    }
  }

  render() {
    return (
      <Fab variant="round" color="primary" aria-label="help" style={styleFab} onClick={this.handleClick}>
        Help
      </Fab>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalDisplayed: state.modalDisplayed || false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setModalDisplayed: data => { dispatch(setModalDisplayed(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabBtn))

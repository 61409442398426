export const setUrl = (url) => {
  return {
    type: 'setUrl',
    url
  }
}

export const setRoutes = (routes) => {
  return {
    type: 'setRoutes',
    routes
  }
}

export const setAuth = (authenticated) => {
  return {
    type: 'setAuth',
    authenticated
  }
}

export const setNotifications = (notification) => {
  return {
    type: 'setNotifications',
    notification
  }
}

export const setActiveUsers = (data) => {
  return {
    type: 'setActiveUsers',
    data
  }
}

export const setOrganizations = (data) => {
  return {
    type: 'setOrganizations',
    data
  }
}

export const setOrgHostsSummary = (data) => {
  return {
    type: 'setOrgHostsSummary',
    data
  }
}

export const setOrganizationHosts = (data) => {
  return {
    type: 'setOrganizationHosts',
    data
  }
}

export const setOrganizationHostsForGroup = (data) => {
  return {
    type: 'setOrganizationHostsForGroup',
    data
  }
}

export const setOrganizationHostsCount = (data) => {
  return {
    type: 'setOrganizationHostsCount',
    data
  }
}

export const setOrganizationExports = (data) => {
  return {
    type: 'setOrganizationExports',
    data
  }
}

export const setOrgHostConnectivityData = (data) => {
  return {
    type: 'setOrgHostConnectivityData',
    data
  }
}

export const setOrgHostSpeedTestData = (data) => {
  return {
    type: 'setOrgHostSpeedTestData',
    data
  }
}

export const setOrgHostPingData = (data) => {
  return {
    type: 'setOrgHostPingData',
    data
  }
}

export const setOrgHostPingChartData = (data) => {
  return {
    type: 'setOrgHostPingChartData',
    data
  }
}

export const setOrganizationUsers = (data) => {
  return {
    type: 'setOrganizationUsers',
    data
  }
}

export const setOrganization = (data) => {
  return {
    type: 'setOrganization',
    data
  }
}

export const setOrganizationGroupProfiles = (data) => {
  return {
    type: 'setOrganizationGroupProfiles',
    data
  }
}

export const setAdminViewReports = (data) => {
  return {
    type: 'setAdminViewReports',
    data
  }
}

export const setUser = (user) => {
  return {
    type: 'setUser',
    user
  }
}

export const setUserPaymentInfo = (data) => {
  return {
    type: 'setUserPaymentInfo',
    data
  }
}

export const setOrgPaymentInfo = (data) => {
  return {
    type: 'setOrgPaymentInfo',
    data
  }
}

export const setLocalProviders = (localProviders) => {
  return {
    type: 'setLocalProviders',
    localProviders
  }
}

export const setProviders = (providers) => {
  return {
    type: 'setProviders',
    providers
  }
}

export const setReportGroups = (reportGroups) => {
  return {
    type: 'setReportGroups',
    reportGroups
  }
}

export const setOnlineUsers = (data) => {
  return {
    type: 'setOnlineUsers',
    data
  }
}

export const setEmails = (emails) => {
  return {
    type: 'setEmails',
    emails
  }
}

export const setPaymentPlan = (plan) => {
  return {
    type: 'setPaymentPlan',
    plan
  }
}

export const setStatsData = (data) => {
  return {
    type: 'setStatsData',
    data
  }
}

export const setUsers = (data) => {
  return {
    type: 'setUsers',
    data
  }
}

export const setAdminViewUser = (data) => {
  return {
    type: 'setAdminViewUser',
    data
  }
}

export const setAdminViewEmails = (data) => {
  return {
    type: 'setAdminViewEmails',
    data
  }
}

export const setConnectivityData = (data) => {
  return {
    type: 'setConnectivityData',
    data
  }
}

export const setPingData = (data) => {
  return {
    type: 'setPingData',
    data
  }
}

export const setPingChartData = (data) => {
  return {
    type: 'setPingChartData',
    data
  }
}

export const setDowntimeData = (data) => {
  return {
    type: 'setDowntimeData',
    data
  }
}

export const setSpeedTestData = (data) => {
  return {
    type: 'setSpeedTestData',
    data
  }
}

export const setSpeedTestAverage7d = (data) => {
  return {
    type: 'setSpeedTestAverage7d',
    data
  }
}

export const setOperatingSystem = (data) => {
  return {
    type: 'setOperatingSystem',
    data
  }
}

export const setGeolocationData = (data) => {
  return {
    type: 'setGeolocationData',
    data
  }
}

export const setModalDisplayed = (data) => {
  return {
    type: 'setModalDisplayed',
    data
  }
}

export const setSpeedTestStatusModalDisplayed = (data) => {
  return {
    type: 'setSpeedTestStatusModalDisplayed',
    data
  }
}

export const setSpeedTestStatusResults = (data) => {
  return {
    type: 'setSpeedTestStatusResults',
    data
  }
}

export const setHostLivenessStatus = (data) => {
  return {
    type: 'setHostLivenessStatus',
    data
  }
}

export const setUserLivenessStatus = (data) => {
  return {
    type: 'setUserLivenessStatus',
    data
  }
}

export const setTermsOfServiceModalDisplayed = (data) => {
  return {
    type: 'setTermsOfServiceModalDisplayed',
    data
  }
}

export const setDownloadOS = (data) => {
  return {
    type: 'setDownloadOS',
    data
  }
}

export const setTheme = (theme) => {
  return {
    type: 'setTheme',
    theme
  }
}

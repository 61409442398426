import React from "react";

// MATERIAL UI
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableToolbarRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: 12,
    borderBottom: `1px solid rgba(224, 224, 224, 1)`
  },
  toolbarStyles: {
    fontSize: 14
  }
}));
const TableToolbar = () => {
  const classes = useStyles();

  return (
    <div className={classes["tableToolbarRoot"]}>
      <GridToolbarColumnsButton className={classes["toolbarStyles"]} />
      <GridToolbarFilterButton className={classes["toolbarStyles"]} />
    </div>
  );
};

export default TableToolbar;

import React from 'react';
import { Link } from 'react-router-dom'
import styles from './home.module.scss'
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'


export default function Help() {
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Show Tips"
      />
      <Fade in={checked}>
      <Grid container spacer={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center">
          <Card variant="outlined" className={ styles.card } >
            <CardContent>
              <Typography variant="body1" component="p">
                <strong>Download & Install:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                Log into the dashboard from your computer. You will see a Download button on this page or you can navigate to the "Downloads page in the left menu. Once downloaded, simply install the app as you would any other software -- good to go."
              </Typography><br />
              <Typography variant="body1" component="p">
                <strong>Running the app:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                Run the desktop app on Windows or Mac, enter your email address in the field, and click start. Leave the app running in the background to ensure you are able to catch issues even when you're away from your computer or home.
              </Typography><br />
              <Typography variant="body1" component="p">
                <strong>Your Profile:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                You are encouraged to fill out your profile information, especially any details you wish to provide about your internet service & provider. If imUp is to negotiate on your behalf, we require this information before we can proceed.
              </Typography><br />
              <Typography variant="body1" component="p">
                <strong>The imUp Plans:</strong>
              </Typography>
              <Typography variant="body2" component="p">
                Our app is awesome -- that's why we let you use it for free. But we also offer extended data retention, additional features, & services under our other plans too.
              </Typography><br />
              <Typography variant="body1" component="p">
                <strong>Help!</strong>
              </Typography>
              <Typography variant="body2" component="p">
                Lastly, if you ever need help -- don't hesitate to reach out to us via email support@imup.io or click the help button in the bottom right of your screen to submit a ticket.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fade>
    </div>
  );
}

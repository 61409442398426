import React, { Component } from 'react'
import styles from './speedTest.module.scss'
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Divider from '@material-ui/core/Divider';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const tickFormat = x => {
  const _d = new Date(x);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
};

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)
  const dateTimeString = `${dateString} at ${timeString}`

  return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
};

const numberFormat = x => {
  return Number.isFinite(x) ? x.toFixed(2) : x
}

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default class SpeedTestTable extends Component {
  constructor(props) {
    super(props)

    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.data.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }
  render(theme) {
    const { page, rowsPerPage } = this.state
    const emptyRows = this.emptyRows()
    const { data } = this.props
    function Chart(data, theme) {}
    return (
      <TableContainer component={Paper}>
      <Divider variant="middle" style={{margin:'15px'}}/>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF', color: '#fff'}}>
            <TableRow>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Time</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Download speed (Mbps)</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Upload speed (Mbps)</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Latency to server (ms)</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Test Server</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Data transferred (Mb)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles['table-body']}>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.time}>
                <TableCell component="th" scope="row">
                  {dateFormat(row.time)}
                </TableCell>
                <TableCell align="right">{numberFormat(row.download_mbps)}</TableCell>
                <TableCell align="right">{numberFormat(row.upload_mbps)}</TableCell>
                <TableCell align="right">{numberFormat(row.latency_ms)}</TableCell>
                <TableCell align="right">{ row.test_server ? numberFormat(row.test_server) : 'no server name for this record'}</TableCell>
                <TableCell align="right">{ row.uploaded_bytes ? numberFormat((row.uploaded_bytes + row.downloaded_bytes)/1048576) : 'no data for this record'}</TableCell> { /*1048576 = 1024*1024 (bytes --> Mb)*/ }
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>

    )
  }
}

import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { setUsers, setAdminViewUser, setAdminViewEmails, setProviders } from '../../actions/connectionActions'
import styles from './admin.module.scss'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>
    </div>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState(row.name);
  const [email, setEmail] = React.useState(Array.isArray(row.email) && row.email[0] ? row.email[0].address : '');
  const [sending, setSending] = React.useState(false);

  const formEl = useRef(null)

  const sendButtonClicked = async () => {
    setSending(true)

    const providerContent = {
      name,
      email,
      id: row._id
    }

    const response = await axios.post(`${props.url}/${props.routes.provider.update}`, providerContent)

    setSending(false)

    if (response.data.success) {
      props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Email sent!'
      })
      props.setProviders(response.data.data)
    } else {
      props.notification({
        type: 'danger',
        title: 'Email failed to send!',
        message: 'Try again later!'
      })
    }
  }

  const deleteButtonClicked = async () => {
    const resp = await axios.delete(`${props.url}/${props.routes.provider.delete}`, { data: { id: row._id} })

    console.log('delete response:', resp)
    if (resp.data.success) {
      props.setProviders(resp.data.data)
    }
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">
          {Array.isArray(row.email) && row.email[0] ? row.email[0].address : 'n/a'}
        </TableCell>
        <TableCell>
          <Button onClick={deleteButtonClicked}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Edit Provider
              </Typography>

              <ValidatorForm
                ref={formEl}
                onSubmit={ sendButtonClicked }
              >
                <TextValidator
                  label="Name"
                  onChange={ (event) => setName(event.target.value) }
                  name="name"
                  value={ name }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                <TextValidator
                  label="Email address"
                  onChange={ (event) => setEmail(event.target.value) }
                  name="email"
                  value={ email }
                  validators={[
                    'minStringLength:0',
                    'maxStringLength:200'
                  ]}
                  errorMessages={[
                    // 'min 2 characters',
                    'max 200 characters'
                  ]}
                  margin="normal"
                  fullWidth
                  style={{ paddingTop:'10px'}}
                />
                {
                  sending ?
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress color="primary" size={ 24 } />
                  </Button> :
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Set provider info for {row.name}
                  </Button>
                }
              </ValidatorForm>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

class ProvidersTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.adminViewEmails.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  // async componentDidMount() {
  //   const users = await axios.get(`${this.props.url}/${this.props.routes.admin.users}`)
  //   console.log('users:', users)
  //   if (users) {
  //     this.props.setUsers(users.data.data)
  //   }
  // }

  displayProvidersTable = (data) => {
    const { page, rowsPerPage } = this.state
    // const emptyRows = this.emptyRows()

    // const that = this
    // async function handleUserClick(email) {
    //   console.log('email:', email)
    //   const userData = await axios.post(`${that.props.url}/${that.props.routes.admin.user}`, { email })
    //   console.log('userData:', userData)
    //   if (userData && userData.data && userData.data.data) {
    //     that.props.setAdminViewUser(userData.data.data)
    //   } else {
    //     that.props.setAdminViewUser({ user: {}, connectivity: [], speedtest: [], downtime: 0 })
    //   }
    //
    //   that.props.history.push('/admin/user')
    // }

    return (
      <TableContainer component={Paper}>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF'}}>
            <TableRow>
              <TableCell />
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Name</TableCell>
              <TableCell align="right" style={{color: '#fff', fontWeight: 'bold'}}>Email Address</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
          ).map((row) => (
             <Row
                key={row.createdAt}
                row={row}
                url={this.props.url}
                routes={this.props.routes}
                notification={this.props.notification}
                setProviders={this.props.setProviders}
              />
           ))}

            {/*<TableRow key={row.email} onClick={() => handleUserClick(row.email)}>*/}
            {/*
              data.map((row) => (
              <TableRow key={row.createdAt}>
                <TableCell component="th" scope="row">
                  {dateFormat(row.createdAt)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.sent ? dateFormat(row.sent) : 'Pending'}
                </TableCell>
                <TableCell align="right">{row.to}</TableCell>
                <TableCell align="right">{row.from}</TableCell>
                <TableCell align="right">{row.subject}</TableCell>
              </TableRow>
            )) */}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  render() {
    return (
      <div>
        {this.props.providers ? this.displayProvidersTable(this.props.providers) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    users: state.users,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    adminViewEmails: state.adminViewEmails,
    notification: state.notification,
    providers: state.providers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: data => { dispatch(setUsers(data)) },
    setAdminViewUser: data => { dispatch(setAdminViewUser(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProvidersTable))

import React, { Component } from 'react'
import styles from './organizations.module.scss'
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>
    </div>
  );
}

class OrganizationsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.data.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }

  rowClicked = (row) => {
    this.props.history.push(`/organization/${row.id}`)
  }

  render() {
    const { page, rowsPerPage } = this.state
    const emptyRows = this.emptyRows()
    const { data } = this.props
    return (
      <div>
        {/*<Table className={classes.table} size="small" aria-label="a dense table">*/}
        <Table size="small" aria-label="speed test data">
          <TableHead style={{backgroundColor: '#1D98FF', color: '#fff'}}>
            <TableRow>
              <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow key={row.createdAt} onClick={() => this.rowClicked(row)}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 40 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setUsers: data => { dispatch(setUsers(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationsTable))

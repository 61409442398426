import React from 'react';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import styles from './internetConnectivity.module.scss'
import { useTheme } from '@material-ui/core/styles';

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)
  const dateTimeString = `${dateString} at ${timeString}`

  return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
};

const numberFormat = x => {
  return Number.isFinite(x) ? x.toFixed(2) : x
}

const tickFormat = x => {
  const _d = new Date(x);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
};

const tickFormatSuccess = x => {
  if (x === 1) {
    return 'Online'
  }

  if (x === -1) {
    return 'Offline'
  }

  return ''
};

function Chart(data, theme) {
  return (
    <div style={{ borderRadius: '5px'}}>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart
          data={data}
          margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
          // wrapperStyle={{ backgroundColor: '#ccc' }}
          // wrapperStyle={{ width: 100, backgroundColor: '#000' }}
          baseValue="dataMin"
        >
          <XAxis
            dataKey="time"
            // tick={{ fontSize: 6 }}
            // type="number"
            // scale="time"
            minTickGap={20}
            tickFormatter={tickFormat}
            stroke={theme === 'dark' ?  '#d3d3d3' : 'black'}
            reversed="true"
          />
          <YAxis
            dataKey="success"
            type="number"
            // interval={0}
            orientation="left"
            domain={['dataMin -1', 'dataMax +1']}
            tickFormatter={tickFormatSuccess}
            axisLine="false"
            stroke={theme === 'dark' ?  '#d3d3d3' : 'black'}
          />
          <defs>
            <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
            </linearGradient>
          </defs>
          <Tooltip
            contentStyle={{backgroundColor: theme === 'dark' ?  '#303030' : 'white'}}
            formatter={
              //(value, name, props) => ["Offline", "Status"]
              (value, name, props) => value === 1 ? ["Online", "Status"] : ["Offline", "Status"]
            }
            labelFormatter={dateFormat}
          />
          <Line type="monotone" dataKey="success" fillOpacity={1} stroke={"#1d98ff"} strokeWidth={2} fill="url(#colorSuccess)" layout="vertical" dot={false}/>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;

import React, { Component } from 'react'
import styles from './speedTest.module.scss'
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Divider from '@material-ui/core/Divider';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const twoDecimals = value => isNaN(value) ? '0.00' : `${value.toFixed(2).toString()}`

const tickFormat = x => {
  const _d = new Date(x);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
};

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)
  const dateTimeString = `${dateString} at ${timeString}`

  return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
};

const numberFormat = x => {
  return Number.isFinite(x) ? x.toFixed(2) : x
}

function tooltipFormatter (value, name, props) {
  const labelNames = {
    latency_ms: 'Latency',
    download_mbps: 'Download',
    upload_mbps: 'Upload',
    test_server: 'Server',
    upload_bytes: 'Data xfer'
  }

  const units = {
    latency_ms: 'ms',
    download_mbps: 'mbps',
    upload_mbps: 'mbps',
    test_server: '',
    upload_bytes: 'bytes'
  }

  const valueWithUnits = `${twoDecimals(value)} ${units[name]}`

  return [valueWithUnits, labelNames[name]]
}

function TablePaginationActions(props) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  // {/*<div className={classes.root}>*/}
  return (
    <div className={styles['table-pagination']}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

export default class SpeedTestChart extends Component {
  constructor(props) {
    super(props)

    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);

    this.state = {
      page: 0,
      rowsPerPage: 5
    }
  }

  emptyRows = () => {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.data.length - this.state.page * this.state.rowsPerPage);
    return emptyRows
  }

  handleChangePage = (event, newPage) => {
    // setPage(newPage);
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);

    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    })
  }
  render(theme) {
    const { page, rowsPerPage } = this.state
    const emptyRows = this.emptyRows()
    const { data } = this.props

    return (
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
            // wrapperStyle={{ backgroundColor: '#ccc' }}
            // wrapperStyle={{ width: 100, backgroundColor: '#000' }}
            baseValue="dataMin"
          >
            <XAxis
              dataKey="time"
              tickMargin={15}
              // tick={{ fontSize: 6 }}
              // type="number"
              // scale="time"
              minTickGap={10}
              reversed="true"
              tickFormatter={tickFormat}
              stroke={this.props.theme === 'dark' ?  '#d3d3d3' : 'black'}
            />
            <YAxis
              dataKey="download_mbps"
              type="number"
              // interval={0}
              orientation="left"
              axisLine="false"
              stroke={this.props.theme === 'dark' ?  '#d3d3d3' : 'black'}
            />
            <defs>
              <linearGradient id="download" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1d98ff" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#1d98ff" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="upload" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="rgb(245, 59, 255)" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="rgb(245, 59, 255)" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="latency" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FE4C40" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#FE4C40" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <Tooltip
              contentStyle={{backgroundColor: this.props.theme === 'dark' ?  '#303030' : 'white'}}
              formatter={tooltipFormatter}
              labelFormatter={dateFormat}
            />
            <Area type="monotone" dataKey="download_mbps" fillOpacity={1} stroke={"#1d98ff"} strokeWidth={2} fill="url(#download)" layout="vertical" animationDuration={2000}/>
            <Area type="monotone" dataKey="upload_mbps" fillOpacity={1} stroke={"rgb(245, 59, 255)"} strokeWidth={2} fill="url(#upload)" layout="vertical" animationDuration={2000}/>
            <Area type="monotone" dataKey="latency_ms" fillOpacity={1} stroke={"#FE4C40"} strokeWidth={2} fill="url(#latency)" layout="vertical" animationDuration={2000}/>
          </AreaChart>
        </ResponsiveContainer>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styles from './admin.module.scss'
import UsersTable from './usersTable'
import ReportGroupsTable from './reportGroupsTable'
import EmailsTable from './emailsTable'
import EmailForm from './emailForm'
import ProvidersTable from './providersTable'
import ProviderForm from './providerForm'
import { setOrganizations, setUsers, setAdminViewEmails, setProviders, setOnlineUsers, setReportGroups, setActiveUsers } from '../../actions/connectionActions'
import Button from '@material-ui/core/Button'
import { Card, CardContent, Grid, Typography, CardHeader } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import Orgs from '../Organizations/Organizations'
import AdminUserRegistrationChart from '../AdminUserRegistrationChart/AdminUserRegistrationChart'

import Fab from './fab'


class Admin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      emailOpen: false,
      providerOpen: false
    }
  }

  async componentDidMount() {
    const users = await axios.get(`${this.props.url}/${this.props.routes.admin.users}`)

    if (users) {
      this.props.setUsers(users.data.data)
    }

    const emails = await axios.get(`${this.props.url}/${this.props.routes.admin.emails}`)

    console.log('ADMIN EMAILS:', emails.data.data)

    if (emails) {
      this.props.setAdminViewEmails(emails.data.data.sort(function(a,b){
        return new Date(b.createdAt) - new Date(a.createdAt)
      }))
    }

    const providers = await axios.get(`${this.props.url}/${this.props.routes.provider.list}`)

    if (providers.data.success && Array.isArray(providers.data.data)) {
      this.props.setProviders(providers.data.data)
    }

    const onlineUsers = await axios.get(`${this.props.url}/${this.props.routes.admin.onlineUsers}`)
    console.log('Users currently online!:', onlineUsers)
    if (onlineUsers.data.success && Array.isArray(onlineUsers.data.data)) {
      console.log('Users currently online:', onlineUsers.data.data)
      this.props.setOnlineUsers(onlineUsers.data.data)
    }

    const reportGroups = await axios.get(`${this.props.url}/${this.props.routes.reports.reportGroups}`)
    console.log('Report groups here:', reportGroups)
    if (reportGroups.data.success && Array.isArray(reportGroups.data.data)) {
      console.log('Report groups:', reportGroups.data.data)
      this.props.setReportGroups(reportGroups.data.data)
      // this.props.setReportGroups(reportGroups.data.data)
    }

    const activeUsers = await axios.get(`${this.props.url}/${this.props.routes.admin.activeUsers}`)
    console.log('active users here:', activeUsers)
    if (activeUsers.data.success && Number.isInteger(activeUsers.data.data)) {
      console.log('activeUsers active users:', activeUsers.data.data)
      this.props.setActiveUsers(activeUsers.data.data)
    }

    const organizations = await axios.get(`${this.props.url}/${this.props.routes.organization.list}`)
    if (organizations && organizations.data.success) {
      this.props.setOrganizations(organizations.data.data)
    }
  }

  toggleEmailForm = () => {
    this.setState({
      emailOpen: !this.state.emailOpen
    })
  }

  toggleProviderForm = () => {
    this.setState({
      providerOpen: !this.state.providerOpen
    })
  }

  runReport = async() => {
    const resp = await axios.post(`${this.props.url}/${this.props.routes.reports.report}`)
    console.log('Response from report:', resp)
  }

  displayTopCards = () => {
    return (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifyContent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                  <strong>Registered Users</strong>
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: '#24F20D'}}>
                    {this.props.users ? this.props.users.length : 0} <Typography variant="body2" component="body1" gutterBottom>
                       users
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <PersonIcon style={{width:'100%', height:'100%', color: '#24F20D'}}/>
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifyContent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                  <strong>Active Users</strong>
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: '#FE4C40'}}>
                    {this.props.activeUsers || 0} <Typography variant="body2" component="body1" gutterBottom>
                       users
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <PersonIcon style={{width:'100%', height:'100%', color: '#FE4C40'}}/>
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifyContent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                  <strong>Percent Active Users</strong>
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: 'rgb(104, 142, 255)'}}>
                    {((this.props.users && this.props.activeUsers ? this.props.activeUsers / this.props.users.length : 0) * 100).toFixed(2)} <Typography variant="body2" component="body1" gutterBottom>
                       %
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <PersonIcon style={{width:'100%', height:'100%', color: 'rgb(104, 142, 255)'}}/>
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifyContent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                  <strong>Registered Orgs</strong>
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography variant="h4" component="h2" gutterBottom style={{color: 'rgb(255, 152, 0)'}}>
                    {this.props.organizations ? this.props.organizations.length : 0} <Typography variant="body2" component="body1" gutterBottom>
                       orgs
                  </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <BusinessIcon style={{width:'100%', height:'100%', color: 'rgb(255, 152, 0)'}}/>
                </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    )
  }

  displayUsers = () => {
    return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={ styles.card }>
            <Typography variant="body1" component="h2" gutterBottom>
              <strong>Users</strong>
            </Typography>
            {this.props.users ? <UsersTable users={this.props.users} /> : null}
          </div>
        </Grid>
      </Grid>
    )
  }

  displayReportGroupsTable = () => {
    return (
      <div className={ styles.home }>
      <Typography variant="body1" component="h2" gutterBottom>
        <strong>Report Groups</strong>
      </Typography>
        {this.props.reportGroups ? <ReportGroupsTable /> : null}
        <button
          onClick={ this.runReport }
          className={ styles.button }
          variant="contained"
          color='primary'
          fullWidth
        >
          Run Report
        </button>
      </div>
    )
  }

  displayProviderTable = () => {
    return (
      <div className={ styles.home }>
      <Typography variant="body1" component="h2" gutterBottom>
        <strong>Providers</strong>
      </Typography>
        <ProvidersTable />
        <button
          onClick={ this.toggleProviderForm }
          className={ styles.button }
          variant="contained"
          color={!this.state.providerOpen ? 'primary' : 'secondary'}
          fullWidth
        >
          {!this.state.providerOpen ? 'Add provider to database' : 'Cancel adding provider'}
        </button>
        {this.state.providerOpen ? <ProviderForm toggleProviderForm={this.toggleProviderForm} /> : null}
      </div>
    )
  }

  displayButtons = () => {
    return (
      <div className={ styles.home }>
      <Typography variant="body1" component="h2" gutterBottom>
        <strong>Additional Features</strong>
      </Typography>
        <button
          onClick={ this.toggleEmailForm }
          className={ styles.button }
          variant="contained"
          color={!this.state.emailOpen ? 'primary' : 'secondary'}
          fullWidth
        >
          {!this.state.emailOpen ? 'Add email to database (does not send email)' : 'Cancel'}
        </button>
        {this.state.emailOpen ? <EmailForm toggleEmailForm={this.toggleEmailForm} /> : null}
      </div>
    )
  }


  displayEmailsTable = () => {
    return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className={ styles.card }>
            <Typography variant="body1" component="h2" gutterBottom>
              <strong>User Submitted Emails</strong>
            </Typography>
            <EmailsTable />
          </div>
        </Grid>
      </Grid>
    )
  }





  render() {
    console.log('statsData', this.props.statsData)

    return (
      <div className={ styles.home }>
        <Fab />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8} justifyContent="center" alignItems="center">
            {this.displayTopCards()}
            User Registrations Per Day
            <AdminUserRegistrationChart users={this.props.users} theme={this.props.theme} type='perDay'/>
            User Registrations Per Month
            <AdminUserRegistrationChart users={this.props.users} theme={this.props.theme} type='perMonth'/>
            {this.displayUsers()}
            {this.displayEmailsTable()}
          <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} justifyContent="center" alignItems="center">
            {this.displayReportGroupsTable()}
            {this.displayProviderTable()}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} justifyContent="center" alignItems="center">
            {this.displayButtons()}
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} justifyContent="center" alignItems="center">
          <Orgs />
        </Grid>
      </Grid>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    users: state.users,
    providers: state.providers,
    onlineUsers: state.onlineUsers,
    organizations: state.organizations,
    reportGroups: state.reportGroups,
    activeUsers: state.activeUsers,
    theme: state.theme,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganizations: data => { dispatch(setOrganizations(data)) },
    setUsers: data => { dispatch(setUsers(data)) },
    setAdminViewEmails: data => { dispatch(setAdminViewEmails(data)) },
    setProviders: (providers) => { dispatch(setProviders(providers)) },
    setOnlineUsers: (data) => { dispatch(setOnlineUsers(data)) },
    setReportGroups: (data) => { dispatch(setReportGroups(data)) },
    setActiveUsers: (data) => { dispatch(setActiveUsers(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))

import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import axios from 'axios'
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";
import { Grid, Button } from '@material-ui/core';
import * as react from "react";
import styles from './organizationUser.module.scss';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { AreaChart, Area, BarChart, Bar, CartesianGrid, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Chip from '@mui/material/Chip';

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
const valueFormatterTwoDecimals = ({ value }) => twoDecimals(value)

function findHostLivenessStatus (hostLivenessStatus, host) {
  let hostAlive = false
  if (!hostLivenessStatus) {
    return hostAlive
  }

  // eslint thinks h isn't being used here even though it clearly is
  // eslint-disable-next-line
  for (const h of hostLivenessStatus) {
    if (host === h.host) {
      hostAlive = h.status
      break;
    }
  }
  return hostAlive
}

async function setLivenessStatus (hostConnectedURL, hosts) {
  const hostConnectedStatus = await axios.post(hostConnectedURL, {
    hosts: hosts ? hosts.map(h => `${h.apiKey}__IMUP_DELIMITER__${h.hostId}`) : []
  })

  if (hostConnectedStatus && hostConnectedStatus.data && hostConnectedStatus.data.success && hostConnectedStatus.data.data && hostConnectedStatus.data.data.status) {
    return !hosts ? [] : hosts.map(u => {
      const hostLivenessStatus = hostConnectedStatus.data.data.status
      u.live = findHostLivenessStatus(hostLivenessStatus, `${u.apiKey}__IMUP_DELIMITER__${u.hostId}`)

      return u
    })
  }

  console.log('failed to get host liveness status')
  return hosts
}

const columnsCommon = [
  { field: 'hostId', headerName: 'Host ID', width: 500 },
  // { field: 'downloadSpeedAvg', headerName: 'Download Avg (7d)', width: 200, valueFormatter: valueFormatterTwoDecimals },
  // { field: 'uploadSpeedAvg', headerName: 'Upload Avg (7d)', width: 200, valueFormatter: valueFormatterTwoDecimals },
  // { field: 'packetLossAvg', headerName: 'Packet Loss Avg % (7d)', width: 200, valueFormatter: valueFormatterTwoDecimals },
];

const columnsWithLiveAndKey =   [
  {
    field: 'live',
    type: 'boolean',
    headerName: 'Status',
    width: 100,
      renderCell: (props) => {
      return (
        <Chip label={props.row.live ? 'true' : 'false' } color={props.row.live ? 'success' : 'error' } />
      );
    },
  },
].concat(columnsCommon)

class HostsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: { page: 0, pageCount: 1, pageSize: 100, paginationMode: 'server', rowCount: 0 },
      rows: props.data,
      rowCount: Array.isArray(props.data) ? props.data.length : 0,
      filterValue: null,
      loading: false,
      sortModel: [{ field: 'hostId', sort: 'asc' }],
      hostStatusIntervalId: null,
      showLive: props.showLiveAndKey,
      columns: props.showLiveAndKey ? columnsWithLiveAndKey : columnsCommon,
      serverSidePagination: !!props.serverSidePagination,
      checkboxSelection: !!props.checkboxSelection,
    }
  }


  async requestRowData () {
    this.setState({ loading: true })

    const { page, filterValue, sortModel } = this.state
    const data = await this.props.loadHostsTableRows({ page, filterValue, sortModel })

    this.setState({ rows: data.rows, rowCount: data.rowCount, loading: false })
  }

  async componentDidMount() {
    if (this.state.serverSidePagination) {
      this.requestRowData()
    }

    if (!this.state.showLive) {
      return
    }

    let intervalId = setInterval(async () => {
      if (Array.isArray(this.state.rows) && this.state.rows.length > 0) {
        try {
          const rows = await setLivenessStatus(`${this.props.url}/${this.props.routes.realtime.hostConnectedStatus}`, this.state.rows)
          this.setState({ rows })
        } catch(e) {
          console.log('error updating rows with liveness status', e)
        }
      }
    }, 5000);

    this.setState({ hostStatusIntervalId: intervalId })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!this.state.serverSidePagination) {
      return
    }

    const pageChanged = () => {
      return this.state.page.page !== prevState.page.page ||
        this.state.page.pageCount !== prevState.page.pageCount ||
        this.state.page.rowCount !== prevState.page.rowCount ||
        this.state.page.pageSize !== prevState.page.pageSize
    }

    const sortModelChanged = () => {
      const currentSortModel = !!this.state.sortModel && !!this.state.sortModel[0]
        ? this.state.sortModel[0]
        : {}

      const prevSortModel = !!prevState.sortModel && !!prevState.sortModel[0]
        ? prevState.sortModel[0]
        : {}

      return currentSortModel.field !== prevSortModel.field ||
        currentSortModel.sort !== prevSortModel.sort
    }

    const rowsDisplayedChanged = () => {
      return this.state.rows.length !== prevState.rows.length
    }

    // super inefficient, better if I can specify the fields instead to make this quick like the others
    const stateChanged = (field) => JSON.stringify(this.state[field]) !== JSON.stringify(prevState[field])

    if (pageChanged() || sortModelChanged() || stateChanged('filterValue')) {
      this.requestRowData()
    }
  }

  componentWillUnmount() {
    if (this.state.showLive) {
      clearInterval(this.state.hostStatusIntervalId);
    }
  }

  onFilterChange = (data) => {
    if (!!data && Array.isArray(data.items)) {
      this.setState({ filterValue: data.items[0] })
    }
  }

  render() {
    console.log('HostsTable render:', this.state.rows, this.state.rowCount)
    return (
      <div>
        <NewCustomDataGrid
          checkboxSelection={this.state.checkboxSelection}
          onSelectionModelChange={(newSelectionModel) => {
            this.props.setSelection(newSelectionModel);
          }}
          // intentionally not passing this in even tho we prob should
          // cause it slows stuff down like crazy and isn't necessary
          // selectionModel={this.props.selectionModel}
          onRowClick={(row) => {
            // move these to internal component functions?
            this.props.onRowClick(row.row)
          }}
          columns={this.state.columns}
          rows={this.state.rows || []}
          rowCount={this.state.rowCount}
          color='#1d98ff'
          showToolbar
          disableDensitySelector='true'
          disableSelectionOnClick
          autoHeight='true'
          density='compact'
          paginationMode={this.state.serverSidePagination ? 'server' : 'client'}
          sortingMode={this.state.serverSidePagination ? 'server' : 'client'}
          filterMode={this.state.serverSidePagination ? 'server' : 'client'}
          onPageChange={(newPage) => {
            // newPage is just an index 0, 1, 2, 3 (not the full page model)
            // I couldn't get onPaginationModelChange to trigger
            return this.setState({ page: Object.assign({}, this.state.page, { page: newPage }) })
          }}
          onPageSizeChange={(pageSize) => {
            console.log('onPageSizeChange: pageSize', pageSize)
            return this.setState({ page: Object.assign({}, this.state.page, { pageSize }) })
          }}
          // version 6 of MUI datagrid starts using this
          onPaginationModelChange={(paginationModel) => {
            console.log('onPaginationModelChange', paginationModel)
          }}
          // paginationModel={this.state.page}
          onFilterModelChange={this.onFilterChange}
          sortModel={this.state.sortModel}
          onSortModelChange={(data) => this.setState({ sortModel: data })}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    notification: state.notification,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setUser: data => { dispatch(setUser(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HostsTable))

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import axios from 'axios'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PersonIcon from '@material-ui/icons/Person'
import Rating from '@mui/material/Rating'

import styles from './LocalProviders.module.scss'
import LocalProvidersAlert from './LocalProvidersAlert'
import LocalProvidersList from './LocalProvidersList'
import LocalProvidersTable from './LocalProvidersTable'
import LocalProvidersTitle from './LocalProvidersTitle'
import LocalProvidersTop from './LocalProvidersTop'
import LocalProvidersZipAlert from './LocalProvidersZipAlert'

import { setLocalProviders } from '../../actions/connectionActions'


function countUnique(iterable) {
  return new Set(iterable).size
}

// https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation#comment358260_160583
function isValidUSAZipcode(zipcode) {
  return !!zipcode && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode)
}

function getFirst5Digits(zipcode) {
  return zipcode.substring(0, 5)
}

class LocalProviders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open:false,
    }
  }

  async componentDidMount(props) {
    if (!this.props.user || !this.props.user.address || !isValidUSAZipcode(this.props.user.address.zipcode)) {
      return this.props.setLocalProviders(null)
    }

    try {
      const localProviders = await axios.get(`${this.props.url}/${this.props.routes.data.localProviders}`, {
        params: {
          zipcode: getFirst5Digits(this.props.user.address.zipcode)
        }
      })

      if (localProviders.data.success && Array.isArray(localProviders.data.data)) {
        this.props.setLocalProviders(localProviders.data.data)
      }
    } catch (err) {
      console.log('Non US Zipcode:', err)
      this.props.setLocalProviders(null)
    }
  }

  getZipcode = (field) => {
    const addressDefaults = {
      zipcode: '',
    }

    if (!this.props.user) {
      return addressDefaults[field]
    }

    const addressValues = Object.assign({}, addressDefaults, this.props.user.address || {})
    return addressValues[field]
  }

  getISPDetails = (field) => {
    const ispDefaults = {
      providerIds: [],
      price: '',
      rating: '',
      downloadSpeed: '',
      uploadSpeed: '',
    }

    if (!this.props.user) {
      return ispDefaults[field]
    }

    const ispValues = Object.assign({}, ispDefaults, this.props.user.isp || {})
    return ispValues[field]
  }

  displayProfile = () => {
    return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card variant="outlined" className={ styles.card }>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                  <strong><center>My Info:</center></strong><br />
                    Zipcode: { this.getZipcode('zipcode') }<br />
                    Provider: { this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : '' }<br />
                    Price: ${ this.getISPDetails('price') }<br />
                    Download: { this.getISPDetails('downloadSpeed') } (mbps)<br />
                    Upload: { this.getISPDetails('uploadSpeed') } (mbps)<br />
                    Rating: <Rating name="read-only" style={{color:"#1d98ff"}} value={ this.getISPDetails('rating') } readOnly /><br />
                  </Typography>
                    <center>
                      <Link to="/profile/modify" className={ styles.link }>
                        <button className={ styles.button }>
                          <PersonIcon fontSize="small" style={{paddingRight: '5px'}}/>Modify
                        </button>
                      </Link>
                    </center>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }

  displayTableInformationAlert = () => {
    const { localProviders, user } = this.props
    const { isp } = user
    // we only let them view this page to begin with if they've filled out a rating
    // so we can assume if there's only 1 provider in the area, it's theirs
    const userOwnsOnlyRatingInArea = Array.isArray(localProviders) && localProviders.length === 1
    const ispInfoIsInProfile = isp.price && isp.downloadSpeed && isp.uploadSpeed && isp.rating && isp.providerIds[0]

    if (userOwnsOnlyRatingInArea) {
      return (
        <Alert severity="info" style={{width: '100%', textAlign: 'left', marginTop:'20px'}}>
          You're currently the only imUp user within 15 miles of your zipcode. If you're willing to
          share the app with your community and social media, you will see more results appear below.
          No pressure though, we just appreciate you being here.
        </Alert>
      )
    }

    if (!!localProviders && ispInfoIsInProfile) {
      return (
        <Alert severity="info" style={{width: '100%', textAlign: 'left', marginTop:'20px'}}>
          Below are records from real imUp users within 15 miles of your zipcode. You can see provider
          price and speed options as well as actual average download/upload speeds for each user if
          they're running our app. (User average speeds may vary due to wifi vs ethernet).
        </Alert>
      )
    }

    return null
  }

  render(props) {
    if (!this.props.user || !this.props.user.isp || !this.props.user.address.zipcode) {
      return (
        <div>
          <LocalProvidersAlert/>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="center" alignItems="center">
              {this.displayProfile()}
            </Grid>
          </Grid>
        </div>
      )
    }

    const { user, localProviders } = this.props
    const { address, isp } = user
    const ispInfoIsInProfile = isp.price && isp.downloadSpeed && isp.uploadSpeed && isp.rating && isp.providerIds[0]

    return (
      <div>
        { ispInfoIsInProfile ? <LocalProvidersTitle data={address.zipcode}/> : <LocalProvidersAlert/> }
        { !localProviders ? <LocalProvidersZipAlert/> : null }
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="center" alignItems="center">
          { !!localProviders && ispInfoIsInProfile ? <LocalProvidersList data={localProviders}/> : null }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} justifyContent="center" alignItems="center">
          { !!localProviders && ispInfoIsInProfile ? <LocalProvidersTop data={localProviders}/> : null }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="center" alignItems="center">
          {this.displayProfile()}
        </Grid>
        </Grid>
        {this.displayTableInformationAlert()}
        { !!localProviders && ispInfoIsInProfile ? <LocalProvidersTable data={localProviders}/> : null }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
    localProviders: state.localProviders,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocalProviders: (data) => { dispatch(setLocalProviders(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocalProviders))

import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
// const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2)
const valueFormatterTwoDecimals = ({ value }) => twoDecimals(value)
const valueFormatterSuccess = ({ value }) => value === 1
const valueFormatterNanoToMilliSeconds = ({ value }) => twoDecimals(value / 1000000)

// const valueFormatterNanoToMilliSeconds = ({ value }) => value / 1000000

const tickFormat = x => {
  const _d = new Date(x);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
};

const dateFormat = x => {
  const _d = new Date(x);
  // looks like: 09:45 PM

  if (_d === 'Invalid Date') {
    return x
  }

  const dateOptions = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const dateString = _d.toLocaleDateString([], dateOptions)
  const timeString = _d.toLocaleTimeString([], timeOptions)
  const dateTimeString = `${dateString} at ${timeString}`

  return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
};

const valueFormatterDate = ({ value }) => {
  const _d = new Date(value);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
}

function tooltipFormatter (value, name, props) {
  const labelNames = {
    packet_loss_avg: 'Packet Loss Avg',
  }

  const valueWithUnits = `${twoDecimals(value)}%`

  return [valueWithUnits, labelNames[name]]
}


// [ { "time": "2021-04-21T23:00:00.000Z", "success": 1, "packet_loss_avg": 0, "min_rtt": 8364688, "max_rtt": 101030778, "avg_rtt": 20873203.1 } ]

export default function PacketLossChart(props) {
  return !props.data || !Array.isArray(props.data) ? null : (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart width={730} height={250} data={props.data}>
          <XAxis
            tickMargin={15}
            dataKey="time"
            minTickGap={10}
            tickFormatter={tickFormat}
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
            reversed="true"
          />
          <YAxis
            stroke={props.theme === 'dark' ?  '#d3d3d3' : 'black'}
          />
          <Tooltip
            contentStyle={{backgroundColor: props.theme === 'dark' ?  '#303030' : 'white'}}
            formatter={tooltipFormatter}
            labelFormatter={dateFormat}
          />
          <Bar dataKey="packet_loss_avg" fill="#FE4C40" fillOpacity={1} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

import React, { Component } from 'react'
import styles from './home.module.scss'
import { makeStyles } from '@material-ui/styles';
import { Collapse, IconButton, Button, ButtonGroup, Card, CardContent, Grid, Typography, CardHeader, CardActionArea, CardActions, CardMedia, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Divider from '@mui/material/Divider';
import Chart from '../InternetConnectivity/chart'
import SpeedTestTable from '../SpeedTest/table'
import SpeedTestChart from '../SpeedTest/chart'
import Modal from './modal'
import TermsOfServiceModal from '../Downloads/modal'
import {
  setConnectivityData,
  setDowntimeData,
  setPingData,
  setPingChartData,
  setSpeedTestData,
  setGeolocationData,
  setSpeedTestAverage7d,
  setTermsOfServiceModalDisplayed,
  setDownloadOS,
  setStatsData,
  setSpeedTestStatusModalDisplayed,
  setSpeedTestStatusResults,
} from '../../actions/connectionActions'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StatsTableSpeedTest from './statsTableSpeedTest'
import StatsTableDowntime from './statsTableDowntime'
import PingDataTable from './pingDataTable'
import PingDataChart from '../PingDataChart/PingDataChart'
import PacketLossChart from './packetLossChart'
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Client from '../../resources/imup_client.png';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import isProfileComplete from './isProfileComplete';
import Help from './help';
import SpeedTestStatusModal from '../SpeedTestStatusModal/SpeedTestStatusModal';
import TimelineIcon from '@material-ui/icons/Timeline';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import SpeedIcon from '@material-ui/icons/Speed';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { Alert, AlertTitle } from '@material-ui/lab';
import DownloadModal from './downloadModal'
import Landing from './landing'
import CircularProgress from '@mui/material/CircularProgress';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';


/// testing
import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/// testing

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      exportInProgress: false,
    }
  }
  getISPDetails = (field) => {
    const ispDefaults = {
      provider: '',
      providerIds: [],
      supportEmail: '',
      price: 0,
      downloadSpeed: 0,
      uploadSpeed: 0,
      dataLimit: 0,
      speedThreshold: 0,
      accountNumber: '',
      username: '',
      password: '',
      pin: '',
      imupAuthorized: false,
      additionalDetails: ''
    }

    if (!this.props.user) {
      return ispDefaults[field]
    }

    const ispValues = Object.assign({}, ispDefaults, this.props.user.isp || {})
    return ispValues[field]
  }

  getNotificationDetails = (field) => {
    const notificationDefaults = {
      email: false,
    }

    if (!this.props.user) {
      return notificationDefaults[field]
    }

    const notificationValues = Object.assign({}, notificationDefaults, this.props.user.notifications || {})
    return notificationValues[field]
  }

  getAddressDetails = (field) => {
    const addressDefaults = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: ''
    }

    if (!this.props.user) {
      return addressDefaults[field]
    }

    const addressValues = Object.assign({}, addressDefaults, this.props.user.address || {})
    return addressValues[field]
  }

  shouldEnableHiddenFeatures = () => {
    return !!this.props.user && !!this.props.user.email && (this.props.user.email.endsWith('@imup.io') || this.props.user.email === 'brad7miller@gmail.com')
  }

  shouldEnablePaidFeatures = () => {
    return !!this.props.user && !!this.props.user.plan && this.props.user.plan !== 'reporting'
  }

  async getDowntimeData() {
    try {
      const downtimeData = await axios.get(`${this.props.url}/${this.props.routes.data.downtime}`)
      if (downtimeData && Number.isInteger(downtimeData.data.data.downtime)) {
        this.props.setDowntimeData(downtimeData.data.data)
        return downtimeData.data.data
      }
    } catch(e) {
      console.log('error getting downtime data', e)
    }

    return null
  }

  async getConnectivityData() {
    try {
      const connectivityData = await axios.get(`${this.props.url}/${this.props.routes.data.connectivity}`)
      if (connectivityData && connectivityData.data.success) {
        this.props.setConnectivityData(connectivityData.data.data)
        return connectivityData.data.data
      }
    } catch(e) {
      console.log('error getting connectivity data', e)
    }

    return null
  }

  async getSpeedTestData() {
    try {
      const speedtestData = await axios.get(`${this.props.url}/${this.props.routes.data.speedtest}`)
      if (speedtestData && speedtestData.data.success) {
        this.props.setSpeedTestData(speedtestData.data.data)
        return speedtestData.data.data
      }
    } catch(e) {
      console.log('error getting speed test data', e)
    }

    return null
  }

  async getSpeedTestAverage7d() {
    try {
      const speedtestAverage7d = await axios.get(`${this.props.url}/${this.props.routes.data.speedtestAverage}`)
      if (speedtestAverage7d && speedtestAverage7d.data.success) {
        this.props.setSpeedTestAverage7d(speedtestAverage7d.data.data)
        return speedtestAverage7d.data.data
      }
    } catch(e) {
      console.log('error getting speed test average data', e)
    }

    return null
  }

  async getStatsData() {
    try {
      // stats
      const statsData = await axios.get(`${this.props.url}/${this.props.routes.data.speedtestStats}`)
      console.log('statsData:', statsData)
      if (statsData && statsData.data && statsData.data.data) {
        this.props.setStatsData(statsData.data.data)
        return statsData.data.data
      }
    } catch(e) {
      console.log('error getting speed test stats data', e)
    }
  }

  // does not return data, just true if it succeeds given it puts everything in redux
  async getPingData() {
    try {
      // ping data
      const pingData = await axios.get(`${this.props.url}/${this.props.routes.data.pingData}`)

      if (!!pingData && pingData.data && pingData.data.data) {
        this.props.setPingData(pingData.data.data.map(p => {
          p.id = p.time
          p.latency = p.avg_rtt / 2
          return p
        }))

        this.props.setPingChartData(pingData.data.data.map(p => {
          return {
            ...p,
            avg_rtt: p.avg_rtt/1000000,
            latency: p.avg_rtt/2/1000000,
          }
        }))

        return true
      }
    } catch(e) {
      console.log('error getting ping stats data', e)
    }

    return null
  }

  async componentDidMount() {
    await Promise.all([
      this.getDowntimeData(),
      this.getConnectivityData(),
      this.getPingData(),
      this.getSpeedTestData(),
      this.getSpeedTestAverage7d(),
      this.getStatsData(),
    ])
  }

  showDownloads = () => {
    const shouldShow = ['Mac OS', 'Windows', 'Linux']
    return shouldShow.includes(this.props.operatingSystem)
  }

  runSpeedTest = async () => {
    this.props.setSpeedTestStatusModalDisplayed(true)
    try {
      const realtimeSpeedTest = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtest}`, {
        hosts: [this.props.user.email]
      })

      if (realtimeSpeedTest && realtimeSpeedTest.data.success) {
        const hostName = Array.isArray(realtimeSpeedTest.data.data) && !!realtimeSpeedTest.data.data[0] ? realtimeSpeedTest.data.data[0].host : ''
        console.log('successfully ran speed test on host', hostName)

        const checkSpeedTestStatus = async () => {
          console.log('checking realtime status on hostName:', hostName)
          try {
            const realtimeSpeedTestStatus = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtestStatus}`, {
              hosts: [hostName]
            })

            if (realtimeSpeedTestStatus && realtimeSpeedTestStatus.data && realtimeSpeedTestStatus.data.success && realtimeSpeedTestStatus.data.data && realtimeSpeedTestStatus.data.data.status) {
              this.props.setSpeedTestStatusResults(realtimeSpeedTestStatus.data.data.status)
            }
          } catch(e) {
            console.log('error checking realtime status:', e)
          }
        }

      const that = this
      async function runner(repeats = 1) {
        if (repeats > 0) {
          await checkSpeedTestStatus()
          setTimeout(() => runner(repeats - 1), 3000)
        } else {
          if (that.props.speedTestStatusResults) {
            const timedOutResults = that.props.speedTestStatusResults.map(r => ({
              host: r.host,
              status: r.status === 'pending' || r.status === 'running' ? 'timed out' : r.status
            }))

            that.props.setSpeedTestStatusResults(timedOutResults)
          }
        }
      }

      runner(100)
      } else {
        console.log('failed to run speed test')
      }
    } catch(e) {
      console.log('error running speed test')
      console.log(e)
    }
  }

  runExport = (exportType) => async () => {
    this.setState({
      exportInProgress: true
    })

    try {
      axios({
        url: `${this.props.url}/${this.props.routes.data.export}`,
        method: 'GET',
        // blob is what allows the file to be downloaded
        responseType: 'blob',
        params: {
          type: exportType,
        },
      }).then((response) => {
        if (!!response) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.props.user.email}-${exportType}.zip`)
          document.body.appendChild(link)
          link.click()

          this.setState({
            exportInProgress: false
          })
        } else {
          console.log(`error exporting user ${exportType} data, response null`)
          this.setState({
            exportInProgress: false
          })
        }
      })
    } catch (e) {
      console.log(`error exporting user ${exportType} data`, e)
      this.setState({
        exportInProgress: false
      })
    }
  }
  displayChart = () => {
    const { connectivityData } = this.props
    if (Array.isArray(connectivityData) && connectivityData.length > 0) {
      return (
        <div style={{marginBottom:'20px'}}>

            {Chart(connectivityData, this.props.theme)}
        </div>
      )
    }
    return null
  }

  displayAverages = () => {
    const { connectivityData, speedTestData, user } = this.props
    if (!user || !connectivityData) {
      return null
    }

    const hasConnectivityData = Array.isArray(connectivityData) && connectivityData.length > 0
    const hasSpeedTestData = Array.isArray(speedTestData) && speedTestData.length > 0
    const hasAnyData = hasConnectivityData || hasSpeedTestData
    const displayDownloadStats = this.props.statsData && this.props.statsData.stats && Array.isArray(this.props.statsData.stats.download) && this.props.statsData.stats.download.length > 0
    const displayDowntimeStats = this.props.statsData && Array.isArray(this.props.statsData.downtimeStats) && this.props.statsData.downtimeStats.length > 0
    const displayUploadStats = this.props.statsData && this.props.statsData.stats && Array.isArray(this.props.statsData.stats.upload) && this.props.statsData.stats.upload.length > 0
    const speedtestDownloadStatsContainer = () => {
      if (hasAnyData) {
        return (
          <Grid container spacing={3} style={{marginTop: '0px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!displayDownloadStats ? null : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Download Speeds</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <StatsTableSpeedTest data={this.props.statsData.stats.download}/>
                  </AccordionDetails>
                </Accordion>
              )}

              {!displayUploadStats ? null : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Upload Speeds</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <StatsTableSpeedTest data={this.props.statsData.stats.upload}/>
                  </AccordionDetails>
                </Accordion>
              )}

              {!displayDowntimeStats ? null : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Days Offline</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <StatsTableDowntime statsData={this.props.statsData}/>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        )

      }
return null
    }

    if (displayDownloadStats || displayDowntimeStats || displayUploadStats)  {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {(displayDownloadStats || displayDowntimeStats || displayUploadStats) ? speedtestDownloadStatsContainer() : null}
        </Grid>
      </Grid>
      )
    }
    return null
  }

  // displayTimeline = () => {
  //   const { connectivityData, speedTestData, user } = this.props
  //   if (!user || !connectivityData) {
  //     return null
  //   }

  //   const hasConnectivityData = Array.isArray(connectivityData) && connectivityData.length > 0
  //   const hasSpeedTestData = Array.isArray(speedTestData) && speedTestData.length > 0
  //   const hasAnyData = hasConnectivityData || hasSpeedTestData

  //   if (user && Array.isArray(user.appTermsOfService) && user.appTermsOfService.length > 0 && isProfileComplete(user)('isp') && hasAnyData) {
  //     return null
  //   }
  //     return (
  //       <Grid container >
  //         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  //         <Divider variant="middle" style={{margin:'15px'}}/>
  //         <Typography variant="body1" component="h2" gutterBottom>
  //           <strong>My Progress</strong>
  //         </Typography>
  //           <Timeline position="alternate">
  //             <TimelineItem>
  //               <TimelineSeparator>
  //                 <TimelineDot className= { this.props.connectivityData === 0 ? styles['int-green'] : styles['int-green'] } variant="outlined">
  //                   <CheckIcon className={styles['int-green']}/>
  //                 </TimelineDot>
  //                 <TimelineConnector />
  //               </TimelineSeparator>
  //               <TimelineContent>
  //                   <Typography variant="body1" component="h1" className={styles.paperTitle}>
  //                     <Link to="/dashboard" className= { this.props.connectivityData === 0 ? styles.int : styles['int-green'] }>Register</Link>
  //                   </Typography>
  //               </TimelineContent>
  //             </TimelineItem>
  //             <TimelineItem>
  //               <TimelineSeparator>
  //                 <TimelineDot className= { this.props.connectivityData === 0 ? styles.int : styles['int-green'] } variant="outlined">
  //                   <LaptopMacIcon className={user && Array.isArray(user.appTermsOfService) && user.appTermsOfService.length > 0 ? styles['int-display-none'] : styles.int}/>
  //                   <CheckIcon className={user && Array.isArray(user.appTermsOfService) && user.appTermsOfService.length > 0 ?  styles['int-green'] : styles['int-display-none']}/>
  //                 </TimelineDot>
  //                 <TimelineConnector />
  //               </TimelineSeparator>
  //               <TimelineContent>
  //                   <Typography variant="body1" component="h1" className={styles.paperTitle}>
  //                     {/* if they've agreed to terms they've downloaded the app */}
  //                     <Link
  //                       to="/downloads"
  //                       className={user && Array.isArray(user.appTermsOfService) && user.appTermsOfService.length > 0 ? styles['int-green'] : styles.int}
  //                     >
  //                       Download
  //                     </Link>
  //                   </Typography>
  //               </TimelineContent>
  //             </TimelineItem>
  //             <TimelineItem>
  //               <TimelineSeparator>
  //                 <TimelineDot className={isProfileComplete(user)('isp') && isProfileComplete(user)('isp') ? styles['int-green'] : styles.int} variant="outlined">
  //                   <PersonIcon className={isProfileComplete(user)('isp') ? styles['int-display-none'] : styles.int}/>
  //                   <CheckIcon className={isProfileComplete(user)('isp') ? styles['int-green'] : styles['int-display-none']}/>
  //                 </TimelineDot>
  //                 <TimelineConnector className={styles.secondaryTail} />
  //               </TimelineSeparator>
  //               <TimelineContent>
  //                   <Typography variant="body1" component="h1" className={styles.paperTitle}>
  //                     <Link
  //                       to="/profile"
  //                       className={isProfileComplete(user)('isp') ? styles['int-green'] : styles.int}
  //                     >
  //                       Profile
  //                     </Link>
  //                   </Typography>
  //               </TimelineContent>
  //             </TimelineItem>
  //             <TimelineItem>
  //               <TimelineSeparator>
  //                 <TimelineDot variant="outlined">
  //                   <SubscriptionsIcon className={user && user.plan !== 'reporting' ? styles['int-display-none'] : styles.int  }/>
  //                   <CheckIcon className={user && user.plan !== 'reporting' ? styles['int-green'] : styles['int-display-none']}/>
  //                 </TimelineDot>
  //                 <TimelineConnector />
  //               </TimelineSeparator>
  //               <TimelineContent>
  //                   <Typography variant="body1" component="h1" className={styles.paperTitle}>
  //                     <Link
  //                       to="/plans"
  //                       className={user && user.plan !== 'reporting' ? styles['int-green'] : styles.int }
  //                     >
  //                       Subscribe
  //                     </Link>
  //                   </Typography>
  //                   <Typography variant="subscript" component="body2" className={styles.paperTitle}>
  //                   (optional)
  //                   </Typography>
  //               </TimelineContent>
  //             </TimelineItem>
  //             <TimelineItem>
  //               <TimelineSeparator>
  //                 <TimelineDot className= { hasAnyData ? styles['int-green'] : styles.int } variant="outlined">
  //                   <RepeatIcon className= { hasAnyData ? styles['int-green'] : styles.int }/>
  //                   <CheckIcon className={ hasAnyData ? styles['int-green'] : styles['int-display-none']}/>
  //                 </TimelineDot>
  //               </TimelineSeparator>
  //               <TimelineContent>
  //                   <Typography variant="body1" component="h1" className={styles.paperTitle}>
  //                     <Link to="/" className=  {hasAnyData ? styles['int-green'] : styles.int }>See your metrics</Link>
  //                   </Typography>
  //               </TimelineContent>
  //             </TimelineItem>
  //           </Timeline>
  //           <Typography variant="body2" component="h1" style={{textDecoration: 'italic'}}>
  //             <center>Progress will disappear once complete</center>
  //             <Divider variant="middle" style={{margin:'15px'}}/>
  //           </Typography>
  //       </Grid>
  //     </Grid>
  //     )
  // }


  displayIPAddress = () => {
    if (!this.props.geolocationData) {
      return null
    }

    // not sure what's happening here...
    const { userIP } = this.props.geolocationData.data ? this.props.geolocationData.data : this.props.geolocationData

    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Current IP Address
          </Typography>
          {userIP}
        </CardContent>
      </Grid>
    )
  }


displayProfile = () => {
  const { connectivityData, user } = this.props
    return (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Divider variant="middle" style={{margin:'15px'}}/>
        <Card className={styles.card}>
            <CardContent>
            <Typography variant="body1" component="h2" gutterBottom>
              <center><strong>My Info</strong></center>
            </Typography>
              <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Details:</strong><br />
                    Zipcode: { this.getAddressDetails('zipcode') }<br />
                    Provider: { this.getISPDetails('providerIds')[0] ? this.getISPDetails('providerIds')[0].name : '' }<br />
                    Download: { this.getISPDetails('downloadSpeed') }(mbps)<br />
                    Upload: { this.getISPDetails('uploadSpeed') }(mbps)<br />
                    Threshold: { this.getISPDetails('speedThreshold')}(mbps)<br />
                    Price: { this.getISPDetails('price') }<br /><br />
                    <strong>Notifications:</strong> <br />
                    Email: { !!this.getNotificationDetails('email') ? 'On' : 'Off' }
                  </Typography>
                    <center><Link to="/profile/modify" className={styles.link}><button className={ styles.button }><PersonIcon fontSize="small" style={{paddingRight: '5px'}}/>Modify</button></Link></center>
                </Grid>
              </Grid>
            </CardContent>
            </Card>
            <Divider variant="middle" style={{margin:'15px'}}/>
        </Grid>
      </Grid>
    )

}

  displayGeolocationData = () => {
    if (!this.props.geolocationData) {
      return null
    }

    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    // not sure what's happening here...
    const { country, region, city } = this.props.geolocationData.data ? this.props.geolocationData.data : this.props.geolocationData

    return (
      <Grid container justify="center" item xl={3} lg={3} md={3} xs={12} spacing={3}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Location
          </Typography>
          {capitalize(city)}, {region.toUpperCase()}, {country}
        </CardContent>
      </Grid>
    )
  }

  displaySpeedTestDownloadAverage = () => {
    if (!this.props.speedTestAverage7d.download || !this.props.speedTestAverage7d.download[0] || !this.props.speedTestAverage7d.download[0].mean) {
      return null
    }
    console.log('last', this.props)
    return (
        <CardContent style={{ paddingBottom:'0px'}}>
          <Grid container justifyContent="right" alignItems="center">
            <Typography variant="body2" component="body1" gutterBottom>
              <strong>Avg Download</strong>
            </Typography><br /><br />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography style={{color: '#1d98ff'}} variant="h5" component="h2" gutterBottom>
                {this.props.speedTestAverage7d.download[0].mean.toFixed(2).toString()} <Typography variant="body2" component="body1" gutterBottom>
                   Mbps
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CloudDownloadIcon style={{width:'100%', height:'100%', color: '#1d98ff'}}/>
            </Grid>
          </Grid>
        </CardContent>
    )
  }

  displayRecentSpeeds = () => {
    const dateFormat = x => {
      const _d = new Date(x);
      // looks like: 09:45 PM

      if (_d === 'Invalid Date') {
        return x
      }

      const dateOptions = {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }

      const timeOptions = {
        hour: '2-digit',
        minute:'2-digit'
      }

      const dateString = _d.toLocaleDateString([], dateOptions)
      const timeString = _d.toLocaleTimeString([], timeOptions)
      const dateTimeString = `${dateString} at ${timeString}`

      return dateTimeString !== 'Invalid Date at Invalid Date' ? dateTimeString : x
    }

    const tickFormat = x => {
      const _d = new Date(x);

      // looks like: 09:45 PM
      const timeOptions = {
        hour: '2-digit',
        minute:'2-digit'
      }

      const timeString = _d.toLocaleTimeString([], timeOptions)
      var mm = _d.getMonth() + 1;
      var dd = _d.getDate();
      return `${mm}/${dd} ${timeString}`
    };

    if (!this.props.user || !this.props.user.isp || !Array.isArray(this.props.speedTestData) || !this.props.speedTestData[0]) {
      return null
    }
    const desktopOS = ['Mac OS', 'Windows', 'Linux']
    const isMobile = !desktopOS.includes(this.props.operatingSystem)
    const isSlowRatioTotal = ((( this.props.speedTestData[0].download_mbps / this.props.user.isp.downloadSpeed )* 100).toFixed(0).toString() )
    const isSlowDifference = ((this.props.user.isp.speedThreshold - this.props.speedTestData[0].download_mbps).toFixed(0).toString() )
    const isSlow = ( this.props.speedTestData[0].download_mbps < this.props.user.isp.speedThreshold )

    const { speedTestData, userLivenessStatus } = this.props

    const livenessStatusExists =  Array.isArray(userLivenessStatus) && userLivenessStatus.length > 0
    const enableSpeedTestButton = livenessStatusExists && !!userLivenessStatus[0].status
    return (
      <div>
      <Card className={styles.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography variant="body2" component="body2" gutterBottom>
                    <strong>Last Test: </strong>
                  </Typography>
                  <Typography variant="body2" component="body2" gutterBottom>
                    {tickFormat(this.props.speedTestData[0].time)}
                  </Typography>
                  <Typography style={{color: '#1d98ff', marginTop:'10px'}} variant="h6" component="h6" gutterBottom>
                    {this.props.speedTestData[0].download_mbps.toFixed(2).toString()}
                    <Typography variant="body2" component="body1" gutterBottom>
                        Mbps (down)
                    </Typography>
                  </Typography>
                  <Typography style={{color: 'rgb(245, 59, 255)'}} variant="h6" component="h6" gutterBottom>
                    {this.props.speedTestData[0].upload_mbps.toFixed(2).toString()}
                    <Typography variant="body2" component="body1" gutterBottom>
                        Mbps (up)
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Tooltip title={this.getRemoteSpeedTestTooltipText(enableSpeedTestButton)}>
            <center><button
              className={styles.button}
              onClick={this.runSpeedTest}
              disabled={!this.shouldEnablePaidFeatures() || !enableSpeedTestButton}
            >
              <SpeedIcon fontSize="small" style={{paddingRight: '5px'}}/>Run speed test
            </button></center>
          </Tooltip>
        </CardContent>

        { isSlow ?
          <Alert
            severity="warning"
            style={{
              textAlign: 'left',
              marginBottom: '10px',
              marginLeft: '10px',
              marginRight: '10px'
            }}
          >
            <AlertTitle style={{textAlign: 'left'}}>Slow test</AlertTitle>
            Your last speed test was only {isSlowRatioTotal}% of the speeds you pay for.
          </Alert> : null
        }
        </Card>
      </div>
    )
  }

  displaySpeedTestUploadAverage = () => {
    if (!this.props.speedTestAverage7d.upload || !this.props.speedTestAverage7d.upload[0] || !this.props.speedTestAverage7d.upload[0].mean) {
      return null
    }

    return (
        <CardContent style={{ paddingBottom:'0px'}}>
          <Grid container justifyContent="right" alignItems="center">
            <Typography variant="body2" component="body1" gutterBottom>
              <strong>Avg Upload</strong>
            </Typography><br /><br />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography style={{color: 'rgb(245, 59, 255)'}} variant="h5" component="h2" gutterBottom>
                {this.props.speedTestAverage7d.upload[0].mean.toFixed(2).toString()} <Typography variant="body2" component="body1" gutterBottom>
                   Mbps
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CloudUploadIcon style={{width:'100%', height:'100%', color: 'rgb(245, 59, 255)'}}/>
            </Grid>
          </Grid>
        </CardContent>
    )
  }


  displayMobileTop = () => {
    const { connectivityData, downtimeData, speedTestData, speedTestAverage7d, user  } = this.props
    const desktopOS = ['Mac OS', 'Windows', 'Linux']
    const isMobile = !desktopOS.includes(this.props.operatingSystem)

    if (!user || !Array.isArray(speedTestData) || !speedTestData[0]) {
      return null
    }

    const isSlowRatioTotal = !user.isp || !speedTestAverage7d || !speedTestAverage7d.download ? '' : ((( speedTestAverage7d.download[0].mean / user.isp.downloadSpeed )* 100).toFixed(0).toString() )
    const isSlowDifference = !user.isp || !speedTestAverage7d || !speedTestAverage7d.download ? '' : ((user.isp.speedThreshold - speedTestAverage7d.download[0].mean).toFixed(0).toString() )
    const isSlow = !user.isp || !speedTestAverage7d || !speedTestAverage7d.download ? false : (speedTestAverage7d.download[0].mean < user.isp.speedThreshold)


    if (!Array.isArray(connectivityData) || connectivityData.length === 0 || isMobile ) {
      return (
        <div>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
            {speedTestAverage7d ? this.displayRecentSpeeds() : null}
            {isMobile ?
              <Card className={styles.card}>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                  <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom style={{marginLeft:'10px'}}>
                      <strong>Average Speeds: </strong>
                    </Typography>
                    {speedTestAverage7d ? this.displaySpeedTestDownloadAverage() : null}
                    {speedTestAverage7d ? this.displaySpeedTestUploadAverage() : null}
                    { isSlow ?
                      <Alert severity="error" style={{width: '100%', textAlign: 'left', marginTop: '10px'}}>
                        <AlertTitle style={{textAlign: 'left'}}>Slow Averages</AlertTitle>
                        Your average speed tests are only {isSlowRatioTotal}% of the speeds you pay for. <br />
                      </Alert> : null
                    }
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            :
            null
            }
            </Grid>
          </Grid>
        </CardContent>
      </div>
    )
  }
  }
  displayTopCards = () => {
    const { connectivityData, downtimeData, speedTestData, speedTestAverage7d, user  } = this.props
    const desktopOS = ['Mac OS', 'Windows', 'Linux']
    const isMobile = !desktopOS.includes(this.props.operatingSystem)

    if (!user || !Array.isArray(speedTestData) || !speedTestData[0] || isMobile) {
      return null
    }

  // if (!downtimeData) {
  //   return null
  // }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
    >
      {this.shouldEnablePaidFeatures() ? (
        <br />
      ) : null}
      {!downtimeData ? null : (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card variant="outlined" className={ styles.card }>
          <CardContent style={{ paddingBottom:'0px'}}>
            <Grid container justifyContent="right" alignItems="stretch" direction="row">
              <Typography variant="body2" component="body1" gutterBottom>
                <strong>Total downtime</strong>
              </Typography><br /><br />
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Typography className= { downtimeData.downtime === 0 ? styles['int-green'] : styles.int } variant="h5" component="h2" gutterBottom>
                {downtimeData.downtime} <Typography variant="body2" component="body1" gutterBottom>
                    min
              </Typography>
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <TrendingDownIcon style={{width:'100%', height:'100%'}} className= { downtimeData.downtime === 0 ? styles['int-green'] : styles.int }/>
            </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      )}
      {!downtimeData ? null : (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card variant="outlined" className={ styles.card }>
          <CardContent style={{ paddingBottom:'0px'}}>
            <Grid container justifyContent="right" alignItems="center">
              <Typography variant="body2" component="body1" gutterBottom>
                <strong>Week's downtime</strong>
              </Typography><br /><br />
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography className= { downtimeData.downtime30d === 0 ? styles['int-green'] : styles.int } variant="h5" component="h2" gutterBottom>
                  {downtimeData.downtime30d} <Typography variant="body2" component="body1" gutterBottom>
                      min
                </Typography>
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <TrendingDownIcon style={{width:'100%', height:'100%'}} className= { downtimeData.downtime30d === 0 ? styles['int-green'] : styles.int }/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      )}
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card variant="outlined" className={ styles.card }>
            {/*
            {geolocationData ? this.displayIPAddress() : null}
            {geolocationData ? this.displayGeolocationData() : null}
            */}
            {speedTestAverage7d ? this.displaySpeedTestDownloadAverage() : null}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card variant="outlined" className={ styles.card }>
            {/*
            {geolocationData ? this.displayIPAddress() : null}
            {geolocationData ? this.displayGeolocationData() : null}
            */}
            {speedTestAverage7d ? this.displaySpeedTestUploadAverage() : null}
        </Card>
      </Grid>
    </Grid>
  )
}

  getRemoteSpeedTestTooltipText = (enableSpeedTestButton) => {
    if (this.shouldEnablePaidFeatures()) {
      return enableSpeedTestButton ? 'Run a speed test now!' : 'Your computer needs to be online to run a speed test!'
    }

    return 'Upgrade now for on-demand remote speed testing!'
  }

  displaySpeedTestTable = () => {
    const { speedTestData, userLivenessStatus } = this.props
    const desktopOS = ['Mac OS', 'Windows', 'Linux']
    const isMobile = !desktopOS.includes(this.props.operatingSystem)
    const livenessStatusExists =  Array.isArray(userLivenessStatus) && userLivenessStatus.length > 0
    const enableSpeedTestButton = livenessStatusExists && !!userLivenessStatus[0].status

    if (Array.isArray(speedTestData) && speedTestData.length > 0) {
      const stArray = this.props.user && this.props.user.plan !== 'reporting'
        ? speedTestData
        : speedTestData.concat([{
          time: 'To view older data...',
          download_mbps: <Link to="/plans" style={{color: '#1D98FF'}}>Upgrade plan for $1</Link>,
          latency_ms: <Link to="/plans" style={{color: '#1D98FF'}}>Upgrade plan for $1</Link>,
          upload_mbps: <Link to="/plans" style={{color: '#1D98FF'}}>Upgrade plan for $1</Link>
        }])
      return (
        <div>
          <Card variant="outlined" className={ styles.card } style={{marginBottom:'20px'}}>
            <CardHeader
              action={
                <ButtonGroup variant="outlined">
                  <Tooltip title={this.getRemoteSpeedTestTooltipText(enableSpeedTestButton)}>
                    <button
                      className={styles.button}
                      onClick={this.runSpeedTest}
                      disabled={!this.shouldEnablePaidFeatures() || !enableSpeedTestButton}
                    >
                    <SpeedIcon fontSize="small" style={{paddingRight: '5px'}}/>{isMobile ? '' : 'Run speed test'}</button>
                  </Tooltip>
                  <Tooltip title={this.shouldEnablePaidFeatures() ? 'Export & Download Speed Test Data' : 'Upgrade now for data exports!'}><button className={ styles.button } onClick={this.runExport('speedtest')} disabled={!this.shouldEnablePaidFeatures() || this.state.exportInProgress}>
                    <DownloadIcon fontSize="small"/>
                  </button></Tooltip>
                </ButtonGroup>
              }
              subheader="Speed Tests"
              className={styles.cardHeader}
            />
                <SpeedTestChart data={speedTestData} theme={this.props.theme}/>
                <br />
                <Accordion style={{margin:'10px'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Speed Test Metrics Table</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SpeedTestTable data={stArray}/>
                  </AccordionDetails>
                </Accordion>
            </Card>
        </div>
      )
    }
    return null
  }

  displayTermsOfServiceModal = (os) => () => {
    this.props.setDownloadOS(os)
    this.props.setTermsOfServiceModalDisplayed(true)
  }

  downloadsButton = () => {
    const { downloadingClient } = this.state
    const downloadBtnColor = downloadingClient ? 'disabled' : 'primary'

    return (
      <Typography component="p">
        <button className={ styles.button } style={{ display:'inherit'}} disabled={downloadingClient} onClick={this.displayTermsOfServiceModal(this.props.operatingSystem)}>Download for {this.props.operatingSystem}</button>
      </Typography>
    )
  }

  loginOnComputer = () => {
    return (
      <Link to="/profile/modify" className={ styles.link }><button className={ styles.button } style={{display:'inherit', textDecoration: 'none'}}>Download</button></Link>
    )
  }

  displayDownloadCard = () => {
    const { connectivityData, speedTestData } = this.props
    const hasSpeedTestData = Array.isArray(speedTestData) && speedTestData.length > 0
    const hasConnectivityData = Array.isArray(connectivityData) && connectivityData.length > 0

    if (hasSpeedTestData || hasConnectivityData) {
      return null
    }

    return (
      <center>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} >
            <Alert severity="success" style={{width: '100%', textAlign: 'left', marginLeft:'10px', marginRight:'10px', marginTop: '10px'}}>
              <AlertTitle style={{textAlign: 'left'}}>Download successful</AlertTitle>
                Metrics should begin to appear in your dashboard 15 minutes after starting the app on your computer. Feel free to take this time to fill out your ISP profile <Link className={styles.link} to="/profile">here</Link>
            </Alert>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3} style={{ justifyContent: 'center', alignItems: 'center', marginTop:'4em' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  style={{justifyContent: 'center'}}>
              <center>
              <DesktopAccessDisabledIcon style={{fontSize: '100px', color: '#1D98FF'}}/><br /><br />
              <center><CircularProgress /></center>
                <h1>Waiting for data</h1>
                <p>Once your computer has successfully sent connectivity data to our servers, your metrics will appear here.</p>
              </center>
          </Grid>
        </Grid>
      </Grid>
    </center>
    )
  }


  displayPingStatsTable = () => {
    const { pingData, pingChartData, theme } = this.props
    if (Array.isArray(pingData) && pingData.length > 0) {
      return (
        <div>
        <Card variant="outlined" className={ styles.card }>
          <CardHeader
            action={
              <Tooltip title={this.shouldEnablePaidFeatures() ? 'Export & Download Connectivity Data' : 'Upgrade now for data exports!'}><button className={ styles.button } onClick={this.runExport('connectivity')} disabled={!this.shouldEnablePaidFeatures() || this.state.exportInProgress}>
                <DownloadIcon fontSize="small"/></button></Tooltip>
            }
            subheader="Connection Health"
            className={styles.cardHeader}
          />
          <PingDataChart data={pingChartData} theme={theme} />
          <CardHeader
            subheader="Packet Loss"
            className={styles.cardHeader}
          />
          <PacketLossChart data={pingData} theme={theme} />
          <br />
          <Accordion style={{margin:'10px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Connection Health Metrics Table</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PingDataTable data={pingData} />
            </AccordionDetails>
          </Accordion>
        </Card>
        </div>
      )
    }
    return null
  }

  displayHelp = () => {
    console.log('Profile.js this.props.org', this.props.org)
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Help/>
        </Grid>
      </Grid>
    )
  }
  displayUpgrade = (theme, props) => {
    if (this.props.user && !!this.props.user.plan && this.props.user.plan !== 'reporting'){
      return null
    }
    return (
      <Card variant="outlined" className={ styles.card }>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                <center>
                  Want more features?
                </center>
              </Typography>
              <Typography variant="body2" component="p">
                <center>
                  <strong>
                    Upgrade your plan.
                  </strong>
                </center>
              </Typography>
              <Typography variant="caption" component="p">
                <center>
                  For $1/month you get:
                </center>
              </Typography>
              <br />
              <Typography variant="body2" component="p">
                  <CalendarIcon style={{color: '#f53bff'}}/> 2 weeks of metrics<br />
                  <CloudDownloadIcon style={{color: '#f53bff'}}/> Export all your data<br />
                  <SpeedIcon style={{color: '#f53bff'}}/> Remote speed testing<br />
                  <MailOutlineIcon style={{color: '#f53bff'}}/> Slow speeds & offline notifications<br />
              </Typography>
            </CardContent>
            <CardActions style={{ display:'inherit'}}>
              <Link to="/plans"><center><button className={ styles.button2 }><center>Upgrade!</center></button></center></Link>
            </CardActions>
        </Grid>
      </Grid>
      </Card>
    )
  }

  render() {
    const { connectivityData, speedTestData } = this.props
    const hasSpeedTestData = Array.isArray(speedTestData) && speedTestData.length > 0
    const hasConnectivityData = Array.isArray(connectivityData) && connectivityData.length > 0

    if (hasSpeedTestData || hasConnectivityData || !!this.props.user && this.props.user.appTermsOfService.length > 0 && !this.state.downloadingClient) {
      return (
        <div className={ styles.home }>
          {/*
          {
            user && !user.active ?
            <RegistrationFinish></RegistrationFinish> :
            null
          }
          */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9} justifyContent="center" alignItems="center">
              { this.props.user ? <Modal /> : null }
              { this.props.user ? <TermsOfServiceModal /> : null }
              { this.props.user ? <SpeedTestStatusModal /> : null }
              {this.displayMobileTop()}
              {this.displayTopCards()}
              {this.displayDownloadCard()}
              {this.displayChart()}
              {this.displaySpeedTestTable()}
              {this.displayPingStatsTable()}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="center" alignItems="center">
              {this.displayRecentSpeeds()}
              {this.displayProfile()}
              {this.displayAverages()}
              {this.displayUpgrade()}
              {/* {this.displayTimeline()} */}
              {this.displayHelp()}
            </Grid>
          </Grid>
        </div>
      )
    }
    return (
      <Landing/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    routes: state.routes,
    user: state.user,
    connectivityData: state.connectivityData,
    downtimeData: state.downtimeData,
    speedTestData: state.speedTestData,
    speedTestAverage7d: state.speedTestAverage7d,
    operatingSystem: state.operatingSystem,
    theme: state.theme,
    geolocationData: state.geolocationData,
    termsOfServiceModalDisplayed: state.termsOfServiceModalDisplayed || false,
    statsData: state.statsData,
    pingData: state.pingData,
    pingChartData: state.pingChartData,
    userLivenessStatus: state.userLivenessStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConnectivityData: data => { dispatch(setConnectivityData(data)) },
    setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
    setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
    setDowntimeData: data => { dispatch(setDowntimeData(data)) },
    setGeolocationData: data => { dispatch(setGeolocationData(data)) },
    setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
    setDownloadOS: data => { dispatch(setDownloadOS(data)) },
    setStatsData: data => { dispatch(setStatsData(data)) },
    setPingData: data => { dispatch(setPingData(data)) },
    setPingChartData: data => { dispatch(setPingChartData(data)) },
    setSpeedTestStatusModalDisplayed: data => { dispatch(setSpeedTestStatusModalDisplayed(data)) },
    setSpeedTestStatusResults: data => { dispatch(setSpeedTestStatusResults(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))

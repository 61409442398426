import React, { Component } from 'react'
// import AddIcon from '@material-ui/icons/Add'
import HelpIcon from '@material-ui/icons/Help';
import { Fab } from '@material-ui/core'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { setModalDisplayed } from '../../actions/connectionActions'

// not sure why styles isn't working with the fab button...
import styles from './navigation.module.scss'

class FabBtn extends Component {
  handleClose = () => {
    this.props.setModalDisplayed(false)
  }

  handleClick = () => {
    if (this.props.modalDisplayed) {
      this.props.setModalDisplayed(false)
    } else {
      this.props.setModalDisplayed(true)
    }
  }

  render() {
    // const { user } = this.props

    return (
      <button
        className={ styles.button }
        onClick={this.handleClick}
      >
        <HelpIcon />
      </button>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modalDisplayed: state.modalDisplayed || false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setModalDisplayed: data => { dispatch(setModalDisplayed(data)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FabBtn))

import React, { Component } from 'react'
import styles from './organization.module.scss'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setOrganization, setOrganizationUsers } from '../../actions/connectionActions'
import AddUsersDataTable from './addUsersDataTable'
import APIKeysDataTable from './apiKeysDataTable'
import APIKeyForm from './apiKeyForm'
import axios from 'axios'

class Organization extends Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadingClient: false,
      addUsersOpen: false,
      manageUsersOpen: false,
      manageAPIKeysOpen: false,
      addUserIds: [],
      manageUserIds: [],
      selectedAPIKeys: [],
      orgId: this.props.match.params.id,
    }
  }

  async componentDidMount() {
    // alert(JSON.stringify(this.state, null, 2))
    const organization = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id:  this.state.orgId } })
    if (organization && organization.data.success) {
      this.props.setOrganization(organization.data.data)
    }

    const organizationUsers = await axios.get(`${this.props.url}/${this.props.routes.organization.getUsers}`, { params: { id:  this.state.orgId } })
    if (organizationUsers && organizationUsers.data.success) {
      this.props.setOrganizationUsers(organizationUsers.data.data)
    }
  }

  addUsers = async () => {
    const organizationUsers = await axios.post(`${this.props.url}/${this.props.routes.organization.addUsers}`, {
      userIds: this.state.addUserIds,
      orgId: this.state.orgId
    })

    if (organizationUsers && organizationUsers.data.success) {
      this.props.setOrganizationUsers(organizationUsers.data.data)

      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Added users to org!'
      })
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Adding users to org failed!',
        message: 'Try again later!'
      })
    }
  }

  deleteUsers = async () => {
    const organizationUsers = await axios.post(`${this.props.url}/${this.props.routes.organization.deleteUsers}`, {
      userIds: this.state.manageUserIds,
      orgId: this.state.orgId
    })

    if (organizationUsers && organizationUsers.data.success) {
      this.props.setOrganizationUsers(organizationUsers.data.data)

      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Deleted users from org!'
      })
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Deleting users from org failed!',
        message: 'Try again later!'
      })
    }
  }

  displayOrganizationInfo = () => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid container item xl={12} lg={12} md={12} xs={12} spacing={3}>
            <Card className={ styles.card }>
              <CardContent>
                <pre>
                {JSON.stringify(this.props.organization, null, 2)}
                </pre>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  displayOrganizationUsers = () => {
    if (!Array.isArray(this.props.organizationUsers)) {
      return null
    }

    return (
      <div>
        <Grid container spacing={1}>
          <Grid container item xl={12} lg={12} md={12} xs={12} spacing={3}>
            <Card className={ styles.card }>
              <CardContent>
                <AddUsersDataTable data={this.props.organizationUsers.map(u => { u.id = u._id; return u})} setUserSelection={this.setManageUserSelection} />
                <br />
                <br />

                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.deleteUsers}
                >
                  Delete users
                </Button>
                {/*<OrganizationUsersTable data={this.props.organizationUsers} /> */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  addUsersTable = () => {
    if (!Array.isArray(this.props.users)) {
      return null
    }

    return (
      <div>
        <Grid container spacing={1}>
          <Grid container item xl={12} lg={12} md={12} xs={12} spacing={3}>
            <Card className={ styles.card }>
              <CardContent>
                <AddUsersDataTable data={this.props.users.map(u => { u.id = u._id; return u})} setUserSelection={this.setAddUserSelection} />
                <br />
                <br />
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={this.addUsers}
                >
                  Add users
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  setAddUserSelection = (rowIds) => {
    this.setState({
      addUserIds: rowIds
    })
  }

  setManageUserSelection = (rowIds) => {
    this.setState({
      manageUserIds: rowIds
    })
  }

  setAPIKeySelection = (rowIds) => {
    this.setState({
      selectedAPIKeys: rowIds
    })
  }

  toggleAddUsersTable = () => {
    this.setState({
      addUsersOpen: !this.state.addUsersOpen
    })
  }

  toggleManageUsersTable = () => {
    this.setState({
      manageUsersOpen: !this.state.manageUsersOpen
    })
  }

  toggleAPIKeysTable = () => {
    this.setState({
      manageAPIKeysOpen: !this.state.manageAPIKeysOpen
    })
  }

  deleteAPIKeys = async () => {
    // alert(JSON.stringify(this.state.selectedAPIKeys, null, 2))
    const response = await axios.post(`${this.props.url}/${this.props.routes.organization.deleteAPIKey}`, {
      orgId: this.state.orgId,
      apiKeys: this.state.selectedAPIKeys
    })

    if (response.data.success) {
      this.props.setOrganization(Object.assign({}, this.props.organization, {
        apiKeys: response.data.data
      }))
    }
  }

  displayAPIKeys = () => {
    if (!this.props.organization || !Array.isArray(this.props.organization.apiKeys)) {
      return null
    }

    const { apiKeys } = this.props.organization

    return (
      <div>
        <ul>
        {apiKeys.map(a => (<li>{a.key}</li>))}
        </ul>
      </div>
    )
  }

  displayAPIKeys = () => {
    const apiKeys = !this.props.organization || !this.props.organization.apiKeys
      ? []
      : this.props.organization.apiKeys

    return (
      <div>
        <Grid container spacing={1}>
          <Grid container item xl={12} lg={12} md={12} xs={12} spacing={3}>
            <Card className={ styles.card }>
              <CardContent>
                <APIKeysDataTable data={apiKeys.map(a => { a.id = a._id; return a})} setSelection={this.setAPIKeySelection} />
                <br />
                <br />
                <Button
                  fullWidth
                  variant='contained'
                  onClick={this.deleteAPIKeys}
                  color='secondary'
                >
                    Delete API keys
                </Button>
                <APIKeyForm orgId={this.state.orgId} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { orgId } = this.state

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography style={{display: 'inline-block'}} variant="h5" component="h2" gutterBottom>
          Org: {this.props.organization ? this.props.organization.name : orgId}
        </Typography>
        {this.displayOrganizationInfo()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <button
          onClick={ this.toggleManageUsersTable }
          className={ styles.button }
          variant="contained"
          fullWidth
          color={!this.state.manageUsersOpen ? 'primary' : 'secondary'}
        >
          {!this.state.manageUsersOpen ? 'Manage users in organization' : 'Cancel manage'}
        </button><br />
        {!this.state.manageUsersOpen ? null : (
          <div>
            <br />
            <br />
            {this.displayOrganizationUsers()}
          </div>
        )}

        <button
          onClick={ this.toggleAddUsersTable }
          className={ styles.button }
          variant="contained"
          fullWidth
          color={!this.state.addUsersOpen ? 'primary' : 'secondary'}
        >
          {!this.state.addUsersOpen ? 'Add users to organization' : 'Cancel add'}
        </button>
        {!this.state.addUsersOpen ? null : (
          <div>
            <br />
            <br />
            {this.addUsersTable()}
          </div>
        )}
        <br />

        <button
          onClick={ this.toggleAPIKeysTable }
          className={ styles.button }
          variant="contained"
          fullWidth
          color={!this.state.manageAPIKeysOpen ? 'primary' : 'secondary'}
        >
          {!this.state.manageAPIKeysOpen ? 'Manage API Keys' : 'Cancel managing API Keys'}
        </button>
        {!this.state.manageAPIKeysOpen ? null : (
          <div>
            <br />
            <br />
            {this.displayAPIKeys()}
          </div>
        )}
      </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    users: state.users,
    notification: state.notification,
    organization: state.organization,
    organizationUsers: state.organizationUsers,
    routes: state.routes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationUsers: data => { dispatch(setOrganizationUsers(data)) }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organization))

import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab'
import './stripe.css'
import config from '../../config'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      makingPayment: false
    }
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {stripe, elements, notification} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({
      makingPayment: true
    })

    // -- simple single payment
    // const paymentIntent = await axios.post(`${this.props.url}/${this.props.routes.payments.payment}`, { item: 'negotiations' })
    // console.log('payment intent:', paymentIntent)
    // const cardElement = elements.getElement(CardElement);
    // stripe
    //   .confirmCardPayment(paymentIntent.data.clientSecret, {
    //     payment_method: {
    //       card: cardElement
    //     }
    //   })
    //   .then(function(result) {
    //     if (result.error) {
    //       // Show error to your customer
    //       console.log('Error occurred processing payment:', result.error.message)
    //     } else {
    //       // The payment succeeded!
    //       console.log('Payment processing successful:', result.paymentIntent.id)
    //     }
    //   });

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      notification({
        type: 'danger',
        title: 'Credit Card rejected!',
        message: 'Make sure you typed it in correctly or try again with another card!'
      })

      this.setState({
        makingPayment: false
      })
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const response = await axios.post(`${this.props.url}/${this.props.routes.payments.updatePaymentMethod}`, { paymentMethod: paymentMethod.id })
      console.log('card info updated:', response)

      if (response && response.data && response.data.success) {
        console.log(response.data)
        // if (response.data.user) {
        //   this.props.setUser(response.data.user)
        // }

        this.props.notification({
          type: 'success',
          title: 'Success!',
          message: `Successfully updated card info! Redirecting you to home...`
        })

        setTimeout(function(){
          window.location.href = `/`
        }, 3000)
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Failed to update card info!',
          message: 'Please reach out to support or try again later!'
        })

        this.setState({
          makingPayment: false
        })
      }
    }
  };

  render() {
    const {stripe} = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Update payment info</h1>

        <Alert severity="info">
          Your card information is not stored in imUp.io. All payments are secured through Stripe Payment Processing. For more information on Stripe security, please visit their site <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe" style={{color: '#1D98FF' }}>here</a>
        </Alert>
        <br />

        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button type="submit" disabled={!stripe || this.state.makingPayment}>
          Update card info
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setConnectivityData: data => { dispatch(setConnectivityData(data)) },
//     setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
//     setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
//     setDowntimeData: data => { dispatch(setDowntimeData(data)) },
//     setGeolocationData: data => { dispatch(setGeolocationData(data)) },
//     setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
//     setDownloadOS: data => { dispatch(setDownloadOS(data)) },
//     setStatsData: data => { dispatch(setStatsData(data)) },
//   }
// }

const ReduxCheckoutForm = withRouter(connect(mapStateToProps)(CheckoutForm))

const InjectedCheckoutForm = (notification, tier, setUser) => {
  return (
    <ElementsConsumer>
      {({elements, stripe}) => (
        <ReduxCheckoutForm elements={elements} stripe={stripe} notification={notification} tier={tier} setUser={setUser} />
      )}
    </ElementsConsumer>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe.key);

const App = (props) => {
  return (
    <Elements stripe={stripePromise}>
      {InjectedCheckoutForm(props.notification, props.tier, props.setUser)}
    </Elements>
  );
};

export default App;

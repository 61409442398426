import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardHeader, ButtonGroup, CardContent, Grid, Typography } from '@material-ui/core'
import Divider from '@mui/material/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import SpeedIcon from '@material-ui/icons/Speed';
import { Alert } from '@material-ui/lab'
import AlertTitle from '@material-ui/lab/AlertTitle';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ComputerIcon from '@material-ui/icons/Computer';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  setOrganization,
  setOrganizationUsers,
  setOrganizationHosts,
  setSpeedTestStatusModalDisplayed,
  setSpeedTestStatusResults,
  setHostLivenessStatus,
  setOrgPaymentInfo,
  setUser,
  setOrganizationGroupProfiles,
  setOrganizationHostsForGroup,
} from '../../actions/connectionActions'
import HostsTable from './hostsTable'
import GroupProfilesTable from './groupProfilesTable'
import DownloadModal from './downloadModal'
import UsersTable from './usersTable'
import APIKeyTable from './apiKeyTable'
import Help from './help'
import SpeedTestStatusModal from '../SpeedTestStatusModal/SpeedTestStatusModal'
import WindowsServiceDownloader from './WindowsServiceDownloader'
import styles from './organizationUser.module.scss'
import GroupMenu from './groupMenu'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// note: not safe to go straight to this page because it uses org id for requests which
//   come from redux "organization" field and filters on "organizationGroupProfiles" redux field
// constructor:
// - determines whether or not it should display "create" or "modify" content based on route
// - gets groupId from route
// - from redux "organizationGroupProfiles" filtered by group id:
//   - gets speed threshold for group
//   - gets download speed
//   - gets the name
//   - gets whether or not its the default group
// in componentDidMount:
// - sets "organization" redux state from GET 'v1/organization/' with '{ id: orgId }' query params
//   * redundant because it exists already or the constructor would white screen
// - sets "organizationUsers" redux state from GET 'v1/organization/users' with '{ id: orgId }' query params
//   * redundant because it exists already or the constructor would white screen
// - sets "organizationHostsForGroup" redux state from GET 'v1/organization/hosts' with '{ id: orgId, groupId: groupId, defaultGroup: true/false }' params
// - sets "organizationGroupProfiles" redux state from GET 'v1/organization/getGroupProfiles' with '{ id: orgId }' query params
//   * redundant because it exists already or the constructor would white screen
// - (all redundant) loops over "organization.apiKeys" redux state to set react state for the following:
//   - apiKeyLoopup: map of api key ids to the number of seats they're allocated
//   - apiKeyNameLookup: map of api key ids to their common name
//   - apiKeyUsageCount: map of an api key id to the number of hosts reporting under that key
// - sets "orgPaymentInfo" redux state from GET 'v1/payments/orgPaymentInfo' with no params
//   * redundant because it exists already or the constructor would white screen
// - sets "hostLivenessStatus" redux state from POST 'v1/realtime/hostconnectedstatus' with the hosts in this group
class OrganizationGroup extends Component {
  constructor(props) {
    super(props)
      // orgId param always exists whether its the create or modify page
      let clientConfig = null
      let notifications = null
      let ispProfiles = []

      const newGroupProfile = !!props.history &&
      !!props.history.location && !!props.history.location.pathname &&
      props.history.location.pathname.startsWith('/profilehostgroup/create')

      const orgId = decodeURIComponent(this.props.match.params.id)
      const groupProfileId = newGroupProfile ? '' : decodeURIComponent(this.props.match.params.groupId)
      const groupProfileMatch = newGroupProfile ? [] : props.organizationGroupProfiles.filter(x => x.id === groupProfileId)

    this.state = {
      downloadingClient: false,
      createOrgOpen: true,
      orgId,
      dialogOpenAddUsers: false,
      dialogOpen: false,
      dialogAPIKeyName: '',
      dialogAPIKey: '',
      apiKeyLookup: {},
      apiKeyNameLookup: {},
      apiKeyUsageCount: {},
      selectedHosts: [],
      hostStatusIntervalId: null,
      exportInProgress: false,
      hideHostsInProgress: false,
      deleteModalOpen: false,
      pendingDelete: false,
      deleteHostsModalOpen: false,
      pendingDeleteHosts: false,
      selectedGroupProfiles: [],
      deleteGroupInProgress: false,
      deleteGroupProfilesModalOpen: false,
      windowsServiceDownloaderOpen: false,
      downloadSpeedThreshold: newGroupProfile ? null : groupProfileMatch[0].downloadSpeedThreshold,
      uploadSpeedThreshold: newGroupProfile ? null : groupProfileMatch[0].uploadSpeedThreshold,
      latencyThreshold: newGroupProfile ? null : groupProfileMatch[0].latencyThreshold,
      packetLossThreshold: newGroupProfile ? null : groupProfileMatch[0].packetLossThreshold,
      downloadSpeed: newGroupProfile ? null : groupProfileMatch[0].downloadSpeed,
      newGroupProfile,
      // this is meaningless if its a new host group being created
      groupProfileId,
      name: newGroupProfile ? 'default' : groupProfileMatch[0].name,
      default: newGroupProfile ? false : groupProfileMatch[0].default,
      groupProfileMatch: groupProfileMatch[0],
      clientConfig,
      notifications,
      loading: false,
      ispProfiles,
      newISPProfile: {
        providerId: null,
        price: 0.00,
        // rating: 0,
        downloadSpeed: 0,
        uploadSpeed: 0,
        dataLimit: 0,
        downloadSpeedThreshold: 0,
        uploadSpeedThreshold: 0,
        latencyThreshold: 0,
        packetLossThreshold: 0,
        additionalDetails: '',
      },
      // used so we can get a default into the new
      // provider modal. not sure this is necessary
      newISPProfileProviderName: '',
      addISPModalOpen: false,
      newISPProfileFormShowing: false,
      // used to prevent form from allowing duplicate group submission
      existingGroupProfileNames: !Array.isArray(props.organizationGroupProfiles)
        ? []
        : props.organizationGroupProfiles.map(p => p.name)
    }
  }

  getGroupProfileMatch (organizationGroupProfiles) {
    const { props } = this
    // TODO: this is copy+paste from the constructor to get the group profile to update when you've already visited
    const newGroupProfile = !!props.history &&
      !!props.history.location && !!props.history.location.pathname &&
      props.history.location.pathname.startsWith('/profilehostgroup/create')

    const groupProfileId = newGroupProfile ? '' : decodeURIComponent(this.props.match.params.groupId)
    const groupProfileMatch = newGroupProfile ? [] : organizationGroupProfiles.filter(x => x.id === groupProfileId)

    return groupProfileMatch[0]
  }

  // TODO: I don't know what the page will do if it fails to get data.
  // maybe I could kick them back to a different page or we can make
  // a support page we automatically send them to
  async getOrganization () {
    try {
      const org = await axios.get(`${this.props.url}/${this.props.routes.organization.get}`, { params: { id:  this.state.orgId } })
      if (org && org.data.success) {
        this.props.setOrganization(org.data.data)
        return org.data.data
      }
    } catch(e) {
      console.log('error getting organization', e)
    }

    return null
  }

  async getOrganizationUsers () {
    try {
      const orgUsers = await axios.get(`${this.props.url}/${this.props.routes.organization.getUsers}`, { params: { id:  this.state.orgId } })
      if (orgUsers && orgUsers.data.success) {
        this.props.setOrganizationUsers(orgUsers.data.data)
        return orgUsers.data.data
      }
    } catch(e) {
      console.log('error getting organization users', e)
    }

    return null
  }

  async getOrganizationHosts () {
    try {
      const orgHosts = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: { id:  this.state.orgId } })
      if (orgHosts && orgHosts.data.success) {
        this.props.setOrganizationHosts(orgHosts.data.data)
        return orgHosts.data.data
      }
    } catch(e) {
      console.log('error getting organization hosts', e)
    }

    return null
  }

  async getOrganizationHostsForGroup () {
    try {
      const orgHostsForGroup = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: {
        id:  this.state.orgId,
        groupId: this.state.groupProfileId,
        defaultGroup: this.state.default,
      } })
      if (orgHostsForGroup && orgHostsForGroup.data && orgHostsForGroup.data.success) {
        this.props.setOrganizationHostsForGroup(orgHostsForGroup.data.data)
        return orgHostsForGroup.data.data
      }
    } catch(e) {
      console.log('error getting organization hosts for group', e)
    }

    return null
  }

  async getOrganizationGroupProfiles () {
    try {
      const groupProfiles = await axios.get(`${this.props.url}/${this.props.routes.organization.getGroupProfiles}`, { params: { id:  this.state.orgId } })
      if (groupProfiles && groupProfiles.data && groupProfiles.data.success) {
        this.props.setOrganizationGroupProfiles(groupProfiles.data.groupProfiles)
        return groupProfiles.data.groupProfiles
      }
    } catch(e) {
      console.log('error getting organization group profiles', e)
    }

    return null
  }

  async getOrganizationPaymentInfo () {
    try {
      const paymentInfo = await axios.get(`${this.props.url}/${this.props.routes.payments.orgGetPaymentInfo}`)
      if (paymentInfo && paymentInfo.data && paymentInfo.data.data) {
        this.props.setOrgPaymentInfo(paymentInfo.data.data)
        return paymentInfo.data.data
      } else {
        this.props.setOrgPaymentInfo({})
        return {}
      }
    } catch(e) {
      console.log('error getting organization payment info', e)
    }

    return null
  }

  // TODO: running this many await's in componentDidMount slows things down
  // because they're synchronous. we should wrap this in a promise.all()
  async componentDidMount() {
    const that = this

    await Promise.all([
      this.getOrganization(),
      this.getOrganizationUsers(),
      this.getOrganizationHosts(),
      this.getOrganizationHostsForGroup(),
      this.getOrganizationGroupProfiles(),
      this.getOrganizationPaymentInfo(),
    ]).then(([organization, orgUsers, orgHosts, orgHostsForGroup, orgGroupProfiles, paymentInfo]) => {
      const orgHasHostsForGroup = !!organization && !!orgHostsForGroup
      const orgHasApiKeys = !!organization && Array.isArray(organization.apiKeys) && organization.apiKeys.length > 0

      if (orgHasHostsForGroup && orgHasApiKeys) {
        const apiKeyLookup = {}
        const apiKeyUsageCount = {}
        const apiKeyNameLookup = {}
        organization.apiKeys.forEach(k => {
          apiKeyLookup[k.id] = k.seats
          apiKeyNameLookup[k.id] = k.name
          apiKeyUsageCount[k.id] = 0
        })

        orgHostsForGroup.forEach(h => {
          apiKeyUsageCount[h.apiKey] = apiKeyUsageCount[h.apiKey] + 1
        })

        that.setState({ apiKeyLookup, apiKeyUsageCount, apiKeyNameLookup })
      }

      // set polling interval for getting hosts liveness status
      if (orgHasHostsForGroup) {
        // every 5 seconds
        let intervalId = setInterval(async function(){
          try {
            // use redux state for this and not the resolved promise because its running a loop and the props could change
            if (Array.isArray(that.props.organizationHostsForGroup) && that.props.organizationHostsForGroup.length > 0) {
              const hostConnectedStatus = await axios.post(`${that.props.url}/${that.props.routes.realtime.hostConnectedStatus}`, {
                hosts: that.props.organizationHostsForGroup ? that.props.organizationHostsForGroup.map(h => `${h.apiKey}__IMUP_DELIMITER__${h.hostId}`) : []
              })

              if (hostConnectedStatus && hostConnectedStatus.data && hostConnectedStatus.data.success && hostConnectedStatus.data.data && hostConnectedStatus.data.data.status) {
                that.props.setHostLivenessStatus(hostConnectedStatus.data.data.status)
              } else {
                console.log('failed to get host liveness status')
              }
            }
          } catch (e) {
            console.log('error getting host connected status', e)
          }
        }, 5000);

        that.setState({ hostStatusIntervalId: intervalId })
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.hostStatusIntervalId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      // no idea if touching window in react is dangerous so wrapping it in a try/catch
      try {
        window.location.href = window.location.href
      } catch (e) {
        console.log('error refreshing page:', e)
      }
    }
  }

  toggleOrgDeleteModal = () => {
    this.setState({
      deleteModalOpen: this.state.deleteModalOpen ? false : true
    })
  }

  toggleOrgDeleteHostsModal = () => {
    this.setState({
      deleteHostsModalOpen: !this.state.deleteHostsModalOpen
    })
  }

  toggleDeleteGroupProfilesModal = () => {
    this.setState({
      deleteGroupProfilesModalOpen: !this.state.deleteGroupProfilesModalOpen
    })
  }

  setHostsSelection = (hosts) => {
    this.setState({
      selectedHosts: hosts
    })
  }

  setGroupProfilesSelection = (hosts) => {
    this.setState({
      selectedGroupProfiles: hosts
    })
  }

  deleteGroups = async () => {
    const groupOrGroups = this.state.selectedGroupProfiles.length > 1 ? 'groups' : 'group'
    const itOrThey = this.state.selectedGroupProfiles.length > 1 ? 'They' : 'It'

    try {
      this.setState({
        deleteGroupInProgress: true
      })

      const deleteGroupProfilesResp = await axios.post(`${this.props.url}/${this.props.routes.organization.deleteGroupProfiles}`, {
        orgId: this.state.orgId,
        groupProfileIds: this.state.selectedGroupProfiles,
      })

      if (!!deleteGroupProfilesResp && !!deleteGroupProfilesResp.data && deleteGroupProfilesResp.data.success) {
        this.props.setOrganizationGroupProfiles(deleteGroupProfilesResp.data.groupProfiles)

        this.props.notification({
          type: 'success',
          title: `Successfully deleted ${this.state.selectedGroupProfiles.length} ${groupOrGroups}!`,
          message: `${itOrThey} will no longer appear in your dashboard.`
        })

        return this.setState({
          selectedGroupProfiles: [],
          deleteGroupInProgress: false,
          deleteGroupProfilesModalOpen: false,
        })
      }
    } catch (e) {
      console.log('error deleting group profiles', e)
    }

    this.setState({
      deleteGroupInProgress: false,
      deleteGroupProfilesModalOpen: false,
    })

    return this.props.notification({
      type: 'danger',
      title: `There was a problem deleting your ${groupOrGroups}.`,
      message: 'Please try again soon or reach out to us for support.'
    })
  }

  hideHosts = async () => {
    this.setState({
      hideHostsInProgress: true,
      pendingDeleteHosts: true,
    })

    try {
      const hideHostsRes = await axios.post(`${this.props.url}/${this.props.routes.organization.hideHostIds}`, {
        orgId: this.state.orgId,
        hosts: this.state.selectedHosts,
      })

      if (!!hideHostsRes && !!hideHostsRes.data && hideHostsRes.data.success) {
        const orgHosts = await axios.get(`${this.props.url}/${this.props.routes.organization.getHostIds}`, { params: { id:  this.state.orgId } })
        if (orgHosts && orgHosts.data.success) {
          this.props.setOrganizationHosts(orgHosts.data.data)
        }

        const hostOrHosts = this.state.selectedHosts.length > 1 ? 'hosts' : 'host'

        this.props.notification({
          type: 'success',
          title: `Successfully hid ${this.state.selectedHosts.length} ${hostOrHosts}!`,
          message: `They will no longer appear in your dashboard.`
        })

        return this.setState({
          selectedHosts: []
        })
      } else {
        return this.props.notification({
          type: 'danger',
          title: 'There was a problem hiding your hosts.',
          message: 'Please try again soon or reach out to us for support.'
        })
      }
    } catch (e) {
      console.log('error hiding hosts:', e)

      return this.props.notification({
        type: 'danger',
        title: 'There was a problem hiding your hosts.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } finally {
      this.setState({
        hideHostsInProgress: false,
        pendingDeleteHosts: false,
      })
      this.toggleOrgDeleteHostsModal()
    }
  }

  runExport = async () => {
    this.setState({
      exportInProgress: true
    })

    try {
      const runExportRes = await axios.post(`${this.props.url}/${this.props.routes.organization.exportMultiple}`, {
        orgId: this.state.orgId,
        hosts: this.state.selectedHosts,
      })

      if (runExportRes && runExportRes.data.success) {
        const hostOrHosts = this.state.selectedHosts.length > 1 ? 'hosts' : 'host'
        return this.props.notification({
          type: 'success',
          title: `Successfully requested export for ${this.state.selectedHosts.length} ${hostOrHosts}!`,
          message: `This may take a few minutes, but we'll send you an email when it's finished.`
        })
      }

      // you'll get a 200 response code but with success: false if there's
      // a failure but the API server wants to communicate a message
      if (runExportRes && !runExportRes.data.success) {
        return this.props.notification({
          type: 'danger',
          title: 'There was a problem requesting your export.',
          message: runExportRes.data.message,
        })
      }

      // don't think the code will ever get here due to
      // axios intercepting anything but 200s
      return this.props.notification({
        type: 'danger',
        title: 'There was a problem requesting your export.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } catch(e) {
      console.log('error running mass export:', e)

      return this.props.notification({
        type: 'danger',
        title: 'There was a problem requesting your export.',
        message: 'Please try again soon or reach out to us for support.'
      })
    } finally {
      this.setState({
        exportInProgress: false
      })
    }
  }

  runSpeedTest = async () => {
    this.props.setSpeedTestStatusModalDisplayed(true)
    try {
      const realtimeSpeedTest = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtest}`, {
        hosts: this.state.selectedHosts
      })
      if (realtimeSpeedTest && realtimeSpeedTest.data.success) {
        const checkSpeedTestStatus = async () => {
          try {
            const realtimeSpeedTestStatus = await axios.post(`${this.props.url}/${this.props.routes.realtime.speedtestStatus}`, {
              hosts: this.state.selectedHosts
            })
            if (realtimeSpeedTestStatus && realtimeSpeedTestStatus.data && realtimeSpeedTestStatus.data.success && realtimeSpeedTestStatus.data.data && realtimeSpeedTestStatus.data.data.status) {
              this.props.setSpeedTestStatusResults(realtimeSpeedTestStatus.data.data.status)
            }
          } catch(e) {
            console.log('error checking realtime status:', e)
          }
        }

      const that = this
      async function runner(repeats = 1) {
        if (repeats > 0) {
          await checkSpeedTestStatus()
          setTimeout(() => runner(repeats - 1), 3000)
        } else {
          if (that.props.speedTestStatusResults) {
            const timedOutResults = that.props.speedTestStatusResults.map(r => ({
              host: r.host,
              status: r.status === 'pending' || r.status === 'running' ? 'timed out' : r.status
            }))

            that.props.setSpeedTestStatusResults(timedOutResults)
          }
        }
      }

      runner(100)

      } else {
        console.log('failed to run speed test')
      }
    } catch(e) {
      console.log('error running speed test')
      console.log(e)
    }
  }

  onHostsTableRowClick = (row) => {
    this.props.history.push(`/host/${row.apiKey}/${encodeURIComponent(row.hostId)}/${encodeURIComponent(this.state.groupProfileId)}/${encodeURIComponent(this.state.name)}`)
  }

  onGroupProfilesTableRowClick = (row) => {
    this.props.history.push(`/profilehostgroup/modify/${this.state.orgId}/${row.id}`)
  }

  onAPIKeyTableRowClick = (row) => {
    this.setState({
      dialogOpen: true,
      dialogAPIKeyName: row.name,
      dialogAPIKey: row.key
    })
  }

  findHostLivenessStatus = (host) => {
    let hostAlive = false
    if (!this.props.hostLivenessStatus) {
      return hostAlive
    }

    // eslint thinks h isn't being used here even though it clearly is
    // eslint-disable-next-line
    for (const h of this.props.hostLivenessStatus) {
      if (host === h.host) {
        hostAlive = h.status
        break;
      }
    }
    return hostAlive
  }

  goToBillingPage = () => {
    this.props.history.push(`/billing/organization`)
  }

  deleteOrganization = async () => {
    this.setState({
      pendingDelete: true
    })

    const deleteOrgReq = await axios.delete(`${this.props.url}/${this.props.routes.organization.deleteOrg}`, {
      data: { orgId: this.state.orgId }
    })

    if (deleteOrgReq && deleteOrgReq.data.success) {
      this.props.setUser(deleteOrgReq.data.user)
      this.props.notification({
        type: 'success',
        title: `Success!`,
        message: `Deleted organization.`
      })

      this.setState({
        pendingDelete: false,
        deleteModalOpen: false,
      })

      return setTimeout(function(){
        window.location.href = `/`
      }, 2000)
    }

    this.setState({
      pendingDelete: false,
    })

    return this.props.notification({
      type: 'danger',
      title: `Error`,
      message: `Failed to delete organization. Please reach out to support.`
    })
  }

  createNewGroup = () => {
    this.props.history.push(`/profilehostgroup/create/${this.state.orgId}`)
  }

  displayOrg = () => {
    const { orgId } = this.state
    return (
      <Grid container spacing={3} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/org/${this.props.organization.id}`} className={styles.link}>
              {this.props.organization.name}
            </Link>
            <span style={{color:'#1d98ff', textDecoration: 'none !important'}}>{this.state.name}{this.state.default ? ' (default)' : ''}</span>
            <Link to={`/profilehostgroup/modify/${this.props.organization.id}/${this.state.groupProfileId}`} className={styles.link}>
              <SettingsIcon style={{color:'#1d98ff'}} />
            </Link>
          </Breadcrumbs>
          </div>
        </Grid>
      </Grid>
    )
  }

  displayTopLeft = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    const groupProfileMatch = this.getGroupProfileMatch(organizationGroupProfiles)

    return (
        <Grid container spacing={3} justifycontent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifycontent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                    High Packet Loss
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Typography variant="h4" component="h2" gutterBottom style={{color:'#00ff33'}}>
                      {!!groupProfileMatch && !!groupProfileMatch.hostsAbovePacketLossThreshold ? groupProfileMatch.hostsAbovePacketLossThreshold : 0}
                      <Typography variant="body2" component="body1" gutterBottom>
                        hosts
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <PersonIcon style={{width:'100%', height:'100%', color:'#00ff33'}} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifycontent="right" alignItems="center">
                  <Typography variant="body2" component="body1" gutterBottom>
                    Slow Speeds
                  </Typography><br /><br />
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Typography variant="h4" component="h2" gutterBottom style={{color:'#FE4C40'}}>
                      {!!groupProfileMatch && !!groupProfileMatch.hostsBelowSpeedThreshold ? groupProfileMatch.hostsBelowSpeedThreshold : 0}
                      <Typography variant="body2" component="body1" gutterBottom>
                        hosts
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    )
  }
  displayTopRight = (props) => {

    const groupProfileId = decodeURIComponent(this.props.match.params.groupId)
    const groupProfileMatch = this.props.organizationGroupProfiles.filter(x => x.id === groupProfileId)
    const ispProfile = !!groupProfileMatch[0] && Array.isArray(groupProfileMatch[0].ispProfiles)
      ? groupProfileMatch[0].ispProfiles[0]
      : null

    return (
        <Grid container spacing={3} justifycontent="center" alignItems="center">
          {/* Disabling client config until it's ready to launch */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifycontent="right" alignItems="center">
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <Typography variant="h6" component="h6" gutterBottom>
                      Client config
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Link to={`/profilehostgroup/modify/${this.props.organization.id}/${this.state.groupProfileId}`} styles={{color: 'none'}}>
                      <SettingsIcon style={{width:'100%', height:'100%', color:'#1d98ff'}} />
                    </Link>
                  </Grid>
                  <Divider className={styles.divider}/>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List sx={{ width: '100%', maxWidth: 360 }} >
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary="Pings enabled" />
                        {groupProfileMatch[0].clientConfig.pingEnabled
                          ? <CheckBoxIcon style={{ color: 'green' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: 'red' }} />}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Speed tests" />
                        {groupProfileMatch[0].clientConfig.speedTestEnabled
                          ? <CheckBoxIcon style={{ color: 'green' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: 'red' }} />}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Blocklist:" />
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', paddingLeft:'10px' }}>
                          {groupProfileMatch[0].clientConfig.blocklistedIPs.map((item) => (
                              <Chip style={{backgroundColor:'#fd5c63', color:'white', margin:'5px', fontSize:'12px'}} label={item} />
                          ))}
                        </div>
                        {groupProfileMatch[0].clientConfig.blocklistedIPs? <Chip>groupProfileMatch[0].clientConfig.blocklistedIPs.join(", ")</Chip> : "-"}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Allowlist:" />
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', paddingLeft:'10px' }}>
                          {groupProfileMatch[0].clientConfig.allowlistedIPs.map((item) => (
                              <Chip style={{backgroundColor:'#1d98ff', color:'white', margin:'5px', fontSize:'12px'}} label={item} />
                          ))}
                        </div>
                        {groupProfileMatch[0].clientConfig.allowlistedIPs? groupProfileMatch[0].clientConfig.allowlistedIPs.join(", ") : "-"}
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card variant="outlined" className={ styles.card }>
              <CardContent style={{ paddingBottom:'0px'}}>
                <Grid container justifycontent="right" alignItems="center">
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <Typography variant="h6" component="h6" gutterBottom>
                      Group Configuration
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Link to={`/profilehostgroup/modify/${this.props.organization.id}/${this.state.groupProfileId}`} className={styles.link}>
                      <Typography variant="body1" component="h2" gutterBottom style={{color:'#1d98ff', textDecoration:'none !important' }}>
                        Edit
                      </Typography>
                    </Link>
                  </Grid>
                  <Divider className={styles.divider}/>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List sx={{ width: '100%', maxWidth: 360 }} >
                      <br />
                      <Typography variant="body1" component="h2" gutterBottom>
                        <strong>ISP Profile:</strong>
                      </Typography>
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary="ISP:" />
                        {!ispProfile || !ispProfile.providerId ? '-' : ispProfile.providerId.name || '-'}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Price" />
                        {!ispProfile || !ispProfile.price ? '-' : ispProfile.price}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Download speed" />
                        {!ispProfile || !ispProfile.downloadSpeed ? '-' : ispProfile.downloadSpeed} Mbps
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Upload speed" />
                        {!ispProfile || !ispProfile.uploadSpeed ? '-' : ispProfile.uploadSpeed} Mbps
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Download Speed threshold" />
                        {!ispProfile || !ispProfile.downloadSpeedThreshold ? '-' : ispProfile.downloadSpeedThreshold} Mbps
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Upload Speed threshold" />
                        {!ispProfile || !ispProfile.uploadSpeedThreshold ? '-' : ispProfile.uploadSpeedThreshold} Mbps
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Average Latency threshold" />
                        {!ispProfile || !ispProfile.latencyThreshold ? '-' : ispProfile.latencyThreshold} ms
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Packet loss threshold" />
                        {!ispProfile || !ispProfile.packetLossThreshold ? '-' : ispProfile.packetLossThreshold} %
                      </ListItem>
                      <br />
                      <Typography variant="body1" component="h2" gutterBottom>
                        <strong>Notifications:</strong>
                      </Typography>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Slow download speed" />
                        {groupProfileMatch[0].notifications.downloadSpeedThreshold
                          ? <CheckBoxIcon style={{ color: '#3cb371' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: '#f08080' }} />}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Slow upload speed" />
                        {groupProfileMatch[0].notifications.uploadSpeedThreshold
                          ? <CheckBoxIcon style={{ color: '#3cb371' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: '#f08080' }} />}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="High latency" />
                        {groupProfileMatch[0].notifications.latencyThreshold
                          ? <CheckBoxIcon style={{ color: '#3cb371' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: '#f08080' }} />}
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Packet loss" />
                        {groupProfileMatch[0].notifications.packetLossThreshold
                          ? <CheckBoxIcon style={{ color: '#3cb371' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: '#f08080' }} />}
                      </ListItem>
                      {/* <ListItem>
                        <ListItemText id="switch-list-label-bluetooth" primary="Offline" />
                        {groupProfileMatch[0].notifications.offlineNotifications
                          ? <CheckBoxIcon style={{ color: '#3cb371' }} />
                          : <CheckBoxOutlineBlankIcon style={{ color: '#f08080' }} />}
                      </ListItem> */}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    )
  }

  displayOrganizations = () => {
    const { orgId } = this.state

    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography style={{display: 'inline-block'}} variant="body1" component="h2" gutterBottom>
              <strong>API Keys</strong>
            </Typography>
            <Tooltip title="API Keys: You can request that imUp create API keys for you. Simply click the Help button at the bottom right and submit a ticket. Once you get a key, plug it into your app to begin reporting metrics to this dashboard."><InfoIcon style={{fontSize:'small'}} style={{paddingLeft:'10px'}}/></Tooltip>
            <Link to="/billing/organization"><button className={ styles.buttonRight } >+ / - seats</button></Link>
            {!this.props.organization ? null : (<APIKeyTable onRowClick={this.onAPIKeyTableRowClick} data={this.props.organization.apiKeys} />)}
            <Dialog
              open={ this.state.dialogOpen }
              onClose={ () => this.setState({ dialogOpen: false }) }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`API Key: ${this.state.dialogAPIKeyName}`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.dialogAPIKey}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ () => this.setState({ dialogOpen: false }) } className={ styles.button2 }>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={ this.state.dialogOpenAddUsers }
              onClose={ () => this.setState({ dialogOpenAddUsers: false }) }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`Add or Remove Users`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please email support@imup.io to add or remove users.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ () => this.setState({ dialogOpenAddUsers: false }) } className={ styles.button2 }>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography style={{display: 'inline-block'}} variant="body1" component="h2" gutterBottom>
              <strong>Users</strong>
            </Typography>
            <Tooltip title="Users: All the users listed in this table have read access to your org. They can also innitiate remote speed tests. If you wish to add more users to your org, click the Help button in the bottom right and submit a request to imUp."><InfoIcon style={{fontSize:'small'}} style={{paddingLeft:'10px'}}/></Tooltip>
            <button className={styles.buttonRight} onClick={() => this.setState({ dialogOpenAddUsers: true })} >add users</button>
            <UsersTable data={this.props.organizationUsers} />
          </Grid>
        </Grid>
        <br />
        <Divider variant="middle" style={{marginBottom:'20px'}}/>

      </div>
    )
  }


  // calls 'v1/organization/hoststable/pagination'
  loadHostsTableRows = async ({ page, filterValue, sortModel }) => {
    // TODO: (I don't think this is still an issue but I'm too burned out to look)
    // handle this case and figure out why their docs suggest this in the table:
    // const [page, setPage] = React.useState(0)
    if (page === 0) {
      return []
    }

    let orgHosts = null
    try {
      orgHosts = await axios.post(`${this.props.url}/${this.props.routes.organization.hostsTablePagination}`, {
        id:  this.state.orgId,
        page,
        filterValue,
        sortModel,
        groupId: this.state.groupProfileId,
        defaultGroup: this.state.default,
      })
    } catch (e) {
      console.log('loadHostsTableRows error:', e)
    }

    if (orgHosts && orgHosts.data.success && !!orgHosts.data.data) {
      // do not update the org hosts list in redux because the liveness status redis polling
      // is based on the state passed into the table and nothing to do with this component
      // the initial data passed in for rows is only read in once
      const rows = Array.isArray(orgHosts.data.data.hostIds) ? orgHosts.data.data.hostIds : []

      console.log('OrganizationGroup loadHostsTableRows rows:', rows)

      return { rows, rowCount: orgHosts.data.data.rows }
    }

    return []
  }

  displayGroupsGrid = () => {
    const { orgId } = this.state
    return (
      <Card variant="outlined" className={ styles.card }>
        <CardHeader
          action={
            <ButtonGroup variant="outlined">
              {/* this does not support distinguishing by group
              <Tooltip title="Hide selected host(s)">
                <button
                  disabled={this.state.selectedHosts.length === 0 || this.state.hideHostsInProgress}
                  className={ styles.button2Red }
                  onClick={this.toggleOrgDeleteHostsModal}
                >
                  <ClearIcon fontSize="small"/>
                </button>
              </Tooltip>
              */}
              <Tooltip title="Run export for selected host(s)">
                <button
                  disabled={this.state.selectedHosts.length === 0}
                  className={ styles.button2 }
                  onClick={this.runExport}
                >
                  <DownloadIcon fontSize="small"/>
                </button>
              </Tooltip>
              <Tooltip title="Run speed test for selected host(s)">
                <button
                  disabled={this.state.selectedHosts.length === 0}
                  className={ styles.button2 }
                  onClick={this.runSpeedTest}
                >
                <SpeedIcon fontSize="small"/></button>
              </Tooltip>
            </ButtonGroup>
          }
          title={`${this.state.name} hosts`}
          subheader="Click a row below to view host-specific metrics"
          className={styles.cardHeader}
        />
        <Divider className={styles.divider}/>
        <HostsTable
          checkboxSelection={true}
          showLiveAndKey={true}
          serverSidePagination={true}
          onRowClick={this.onHostsTableRowClick}
          setSelection={this.setHostsSelection}
          loadHostsTableRows={this.loadHostsTableRows}
          data={!this.props.organizationHostsForGroup
                  ? []
                  : this.props.organizationHostsForGroup.map(u => {
                    u.id = `${u.apiKey}__IMUP_DELIMITER__${u.hostId}`
                    u.live = this.findHostLivenessStatus(`${u.apiKey}__IMUP_DELIMITER__${u.hostId}`)

                    if (!this.props.organization || !Array.isArray(this.props.organization.apiKeys)) {
                      u.apiKeyName = ''
                      return u
                    }

                    const apiKeyInfo = this.props.organization.apiKeys.filter(key => key.id === u.apiKey)
                    u.apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''

                    return u
          })}
        />

      </Card>
    )
  }

  getPaymentCardDetails = (field) => {
    const { orgPaymentInfo } = this.props
    if (!orgPaymentInfo || !orgPaymentInfo.card) {
      return ''
    }

    return orgPaymentInfo.card[field]
  }

  goToPaymentUpdate = () => {
    window.location.href = `/organization/paymentupdate`
  }

  displayBilling = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableContainer component={Paper}>
              <Table aria-label="imUp billing table">
                <TableBody>
                  <TableRow style={{backgroundColor: '#1D98FF'}}>
                    <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Billing</TableCell>
                    <TableCell/>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card Type</TableCell>
                    <TableCell align="right">{ this.getPaymentCardDetails('brand') }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card Last 4 Digits</TableCell>
                    <TableCell align="right">{ this.getPaymentCardDetails('last4') }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Update payment info</TableCell>
                    <TableCell align="right"><Button onClick={this.goToPaymentUpdate} disabled={!this.getPaymentCardDetails('last4')} className={ styles.button }>Update</Button></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <center><button onClick={this.toggleOrgDeleteModal} className={ styles.button } ><LocalAtmIcon fontSize="small" style={{paddingRight: '5px'}}/>Delete Organization</button></center>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <center><button onClick={this.toggleWindowsServiceDownloader} className={ styles.button } ><DownloadIcon fontSize="small" style={{paddingRight: '5px'}}/>Download Windows Service Installer</button></center>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  displayHelp = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Help/>
        </Grid>
      </Grid>
    )
  }

  displayGroupMenu = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GroupMenu data={organizationGroupProfiles}/>
        </Grid>
      </Grid>
    )
  }

  displayAlerts = () => {
    return (
      <div>
        {Object.keys(this.state.apiKeyUsageCount).map(c => {
          const usageCount = this.state.apiKeyUsageCount[c]
          const allowedCount = this.state.apiKeyLookup[c]
          const keyName = this.state.apiKeyNameLookup[c]
          return usageCount <= allowedCount ? null : (
            <Alert severity="warning">You are using {usageCount} of {allowedCount} seats with {keyName} key!</Alert>
          )
        })}
      </div>
    )
  }

  displayApiAlerts = () => {
      if (!!this.props.organization && Array.isArray(this.props.organization.apiKeys) && this.props.organization.apiKeys.length > 0) {
        return null
      }
      return (
        <Alert severity="success">Welcome! Create your API key <a href="/billing/organization" style={{ color:'#1d98ff'}}>here</a> to get started</Alert>
      )
  }

  displayHostsAlerts = () => {
      if (Array.isArray(this.props.organizationHosts) && this.props.organizationHosts.length > 0) {
        return null
      }
      return (
        <Alert severity="success">To add hosts to your org: <a href="/downloads" style={{ color:'#1d98ff'}}>download the app</a>, paste in your API key, then click start!</Alert>
      )
  }

  displayBreachAlerts = () => {
    const { orgId } = this.state
    const { organizationGroupProfiles } = this.props
    const groupProfileMatch = this.getGroupProfileMatch(organizationGroupProfiles)


    const isHighPacketLoss = groupProfileMatch.hostsAbovePacketLossThreshold > 0
    const isHighLatency = groupProfileMatch.hostsAboveLatencyThreshold > 0
    const isLowDownloadSpeed = groupProfileMatch.hostsBelowDownloadSpeedThreshold > 0
    const isLowUploadSpeed = groupProfileMatch.hostsBelowUploadSpeedThreshold > 0

    let hostsAboveLatencyThreshold = []
    let hostsAbovePacketLossThreshold = []
    let hostsBelowDownloadSpeedThreshold = []
    let hostsBelowUploadSpeedThreshold = []

    const ispProfile = !!groupProfileMatch && Array.isArray(groupProfileMatch.ispProfiles) && groupProfileMatch.ispProfiles.length > 0 ? groupProfileMatch.ispProfiles[0] : null

    if (!!ispProfile && !!ispProfile.hostsAboveLatencyThreshold) {
      hostsAboveLatencyThreshold = Object.keys(ispProfile.hostsAboveLatencyThreshold).map(key => {
        const hostIdsAboveLatencyThreshold = ispProfile.hostsAboveLatencyThreshold[key]

        let apiKeyName = ''
        if (this.props.organization && Array.isArray(this.props.organization.apiKeys)) {
          const apiKeyInfo = this.props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
          apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
        }

        return hostIdsAboveLatencyThreshold.map(hostId => {
          return {
            id: `${key}__IMUP_DELIMITER__${hostId}`,
            hostId: hostId,
            apiKeyName: apiKeyName,
            apiKey: key,
          }
        })
      }).flat()
    }

    if (!!ispProfile && !!ispProfile.hostsAbovePacketLossThreshold) {
      hostsAbovePacketLossThreshold = Object.keys(ispProfile.hostsAbovePacketLossThreshold).map(key => {
        const hostIdsAbovePacketLossThreshold = ispProfile.hostsAbovePacketLossThreshold[key]

        let apiKeyName = ''
        if (this.props.organization && Array.isArray(this.props.organization.apiKeys)) {
          const apiKeyInfo = this.props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
          apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
        }

        return hostIdsAbovePacketLossThreshold.map(hostId => {
          return {
            id: `${key}__IMUP_DELIMITER__${hostId}`,
            hostId: hostId,
            apiKeyName: apiKeyName,
            apiKey: key,
          }
        })
      }).flat()
    }

    if (!!ispProfile && !!ispProfile.hostsBelowDownloadSpeedThreshold) {
      hostsBelowDownloadSpeedThreshold = Object.keys(ispProfile.hostsBelowDownloadSpeedThreshold).map(key => {
        const hostIdsAboveDownloadSpeedThreshold = ispProfile.hostsBelowDownloadSpeedThreshold[key]

        let apiKeyName = ''
        if (this.props.organization && Array.isArray(this.props.organization.apiKeys)) {
          const apiKeyInfo = this.props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
          apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
        }

        return hostIdsAboveDownloadSpeedThreshold.map(hostId => {
          return {
            id: `${key}__IMUP_DELIMITER__${hostId}`,
            hostId: hostId,
            apiKeyName: apiKeyName,
            apiKey: key,
          }
        })
      }).flat()
    }

    if (!!ispProfile && !!ispProfile.hostsBelowUploadSpeedThreshold) {
      hostsBelowUploadSpeedThreshold = Object.keys(ispProfile.hostsBelowUploadSpeedThreshold).map(key => {
        const hostIdsAboveUploadSpeedThreshold = ispProfile.hostsBelowUploadSpeedThreshold[key]

        let apiKeyName = ''
        if (this.props.organization && Array.isArray(this.props.organization.apiKeys)) {
          const apiKeyInfo = this.props.organization.apiKeys.filter(keyInfo => keyInfo.id === key)
          apiKeyName = apiKeyInfo.length > 0 ? apiKeyInfo[0].name : ''
        }

        return hostIdsAboveUploadSpeedThreshold.map(hostId => {
          return {
            id: `${key}__IMUP_DELIMITER__${hostId}`,
            hostId: hostId,
            apiKeyName: apiKeyName,
            apiKey: key,
          }
        })
      }).flat()
    }

    return (
      <div style={{marginTop: '20px'}}>
        { isHighPacketLoss ?
          <Alert
            severity="warning"
            style={{
              textAlign: 'left',
              marginBottom: '10px',

            }}
          >
            <AlertTitle style={{textAlign: 'left'}}>Packet loss detected</AlertTitle>
            Within the last hour, there are {groupProfileMatch.hostsAbovePacketLossThreshold} hosts breaching their respective packet loss thresholds.

            <Accordion elevation={0}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="high-packet-loss-content"
                  id="high-packet-loss-accordian"
              >
                <Typography>View hosts with high packet loss</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <HostsTable
                  showLiveAndKey={false}
                  serverSidePagination={false}
                  onRowClick={this.onHostsTableRowClick}
                  setSelection={() => console.log(`this.setHostsSelection`)}
                  loadHostsTableRows={() => console.log(`this.loadHostsTableRows`)}
                  data={hostsAbovePacketLossThreshold}
                />
              </AccordionDetails>
            </Accordion>
          </Alert>  : null
        }
        { isHighLatency ?
            <Alert
              severity="warning"
              style={{
                textAlign: 'left',
                marginBottom: '10px',

              }}
            >
              <AlertTitle style={{textAlign: 'left'}}>High latency detected</AlertTitle>
              Within the last hour, there are {groupProfileMatch.hostsAboveLatencyThreshold} hosts breaching their respective latency thresholds.

              <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="slow-latency-content"
                    id="slow-latency-accordian"
                >
                  <Typography>View hosts with high latency</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <HostsTable
                    showLiveAndKey={false}
                    serverSidePagination={false}
                    onRowClick={this.onHostsTableRowClick}
                    setSelection={() => console.log(`this.setHostsSelection`)}
                    loadHostsTableRows={() => console.log(`this.loadHostsTableRows`)}
                    data={hostsAboveLatencyThreshold}
                  />
                </AccordionDetails>
              </Accordion>
            </Alert>  : null
          }
          { isLowDownloadSpeed ?
            <Alert
              severity="warning"
              style={{
                textAlign: 'left',
                marginBottom: '10px',

              }}
            >
              <AlertTitle style={{textAlign: 'left'}}>Slow download speeds detected</AlertTitle>
              Within the last hour, there are {groupProfileMatch.hostsBelowDownloadSpeedThreshold} hosts breaching their respective download speed thresholds.

              <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="slow-download-speeds-content"
                    id="slow-download-speeds-accordian"
                >
                  <Typography>View hosts with slow download speeds</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <HostsTable
                    showLiveAndKey={false}
                    serverSidePagination={false}
                    onRowClick={this.onHostsTableRowClick}
                    setSelection={() => console.log(`this.setHostsSelection`)}
                    loadHostsTableRows={() => console.log(`this.loadHostsTableRows`)}
                    data={hostsBelowDownloadSpeedThreshold}
                  />
                </AccordionDetails>
              </Accordion>
            </Alert> : null
          }
          { isLowUploadSpeed ?
            <Alert
              severity="warning"
              style={{
                textAlign: 'left',
                marginBottom: '10px',

              }}
            >
            <AlertTitle style={{textAlign: 'left'}}>Slow upload speeds detected</AlertTitle>
              Within the last hour, there are {groupProfileMatch.hostsBelowUploadSpeedThreshold} hosts breaching their respective upload speed thresholds.

              <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="slow-upload-speeds-content"
                    id="slow-upload-speeds-accordian"
                >
                  <Typography>View hosts with slow upload speeds</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <HostsTable
                    checkboxSelection={false}
                    showLiveAndKey={false}
                    serverSidePagination={false}
                    onRowClick={this.onHostsTableRowClick}
                    setSelection={() => console.log(`this.setHostsSelection`)}
                    loadHostsTableRows={() => console.log(`this.loadHostsTableRows`)}
                    data={hostsBelowUploadSpeedThreshold}
                  />
                </AccordionDetails>
              </Accordion>
            </Alert> : null
          }
          { isHighLatency || isHighPacketLoss || isLowDownloadSpeed || isLowUploadSpeed || (!!groupProfileMatch.hostCount && groupProfileMatch.hostCount.count == 0) ?
            null
         :
            <Alert
              severity="success"
              style={{
                textAlign: 'left',
              }}
            >
              All hosts in {this.state.name} are reporting optimal performance.
            </Alert>
          }
          { (!!groupProfileMatch.hostCount && groupProfileMatch.hostCount.count == 0)?
              <Alert
              severity="success"
              style={{
                textAlign: 'left',
              }}
            >
              Click the <strong>ADD ENDPOINTS</strong> button to build a script to download the imUp client on all of your endpoints
            </Alert>
         :
            null
          }
      </div>
    )
  }

  displayPlanExpiring = () => {
    if (!this.props.organization || !this.props.organization.stripe || !this.props.organization.stripe.cancelAt) {
      return null
    }
    const expiryDate = new Date(this.props.organization.stripe.cancelAt)
    const humanReadableDate = expiryDate.toLocaleDateString([], {
      // weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

    return (
      <Alert severity="warning">Your paid plan and API keys expire on { humanReadableDate }: <a href="/billing/organization" style={{ color:'#1d98ff'}}>reactivate</a> to continue your subscription</Alert>
    )
  }

  toggleWindowsServiceDownloader = () => {
    this.setState({
      windowsServiceDownloaderOpen: !this.state.windowsServiceDownloaderOpen
    })
  }

  render() {
    const pluralGroupProfiles = Array.isArray(this.state.selectedGroupProfiles) && this.state.selectedGroupProfiles.length > 1

    return (
      <div>
        <Grid container spacing={5}>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} justifycontent="center" >
          {this.displayOrg()}
            <SpeedTestStatusModal />
            {this.displayApiAlerts()}
            {!!this.props.organizationHosts && this.props.organizationHosts.length == 0 ?
              null :
              this.displayHostsAlerts()
            }
            {this.displayBreachAlerts()}
            {this.displayPlanExpiring()}
            {this.displayGroupsGrid()}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} justifycontent="center" >
            <DownloadModal data={this.props} group={this.state.groupProfileMatch} theme={this.props.theme}/>
            {this.displayTopRight()}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifycontent="center" >

          </Grid>
        </Grid>
        <WindowsServiceDownloader
          modalOpen={this.state.windowsServiceDownloaderOpen}
          toggleOpen={this.toggleWindowsServiceDownloader}
        />
        {/* confirmation modal for deleting entire organization */}
        <Dialog
          open={ this.state.deleteModalOpen }
          onClose={ this.toggleOrgDeleteModal }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete your organization?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="error">Are you sure? This action is irreversible.</Alert>
              <br/>
              Your organization will be unsubscribed from any payment plans, and all API keys will be deleted.
              <br/>
              You will lose all access to your data in the future.
              <br/>
              Alternatively, you can <a href="/billing/organization" style={{ color:'#1d98ff'}}>unsubscribe</a> without deleting your organization.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={ styles.buttonGroup }>
              <button disabled={this.state.pendingDelete} onClick={ this.toggleOrgDeleteModal } className={ styles.button2 }>
                No
              </button>
              <button disabled={this.state.pendingDelete} onClick={ this.deleteOrganization } className={ styles.button3 }>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        {/* confirmation modal for deleting hosts */}
        <Dialog
          open={ this.state.deleteHostsModalOpen }
          onClose={ this.toggleOrgDeleteHostsModal }
          aria-labelledby="alert-dialog-delete-hosts"
          aria-describedby="alert-dialog-delete-hosts"
        >
          <DialogTitle id="alert-dialog-delete-hosts">{'Hide your hosts?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-hosts">
              <Alert severity="error">Are you sure?</Alert>
              <br/>
              Your selected hosts will no longer be visible in the dashboard.
              <br/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={ styles.buttonGroup }>
              <button disabled={this.state.pendingDeleteHosts} onClick={ this.toggleOrgDeleteHostsModal } className={ styles.button2 }>
                No
              </button>
              <button disabled={this.state.pendingDeleteHosts} onClick={ this.hideHosts } className={ styles.button3 }>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
        {/* confirmation modal for deleting group profiles */}
        <Dialog
          open={ this.state.deleteGroupProfilesModalOpen }
          onClose={ this.toggleDeleteGroupProfilesModal }
          aria-labelledby="alert-dialog-delete-groups"
          aria-describedby="alert-dialog-delete-groups"
        >
          <DialogTitle id="alert-dialog-delete-groups">{`Delete group profile${pluralGroupProfiles ? 's' : ''}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete-groups">
              <Alert severity="error">Are you sure?</Alert>
              <br/>
              {pluralGroupProfiles ? 'These' : 'This'} group profile{pluralGroupProfiles ? 's' : ''} will be permanently deleted.
              <br/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ButtonGroup className={ styles.buttonGroup }>
              <button disabled={this.state.deleteGroupInProgress} onClick={ this.toggleDeleteGroupProfilesModal } className={ styles.button2 }>
                No
              </button>
              <button disabled={this.state.deleteGroupInProgress} onClick={ this.deleteGroups } className={ styles.button3 }>
                Delete
              </button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    url: state.url,
    user: state.user,
    notification: state.notification,
    organization: state.organization,
    organizationUsers: state.organizationUsers,
    organizationHosts: state.organizationHosts,
    organizationHostsForGroup: state.organizationHostsForGroup,
    organizationGroupProfiles: state.organizationGroupProfiles,
    routes: state.routes,
    speedTestStatusResults: state.speedTestStatusResults,
    hostLivenessStatus: state.hostLivenessStatus,
    orgPaymentInfo: state.orgPaymentInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrganization: data => { dispatch(setOrganization(data)) },
    setOrganizationUsers: data => { dispatch(setOrganizationUsers(data)) },
    setOrganizationHosts: data => { dispatch(setOrganizationHosts(data)) },
    setOrganizationHostsForGroup: data => { dispatch(setOrganizationHostsForGroup(data)) },
    setOrganizationGroupProfiles: data => { dispatch(setOrganizationGroupProfiles(data)) },
    setSpeedTestStatusModalDisplayed: data => { dispatch(setSpeedTestStatusModalDisplayed(data)) },
    setSpeedTestStatusResults: data => { dispatch(setSpeedTestStatusResults(data)) },
    setHostLivenessStatus: data => { dispatch(setHostLivenessStatus(data)) },
    setOrgPaymentInfo: data => { dispatch(setOrgPaymentInfo(data)) },
    setUser: (user) => { dispatch(setUser(user)) },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationGroup))

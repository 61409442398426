const initState = {
  user: null,
  authenticated: false,
  notification: null,
  theme: 'dark'
}

const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case 'setUrl':
      return {
        ...state,
        url: action.url
      }
    case 'setRoutes':
      return {
        ...state,
        routes: action.routes
      }
    case 'setNotifications':
      return {
        ...state,
        notification: action.notification
      }
    case 'setActiveUsers':
      return {
        ...state,
        activeUsers: action.data
      }
    case 'setAuth':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'setUser':
      return {
        ...state,
        user: action.user
      }
    case 'setUserPaymentInfo':
      return {
        ...state,
        userPaymentInfo: action.data
      }
    case 'setOrgPaymentInfo':
      return {
        ...state,
        orgPaymentInfo: action.data
      }
    case 'setProviders':
      return {
        ...state,
        providers: action.providers
      }
    case 'setLocalProviders':
      return {
        ...state,
        localProviders: action.localProviders
      }
    case 'setOrganizations':
      return {
        ...state,
        organizations: action.data
      }

    case 'setOrgHostsSummary':
      return {
        ...state,
        orgHostsSummary: action.data
      }

    case 'setOrganizationUsers':
      return {
        ...state,
        organizationUsers: action.data
      }

    case 'setOrganizationExports':
      return {
        ...state,
        organizationExports: action.data
      }

    case 'setOrganization':
      return {
        ...state,
        organization: action.data
      }

    case 'setOrganizationHosts':
      return {
        ...state,
        organizationHosts: action.data
      }

    case 'setOrganizationHostsForGroup':
      return {
        ...state,
        organizationHostsForGroup: action.data
      }

    case 'setOrganizationGroupProfiles':
      return {
        ...state,
        organizationGroupProfiles: action.data
      }

    case 'setOrganizationHostsCount':
      return {
        ...state,
        organizationHostsCount: action.data
      }

    case 'setOrgHostConnectivityData':
      return {
        ...state,
        orgHostConnectivityData: action.data
      }

    case 'setOrgHostSpeedTestData':
      return {
        ...state,
        orgHostSpeedTestData: action.data
      }

    case 'setOrgHostPingData':
      return {
        ...state,
        orgHostPingData: action.data
      }

    case 'setOrgHostPingChartData':
      return {
        ...state,
        orgHostPingChartData: action.data
      }

    case 'setAdminViewReports':
      return {
        ...state,
        adminViewReports: action.data
      }

    case 'setReportGroups':
      return {
        ...state,
        reportGroups: action.reportGroups
      }

    case 'setOnlineUsers':
      return {
        ...state,
        onlineUsers: action.data
      }

    case 'setEmails':
      return {
        ...state,
        emails: action.emails
      }

    case 'setPaymentPlan':
      return {
        ...state,
        paymentPlan: action.plan
      }

    case 'setStatsData':
      return {
        ...state,
        statsData: action.data
      }

    case 'setPingData':
      return {
        ...state,
        pingData: action.data
      }

    case 'setPingChartData':
      return {
        ...state,
        pingChartData: action.data
      }

    case 'setUsers':
      return {
        ...state,
        users: action.data
      }
    case 'setAdminViewUser':
      return {
        ...state,
        adminViewUser: action.data
      }
    case 'setAdminViewEmails':
      return {
        ...state,
        adminViewEmails: action.data
      }

    case 'setConnectivityData':
      return {
        ...state,
        connectivityData: action.data
      }

    case 'setDowntimeData':
      return {
        ...state,
        downtimeData: action.data
      }

    case 'setSpeedTestData':
      return {
        ...state,
        speedTestData: action.data
      }

    case 'setSpeedTestAverage7d':
      return {
        ...state,
        speedTestAverage7d: action.data
      }

    case 'setOperatingSystem':
      return {
        ...state,
        operatingSystem: action.data
      }
    case 'setGeolocationData':
      return {
        ...state,
        geolocationData: action.data
      }
    case 'setModalDisplayed':
      return {
        ...state,
        modalDisplayed: action.data
      }
    case 'setSpeedTestStatusModalDisplayed':
      return {
        ...state,
        speedTestStatusModalDisplayed: action.data
      }
    case 'setSpeedTestStatusResults':
      return {
        ...state,
        speedTestStatusResults: action.data
      }
    case 'setHostLivenessStatus':
      return {
        ...state,
        hostLivenessStatus: action.data
      }
    case 'setUserLivenessStatus':
      return {
        ...state,
        userLivenessStatus: action.data
      }
    case 'setTermsOfServiceModalDisplayed':
      return {
        ...state,
        termsOfServiceModalDisplayed: action.data
      }
    case 'setDownloadOS':
      return {
        ...state,
        downloadOS: action.data
      }
    case 'setTheme':
      return {
        ...state,
        theme: action.theme
      }
    default:
      return state
  }
}

export default rootReducer

// COMPONENTS
import React, { useState, Component } from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
// MATERIAL UI CORES
import styles from './LocalProviders.module.scss'
import { withStyles } from "@material-ui/core/styles";
import TwitterIcon from '@material-ui/icons/Twitter'


export default function LocalProvidersTitle(data) {
  const twitterBase = 'https://twitter.com/intent/tweet?text='
  const twitterText = `Check out internet prices in your area. See actual speed test results and whether there are outages around you with @imUp_ISP https://www.imup.io/#shopping-for-new-internet`
  const twitterTweet = `${twitterBase}${encodeURIComponent(twitterText)}`

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h6" component="h6" gutterBottom>
          US Internet Provider data within 15 miles of you: { data.data }
        </Typography>
        </Grid>
        {/* Disabling and setting the above grid to 12, until twitter can get their shit together
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        <Tooltip title="Share this feature on Twitter!">
          <Button target="_blank" rel="noopener noreferrer" href={twitterTweet}>
            <TwitterIcon fontSize="small" style={{color:'#1DA1F2'}}></TwitterIcon>
          </Button>
        </Tooltip>
        </Grid> */}
      </Grid>
      <hr />
    </div>
  )
}

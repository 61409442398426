// COMPONENTS
import React, { useState, Component } from "react";

// MATERIAL UI CORES
import { DataGrid, GridMenu } from "@mui/x-data-grid";
import { Collapse, IconButton, Button, ButtonGroup, Card, CardContent, Grid, Typography, CardHeader, CardActionArea, CardActions, CardMedia, Tooltip } from '@material-ui/core';
import styles from './LocalProviders.module.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Rating from '@mui/material/Rating';
import { withStyles } from "@material-ui/core/styles";


export default function LocalProviderList(data) {
  const list = data.data;

  const rating = list.map((list) => list.rating)
  const rating_sum = rating.reduce((a, b) => a + b, 0);
  const rating_avg = (rating_sum / rating.length) || 0;

  const zip = list.map((list) => list.zipcode)

  const prices = list.map((list) => list.price)
  const price_sum = prices.reduce((a, b) => a + b, 0);
  const price_avg = (price_sum / prices.length) || 0;

  const download = list.map((list) => list.downloadSpeed)
  const download_sum = download.reduce((a, b) => a + b, 0);
  const download_avg = (download_sum / download.length) || 0;

  const upload = list.map((list) => list.uploadSpeed)
  const upload_sum = upload.reduce((a, b) => a + b, 0);
  const upload_avg = (upload_sum / upload.length) || 0;

  return (
    <div>
      <Grid container spacing={1} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={ styles.card }>
            <CardContent style={{ paddingBottom:'0px'}}>
              <Typography variant="body2" component="body1" gutterBottom>
                <center>Avg<br /> Rating</center>
              </Typography>
              <Tooltip title={rating_avg}>
                <center><Rating precision={0.5} name="read-only" style={{color:"#1d98ff", marginTop:'10px', marginBottom:'15px'}} size="small" value={rating_avg} readOnly /></center>
              </Tooltip>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={ styles.card }>
            <CardContent style={{ paddingBottom:'0px'}}>
              <Typography variant="body2" component="body1" gutterBottom>
                <center>Avg<br /> Price</center>
              </Typography>
              <Typography variant="h6" component="h2" gutterBottom style={{color:'#1d98ff'}}>
                <center>${price_avg.toFixed(2)}</center>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifycontent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={ styles.card }>
            <CardContent style={{ paddingBottom:'0px'}}>
              <Typography variant="body2" component="body1" gutterBottom>
                <center>Avg<br /> Download</center>
              </Typography>
              <Typography variant="h6" component="h2" gutterBottom style={{color:'#1d98ff'}}>
                <center>{download_avg.toFixed(2)}</center>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Card variant="outlined" className={ styles.card }>
            <CardContent style={{ paddingBottom:'0px'}}>
              <Typography variant="body2" component="body1" gutterBottom>
                <center>Avg<br /> Upload</center>
              </Typography>
              <Typography variant="h6" component="h2" gutterBottom style={{color:'#1d98ff'}}>
                <center>{upload_avg.toFixed(2)}</center>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      </div>
  )
}

import React from 'react'
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2).toString()
// const twoDecimals = value => isNaN(value) ? '0.00' : value.toFixed(2)
const valueFormatterTwoDecimals = ({ value }) => twoDecimals(value)
const valueFormatterSuccess = ({ value }) => value === 1
const valueFormatterNanoToMilliSeconds = ({ value }) => twoDecimals(value / 1000000)

const valueFormatterDate = ({ value }) => {
  const _d = new Date(value);

  // looks like: 09:45 PM
  const timeOptions = {
    hour: '2-digit',
    minute:'2-digit'
  }

  const timeString = _d.toLocaleTimeString([], timeOptions)
  var mm = _d.getMonth() + 1;
  var dd = _d.getDate();
  return `${mm}/${dd} ${timeString}`
}

const columns = [
  { field: 'time', headerName: 'Time', width: 150, type: 'date', valueFormatter: valueFormatterDate },
  { field: 'packet_loss_avg', headerName: 'Packet Loss Avg (%)', width: 220, type: 'number', valueFormatter: valueFormatterTwoDecimals },
  { field: 'latency', headerName: 'Avg Latency (ms)', width: 190, type: 'number', valueFormatter: valueFormatterNanoToMilliSeconds },
  { field: 'min_rtt', headerName: 'Min RTT (ms)', width: 200, type: 'number', valueFormatter: valueFormatterNanoToMilliSeconds },
  { field: 'max_rtt', headerName: 'Max RTT (ms)', width: 200, type: 'number', valueFormatter: valueFormatterNanoToMilliSeconds },
  { field: 'avg_rtt', headerName: 'Avg RTT (ms)', width: 200, type: 'number', valueFormatter: valueFormatterNanoToMilliSeconds },
  { field: 'success', headerName: 'Connected', width: 150, type: 'number', valueFormatter: valueFormatterSuccess }
];

// [ { "time": "2021-04-21T23:00:00.000Z", "success": 1, "packet_loss_avg": 0, "min_rtt": 8364688, "max_rtt": 101030778, "avg_rtt": 20873203.1 } ]

export default function PingDataTable(props) {
  return !props.data || !Array.isArray(props.data) ? null : (
    <div style={{ height: '200vh', maxHeight: '100%', width: '100%' }}>
      <NewCustomDataGrid
        columns={columns}
        rows={props.data}
        showToolbar
      />
    </div>
  )
}

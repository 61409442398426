import React from 'react';
import styles from './payments.module.scss'
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Alert } from '@material-ui/lab'
import './stripe.css'
import config from '../../config'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'

const plans = {
  'Lite Plan': 'lite',
  'Integrity Plan': 'integrity',
  'Rise Plan': 'rise',
  'Pro Plan': 'professional',
  'You Plan': 'you',
}

const pricesMonthly = {
  'Lite Plan': '$1/month + tax',
  'Integrity Plan': '$3/month + tax',
  'Rise Plan': '$7/month + tax',
  'Pro Plan': '$10/month + tax',
  'You Plan': '$100/month + tax',
}

const pricesAnnually = {
  'Lite Plan': '$10/year + tax',
  'Integrity Plan': '$30/year + tax',
  'Rise Plan': '$70/year + tax',
  'Pro Plan': '$100/year + tax',
  'You Plan': '$1000/year + tax',
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      makingPayment: false,
      subscriptionType: 'annually',
    }
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const { stripe, elements, notification } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({
      makingPayment: true
    })

    // -- simple single payment
    // const paymentIntent = await axios.post(`${this.props.url}/${this.props.routes.payments.payment}`, { item: 'negotiations' })
    // console.log('payment intent:', paymentIntent)
    // const cardElement = elements.getElement(CardElement);
    // stripe
    //   .confirmCardPayment(paymentIntent.data.clientSecret, {
    //     payment_method: {
    //       card: cardElement
    //     }
    //   })
    //   .then(function(result) {
    //     if (result.error) {
    //       // Show error to your customer
    //       console.log('Error occurred processing payment:', result.error.message)
    //     } else {
    //       // The payment succeeded!
    //       console.log('Payment processing successful:', result.paymentIntent.id)
    //     }
    //   });

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      notification({
        type: 'danger',
        title: 'Credit Card rejected!',
        message: 'Make sure you typed it in correctly or try again with another card!'
      })

      this.setState({
        makingPayment: false
      })
    } else {
      console.log('[PaymentMethod]', paymentMethod);

      const plan = plans[this.props.tier]
      const response = await axios.post(`${this.props.url}/${this.props.routes.payments.subscription}`, {
        plan: plan,
        paymentMethod: paymentMethod.id,
        subscriptionType: this.state.subscriptionType,
      })
      console.log('subscription created:', response)

      if (response.data.success) {
        console.log(response.data)
        // check for user even on success cause we'll send back success if
        // the subscription was successful but the mongo store failed
        if (response.data.user) {
          this.props.setUser(response.data.user)
        }

        this.props.notification({
          type: 'success',
          title: 'Success!',
          message: `Successfully subscribed to the ${this.props.tier}! Redirecting you to home...`
        })

        setTimeout(function () {
          window.location.href = `/`
        }, 5000)
      } else {
        this.props.notification({
          type: 'danger',
          title: 'Failed to subscribe!',
          message: 'Please reach out to support or try again later!'
        })

        this.setState({
          makingPayment: false
        })
      }
    }
  };

  handleSubscriptionTypeToggle = () => {
    this.setState({
      subscriptionType: this.state.subscriptionType === 'annually' ? 'monthly' : 'annually'
    })
  }

  render() {
    console.log('tier:', this.props.tier)
    const { stripe } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Subscribe to {this.props.tier}</h1>
        <Alert severity="info">
          Your card information is not stored in imUp.io. All payments are secured through Stripe Payment Processing. For more information on Stripe security, please visit their site <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/docs/security/stripe" style={{ color: '#1D98FF' }}>here</a>

          {this.state.subscriptionType === 'annually' ? '' : (
            <div>
              <br />
              <strong>Speaking of stripe...</strong> they charge us a minimum of $0.30 per transaction (which is 30% of our Lite plan profit, holy smokes).
              Consider subscribing to our annual plans to reduce the profit they make from us. If not, no worries -- monthly plans are still available
              without passing this cost onto  you.
            </div>
          )}
        </Alert>
        <form noValidate autoComplete="off" className={styles.form}>
          <ToggleButtonGroup
            value={this.state.subscriptionType}
            exclusive
            onChange={this.handleSubscriptionTypeToggle}
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="annually">Annually</ToggleButton>
          </ToggleButtonGroup>
          <br />
          <br />
        </form>

        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button type="submit" className={styles.button} disabled={!stripe || this.state.makingPayment}>
          Sign up {this.state.subscriptionType === 'monthly' ? pricesMonthly[this.props.tier] : pricesAnnually[this.props.tier]}
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    routes: state.routes,
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setConnectivityData: data => { dispatch(setConnectivityData(data)) },
//     setSpeedTestData: data => { dispatch(setSpeedTestData(data)) },
//     setSpeedTestAverage7d: data => { dispatch(setSpeedTestAverage7d(data)) },
//     setDowntimeData: data => { dispatch(setDowntimeData(data)) },
//     setGeolocationData: data => { dispatch(setGeolocationData(data)) },
//     setTermsOfServiceModalDisplayed: data => { dispatch(setTermsOfServiceModalDisplayed(data)) },
//     setDownloadOS: data => { dispatch(setDownloadOS(data)) },
//     setStatsData: data => { dispatch(setStatsData(data)) },
//   }
// }

const ReduxCheckoutForm = withRouter(connect(mapStateToProps)(CheckoutForm))

const InjectedCheckoutForm = (notification, tier, setUser) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <ReduxCheckoutForm elements={elements} stripe={stripe} notification={notification} tier={tier} setUser={setUser} />
      )}
    </ElementsConsumer>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe.key);

const App = (props) => {
  return (
    <Elements stripe={stripePromise}>
      {InjectedCheckoutForm(props.notification, props.tier, props.setUser)}
    </Elements>
  );
};

export default App;

// COMPONENTS
import React, { useState, Component } from "react";

// MATERIAL UI CORES
import { DataGrid, GridMenu } from "@mui/x-data-grid";
import { Collapse, IconButton, Button, ButtonGroup, Card, CardContent, Grid, Typography, CardHeader, CardActionLocal, CardActions, CardMedia, Tooltip } from '@material-ui/core';
import styles from './LocalProviders.module.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from "@material-ui/core/styles";


export default function LocalProvidersList(data) {
    console.log('listdisplay', data.data)
    const list = data.data;
    const isps = list.map((list) => list.name)
    const flatIsps = isps.flat()
    const uniqueLocalProviders = [ ...new Set(flatIsps)]
    const zipcodes = list.map((list) => list.zipcode)

    return (
      <div>
        <Grid container spacing={1} justifycontent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableContainer component={Paper} style={{marginTop:'10px'}}>
              <Table size="small" aria-label="speed test data">
                <TableHead style={{backgroundColor: '#1D98FF'}}>
                  <TableRow>
                    <TableCell style={{color: '#fff', fontWeight: 'bold'}}>Providers in your area</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles['table-body']}>
                  {uniqueLocalProviders.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    )
}

import React, {useState} from 'react'
import NewCustomDataGrid from "../DataGrid/CustomDataGrid";

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import styles from './organizationUser.module.scss';
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';



export default function GroupProfilesTable(props) {
  const [linkText, setLinkText] = useState('');
  const handleLinkClick = (event) => {
    setLinkText(event.target.innerText);
    console.log('linkText', linkText)
  }
  const theme = props.theme
  const columns = [
    {
      field: 'edit',
      headerName: 'Edit',
      width: 50,
        renderCell: (props) => {
        return (
          <Link to={`/profilehostgroup/modify/${props.row.organization}/${props.row.id}`} className={styles.link}>
            <SettingsIcon />
          </Link>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Group Name',
      width: 200,
        renderCell: (props) => {
        return (
          <Link to={`/org/${props.row.organization}/group/${props.row.id}`} onClick={handleLinkClick}>
            <Typography style={{float: 'left', color: '#1d98ff'}} gutterBottom>{props.row.name}</Typography>
          </Link>
        );
      },
    },
    // Unnecessary right now because the download modal pre-populates the group ID in the download script
    // {
    //   field: 'group_id',
    //   headerName: 'Group ID',
    //   width: 260,
    //     renderCell: (props) => {
    //     return (
    //       <Typography style={{float: 'left'}} gutterBottom>{props.row._id || ''}</Typography>
    //     );
    //   },
    // },
    {
      field: 'hosts',
      headerName: 'Hosts',
      width: 100,
        renderCell: (props) => {
        return (
          <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
            {!!props.row.hostCount && !!props.row.hostCount ? props.row.hostCount.count : 0}
          </Typography>
        );
      },
    },
    // TODO-STEVEN: ISP column is not working due to the ispProfiles array stuff
    // {
    //   field: 'provider',
    //   headerName: 'ISP',
    //   width: 150,
    //     renderCell: (props) => {
    //       console.log('fuck', theme)
    //     return (
    //         <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
    //           {!props.row.ispProfiles[0] || !props.row.ispProfiles[0] ? '' : props.row.ispProfiles[0].providerId.name}
    //         </Typography>
    //     );
    //   },
    // },
    {
      field: 'hostsBelowDownloadSpeedThreshold',
      headerName: 'Breaching DL Speed Threshold',
      width: 205,
        renderCell: (props) => {
        return (
          <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
            {props.row.hostsBelowDownloadSpeedThreshold || 0}
          </Typography>
        );
      },
    },
    {
      field: 'hostsBelowUploadSpeedThreshold',
      headerName: 'Breaching UL Speed Threshold',
      width: 205,
        renderCell: (props) => {
        return (
          <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
            {props.row.hostsBelowUploadSpeedThreshold || 0}
          </Typography>
        );
      },
    },
    {
      field: 'hostsAboveLatencyThreshold',
      headerName: 'Breaching Average Latency Threshold',
      width: 205,
        renderCell: (props) => {
        return (
          <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
            {props.row.hostsAboveLatencyThreshold || 0}
          </Typography>
        );
      },
    },
    {
      field: 'hostsAbovePacketLossThreshold',
      headerName: 'Breaching Packet Loss Threshold',
      width: 240,
        renderCell: (props) => {
        return (
          <Typography style={{float: 'left', color: theme === 'dark' ?  '#f3f3f3' : '#303030'}} gutterBottom>
            {props.row.hostsAbovePacketLossThreshold || 0}
          </Typography>
        );
      },
    },
    {
      field: 'downloadSpeedThreshold',
      type: 'boolean',
      headerName: 'Slow DL Speed Notifications',
      width: 175,
        renderCell: (props) => {
        return !props.row.notifications.downloadSpeedThreshold
          ? <NotificationsOffIcon style={{ color: '#f08080' }} />
          : <NotificationsActiveIcon style={{ color: '#90ee90' }} />
      },
    },
    {
      field: 'uploadSpeedThreshold',
      type: 'boolean',
      headerName: 'Slow UL Speed Notifications',
      width: 175,
        renderCell: (props) => {
        return !props.row.notifications.uploadSpeedThreshold
          ? <NotificationsOffIcon style={{ color: '#f08080' }} />
          : <NotificationsActiveIcon style={{ color: '#90ee90' }} />
      },
    },
    {
      field: 'packetLossThreshold',
      type: 'boolean',
      headerName: 'P/L Notifications',
      width: 140,
        renderCell: (props) => {
        return !props.row.notifications.packetLossThreshold
          ? <NotificationsOffIcon style={{ color: '#f08080' }} />
          : <NotificationsActiveIcon style={{ color: '#90ee90' }} />
      },
    },
    {
      field: 'latencyThreshold',
      type: 'boolean',
      headerName: 'Latency Notifications',
      width: 175,
        renderCell: (props) => {
        return !props.row.notifications.latencyThreshold
          ? <NotificationsOffIcon style={{ color: '#f08080' }} />
          : <NotificationsActiveIcon style={{ color: '#90ee90' }} />
      },
    },
    {
      field: 'offlineNotifications',
      type: 'boolean',
      headerName: 'Offline Notifications',
      width: 150,
        renderCell: (props) => {
        return !props.row.notifications.offlineNotifications
          ? <NotificationsOffIcon style={{ color: '#f08080' }} />
          : <NotificationsActiveIcon style={{ color: '#90ee90' }} />
      },
    },
    // {
    //   field: 'default',
    //   type: 'boolean',
    //   headerName: 'Default Profile',
    //   width: 150,
    //     renderCell: (props) => {
    //     return !props.row.default ? '' : (
    //       <Chip label={'default' } color={'#90ee90'} />
    //     )
    //   },
    // },

  ]

  return !props.data ? null : (
    <div>
      <NewCustomDataGrid
        onSelectionModelChange={(newSelectionModel) => {
          props.setSelection(newSelectionModel)
        }}

        columns={columns}
        rows={props.data}
        disableDensitySelector='true'
        showToolbar
        checkboxSelection
        disableSelectionOnClick
        autoHeight='true'
      />
    </div>
  )
}

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './downloads.module.scss';

const HomePage = () => {
  return (
    <div>
      <div>
        <center>
        <p>
          The downloads page has been moved to support the new download scripts.
          You can now find the downloads at the bottom of the side menu.
        </p>
        <Link to="/" className={styles.link}>
          <button className={styles.button} styles={{maxWidth: '200px'}}>Go to Dashboard</button>
        </Link>
        </center>
      </div>
    </div>
  );
};


export default HomePage;

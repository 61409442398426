import React from 'react'
import Typography from '@material-ui/core/Typography';
import styles from './navigation.module.scss'

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default function ProfileInfo(props) {
  return (
    <div className={ styles.nav }>
      <div className={ styles.flexGrow }>
        {!props.authenticated ? <font></font> : (
          <font>
            <Typography variant="h5" gutterBottom style={{textAlign:'center',padding:'10px'}}>
              { !!props.user && props.user.name }
            </Typography>
            <Typography variant="body2" gutterBottom style={{textAlign:'center',padding:'10px'}}>
              Plan: { !!props.user && !!props.user.plan ? `${capitalize(props.user.plan)} Plan` : null }
              {!props.user || !props.user.stripe || !props.user.stripe.cancelAt ? null : (
              <p>
              Ends: {new Date(props.user.stripe.cancelAt).toLocaleDateString([], {
                      // weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
              </p>
              )}
            </Typography>
          </font>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import styles from './faq.module.scss'

export default function FAQ() {
  return (
    <div className={ styles.home }>
      <div>
        <h1>imUp.io FAQ</h1>
        <p><strong>Q: How do I set up imUp.io? </strong><br />A: First, you'll need to create an imUp.io account (you can also enter your internet service provider's information next if you want us to negotiate credits for you). Once your profile is complete, it's time to download the imUp.io app. Once downloaded, you will need to enter into the app the same email address you used to register with our service, then click Start imUp!. If you are an imUp Org user, you will need to enter your API key & a hostname prior to starting the app. </p>
        <p><strong>Q: What firewall configurations need to be made for imUp to work?</strong><br />A: imUp is different from other "monitoring" software in an important way. We do not require any special network configuration outside of the typical opening of TCP 53(NAT/DNS) and 443(HTTPS) to begin using imUp!</p>
        <p><strong>Q: What kind of device should I download imUp.io on?</strong><br />A: In order for imUp.io to collect accurate information, it should be downloaded on a device that is always up and running. If the device that imUp.io is on is off, then internet statistics cannot be collected and will show a lapse in data on your account online. With that said, it is best to download imUp.io on a stationary computer. A laptop that is often shut down and taken to work, school, etc would not be a great place to download imUp.io bas it would collect and reflect inaccurate data. Additionally, for the best speed-test data you should run imUp on a computer connected with Ethernet and not WiFi.</p>
        <p><strong>Q: Is imUp supported on all devices and operating systems? </strong><br />A: Right now we support Windows, MacOS, and Docker. We are actively working on Windows Server & Linux and expect releases soon!</p>
        <p><strong>Q: Is imUp always running and recording my internet uptime and speeds?</strong><br />A: The imUp app is always recording your uptime and outages, and speed tests are conducted at random intervals throughout the day.</p>
        <p><strong>Q: What happens when I first sign up for a paid imUp plan?</strong><br />A: Our base plans do not include any negotiation services, so as soon as you register you will immediately receive the added benefits for each plan respectively. If you sign up for the Integrity, Rise, or Org plans; one of our representatives will touch base to confirm authorization and gather any further details needed in order to conduct an initial account assessment - to see if there’s anything we can do right off the bat to improve your internet speed at no cost, or even save you money on your current speeds.</p>
        <p><strong>Q: How does imUp negotiate on my behalf? </strong><br />A: In order for imUp to negotiate on your behalf, you must list our business name as an authorized user on your account with your Internet Service Provider (ISP). If we are not listed as an authorized user, ISPs will not allow us to discuss your account, which means we can’t save you any money. </p>
        <p><strong>Q: How do I know if my internet goes offline? </strong><br />A: The imUp app will be keeping track of your connectivity as long as it is running and report all of your metrics to your Dashboard. If you are subscribed to the Lite plan or higher, you will receive an email notifying you that your internet was out and has come back online.</p>
        <p><strong>Q: How do I know if I’m getting degraded internet speed? </strong><br />A: Once logged in, you will see your speed statistics on your imUp.io dashboard. We compare this to the speed you tell us you’re paying for in your profile and let you know if services are degraded. Keep in mind, speeds received will vary and are really only reliable if testing on a computer connected with Ethernet and not running on WiFi.</p>
        <p><strong>Q: How do I request imUp to negotiate with my ISP for degraded performance?</strong><br />A: In order to have imUp negotiate on your behalf, you must be signed up for the Integrity plan or higher and have imUp.io listed as an authorized user on your ISP account. Visit your <a href="https://app.imup.io/profile" style={{color: '#1D98FF'}}>profile page</a> to ensure you have your provider information entered properly and that you are subscribed to either the <a href="https://app.imup.io/payments" style={{color: '#1D98FF'}}>Integrity Plan</a>, <a href="https://app.imup.io/payments" style={{color: '#1D98FF'}}>Rise Plan</a>, or <a href="https://app.imup.io/payments" style={{color: '#1D98FF'}}>Org Plan</a>. </p>
        <p><strong>Q: When will imUp negotiate for downtime credits/refunds? </strong><br />A: Unless you reach out to us sooner, under the Rise & Org plans imUp will negotiate your account at the end of each of your internet billing cycles. On the Integrity plan, we will negotiate as requested.</p>
        <p><strong>Q: Can I request negotiations sooner than the end of my billing cycle?</strong><br />A: Absolutely! If you experience frustrations from your internet service, you can request a negotiation by logging into your imUp.io account and reaching out by selecting the floating  “Help” button in the bottom right, or emailing support@imUp.io directly. If you’re experiencing inconveniences because of your internet service, we encourage you to reach out to us sooner to increase the odds of a successful negotiation. </p>
        <p><strong>Q: What happens when imUp successfully negotiates a refund/credit for my degraded service?</strong><br />A: An imUp.io representative will reach out to follow up with you, regardless of whether or not a refund/credit has been issued, after negotiating with your ISP. We’ll give you the rundown of the conversation and explain how the solution was reached. If your ISP issued a refund/credit, 30% of that will be due to imUp.io, and be debited as soon as an agreement with your provider is reached.</p>
        <p><strong>Q: When do I get billed for my successful negotiations?</strong><br />A: As soon as we confirm with your provider that a credit will be issued, we will automatically debit your card on file for 30% of the total credit or refund. </p>
        <p><strong>Q: How do I know the imUp app is running?</strong><br />A: While the app is running, you will see a menu icon in each respective operating system showing the status of the app. For Windows, it will be down in the start bar near your clock, and on Mac you will see the icon in the menu bar also near the clock. </p>
        <p><strong>Q: Could I be at fault for degraded internet service or is my ISP always responsible for my internet quality? </strong><br />A: Degraded internet service could absolutely be caused by something outside of your ISP’s control. The location of your modem and/or router, the type of connection (wifi vs. hardwired), the setup of your connected devices, or even power failures. We will communicate to understand the details of your setup and wants/needs in each negotiation. </p>
        <p><strong>Q: Who do I reach out to if I have any other questions or concerns? </strong><br />A: You can always reach out to support@imUp.io. We’re happy to help!</p>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Paper, Grid, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './organizationUser.module.scss';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@mui/material/Alert';
import IPCIDR from 'ip-cidr';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/prism';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '75%',
    height: '80%',
    overflow: 'auto'
  },
  MuiAccordionDetails: {
    display: 'block'
  },
  checkbox: {
    color: 'red',
    '&$checked': {
      color: 'red',
    },
  },
  checkboxGreen: {
    color: 'green',
    '&$checked': {
      color: 'green',
    },
  },
  checked: {},
}));



export default function Download(data) {
  const classes = useStyles();

  // Functional modal state
  const [open, setOpen] = useState(false);
  const [expandedPing, setExpandedPing] = useState(false);
  const [expandedSpeedTesting, setExpandedSpeedTesting] = useState(false);
  const [expandedAlternativeInstallation, setExpandedAlternativeInstallation] = useState(false);

  // Parameter state
  const nonOrgUserEmail = data.data.user.email
  const [os, setOs] = useState('');
  const [group, setGroup] = useState(data.group);
  const [groupOptions, setGroupOptions] = useState([]);
  const [email, setEmail] = useState('');
  const [endpointId, setEndpointId] = useState('');
  const [copied, setCopied] = useState('');
  const [uninstall, setUninstall] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [tos, setTos] = useState(false);
  const [allowlistedIPs, setAllowlistedIPs] = useState('');
  const [blocklistedIPs, setBlocklistedIPs] = useState('');
  const [SpeedTestEnabled, setSpeedTestEnabled] = useState('true');
  const [ImUpExecutablePath, setImUpExecutablePath] = useState('');
  const [InstallationScriptPath, setInstallationScriptPath] = useState('');
  const [PingEnabled, setPingEnabled] = useState('true');
  const [PingAddressExternal, setPingAddressExternal] = useState('');
  const [PingAddressInternal, setPingAddressInternal] = useState('');
  const [PingDelay, setPingDelay] = useState('');
  const [PingInterval, setPingInterval] = useState('');
  const [PingRequests, setPingRequests] = useState('');
  const [errorAllowListedIPs, setErrorAllowListedIPs] = useState(false);
  const [errorBlockListedIPs, setErrorBlockListedIPs] = useState(false);
  const [errorPingAddressesIPs, setErrorPingAddressesIPs] = useState(false);
  const [errorPingSingleAddressIPs, setErrorPingSingleAddressIPs] = useState(false);
  const [errorImUpExecutablePath, setErrorImUpExecutablePath] = useState(false);
  const [errorInstallationScriptPath, setErrorInstallationScriptPath] = useState(false);


  // Functional modal state functions
  const handleCopy = () => {
    navigator.clipboard.writeText(script);
    setCopied('Copied to clipboard!');
    setTimeout(() => setCopied(''), 2000);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePingClick = () => {
    setExpandedPing(!expandedPing);
  };

  const handleSpeedTestingClick = () => {
    setExpandedSpeedTesting(!expandedSpeedTesting);
  };

  const handleAlternativeInstallationClick = () => {
    setExpandedAlternativeInstallation(!expandedAlternativeInstallation);
  };


  // Parameter state functions
  const handleOsChange = (event) => {
    setOs(event.target.value);
  };

  const handleGroupChange = (event) => {
    // this will only match one so will only call setGroup once
    groupOptions
      .filter(group => group.id === event.target.value)
      .map(group => setGroup(group))
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEndpointIdChange = (event) => {
    setEndpointId(event.target.value);
  };

  ////////////////////////
  /// HANDLE IP INPUTS ///
  ////////////////////////
  const handleImUpExecutablePathChange = (e) => {
    const value = e.target.value;
    setImUpExecutablePath(value);

    // check if proper file path
    // const isValid = isValidFilePath(value);
    // setErrorImUpExecutablePath(!isValid);
  };
  const handleInstallationScriptPathChange = (e) => {
    const value = e.target.value;
    setInstallationScriptPath(value);

    // check if proper file path
    // const isValid = isValidFilePath(value);
    // setErrorInstallationScriptPath(!isValid);
  };

  const handleAllowlistedIPsChange = (e) => {
    const value = e.target.value;
    setAllowlistedIPs(value);

    // Validate the input and update the error state
    const isValid = isValidCIDR(value);
    setErrorAllowListedIPs(!isValid);
  };
  const handleBlocklistedIPsChange = (e) => {
    const value = e.target.value;
    setBlocklistedIPs(value);

    // Validate the input and update the error state
    const isValid = isValidCIDR(value);
    setErrorBlockListedIPs(!isValid);
  };
  const handleIpAddressesChange = (e) => {
    const value = e.target.value;
    setPingAddressExternal(value);

    // Validate the input and update the error state
    const isValid = isValidCIDR(value);
    setErrorPingAddressesIPs(!isValid);
  };
  const handleSingleIpAddressChange = (e) => {
    const value = e.target.value;
    setPingAddressInternal(value);

    // Validate the input and update the error state
    const isValid = isValidIPAddress(value);
    setErrorPingSingleAddressIPs(!isValid);
  };

  ////////////////////////
  // VALIDATE IP INPUTS //
  ////////////////////////

  const isValidCIDR = (value) => {
    // Splitting the value by comma and checking each CIDR
    const cidrList = value.split(',');
    for (const cidr of cidrList) {
      if (!IPCIDR.isValidCIDR(cidr.trim())) {
        return false;
      }
    }
    return true;
  };

  const isValidIPAddress = (value) => {
    // CIDR is not valid for our single IP fields
    if (IPCIDR.isValidCIDR(value.trim()) || !IPCIDR.isValidAddress(value.trim())) {
      return false;
    }
    return true
  };

  const disabledButton = data.disabled
  const orgName = data.data.user.organizations[0].name
  const apiKeys = data.data.user.organizations[0].apiKeys
  const key = Array.isArray(apiKeys) && apiKeys.length > 0 ? apiKeys[0].key : null;
  //   const email = data.data.user.email
  const getKey = (key) => `${key ? ` API_KEY="${key}"` : ''}`;
  const getEmail = (email) => `${email ? ` EMAIL="${email}"` : ''}`;
  const getGroupParam = (group) => `${group ? ` GROUP_ID="${group.id}"` : ''}`;
  const getEndpointIdParam = (endpointId) => `${endpointId ? ` HOST_ID="${endpointId}"` : ''}`;
  const getUninstallParam = (uninstall) => `${uninstall ? ' UNINSTALL="true"' : ''}`;
  const getUpgradeParam = (upgrade) => `${upgrade ? ' UPGRADE="true"' : ''}`;
  const getTosParam = (tos) => `${tos ? ' ACCEPT_THE_TOS="yes"' : ''}`;
  const getImUpExecutablePathParam = (ImUpExecutablePath) => `${ImUpExecutablePath ? ` IMUP_EXECUTABLE_PATH="${ImUpExecutablePath}"` : ''}`;
  const getAllowlistedIPsParam = (allowlistedIPs) => `${allowlistedIPs ? ` ALLOWLISTED_IPS="${allowlistedIPs}"` : ''}`;
  const getBlocklistedIPsParam = (blocklistedIPs) => `${blocklistedIPs ? ` BLOCKLISTED_IPS="${blocklistedIPs}"` : ''}`;
  const getSpeedTestEnabledParam = (SpeedTestEnabled) => `${SpeedTestEnabled ? '' : ' NO_SPEED_TEST="true"'}`;
  const getPingEnabledParam = (PingEnabled) => `${PingEnabled ? '' : ' PING_ENABLED="false"'}`;
  const getPingAddressesExternalParam = (PingAddressesExternal) => `${PingAddressesExternal ? ` PING_ADDRESS="${PingAddressesExternal}"` : ''}`;
  const getPingAddressInternalParam = (PingAddressInternal) => `${PingAddressInternal ? ` PING_ADDRESS_INTERNAL="${PingAddressInternal}"` : ''}`;
  const getPingDelayParam = (PingDelay) => `${PingDelay ? ` PING_DELAY="${PingDelay}"` : ''}`;
  const getPingIntervalParam = (PingInterval) => `${PingInterval ? ` PING_INTERVAL="${PingInterval}"` : ''}`;
  const getPingRequestsParam = (PingRequests) => `${PingRequests ? ` PING_REQUESTS="${PingRequests}"` : ''}`;


  const getScript = (os, group) => {
    const installScriptMacLinux = `bash -c "${InstallationScriptPath || '$(curl -L https://storage.googleapis.com/imup-binaries/public/install/darwin-linux-install.sh)'}"`
    const installScriptWindows = InstallationScriptPath ? `Set-ExecutionPolicy Unrestricted -Scope CurrentUser -Force; & '${InstallationScriptPath}'` : 'Set-ExecutionPolicy RemoteSigned -Scope Process -Force; [Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; iwr -useb https://storage.googleapis.com/imup-binaries/public/install/windows-install.ps1 | iex'

    // Cleaning this up to make it modular rather than declaring each variable for each OS
    const keyParam = getKey(key);
    const emailParam = getEmail(email);
    const groupParam = getGroupParam(group);
    const endpointIdParam = getEndpointIdParam(endpointId);
    const uninstallParam = getUninstallParam(uninstall);
    const upgradeParam = getUpgradeParam(upgrade);
    const tosParam = getTosParam(tos);
    const imUpExecutablePathParam = getImUpExecutablePathParam(ImUpExecutablePath);
    const allowlistedIPsParam = getAllowlistedIPsParam(allowlistedIPs);
    const blocklistedIPsParam = getBlocklistedIPsParam(blocklistedIPs);
    const SpeedTestEnabledParam = getSpeedTestEnabledParam(SpeedTestEnabled);
    const PingEnabledParam = getPingEnabledParam(PingEnabled);
    const PingAddressesExternalParam = getPingAddressesExternalParam(PingAddressExternal);
    const PingAddressInternalParam = getPingAddressInternalParam(PingAddressInternal);
    const PingDelayParam = getPingDelayParam(PingDelay);
    const PingIntervalParam = getPingIntervalParam(PingInterval);
    const PingRequestsParam = getPingRequestsParam(PingRequests);

    const getEnvVars = () => {
      const linuxOrMac = ['Arch Linux ARM', 'Linux', 'MacOS']
      // TODO-BUG: ask brad what we want this to do exactly / if this should be a ternary on emailParam
      const keyOrEmailParam = keyParam ? keyParam : ` EMAIL="${nonOrgUserEmail}"`

      if (linuxOrMac.includes(os)) {
        // jam all the env vars together if not uninstalling
        return uninstallParam ? `UNINSTALL=true ${installScriptMacLinux}`
             : upgradeParam   ? `UPGRADE=true${imUpExecutablePathParam}${tosParam} ${installScriptMacLinux}`
             : `${keyOrEmailParam.trimStart()}${endpointIdParam}${groupParam}${keyParam ? emailParam : ''}${imUpExecutablePathParam}${SpeedTestEnabledParam}${allowlistedIPsParam}${blocklistedIPsParam}${PingEnabledParam}`
               + `${PingAddressesExternalParam}${PingAddressInternalParam}${PingDelayParam}${PingIntervalParam}${PingRequestsParam}${tosParam}`
               + ` ${installScriptMacLinux}`
      }

      if (os === 'Windows') {
        const wEnv = (param) => param ? ` $env:${param.trim()};` : ''

        // jam all the env vars together if not uninstalling
        return uninstallParam ? `$env:UNINSTALL="true"; ${installScriptWindows}`
             : upgradeParam   ? `$env:UPGRADE="true";${wEnv(imUpExecutablePathParam)}${wEnv(tosParam)} ${installScriptWindows}`
             : `${wEnv(keyOrEmailParam).trimStart()}${wEnv(endpointIdParam)}${wEnv(groupParam)}${wEnv(imUpExecutablePathParam)}${wEnv(keyParam ? emailParam : '')}${wEnv(SpeedTestEnabledParam)}${wEnv(allowlistedIPsParam)}${wEnv(blocklistedIPsParam)}${wEnv(PingEnabledParam)}`
               + `${wEnv(PingAddressesExternalParam)}${wEnv(PingAddressInternalParam)}${wEnv(PingDelayParam)}${wEnv(PingIntervalParam)}${wEnv(PingRequestsParam)}${wEnv(tosParam)}`
               + ` ${installScriptWindows}`
      }

      if (os === 'Docker') {
        const dEnv = (param) => param ? ` -e ${param.trim()}` : ''

        // jam all the env vars together if not uninstalling
        return uninstallParam ? `docker rm -f imup; docker rmi imup/client`
          : `docker run -d --restart unless-stopped --name imup ${dEnv(keyOrEmailParam).trimStart()}${dEnv(endpointIdParam)}${dEnv(groupParam)}${dEnv(keyParam ? emailParam : '')}${dEnv(SpeedTestEnabledParam)}${dEnv(allowlistedIPsParam)}${dEnv(blocklistedIPsParam)}${dEnv(PingEnabledParam)}`
            + `${dEnv(PingAddressesExternalParam)}${dEnv(PingAddressInternalParam)}${dEnv(PingDelayParam)}${dEnv(PingIntervalParam)}${dEnv(PingRequestsParam)}${dEnv(tosParam)}`
            + ` imup/client:latest`
      }

      return ''
    }

    return getEnvVars().trim()
  };

  const getDisclaimer = (os) => {
    switch (os) {
      case 'Linux':
        return `Sudo is required so we can add imUp to systemD.`;
      case 'Windows':
        return `Administrative powershell is required to install imUp as a windows service`;
      case 'MacOS':
        return `Sudo is required so we can create a .plist file which allows imUp to run at computer start-up.`;
      case 'Docker':
        return (
          <span>
            <Link to="/DockerInstallation" target="_blank" style={{ color: '#1D98FF', cursor:'pointer' }}>
              Install instructions
            </Link>
          </span>
        );
      default:
        return '';
    }
  };

  const script = getScript(os, group); // call the getScript() function to generate the script
  const disclaimer = getDisclaimer(os, group); // call the getDisclaimer() function to generate the disclaimer
  const language = os === 'Windows' ? 'powershell' : 'bash'; // use ternary operator to set the language based on the OS

  useEffect(() => {
    // Set the group options from the data array
    setGroupOptions(data.data.orgGroups);
  }, [data]);




  return (
    <div>
      { disabledButton ?
        <center>
          <Tooltip title="Please create an API key and org group prior to adding endpoints to your org!">
            <span><Button className={styles.buttonAction} disabled style={{margin: 'none !important'}} onClick={handleOpen}>Add endpoints</Button></span>
          </Tooltip>
        </center>
      :
        <center><Button className={styles.buttonAction} style={{margin: 'none !important'}} onClick={handleOpen}>Add endpoints</Button></center>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="download-modal-title"
        aria-describedby="download-modal-description"
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <h2 id="download-modal-title">Add endpoints</h2>
          <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '20px' }}>
            <Grid item xs={8}>
                <div style={{ maxHeight: 'calc(100vh - 500px)', overflow: 'auto' }}>
                <p id="download-modal-description">Download the imUp client for your operating system and configure additional parameters. <a href="https://github.com/imup-io/client" target="_blank" style={{color:"#1d98ff"}}>Read about our client here</a><br />
                </p>
                </div>
            </Grid>
            </Grid>
            <Divider style={{marginBottom:'20px'}}/>
          <Grid container spacing={1}>
            <Grid item xs={3}>
            <div style={{ maxHeight: 'calc(100vh - 450px)', overflow: 'auto', paddingRight: '24px' }}>
                <Typography variant="body" gutterBottom>
                    <center><strong>Common</strong></center>
                </Typography>
                <br />
                <br />
              <TextField
                variant="outlined"
                select
                label="Operating System"
                value={os}
                onChange={handleOsChange}
                fullWidth
              >
                <MenuItem value="Linux">Linux</MenuItem>
                <MenuItem value="Windows">Windows</MenuItem>
                <MenuItem value="MacOS">MacOS</MenuItem>
                <MenuItem value="Docker">Docker</MenuItem>
                <MenuItem value="Arch Linux ARM">Arch Linux ARM</MenuItem>
              </TextField>

              <br />
              <br />
              {Array.isArray(groupOptions) && groupOptions.length > 0 || data.groupName ? (
                  <TextField
                    variant="outlined"
                    select
                    label="Group"
                    defaultValue={!!group ? group.id : null}
                    onChange={handleGroupChange}
                    fullWidth
                    sx={{ marginTop: '0 !important' }}
                  >
                    {groupOptions.map((groupOption) => (
                      <MenuItem key={groupOption.id} value={groupOption.id}>
                        {groupOption.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  null
                )}
                <br />
                <br />
                {Array.isArray(apiKeys) && apiKeys.length > 0 ? (
                    <TextField
                      variant="outlined"
                      label="Email"
                      // value={email}
                      onChange={handleEmailChange}
                      fullWidth
                      sx={{ mt: 0 }}
                      InputProps={{
                          endAdornment: (
                              <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="This email can login to see this hosts's performance metrics. Primarily so remote users / office managers can see their specific metrics without having access to the whole org. (Supports RMM variables like %user.Email%)">
                              <InputAdornment
                                  position="start"
                                  className={styles.adornment}

                              >
                                  <InfoIcon className={styles.infoIcon}/>
                              </InputAdornment>
                              </Tooltip>
                          )
                      }}
                    >
                      {email}
                    </TextField>
                ) : (
                    ''
                )}
                <br />
                    <br />
                    <TextField
                      variant="outlined"
                      label="Endpoint ID"
                      onChange={handleEndpointIdChange}
                      fullWidth
                      sx={{ mt: 0 }}
                      InputProps={{
                          endAdornment: (
                              <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="This is the ID that this endpoint will report under. Leaving this blank will cause the client to use the default machine name. Similarly, we recommend using a RMM variable if you don't want to assume default machine name.">
                              <InputAdornment
                                  position="start"
                                  className={styles.adornment}

                              >
                                  <InfoIcon className={styles.infoIcon}/>
                              </InputAdornment>
                              </Tooltip>
                          )
                      }}
                    >
                    {endpointId}
                </TextField>
                <br />
                <br />
                <Divider/>
                <br />
                <Typography variant="body" gutterBottom>
                    <center><strong>Advanced</strong></center>
                </Typography>
                <br />
                <br />
                <Accordion
                    expanded={expandedSpeedTesting}
                    elevation={0}
                    style={{display: 'block !important'}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={handleSpeedTestingClick}
                        aria-controls="speed-testing-content"
                        id="speed-testing-header"
                    >
                        <Typography>Speed Testing</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.MuiAccordionDetails}>
                        <FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                color='info'
                                defaultChecked
                                checked={SpeedTestEnabled}
                                onChange={(event) => setSpeedTestEnabled(event.target.checked)}
                                className={classes.checkboxGreen}
                                />
                            }
                            label={
                                <span>
                                  Enable Speed Testing
                                </span>
                              }
                        />
                        </FormControl>
                        <TextField
                          variant="outlined"
                          label="Allow tests from IPs"
                          value={allowlistedIPs}
                          onChange={handleAllowlistedIPsChange}
                          error={errorAllowListedIPs}
                          helperText={errorAllowListedIPs ? `Invalid CIDR format.${isValidIPAddress(allowlistedIPs) ? ' Add /32 to a valid IP to target a single address.' : ''}` : ''}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="imUp will check your public IP address prior to running each speed test. Enter a comma-separated list of CIDRs to allow speed tests to run from. (Ex: 1.2.3.4/24,22.33.44.0/16)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                        <br />
                        <br />
                        <TextField
                          variant="outlined"
                          label="Block tests from IPs"
                          value={blocklistedIPs}
                          onChange={handleBlocklistedIPsChange}
                          error={errorBlockListedIPs}
                          helperText={errorBlockListedIPs ? `Invalid CIDR format.${isValidIPAddress(blocklistedIPs) ? ' Add /32 to a valid IP to target a single address.' : ''}` : ''}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="imUp will check your public IP address prior to running each speed test. Enter a comma-separated list of CIDRs that speed tests should not run from. (Ex: 1.2.3.4/24,22.33.44.0/16)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expandedPing}
                    elevation={0}
                    sx={{
                        '&:before': {
                            display: 'none',
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={handlePingClick}
                        aria-controls="ping-content"
                        id="ping-header"
                        sx={{
                            '&:before': {
                                backgroundColor: '#1d98ff'
                            }
                        }}
                    >
                        <Typography>Ping</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.MuiAccordionDetails}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                defaultChecked
                                color='info'
                                checked={PingEnabled}
                                onChange={(event) => setPingEnabled(event.target.checked)}
                                className={classes.checkboxGreen}
                                />
                            }
                            label="Enable pings"
                        />
                        <TextField
                          variant="outlined"
                          label="Ping Addresses (external)"
                          value={PingAddressExternal}
                          onChange={handleIpAddressesChange}
                          error={errorPingAddressesIPs}
                          helperText={errorPingAddressesIPs ? `Invalid CIDR format.${isValidIPAddress(PingAddressExternal) ? ' Add /32 to a valid IP to target a single address.' : ''}` : ''}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="Enter a comma-separated list of IP addresses these endpoints will ping to measure latency and packet loss. (Supports internal & external targets, default: 1.1.1.1,1.0.0.1,8.8.8.8,8.8.4.4)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                        <br />
                        <br />
                        <TextField
                          variant="outlined"
                          label="Ping Address (internal)"
                          value={PingAddressInternal}
                          onChange={handleSingleIpAddressChange}
                          error={errorPingSingleAddressIPs}
                          helperText={errorPingSingleAddressIPs ? 'Invalid IP address' : ''}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="Enter an IP address that the endpoints will ping if the 'Ping Addresses' fail to respond; generally a network gateway. Used to help determine if an outage is WAN vs LAN. This is automatically discovered if left empty">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                        {/* <br />
                        <br />
                        <TextField
                          variant="outlined"
                          label="Ping Delay"
                          value={PingDelay}
                          onChange={(e) => setPingDelay(e.target.value)}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="Time between pings in milliseconds. (Default 100)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                        <br />
                        <br />
                        <TextField
                          variant="outlined"
                          label="Ping Interval"
                          value={PingInterval}
                          onChange={(e) => setPingInterval(e.target.value)}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="Ping intervals in seconds. Used to group ping results into blocks that are sent to your dashboard. (Default 60)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        />
                        <br />
                        <br />
                        <TextField
                          variant="outlined"
                          label="Ping Requests"
                          value={PingRequests}
                          onChange={(e) => setPingRequests(e.target.value)}
                          fullWidth
                          InputProps={{
                              endAdornment: (
                                  <Tooltip componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: 'common.black',
                                      '& .MuiTooltip-arrow': {
                                        color: 'common.black',
                                      },
                                    },
                                  },
                                }}title="Number of requests in each test. (Default 600)">
                                  <InputAdornment
                                      position="end"
                                      sx={{boxShadow: 0, cursor: 'pointer'}}
                                  >
                                      <InfoIcon className={styles.infoIcon}/>
                                  </InputAdornment>
                                  </Tooltip>
                              )
                          }}
                        /> */}
                    </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expandedAlternativeInstallation}
                  elevation={0}
                  sx={{
                    '&:before': {
                      display: 'none',
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleAlternativeInstallationClick}
                    aria-controls="alternative-installation-content"
                    id="alternative-installation-header"
                    sx={{
                      '&:before': {
                        backgroundColor: '#1d98ff'
                      }
                    }}
                  >
                      <Typography>Alternative Installation</Typography>
                  </AccordionSummary>

                  <AccordionDetails className={classes.MuiAccordionDetails}>
                    <TextField
                      variant="outlined"
                      label="imUp executable path"
                      value={ImUpExecutablePath}
                      onChange={handleImUpExecutablePathChange}
                      // error={errorImUpExecutablePath}
                      // helperText={errorImUpExecutablePath ? `Invalid executable path.` : ''}
                      fullWidth
                      InputProps={{
                          endAdornment: (
                              <Tooltip componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'common.black',
                                  '& .MuiTooltip-arrow': {
                                    color: 'common.black',
                                  },
                                },
                              },
                            }}title="Enter a local path to install imUp from. Must be a raw executable, not an archive. Example: 'C:\File-Srv\share\executables\imUp.exe'">
                              <InputAdornment
                                  position="end"
                                  sx={{boxShadow: 0, cursor: 'pointer'}}
                              >
                                  <InfoIcon className={styles.infoIcon}/>
                              </InputAdornment>
                              </Tooltip>
                          )
                      }}
                    />
                    <br />
                    <br />
                  </AccordionDetails>

                  <AccordionDetails className={classes.MuiAccordionDetails}>
                    <TextField
                      variant="outlined"
                      label="Installation script path"
                      value={InstallationScriptPath}
                      onChange={handleInstallationScriptPathChange}
                      // error={errorInstallationScriptPath}
                      // helperText={errorInstallationScriptPath ? `Invalid path.` : ''}
                      fullWidth
                      InputProps={{
                          endAdornment: (
                              <Tooltip componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: 'common.black',
                                  '& .MuiTooltip-arrow': {
                                    color: 'common.black',
                                  },
                                },
                              },
                            }}title="Enter a local path to an imUp installation script. Example: 'C:\File-Srv\share\installers\imup-installer.ps1'">
                              <InputAdornment
                                  position="end"
                                  sx={{boxShadow: 0, cursor: 'pointer'}}
                              >
                                  <InfoIcon className={styles.infoIcon}/>
                              </InputAdornment>
                              </Tooltip>
                          )
                      }}
                    />
                    <br />
                    <br />
                  </AccordionDetails>
                </Accordion>
                </div>
            </Grid>
            <div>
              <Divider orientation="vertical" style={{ height: '100%' }} />
            </div>
            <Grid item xs={8}  style={{marginTop:'35px', marginLeft: '30px'}}>
            { !os ?
                        <Alert severity="info" ><p>Select an operating system to generate the download script.</p></Alert>
                    :
                    <div>
                        <SyntaxHighlighter
                            language={language}
                            style={nightOwl}
                            wrapLongLines={true}
                        >
                            {script}
                        </SyntaxHighlighter>
                        <Alert severity="info" style={{marginBottom:'10px'}}>
                        {disclaimer}
                        </Alert>
                        { tos || uninstall ?
                          <button className={styles.button} style={{marginTop: '10px !important'}}  variant="contained" onClick={handleCopy}>Copy script</button>
                          :
                          <button className={styles.buttonDisabled} style={{marginTop: '10px !important'}}  variant="contained" disabled>Copy script</button>
                        }
                        {copied && <span style={{ color: 'green', marginLeft: 10, maringTop: 10 }}>{copied}</span>}
                        { !!uninstall ? null :
                          <FormControlLabel
                            control={
                            <Checkbox
                                checked={tos}
                                color='error'
                                onChange={(event) => setTos(event.target.checked)}
                                className={classes.checkboxGreen}
                            />
                            }
                            label="Agree to TOS"
                            style={{ float: 'right' }}
                          />
                        }
                        { !!uninstall ? null :
                        <FormControlLabel
                          control={
                          <Checkbox
                              checked={upgrade}
                              color='error'
                              onChange={(event) => setUpgrade(event.target.checked)}
                              className={classes.checkboxGreen}
                          />
                          }
                          label="Upgrade"
                          style={{ float: 'right' }}
                        />
                        }
                        <FormControlLabel
                          control={
                          <Checkbox
                              checked={uninstall}
                              color='error'
                              onChange={(event) => setUninstall(event.target.checked)}
                              className={classes.checkbox}
                          />
                          }
                          label="Uninstall"
                          style={{ float: 'right' }}
                      />
                    </div>
                  }
            </Grid>
            <Grid item xs={12}  style={{marginTop:'35px', marginLeft: '30px'}}>
              <button style={{ float: 'right' }} onClick={handleClose}>Close</button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}
